import { Flex } from "@chakra-ui/react";
import CircularProgress from "components/progress/CircularProgress";
import { observer } from "mobx-react";
import { Suspense, useEffect } from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { useMount } from "react-use";
import { FeatureFlagModal } from "routes/FeatureFlagModal";
import { useStores } from "stores";
import history from "utils/history";
import {
  LATEST_CAMPAIGN,
  STORAGE_TYPE,
  storeDirectlyIntoStorage,
  URL_SEARCH_PARAMS,
} from "utils/localStorage";
import Logger from "utils/logger";
import { ErrorRedirect } from "./AuthRoute";
import { ExperimentsModal } from "./ExperimentModal";
import { Layout } from "./Layout";
import {
  noLayoutRoutes,
  privateRoutes,
  publicRoutes,
  publicRoutesSecondary,
  redirectRoutes,
} from "./Routes";
import { redirects, routes } from "./routeUtils";
import { ApiBlocker } from "./ApiBlocker";

history.listen(() => {
  window.scrollTo(0, 0);
});

export const Routes = observer(() => {
  const { userStore } = useStores();
  const { currentId, currentUser, currentEmail } = userStore;

  useMount(() => {
    const query = new URLSearchParams(document.location.search);

    // Prioritize campaign over utm_campaign until we remove campaign.
    const queryCampaign =
      query?.get?.("campaign") || query?.get?.("utm_campaign");

    if (queryCampaign) {
      const campaignItems = queryCampaign
        ?.replace(" ", "")
        ?.split(",")
        .filter((s) => s);
      storeDirectlyIntoStorage(
        LATEST_CAMPAIGN,
        campaignItems,
        URL_SEARCH_PARAMS,
        STORAGE_TYPE.LOCAL,
      );
    }
  });

  useEffect(() => {
    const person = {
      id: currentId,
      username: currentUser,
      email: currentEmail,
    };

    Logger.setUser(person);
  }, [currentId, currentUser, currentEmail]);

  return (
    <Router history={history}>
      <Layout>
        <ApiBlocker />
        <ExperimentsModal />
        <FeatureFlagModal />
        <Route
          render={({ location }) => (
            <Suspense
              fallback={
                <Flex
                  w="100%"
                  h="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  <CircularProgress size="lg" />
                </Flex>
              }
            >
              <Flex
                direction="column"
                position="relative"
                height="100%"
                overflow="auto"
              >
                <Switch location={location}>
                  <Redirect
                    from="/:url*(/+)"
                    to={location.pathname.slice(0, -1)}
                  />
                  {[
                    ...routes(privateRoutes)(),
                    ...routes(noLayoutRoutes)(),
                    ...routes(publicRoutesSecondary)(),
                    ...routes(publicRoutes)(),
                    ...redirects(redirectRoutes)(),
                  ]}
                  <ErrorRedirect />
                </Switch>
              </Flex>
            </Suspense>
          )}
        />
      </Layout>
    </Router>
  );
});
