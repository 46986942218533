import { ComponentStyleConfig } from "@chakra-ui/theme";
import { SystemStyleObject } from "@chakra-ui/react";

const getColorStyles = (color: string) => {
  return {
    bg: color,
    borderColor: color,
  };
};

const baseStyleControl: SystemStyleObject = {
  position: "relative",
  borderColor: "grayscale.700",
  borderRadius: "4px",
  top: "1px",
  _focus: {
    boxShadow: "none",
  },
  _disabled: {
    color: "grayscale.600",
    borderColor: "grayscale.400",
    bg: "white",
    cursor: "default",
  },
  _checked: {
    ...getColorStyles("primary.900"),
    color: "grayscale.0",
    _hover: {
      ...getColorStyles("primary.900"),
    },
    _focus: {
      boxShadow: "none",
    },
    _disabled: {
      color: "grayscale.0",
      borderColor: "grayscale.300",
      bg: "grayscale.300",
      cursor: "default",
    },
  },
  _indeterminate: {
    ...getColorStyles("primary.900"),
    color: "grayscale.0",
  },
};

const baseStyleLabel: SystemStyleObject = {
  _disabled: {
    opacity: 1,
    cursor: "default",
  },
};

const baseStyleIcon: SystemStyleObject = {
  transitionProperty: "opacity, transform",
  transitionDuration: (theme) => theme.transition.duration.extraFast,
  transitionTimingFunction: (theme) => theme.transition.easing.easeOut,
};

export const Checkbox: ComponentStyleConfig = {
  baseStyle: {
    control: baseStyleControl,
    label: baseStyleLabel,
    icon: baseStyleIcon,
  },
};
