import React from "react";
import { runInAction, makeAutoObservable } from "mobx";
import { timescaleTheme } from "theme";
import { Snackbar } from "components/snackbar/Snackbar";
import { createStandaloneToast } from "@chakra-ui/toast";
import { ChakraAlertStatus } from "types";

type ToastType = "success" | "warning" | "error";
type NotificationBannerProps = {
  show: boolean;
  message?: string | (() => JSX.Element);
  alertType?: ChakraAlertStatus;
  type?: any;
  canDismiss?: boolean;
};

const { toast } = createStandaloneToast({ theme: timescaleTheme });

export class NotificationStore {
  alert: NotificationBannerProps = {
    show: false,
    message: "",
    alertType: "info",
    type: "",
    canDismiss: false,
  };

  constructor() {
    makeAutoObservable(this);
  }

  showSuccessToaster = (message: string) => {
    this._makeToast({ type: "success", message });
  };

  showInfoToaster = (message: string) => {
    this._makeToast({ type: "warning", message });
  };

  showErrorToaster = (message: string) => {
    this._makeToast({ type: "error", message });
  };

  _makeToast = ({ type, message }: { type: ToastType; message: string }) => {
    const toastIdRef = toast({
      position: "bottom-right",
      render: () =>
        React.createElement(
          Snackbar,
          { type, onClose: () => this._closeToast({ toastIdRef }) },
          message,
        ),
    });
  };

  _closeToast = ({ toastIdRef }: any) => {
    toast.close(toastIdRef);
  };

  setAlert({
    show,
    message = "",
    alertType = "info",
    type = "",
    canDismiss = false,
  }: NotificationBannerProps) {
    runInAction(
      () =>
        (this.alert = {
          show,
          message,
          alertType,
          type,
          canDismiss,
        }),
    );
  }
}

const notificationStore = new NotificationStore();

export default notificationStore;
