import Select, {
  components,
  DropdownIndicatorProps,
  OptionProps,
  SingleValueProps,
  ValueContainerProps,
} from "react-select";
import Icon, { IconProps } from "../icon/Icon";
import { ClosedTagProps, DropdownProps, OptionType } from "./dropdownTypes";
import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { useBreakpointValue } from "@chakra-ui/react";
import { dropdownCustomStyles } from "./dropdownStyles";
import { isNil } from "lodash";
import TagInfo from "components/tags/TagInfo/TagInfo";

const {
  Option,
  SingleValue: SingleValueComponent,
  DropdownIndicator,
  ValueContainer,
} = components;

const LabelWithDescription = ({
  label,
  description,
  isSelected,
  isDisabled,
}: {
  label: string;
  description: string;
  isSelected: boolean;
  isDisabled?: boolean;
}) => {
  return (
    <Flex flexDir="column">
      <Text
        color={
          isDisabled
            ? "grayscale.300"
            : isSelected
              ? "grayscale.800"
              : "grayscale.700"
        }
      >
        {label}
      </Text>
      <Box
        textStyle="caption"
        color={isDisabled ? "grayscale.300" : "grayscale.600"}
      >
        {description}
      </Box>
    </Flex>
  );
};

const CustomOption = (props: OptionProps<OptionType, false>) => {
  const { data, isSelected } = props;
  const {
    iconLeft,
    iconLeftCustomStyles,
    label,
    description,
    iconRight,
    showIconRightForSelectedOnly: _showIconRightForSelectedOnly,
    iconRightCustomStyles,
    isDisabled,
    tag,
  } = data;
  const [isChecked, setIsChecked] = useState(isSelected);

  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    props.selectOption({ ...data });
    setIsChecked(!isChecked);
    e.stopPropagation();
    e.preventDefault();
  };

  const showIconRightForSelectedOnly = () => {
    if (!isNil(_showIconRightForSelectedOnly)) {
      return isSelected;
    } else {
      return true;
    }
  };

  return (
    <Box {...(!isDisabled && { onClick: onClick })}>
      <Option {...props}>
        <Flex w="100%">
          <Flex w="100%" alignItems="center" gap="8px">
            {iconLeft && <Icon name={iconLeft} sx={iconLeftCustomStyles} />}
            {description ? (
              <LabelWithDescription
                label={label}
                description={description}
                isSelected={isSelected}
                isDisabled={isDisabled}
              />
            ) : (
              label
            )}
          </Flex>
          {showIconRightForSelectedOnly() && iconRight && (
            <Icon name={iconRight} ml="auto" sx={iconRightCustomStyles} />
          )}
          {tag && (
            <TagInfo
              {...tag}
              label={tag.label}
              mx="3px"
              maxH="22px"
              whiteSpace="nowrap"
              minW="auto"
            />
          )}
        </Flex>
      </Option>
    </Box>
  );
};

const CustomSingleValue = (
  props: SingleValueProps<OptionType, false>,
  closedTag?: ClosedTagProps,
) => {
  const { data } = props;
  const { iconLeft, iconLeftCustomStyles, label } = data;
  return (
    <SingleValueComponent {...props}>
      <Flex w="100%" justifyContent="space-between" alignItems="center">
        <Flex>
          {iconLeft && (
            <Icon name={iconLeft} mr="8px" sx={iconLeftCustomStyles} />
          )}
          {label}
        </Flex>
        {closedTag && <TagInfo label={closedTag.label} {...closedTag.props} />}
      </Flex>
    </SingleValueComponent>
  );
};

interface CustomDropdownIndicatorProps
  extends DropdownIndicatorProps<OptionType, false> {
  iconFill?: IconProps["fill"];
}

export const CustomDropdownIndicator = ({
  iconFill = "grayscale.700",
  ...props
}: CustomDropdownIndicatorProps) => (
  <DropdownIndicator {...props}>
    <Icon name="actions/Chevron/Down" fill={iconFill} cursor="pointer" />
  </DropdownIndicator>
);

const CustomValueContainer = (
  props: ValueContainerProps<OptionType, false>,
) => {
  const smallScreen = useBreakpointValue({ base: true, md: false });
  const newProps = { ...props, smallScreen };
  const customIcon = props.selectProps?.inputIcon;
  return (
    <>
      {customIcon && <Icon name={customIcon} pl="8px" />}
      <ValueContainer {...newProps} />
    </>
  );
};

export const Dropdown = ({
  name,
  options,
  placeholder,
  defaultValue,
  isDisabled,
  onChange,
  components,
  closedTag,
  customStyles,
  ...rest
}: DropdownProps) => {
  const dropdownComponents = {
    Option: CustomOption,
    SingleValue: (props: any) => CustomSingleValue(props, closedTag),
    DropdownIndicator: CustomDropdownIndicator,
    ValueContainer: CustomValueContainer,
  };

  return (
    <Select
      classNamePrefix="Select"
      isSearchable={false}
      closeMenuOnScroll={true}
      closeMenuOnSelect={true}
      placeholder={placeholder}
      components={{
        ...dropdownComponents,
        ...components,
      }}
      options={options}
      defaultValue={defaultValue}
      isDisabled={isDisabled}
      onChange={onChange}
      hideSelectedOptions={false}
      styles={customStyles ? customStyles : dropdownCustomStyles}
      {...rest}
    />
  );
};

export default Dropdown;
