import { ComponentStyleConfig } from "@chakra-ui/theme";
import { SystemStyleFunction } from "@chakra-ui/theme-tools";

const variantLine: SystemStyleFunction = ({ theme }) => ({
  tablist: {
    borderBottom: "none",
  },
  tabpanel: {
    padding: "0",
  },
  tab: {
    borderBottom: "2px solid",
    borderColor: "grayscale.200",
    position: "relative",
    marginBottom: "-1px",
    ...theme.textStyles.base,
    color: "grayscale.700",
    _selected: {
      borderColor: "primary.900",
      ...theme.textStyles.baseBold,
    },
    _active: {
      bg: "unset",
    },
    _focus: {
      boxShadow: "none",
    },
    _hover: {
      borderColor: "grayscale.300",
      transitionProperty: "all",
      transitionDuration: theme.transition.durationMs.normal,
      _selected: {
        borderColor: "primary.900",
      },
    },
    _disabled: {
      cursor: "default",
      color: "grayscale.300",
      opacity: 1,
      _hover: {
        color: "grayscale.300",
        borderColor: "grayscale.200",
      },
    },
  },
});

const variantDark: SystemStyleFunction = ({ theme }) => ({
  tablist: {
    background: "grayscale.900",
    padding: "0",
    borderTopLeftRadius: "6px",
    borderTopRightRadius: "6px",
    gap: "2px",
  },
  tabpanel: {
    padding: "0",
  },
  tab: {
    position: "relative",
    borderBottom: "unset",
    marginBottom: "-1px",
    ...theme.textStyles.base,
    color: "grayscale.0",
    bgColor: "grayscale.900",
    borderTopLeftRadius: "6px",
    borderTopRightRadius: "6px",
    _after: {
      content: `""`,
      background: "primary.800",
      height: "3px",
      position: "absolute",
      bottom: "0",
      transitionProperty: "all",
      transitionDuration: theme.transition.durationMs.normal,
      left: "100%",
      right: "0",
    },
    _selected: {
      color: "grayscale.0",
      bgColor: "grayscale.700",
      ...theme.textStyles.baseBold,
      "~ button::after": {
        left: "0",
        right: "100%",
      },
      _after: {
        left: "0",
        right: "0",
      },
    },
    _active: {
      bg: "unset",
    },
    _focus: {
      boxShadow: "none",
    },
    _hover: {
      bgColor: "grayscale.800",
      _selected: {
        color: "grayscale.0",
      },
    },
    _disabled: {
      cursor: "default",
      borderBottom: "3px solid",
      borderColor: "grayscale.300",
      marginBottom: "-1px",
      color: "grayscale.300",
      opacity: 1,
      _hover: {
        ...theme.textStyles.base,
        color: "grayscale.300",
        bgColor: "unset",
        borderColor: "grayscale.300",
      },
    },
  },
});

export const Tabs: ComponentStyleConfig = {
  variants: {
    line: variantLine,
    dark: variantDark,
  },
};
