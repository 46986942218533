import {
  CSSObject,
  Flex,
  StyleProps,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs as ChakraTabs,
  useBreakpoint,
} from "@chakra-ui/react";
import Icon from "components/icon/Icon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { breakpoints } from "theme";

export interface InternalNavProps extends StyleProps {
  tabList: TabHeaderProp[];
  tabPanel: any;
  defaultIndex?: number;
  isFitted?: boolean;
  variant?: string;
  sx?: CSSObject;
  onChange?: (index: number) => void;
  setTabIndex?: number;
}

export interface TabHeaderProp {
  tab: string;
  isDisabled?: boolean;
  dataCy?: string;
}

const { sm, md, ...nonMobileBreakpoints } = breakpoints;

const Tabs = ({
  tabList = [],
  tabPanel,
  defaultIndex,
  isFitted = true,
  variant = "line",
  sx,
  onChange,
  setTabIndex,
  ...customStyles
}: InternalNavProps) => {
  const breakPt = useBreakpoint();
  const isLargeScreen = useMemo(
    () => Object.keys(nonMobileBreakpoints).includes(breakPt || "base"),
    [breakPt],
  );
  const windowSize = breakPt === "md" ? 4 : 2;
  const [windowStart, setWindowStart] = useState(0);
  const windowItems = !isLargeScreen
    ? tabList.slice(windowStart, windowStart + windowSize)
    : tabList;

  const shiftWindowRight = () => setWindowStart((prev) => prev + 1);
  const shiftWindowLeft = () => setWindowStart((prev) => prev - 1);

  const [index, setIndex] = useState(defaultIndex);

  const handleTabChange = useCallback(
    (index: number) => {
      setIndex(index);
      if (onChange) {
        onChange(index);
      }
    },
    [onChange],
  );

  useEffect(() => {
    setIndex(defaultIndex);
  }, [defaultIndex, tabList, setIndex]);

  // If using setTabIndex, you almost always want to pair it with onChange.
  useEffect(() => {
    if (setTabIndex !== null) {
      // this was previously evaluating to false when it was 0
      setIndex(setTabIndex);
    }
  }, [setTabIndex]);

  return (
    <ChakraTabs
      index={index}
      isFitted={isFitted}
      variant={variant === "line-bordered" ? "line" : variant}
      position="relative"
      mt="24px"
      defaultIndex={defaultIndex}
      onChange={handleTabChange}
      sx={sx}
      {...customStyles}
    >
      <TabList
        sx={
          variant === "line-bordered"
            ? {
                "&": {
                  border: "1px solid",
                  borderBottom: "none",
                  borderColor: "primary.600",
                  borderRadius: "6px 6px 0 0",
                },
                "& > button:hover": {
                  borderRadius: "6px 6px 0 0",
                },
              }
            : {}
        }
      >
        {!isLargeScreen && windowStart > 0 && (
          <Flex onClick={shiftWindowLeft} p="0 12px" alignItems="center">
            <Icon
              name="actions/Chevron/Left"
              cursor="pointer"
              fill="grayscale.600"
            />
          </Flex>
        )}
        {windowItems.map((item: TabHeaderProp, idx: number) => {
          return (
            <Tab
              key={idx}
              isDisabled={item?.isDisabled}
              minW="fit-content"
              data-cy={
                item?.dataCy ?? `explorer-${item?.tab?.replace(/ /g, "-")}-tab`
              }
            >
              {item?.tab}
            </Tab>
          );
        })}
        {!isLargeScreen && windowStart + windowSize <= tabList.length - 1 && (
          <Flex onClick={shiftWindowRight} p="0 12px" alignItems="center">
            <Icon
              name="actions/Chevron/Right"
              cursor="pointer"
              fill="grayscale.600"
            />
          </Flex>
        )}
      </TabList>
      <TabPanels>
        {tabPanel.map((page: any, idx: number) => (
          <TabPanel key={idx} p="0">
            {page}
          </TabPanel>
        ))}
      </TabPanels>
    </ChakraTabs>
  );
};

export default Tabs;
