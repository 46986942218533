import { useHistory } from "react-router-dom";
import { Box, Flex, Grid, Text } from "@chakra-ui/react";
import Icon from "components/icon/Icon";
import Button from "components/button/Button";
import { ReactComponent as FourZeroFour } from "assets/images/404.svg";

const ErrorPage = () => {
  const history = useHistory();
  return (
    <Grid backgroundColor="grayscale.100" minH="100vh" placeItems="center">
      <Flex direction="column" gap="8px" textAlign="center">
        <Box m="0 auto 40px auto">
          <FourZeroFour />
        </Box>
        <Text textStyle="h1">Page not found</Text>
        <Text as="span" textStyle="subtitle4" mb="32px">
          Hmm... We can’t seem to find what you’re looking for
        </Text>
        <Flex gap="16px" justifyContent="center">
          <Button
            w="183px"
            icon={<Icon name="actions/Arrow/Simple/Left" />}
            onClick={() => history.push("/")}
          >
            Go back
          </Button>
          <Button
            as="a"
            w="183px"
            href="mailto:support@timescale.com"
            variant="secondary"
            icon={<Icon name="actions/Email" />}
          >
            Report the issue
          </Button>
        </Flex>
      </Flex>
    </Grid>
  );
};

export default ErrorPage;
