import React, { useEffect, useState } from "react";
import { Checkbox as ChakraCheckbox, Text } from "@chakra-ui/react";
import { SelectorProps } from "./selectorsTypes";
import Icon from "../icon/Icon";

export interface CheckboxProps extends SelectorProps {
  isIndeterminate?: boolean;
}

const CustomIcon = ({
  isIndeterminate,
  isChecked,
}: Pick<CheckboxProps, "isIndeterminate" | "isChecked">) => {
  const iconName = isIndeterminate
    ? "actions/Remove/Remove"
    : isChecked
      ? "status/Check/Check"
      : "placeholder/Blank";
  return <Icon name={iconName} sx={{ path: { fill: "currentColor" } }} />;
};

export const Checkbox = ({
  id,
  isDisabled,
  isIndeterminate,
  children,
  isChecked,
  onChange,
  ...customStyles
}: CheckboxProps) => {
  const [checked, setChecked] = useState(isChecked);

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  const handleCheckboxChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(evt);
    setChecked(evt.target.checked);
  };

  const elementId = id || `Checkbox-${new Date().getTime()}`;

  return (
    <ChakraCheckbox
      id={elementId}
      isDisabled={isDisabled}
      isIndeterminate={isIndeterminate}
      isChecked={checked}
      icon={
        <CustomIcon isIndeterminate={isIndeterminate} isChecked={isChecked} />
      }
      onChange={handleCheckboxChange}
      cursor={isDisabled ? "default !important" : "pointer"}
      alignItems="start"
      {...customStyles}
    >
      {children && (
        <Text color={isDisabled ? "grayscale.500" : ""}>{children}</Text>
      )}
    </ChakraCheckbox>
  );
};

export default Checkbox;
