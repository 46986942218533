import { Button as ChakraButton } from "@chakra-ui/react";
import { ButtonProps } from "./buttonTypes";
import { getStyles } from "./utils";
import Icon from "components/icon/Icon";
import { forwardRef } from "react";

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      size = "md",
      isDisabled,
      isLoading = false,
      variant = "primary",
      icon,
      type = "button",
      children,
      onClick,
      ...customStyles
    }: ButtonProps,
    ref,
  ) => {
    const iconSpacing = size === "sm" ? "4px" : "8px";

    if (isLoading) {
      icon = <Icon name="status/ProgressAnimated" />;
    }
    return (
      <ChakraButton
        variant={variant}
        isDisabled={isDisabled}
        leftIcon={icon}
        size={size}
        type={type}
        iconSpacing={iconSpacing}
        sx={getStyles(isLoading)}
        onClick={onClick}
        ref={ref}
        {...customStyles}
      >
        {children}
      </ChakraButton>
    );
  },
);

export type { ButtonProps };
export default Button;
