import { typography } from "./typography";
import { colors } from "./colors";
import transition from "./transition";

const foundations = {
  colors,
  ...typography,
  transition,
};

export default foundations;
