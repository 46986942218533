import { ValueOf } from "types/extensions";
import { RegionCode } from "types";
import { ServiceTypeValues } from "pages/project/service/create/CreateServicePage";
import { ServiceEnvironment } from "graphql/generated";

export type ServiceFrame = "simple" | "advanced" | "fork"; // todo - update this

export type CreateServiceRouteParams = {
  serviceId?: string;
  cloneType?: CloneType;
};

export interface SimpleServiceConfig {
  milliCPU: number;
  memoryGB: number;
  storageGB: number;
  name: string;
  regionCode: RegionCode;
  environmentTag: ValueOf<typeof ServiceEnvironment>;
  replicaCount: number;
  enableConnectionPooler: boolean;
  serviceType: ServiceTypeValues | null;
  vpcId?: string;
  targetTime?: string; //for point-in-time-recovery
  showReplicaSwitchWarning?: boolean;
}

export type RegionList = {
  id: RegionCode;
  value: string;
  label: RegionCode;
};

export type CloneType = "standard_fork" | "replica" | "recovery_fork";

export const WORKLOAD_OPTIONS = {
  STEADY: "STEADY",
  BURSTY: "BURSTY",
  CYCLICAL: "CYCLICAL",
} as const;
