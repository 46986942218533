import { Box } from "@chakra-ui/react";
import { useRef, useEffect } from "react";
import { ENV } from "utils/hostEnv";
import { useBreakpointValue } from "@chakra-ui/react";

type GoogleSSOButtonText = "continue_with" | "signup_with";

/*
We use the `login_uri` instead of the `callback` to handle the redirect.
But this could change, so the types are here for future use. Be careful, you
will get an error about `google` namespace not existing. You'll need to solve
that.

callback?: (res: google.accounts.id.CredentialResponse) => void;

Note: localhost redirects to `DEV`, but you can just hit back a few times.
*/

export const GoogleSSOButton = ({
  textType,
}: {
  textType: GoogleSSOButtonText;
}) => {
  const g_sso = useRef(null);
  // Uses `REACT_APP_API_HOSTNAME` for local development, otherwise pull from the domain like `client.ts`.
  const url = process.env.REACT_APP_API_HOSTNAME || window.location.origin;
  const smallScreen = useBreakpointValue({ base: true, md: false });
  const buttonWidth = smallScreen ? window.innerWidth - 84 : 400; // 84 = horizontal margin applied to the main div on mobile devices.

  useEffect(() => {
    if (g_sso.current && window.google) {
      google.accounts.id.initialize({
        client_id: ENV.GOOGLE_CLIENT_ID,
        ux_mode: "redirect",
        login_uri: `${url}/api/idp/provider/ad3e5d03-22ba-4588-828b-2926fa88d60b/oidc/token`,
      });

      google.accounts.id.renderButton(g_sso.current, {
        type: "standard",
        shape: "pill",
        theme: "outline",
        text: textType,
        size: "large",
        logo_alignment: "left",
        width: buttonWidth,
      });
    }
  }, [textType, url, buttonWidth]);

  return <Box ref={g_sso} />;
};
