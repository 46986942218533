import { createContext, useContext, Children, ReactNode } from "react";
import {
  Flex,
  Box,
  Grid,
  chakra,
  useBreakpointValue,
  HTMLChakraProps,
} from "@chakra-ui/react";
import StepNumber, {
  StepNumberProps,
  SIZE_CONFIG,
} from "components/stepNumber/StepNumber";
import { mapValues } from "lodash";

export interface StepProps extends StepNumberProps {
  children: JSX.Element[];
  lineColor?: string;
  stepGap?: string;
  isLineVisible?: boolean;
  titleGap?: string;
  showNumber?: boolean;
}

export type StepsSubcomponentProps = HTMLChakraProps<"div"> & {
  children?: ReactNode;
  hasSubtext?: boolean;
};

const StepLayoutContext = createContext<any>({ height: "", stepGap: "10px" });

const HEADER_HEIGHTS = mapValues(SIZE_CONFIG, ({ boxSize }) => boxSize);
namespace Step {
  export const Layout = ({
    children: _children,
    size = "large",
    color,
    isFilled,
    lineColor = "primary.400",
    stepGap = "40px",
    titleGap = "16px",
    label,
    isLineVisible = true,
    showNumber = true,
    ...rest
  }: StepProps) => {
    const isLargerScreen = useBreakpointValue({
      base: false,
      sm: true,
    });
    const children = Children.toArray(_children);

    // Since we're using a grid and template areas, and step numbers
    // can possibly be hidden, we want to drop the "number" grid area
    // whenever they're not shown.
    const number = showNumber ? "number " : ``;

    return (
      <StepLayoutContext.Provider
        value={{ height: SIZE_CONFIG[size].boxSize, stepGap, titleGap }}
      >
        <Grid
          templateColumns={`${SIZE_CONFIG[size]?.boxSize} ${
            showNumber ? "1fr" : "1"
          }`}
          templateRows={{
            base: "min-content min-content",
            sm: "min-content",
          }}
          templateAreas={{
            base: `"${number}step_header" "step_body step_body"`,
            md: `"${number}step_header" "${number}step_body"`,
          }}
          justifyItems="flex-start"
          columnGap="16px"
          width="100%"
          data-cy={`steplist-step-${label}`}
          {...rest}
        >
          {showNumber && (
            <Flex
              gridArea="number"
              direction="column"
              gap="0px"
              alignItems="center"
              justifyContent="flex-start"
              height="100%"
            >
              <StepNumber
                size={size}
                color={color}
                isFilled={isFilled}
                label={label}
              />
              {isLargerScreen && isLineVisible && (
                <chakra.hr
                  width="1px"
                  flex="1 auto"
                  backgroundColor={lineColor}
                  border={`0px`}
                  height="100%"
                />
              )}
            </Flex>
          )}
          {children}
        </Grid>
      </StepLayoutContext.Provider>
    );
  };
  export const Header = ({ children, ...rest }: StepsSubcomponentProps) => {
    const { height } = useContext(StepLayoutContext);
    return children ? (
      <Flex
        height={height}
        gridArea="step_header"
        alignItems="center"
        {...rest}
      >
        {children}
      </Flex>
    ) : null;
  };
  export const Body = ({
    children,
    hasSubtext,
    ...rest
  }: StepsSubcomponentProps) => {
    const { stepGap, titleGap } = useContext(StepLayoutContext);
    return children ? (
      <Box
        gridArea="step_body"
        width="100%"
        {...rest}
        paddingBottom={stepGap}
        paddingTop={hasSubtext ? "0px" : titleGap}
      >
        {children}
      </Box>
    ) : null;
  };
}

//To allow easy sync of step header heights with step numbers
export { HEADER_HEIGHTS };

export default Step;
