import { avatarAnatomy as parts } from "@chakra-ui/anatomy";
import { ComponentStyleConfig } from "@chakra-ui/theme";
import type { PartsStyleObject } from "@chakra-ui/theme-tools";

const getSize = (size: string): PartsStyleObject<typeof parts> => ({
  container: {
    width: size,
    height: size,
  },
});

// @ts-expect-error this should be migrated to use `defineStyleConfig`
export const Avatar: ComponentStyleConfig = {
  sizes: {
    xs: getSize("5"),
    sm: getSize("6"),
    md: getSize("10"),
    lg: getSize("20"),
  },
};
