import { observer } from "mobx-react";
import { Grid, Image, Box, StyleProps } from "@chakra-ui/react";
import IconButton from "components/button/IconButton";
import Logo from "assets/logos/timescale-logo.svg";
import { LeftNav } from "./navigation/LeftNav";
import { useContext } from "react";
import { LeftNavContext } from "./navigation/NavigationProvider";

export const SmallScreenLayout = observer(() => {
  const { isMenuOpen, setIsMenuOpen } = useContext(LeftNavContext);
  return isMenuOpen ? (
    <SmallScreenNavWrapper>
      <LeftNav />
    </SmallScreenNavWrapper>
  ) : (
    <Grid
      padding="18px 16px 16px 16px"
      gridTemplateColumns="auto auto"
      alignItems="center"
      w="100%"
      h="100%"
      justifyContent="space-between"
      data-cy="Dashboard-Header-Small"
    >
      <Box>
        <Image src={Logo} h="30px" />
      </Box>
      <IconButton
        label="Menu"
        iconName={"actions/Menu/Hamburger"}
        size="lg"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        variant="ghostIcon"
      />
    </Grid>
  );
});

export const SmallScreenNavWrapper = ({
  children,
  ...customStyles
}: StyleProps & {
  children: JSX.Element | Array<JSX.Element>;
}) => (
  <Box
    position="absolute"
    zIndex="100"
    w="100vw"
    h="fill-available"
    left="0"
    overflowY="auto"
    bgColor="grayscale.0"
    {...customStyles}
  >
    {children}
  </Box>
);
