import { Redirect, Route } from "react-router-dom";
import stores from "stores";
import { RedirectProps, RouteProps as _RouteProps } from "react-router";
import { FunctionComponent } from "react";

export const createServicePath = `/dashboard/create_services`;

type RouteProps = Omit<_RouteProps, "path | component"> & {
  path?: string;
  component: FunctionComponent<any>;
  feature?: string;
};

export const pathArray = (routes: RouteProps[]) => {
  const routePaths = routes.map(({ path }) => path);
  // In react-router 5 path property is typed: Path | readonly Path[] | undefined, where Path is a string by default.
  // Since we are going to pass an array, it should be readonly string[]
  return routePaths as readonly string[];
};

export const routes = (routes: Array<RouteProps>) => () => {
  const { featuresStore } = stores;

  return routes
    .filter(
      (route) => !route.feature || featuresStore.isActiveFeature(route.feature),
    )
    .map(({ component: Component, ...rest }) => (
      <Route
        key={rest.path}
        render={(props) => <Component {...props} />}
        {...rest}
      />
    ));
};

export const redirects = (routes: RedirectProps[]) => () =>
  routes.map((props) => <Redirect key={props.from} {...props} />);
