import { StatsigProvider as TSStatsigProvider } from "statsig-react";
import { PROD, STAT_SIG_API_URL, STAT_SIG_CLIENT_KEY } from "utils/config";
import { ENV } from "utils/hostEnv";

export const StatsigProvider = ({
  children,
}: {
  children: React.ReactElement[] | React.ReactElement;
}) => {
  return (
    <TSStatsigProvider
      sdkKey={STAT_SIG_CLIENT_KEY}
      options={{
        api: STAT_SIG_API_URL,
        environment: { tier: ENV.NAME },
        loggingIntervalMillis: ENV.NAME === PROD ? 5000 : 1000,
      }}
      user={{}} // Blank user object until they log in
      waitForInitialization={true}
    >
      {children}
    </TSStatsigProvider>
  );
};
