import { Button, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useStores } from "stores";
import { ACTION } from "utils/config";
import { NotificationCard } from "components/notificationCard/NotificationCard";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { Invitation } from "./NotificationCenter";

dayjs.extend(relativeTime);

type NotificationCardContainerProps = {
  invite: Invitation;
  onClose: () => void;
};

export const NotificationCardContainer = ({
  invite,
  onClose,
}: NotificationCardContainerProps) => {
  const { userStore, projectsStore } = useStores();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInvitation = async (
    onClose: () => void,
    id: string,
    hash?: string,
  ) => {
    setIsSubmitting(true);
    try {
      if (hash) {
        const joinedProjectId = await userStore.confirmProjectInvitation({
          id,
          action: ACTION.ACCEPT,
          hash,
        });

        if (joinedProjectId) {
          await userStore.addHubspot("acceptProjectInvite", [
            { name: "email", value: userStore.currentEmail },
            {
              name: "project_id",
              value: joinedProjectId,
            },
          ]);
          projectsStore.switchProjects(joinedProjectId);
          history.push("/dashboard/services");
        }
      } else {
        await userStore.confirmProjectInvitation({
          id,
          action: ACTION.DECLINE,
        });
      }
    } catch {
      onClose();
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <NotificationCard
      isSeen={false}
      title="Project invite"
      timestamp={dayjs().to(invite.created)}
      ctaPrimary={
        <Button
          size="sm"
          variant="primary"
          onClick={() => handleInvitation(onClose, invite.id, invite.hash)}
          isDisabled={isSubmitting}
          data-cy="accept-project-invite"
        >
          Accept
        </Button>
      }
      ctaSecondary={
        <Button
          size="sm"
          variant="secondary"
          onClick={() => handleInvitation(onClose, invite.id)}
          isDisabled={isSubmitting}
          data-cy="decline-project-invite"
        >
          Decline
        </Button>
      }
    >
      {invite.inviter
        ? `${invite.inviter} has invited you to project `
        : `You have been invited to project `}
      <Text as="span" textStyle="captionBold">
        {invite.projectName}
      </Text>
      .
    </NotificationCard>
  );
};
