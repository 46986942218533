import { Flex, Grid, Text } from "@chakra-ui/react";
import Container from "components/container/Container";
import Divider from "components/divider/Divider";
import Button from "components/button/Button";
import Icon from "components/icon/Icon";
import copy from "copy-to-clipboard";
import {
  generateRecoveryCodeFile,
  formatRecoveryCodes,
} from "pages/account/mfa/mfaUtils";

type RecoveryCodeDisplayProps = {
  codes: string[];
  email: string;
};

const RecoveryCodeDisplay = ({ codes, email }: RecoveryCodeDisplayProps) => {
  const handleCopyCodes = () => {
    copy(codes.join("\n"));
  };

  return (
    <Container
      bg="primary.200"
      borderRadius={"8px"}
      boxShadow="none"
      width="100%"
      padding="24px 0px"
    >
      <Flex direction="column" gap="24px" alignItems={"center"}>
        <Grid
          templateColumns="1fr 1fr"
          columnGap="40px"
          rowGap="8px"
          alignContent="center"
          width="fit-content"
          margin="0 auto"
          data-cy="recovery-codes"
        >
          {formatRecoveryCodes(codes).map((code: string) => (
            <Text key={code} as="span" textStyle="codeS" whiteSpace={"nowrap"}>
              {code}
            </Text>
          ))}
        </Grid>
        <Divider
          color={"primary.400"}
          orientation={"horizontal"}
          height="1px"
          width="100%"
        />
        <Flex gap="8px">
          <Button
            variant="secondary"
            size={"sm"}
            icon={<Icon name={"actions/Copy"} />}
            onClick={handleCopyCodes}
            data-cy={"button-copy-recovery-codes"}
          >
            Copy
          </Button>
          <a
            href={generateRecoveryCodeFile({
              codes,
              email,
            })}
            download={"timescale_recovery_codes.txt"}
            data-cy="button-download-recovery-codes"
          >
            <Button
              variant="secondary"
              size={"sm"}
              icon={<Icon name={"actions/Arrow/WithBase/Down"} />}
            >
              Download
            </Button>
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href={generateRecoveryCodeFile({
              codes,
              email,
            })}
            data-cy="button-print-recovery-codes"
          >
            <Button
              variant="secondary"
              size={"sm"}
              icon={<Icon name={"actions/Print"} />}
            >
              Print
            </Button>
          </a>
        </Flex>
      </Flex>
    </Container>
  );
};

export default RecoveryCodeDisplay;
