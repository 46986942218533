import { ComponentStyleConfig } from "@chakra-ui/theme";
import {
  generateStripe,
  getColor,
  mode,
  StyleFunctionProps,
} from "@chakra-ui/theme-tools";

const filledStyle = (props: StyleFunctionProps) => {
  const {
    theme: t,
    isIndeterminate,
    hasStripe,
    color: filledTrackColor,
  } = props;

  const stripeStyle = mode(
    generateStripe(),
    generateStripe("1rem", "rgba(0,0,0,0.1)"),
  )(props);

  const gradient = `linear-gradient(
    to right,
    transparent 0%,
    ${getColor(t, filledTrackColor)} 50%,
    transparent 100%
  )`;

  const addStripe = !isIndeterminate && hasStripe;

  return {
    ...(addStripe && stripeStyle),
    ...(isIndeterminate
      ? { bgImage: gradient }
      : { bgColor: filledTrackColor }),
  };
};

export const Progress: ComponentStyleConfig = {
  baseStyle: (props) => ({
    filledTrack: {
      transitionProperty: "common",
      transitionDuration: "slow",
      ...filledStyle(props),
    },
  }),
};
