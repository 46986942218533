import { PrismTheme } from "prism-react-renderer";
import { colors } from "theme/foundations/colors";

var theme: PrismTheme = {
  plain: {
    color: colors.grayscale[0],
    backgroundColor: colors.grayscale[800],
  },
  styles: [
    {
      types: ["prolog"],
      style: {
        color: colors.crispBlue[900],
      },
    },
    {
      types: ["comment"],
      style: {
        color: colors.green[900],
      },
    },
    {
      types: ["builtin", "changed", "keyword", "interpolation-punctuation"],
      style: {
        color: colors.grayscale[0],
      },
    },
    {
      types: ["number", "inserted"],
      style: {
        color: colors.purple[900],
      },
    },
    {
      types: ["attr-name", "variable"],
      style: {
        color: colors.grayscale[0],
      },
    },
    {
      types: ["deleted", "string", "attr-value", "template-punctuation"],
      style: {
        color: colors.green[900],
      },
    },
    {
      types: ["selector"],
      style: {
        color: colors.yellow[900],
      },
    },
    {
      // Fix tag color
      types: ["tag"],
      style: {
        color: colors.success[900],
      },
    },
    {
      // Fix tag color for HTML
      types: ["tag"],
      languages: ["markup"],
      style: {
        color: colors.primary[700],
      },
    },
    {
      types: ["punctuation", "operator"],
      style: {
        color: colors.grayscale[0],
      },
    },
    {
      // Fix punctuation color for HTML
      types: ["punctuation"],
      languages: ["markup"],
      style: {
        color: colors.grayscale[700],
      },
    },
    {
      types: ["function"],
      style: {
        color: colors.green[900],
      },
    },
    {
      types: ["constant"],
      style: {
        color: colors.crispBlue[900],
      },
    },
    {
      types: ["class-name"],
      style: {
        color: colors.green[900],
      },
    },
    {
      types: ["char"],
      style: {
        color: colors.pink[900],
      },
    },
  ],
};

export default theme;
