// @ts-nocheck
import {
  Flex,
  Link,
  Text,
  useBreakpointValue,
  MenuItem as ChakraMenuItem,
  StyleProps,
} from "@chakra-ui/react";
import Icon, { Icons } from "components/icon/Icon";
import { Link as ReactLink } from "react-router-dom";
import { SmallScreenNavWrapper } from "../SmallScreenLayout";

export const SmallScreenMenu = ({
  title,
  onTitleClick = () => {},
  children,
}: {
  title: string;
  onTitleClick: () => void;
  children: JSX.Element | Array<JSX.Element>;
}) => {
  return (
    <SmallScreenNavWrapper zIndex="101" top="64px">
      <Flex direction="column">
        <Flex
          alignItems="center"
          gap="8px"
          p="8px"
          m="16px"
          borderRadius="8px"
          borderWidth="1px"
          borderColor="grayscale.200"
          bgColor="grayscale.100"
          onClick={onTitleClick}
        >
          <Icon name="actions/Arrow/Simple/Left" fill="grayscale.900" />
          <Text textStyle="baseBold" color="grayscale.900">
            {title}
          </Text>
        </Flex>
        {children}
      </Flex>
    </SmallScreenNavWrapper>
  );
};

export const MenuGroup = ({
  title,
  children,
}: {
  title: string;
  children: JSX.Element | Array<JSX.Element>;
}) => (
  <Flex direction="column">
    <Text textStyle="baseBold" color="grayscale.700" mx="8px" mb="4px">
      {title}
    </Text>
    {children}
  </Flex>
);

export type MenuItemLinkProps = StyleProps & {
  id: string;
  label: string;
  iconName: Icons;
  onClick?: () => void;
  isExternal?: boolean;
  to?: string;
};

export const MenuItem = ({ ...item }: MenuItemLinkProps) => {
  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  return isSmallScreen ? (
    <Flex px="16px" py="8px">
      <MenuItemLink {...item} />
    </Flex>
  ) : (
    <ChakraMenuItem px="8px" bg="transparent">
      <MenuItemLink {...item} />
    </ChakraMenuItem>
  );
};

export const MenuItemLink = ({
  id,
  label,
  iconName,
  onClick = () => {},
  isExternal,
  to = "#",
  ...customStyles
}: MenuItemLinkProps) => (
  <Link
    as={isExternal ? Link : ReactLink}
    isExternal={isExternal}
    href={to}
    to={to}
    onClick={onClick}
    width="full"
    color="grayscale.700"
    display="flex"
    alignItems="center"
    textDecorationLine="none"
    _hover={{ textDecoration: "none" }}
    data-cy={`navigation-menu-${id}`}
    {...customStyles}
  >
    <Icon name={iconName as Icons} mr="8px" />
    {label}
    {isExternal && <Icon name="actions/External-Link" ml="auto" />}
  </Link>
);
