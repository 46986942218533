import * as Sentry from "@sentry/react";
import { SeverityLevel } from "@sentry/types";
import { withoutGraphQLFetch } from "apollo-link-sentry";
import { Maybe } from "graphql/generated";
import { Expand, ExpandRecursively } from "types/extensions";
import { breadcrumbSanitize } from "utils/breadcrumbSanitize";
import { denyUrls } from "./config";

interface Init {
  dsn: string;
  environment: string;
  release: string;
}

interface User {
  id: string;
  username: string;
  email: string;
}

// Enforces strict types for certain tags, while allowing extra tags as well.
// All tags will get `String()`ified before being sent across the wire anyway.
interface LoggerTags {
  [key: string]: any;
  source?: "client" | "network" | "unknown";
  statusCode?: number;
  serviceId?: string;
  projectId?: Maybe<string>;
}

interface LoggerFn {
  (message: string, tags?: LoggerTags): void;
}

// Similar interface to JS `console` and
// Log4j(s) https://github.com/log4js-node/log4js-node
interface LoggerInterface {
  init: (config: Init) => void;
  setUser: (user: Expand<User> | null) => void;
  debug: ExpandRecursively<LoggerFn>;
  info: ExpandRecursively<LoggerFn>;
  log: ExpandRecursively<LoggerFn>;
  warning: ExpandRecursively<LoggerFn>;
  error: ExpandRecursively<LoggerFn>;
}

const send = (
  level: SeverityLevel,
  message: string,
  tags: LoggerTags,
): void => {
  Sentry.captureException(message, {
    tags,
    level,
  });
};

// Sets the global scope with user data when we have it.
const userScope = (user: User | null) => {
  Sentry.configureScope((scope) => scope.setUser({ ...user }));
};

const Logger: LoggerInterface = {
  init: ({ dsn, environment, release }) => {
    Sentry.init({
      dsn,
      environment,
      release,
      denyUrls: denyUrls,
      attachStacktrace: true,
      beforeBreadcrumb: withoutGraphQLFetch(breadcrumbSanitize),
      integrations: [new Sentry.BrowserTracing()],
      tracesSampleRate: 0.01,
      replaysOnErrorSampleRate: 1.0,
    });
  },
  setUser: (user) => userScope(user),
  debug: (message, tags = {}) => send("debug", message, tags),
  info: (message, tags = {}) => send("info", message, tags),
  log: (message, tags = {}) => send("log", message, tags),
  warning: (message, tags = {}) => send("warning", message, tags),
  error: (message, tags = {}) => send("error", message, tags),
};

export default Logger;
