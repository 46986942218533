import { createContext, useEffect, useState } from "react";
import { Maybe } from "graphql/generated";
import { useStores } from "stores";
import { isUndefined } from "lodash";

export const GuidedTourContext = createContext(
  {} as {
    currentTourStep: Maybe<number>;
    setStepDone: () => void;
    goStepBack: () => void;
    skipTour: () => void;
  }
);

export const GuidedTourProvider = ({ children }: { children: JSX.Element }) => {
  const { userStore } = useStores();
  const { uiState, mergeUiState } = userStore;
  const [currentTourStep, setCurrentTourStep] = useState<Maybe<number>>(
    isUndefined(uiState.navigationTourStep) ? 0 : uiState.navigationTourStep
  );

  useEffect(() => {
    mergeUiState({
      navigationTourStep: currentTourStep,
    });
  }, [currentTourStep, mergeUiState]);

  const setStepDone = () => {
    if (currentTourStep !== null) {
      setCurrentTourStep(currentTourStep + 1);
    }
  };

  const goStepBack = () => {
    if (currentTourStep !== null) {
      setCurrentTourStep(currentTourStep - 1);
    }
  };

  const skipTour = () => {
    setCurrentTourStep(null);
  };

  return (
    <GuidedTourContext.Provider
      value={{ currentTourStep, setStepDone, goStepBack, skipTour }}
    >
      {children}
    </GuidedTourContext.Provider>
  );
};
