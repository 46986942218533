import { EXP_EVENTS, EXP_META } from "./featuresGates";

type VariantParameter = {
  ID: string;
  DEFAULT_VALUE: string;
  VARIANTS: string[];
};

export const ONBOARDING_TYPE_VARIANT = {
  NEW: "new",
  OLD: "old_without_dpg",
  OLD_CONTROL: "old_control",
} as const;

export const onboardingTypeVariants = Object.values(ONBOARDING_TYPE_VARIANT);

/**
 * Parameters used in experiments.
 * If you need custom/variant parameters for your experiment,
 * include them in this PARAMS property, as an optional property.
 **/
type Params = {
  SHOW_FEATURE: typeof PARAMETERS.SHOW_FEATURE;
  ONBOARDING_TYPE?: VariantParameter;
};

// StatSig experiments
export type ExperimentConfig = {
  ID: string; // The experiment_name on Statsig
  NAME: string; // The display name of the experiment.
  PARAMS: Params; // corresponds to the parameters in Statsig
  DESCRIPTION: string; // Keep it short and concise!
  EXPIRATION: string; // The date the experiment plans on being removed.
  EVENTS?: { [key in EXP_EVENTS]?: EXP_EVENTS }; // Optional events that can be logged for this experiment.
  META?: { [key in EXP_META]?: string }; // Optional meta events that can be logged for this experiment.
};

// A template for creating new experiments.
//
// export const TEMPLATE: ExperimentConfig = {
//   ID: "",
//   NAME: "",
//   PARAMS: { SHOW_FEATURE: PARAMETERS.SHOW_FEATURE },
//   DESCRIPTION: "",
//   EXPIRATION: "",
//   EVENTS: {},
//   META: {},
// };

/**
 * For A/B Testing.
 *
 * If using variant parameters, add them here.
 * The value should be the ID of the parameter
 * exactly as set in Statsig.
 *
 */
export const PARAMETERS = {
  SHOW_FEATURE: "show_feature",
  ONBOARDING_TYPE: "onboarding_type",
} as const;

export const EXP_COMPUTE_DEFAULT_1_CPU: ExperimentConfig & {
  EVENTS: {
    SERVICE_CREATED: EXP_EVENTS.SERVICE_CREATED;
    SERVICE_DELETED: EXP_EVENTS.SERVICE_DELETED;
  };
} = {
  ID: "exp_compute_default_1_cpu",
  NAME: "Default Compute 1 CPU",
  PARAMS: { SHOW_FEATURE: PARAMETERS.SHOW_FEATURE },
  DESCRIPTION: "",
  EXPIRATION: "2024-10-01",
  EVENTS: {
    SERVICE_CREATED: EXP_EVENTS.SERVICE_CREATED,
    SERVICE_DELETED: EXP_EVENTS.SERVICE_DELETED,
  },
  META: {},
};

export const EXP_SAMPLE_DATA_V2: ExperimentConfig = {
  ID: "exp_sample_data_v2",
  NAME: "Sample Data V2",
  PARAMS: { SHOW_FEATURE: PARAMETERS.SHOW_FEATURE },
  DESCRIPTION: "Adds the ability to preload a service with sample data.",
  EXPIRATION: "2024-08-30",
  EVENTS: {},
  META: {},
};

export const EXP_PARQUET_IMPORT: ExperimentConfig = {
  ID: "exp_parquet_import",
  NAME: "Parquet Import - Upload UI",
  PARAMS: { SHOW_FEATURE: PARAMETERS.SHOW_FEATURE },
  DESCRIPTION: "",
  EXPIRATION: "2024-09-30",
  META: {},
};

export const EXP_CUSTOM_ONBOARDING: ExperimentConfig & {
  EVENTS: {
    ESTIMATED_DATA_VOLUME: EXP_EVENTS.ESTIMATED_DATA_VOLUME;
  };
} = {
  ID: "exp_custom_onboarding_v1",
  NAME: "Custom Onboarding",
  PARAMS: {
    SHOW_FEATURE: PARAMETERS.SHOW_FEATURE,
    ONBOARDING_TYPE: {
      ID: PARAMETERS.ONBOARDING_TYPE,
      DEFAULT_VALUE: ONBOARDING_TYPE_VARIANT.OLD_CONTROL,
      VARIANTS: onboardingTypeVariants,
    },
  },
  DESCRIPTION:
    "Displays a new flow in replacement of our default Create Service flow for new users",
  EXPIRATION: "2024-11-01",
  EVENTS: {
    ESTIMATED_DATA_VOLUME: EXP_EVENTS.ESTIMATED_DATA_VOLUME,
  },
  META: {},
};

export const EXP_LIVE_MIGRATIONS: ExperimentConfig = {
  ID: "exp_live_migrations",
  NAME: "Advocate live-migrations",
  PARAMS: { SHOW_FEATURE: PARAMETERS.SHOW_FEATURE },
  DESCRIPTION:
    "Displays a new tile for live migrations in data import page and actions hub",
  EXPIRATION: "2024-11-01",
  EVENTS: {},
  META: {},
};

export const EXP_AI_CHAT: ExperimentConfig = {
  ID: "exp_ai_chat",
  NAME: "AI Chat",
  PARAMS: { SHOW_FEATURE: PARAMETERS.SHOW_FEATURE },
  DESCRIPTION: "AI Chat feature",
  EXPIRATION: "2025-01-01",
  EVENTS: {},
  META: {},
};

export const EXP_RECOMMENDATIONS_CARD: ExperimentConfig = {
  ID: "exp_recommendations_card",
  NAME: "Recommendations Card",
  PARAMS: { SHOW_FEATURE: PARAMETERS.SHOW_FEATURE },
  DESCRIPTION:
    "A card that lives on the Overview page with Platform-found recommendations.",
  EXPIRATION: "2025-01-01",
  EVENTS: {},
  META: {},
};

export const EXP_DOTNET_INSTRUCTIONS: ExperimentConfig & {
  EVENTS: {
    ESTIMATED_DATA_VOLUME: EXP_EVENTS.ESTIMATED_DATA_VOLUME;
  };
} = {
  ID: "exp_dotnet_instructions",
  NAME: ".NET Instructions",
  PARAMS: { SHOW_FEATURE: PARAMETERS.SHOW_FEATURE },
  DESCRIPTION:
    "Displays setup instructions for .NET users when creating and managing services.",
  EXPIRATION: "2025-01-01",
  EVENTS: {
    ESTIMATED_DATA_VOLUME: EXP_EVENTS.ESTIMATED_DATA_VOLUME,
  },
  META: {},
};

// Add to the list to show for the ExperimentsModal.
export const ALL_EXPERIMENTS: ExperimentConfig[] = [
  EXP_COMPUTE_DEFAULT_1_CPU,
  EXP_SAMPLE_DATA_V2,
  EXP_PARQUET_IMPORT,
  EXP_CUSTOM_ONBOARDING,
  EXP_LIVE_MIGRATIONS,
  EXP_AI_CHAT,
  EXP_RECOMMENDATIONS_CARD,
  EXP_DOTNET_INSTRUCTIONS,
];
