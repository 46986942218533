import { ComponentStyleConfig } from "@chakra-ui/react";
import { focusIndicator } from "../foundations/accessibility";

export const inputStyle = {
  defaultProps: {
    size: "md",
    variant: "outline",
  },
  baseStyle: {
    borderRadius: "8px",
  },
  variants: {
    outline: {
      element: {
        fill: "grayscale.600",
      },
      field: {
        textStyle: "base",
        _placeholder: {
          opacity: 1,
          color: "grayscale.400",
        },
        borderRadius: "8px",
        height: "36px",
        px: "8px",
        borderColor: "grayscale.500",
        _hover: {
          borderColor: "primary.900",
          "& + div": {
            //to control color of search icon
            fill: "grayscale.800",
          },
        },
        _focus: {
          ...focusIndicator,
          "& + div": {
            fill: "grayscale.800",
          },
        },
        _invalid: {
          borderColor: "error.900",
          borderWidth: "1px",
          boxShadow: "none",
        },
        _disabled: {
          borderColor: "grayscale.300",
          color: "grayscale.300",
          cursor: "default",
          opacity: 1,
          _placeholder: {
            color: "grayscale.300",
          },
          "& + div": {
            fill: "grayscale.300",
          },
        },
      },
    },
    edit: {
      field: {
        textStyle: "base",
        padding: 0,
        borderRadius: 0,
        height: "20px",
        border: "none",
        borderBottom: "1px solid",
        borderColor: "grayscale.500",
        _invalid: {
          borderColor: "error.900",
        },
      },
    },
    //variant for Search component
    line: {
      element: {
        width: "18px",
        fill: "grayscale.600",
      },
      field: {
        _placeholder: {
          color: "grayscale.400",
        },
        color: "grayscale.800",
        borderBottom: "1px solid",
        height: "32px",
        "padding-inline-start": "26px",
        "padding-inline-end": "26px",
        borderRadius: "0px",
        borderColor: "grayscale.500",
        _focus: {
          borderColor: "primary.900",
          "& + div": {
            fill: "grayscale.800",
          },
        },
        _hover: {
          borderColor: "primary.900",
          "& + div": {
            fill: "grayscale.800",
          },
        },
        _disabled: {
          borderColor: "grayscale.400",
          color: "grayscale.500",
          "::placeholder": {
            color: "grayscale.500",
          },
          "& + div": {
            fill: "grayscale.500",
          },
        },
      },
    },
    //variant for Search component
    ghost: {
      element: {
        width: "18px",
        fill: "grayscale.700",
      },
      field: {
        _placeholder: {
          color: "grayscale.700",
        },
        border: "none",
        color: "grayscale.800",
        "padding-inline-start": "26px",
        "padding-inline-end": "26px",
        _hover: {
          "& + div": {
            //to control color of search icon
            fill: "grayscale.800",
          },
        },
        _focus: {
          "& + div": {
            //to control color of search icon
            fill: "grayscale.800",
          },
        },
        _disabled: {
          color: "grayscale.500",
          "::placeholder": {
            color: "grayscale.500",
          },
          "& + div": {
            fill: "grayscale.500",
          },
        },
      },
    },
  },
};

export const Input: ComponentStyleConfig = inputStyle;
