import { Box, Flex, StyleProps } from "@chakra-ui/react";
import Icon from "../icon/Icon";
import { Icons } from "../icon/iconTypes";

export interface FeedbackSecondaryProps extends StyleProps {
  iconName: Icons;
  children?: React.ReactNode;
  isDisabled?: boolean;
}

const FeedbackSecondary = ({
  iconName,
  children,
  isDisabled = false,
  ...customStyles
}: FeedbackSecondaryProps) => {
  return (
    <Flex alignItems="center" {...customStyles}>
      <Flex
        bgColor={isDisabled ? "grayscale.200" : "primary.200"}
        borderRadius="24px"
        h="24px"
        w="24px"
        alignItems="center"
        justifyContent="center"
        mr="8px"
      >
        <Icon
          name={iconName}
          fill={isDisabled ? "grayscale.300" : "primary.900"}
        />
      </Flex>
      <Box
        textStyle="caption"
        color={isDisabled ? "grayscale.300" : "grayscale.700"}
      >
        {children}
      </Box>
    </Flex>
  );
};

export default FeedbackSecondary;
