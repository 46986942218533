import ContainerComponent, {
  type ContainerProps,
} from "components/container/Container";
import { type FC } from "react";

export const Container: FC<ContainerProps> = ({ children, ...rest }) => (
  <ContainerComponent
    display="grid"
    p={{ base: "64px 80px", lg: "64px 80px", sm: "40px 32px" }}
    borderRadius={{ base: "8px", lg: "16px", sm: "40px" }}
    boxShadow="lg"
    marginBottom="16px"
    marginLeft="8px"
    marginRight="16px"
    {...rest}
  >
    {children}
  </ContainerComponent>
);
