import { Textarea, TextareaProps } from "@chakra-ui/react";
import GroupBase, { GroupProps } from "../shared/GroupBase";

type TextareaGroupProps = TextareaProps & GroupProps;

const TextareaGroup = ({
  name,
  label,
  error,
  isDisabled,
  layoutProps,
  ...rest
}: TextareaGroupProps & { layoutProps?: GroupProps }) => (
  <GroupBase
    name={name}
    label={label}
    error={error}
    isDisabled={isDisabled}
    {...layoutProps}
  >
    <Textarea
      name={name}
      isInvalid={Boolean(error)}
      isDisabled={isDisabled}
      {...rest}
    />
  </GroupBase>
);

export default TextareaGroup;
