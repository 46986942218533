import { ElementType } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import { useStores } from "stores";
import { READY } from "stores/constants";
import notificationStore from "stores/notificationStore";
import { useEffectOnce } from "react-use";

type LocationState = {
  query: string;
  pathname: string;
  search?: string;
};

export const AuthRoute = observer(
  ({
    render: Render,
    path,
  }: {
    render: ElementType;
    path: readonly string[];
  }) => {
    const { authStore, userStore } = useStores();
    const isLoading = userStore.storeStatus !== READY;
    const isAuthed = userStore.currentId && !authStore.inProgress;
    const { pathname, search } = useLocation<LocationState>();

    useEffectOnce(() => {
      if (!isAuthed) {
        const pathsList = ["/joinProject"];

        if (pathsList.some((path) => path === pathname)) {
          switch (pathname) {
            case "/joinProject":
              return notificationStore.showErrorToaster(
                "You must login before accepting the invitation",
              );
            default:
              return;
          }
        }
      }
    });

    return (
      <Route
        path={path}
        render={(props) =>
          isLoading ? (
            <div></div>
          ) : isAuthed ? (
            <Render {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  query: search,
                },
              }}
            />
          )
        }
      />
    );
  },
);

export const PublicRoute = observer(
  ({
    render: Render,
    path,
  }: {
    render: ElementType;
    path: readonly string[];
  }) => {
    const { authStore, userStore } = useStores();
    const isAuthed = userStore.currentId && !authStore.inProgress;
    const { state } = useLocation<LocationState>();

    return (
      <Route
        path={path}
        render={(props) =>
          !isAuthed ? (
            <Render {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/dashboard/services",
                search: state?.query,
              }}
            />
          )
        }
      />
    );
  },
);

export const ErrorRedirect = observer(({ ...rest }) => {
  const { authStore, userStore } = useStores();
  const isAuthed = userStore.currentId && !authStore.inProgress;

  return isAuthed ? (
    <Redirect to="/dashboard/not-found" {...rest} />
  ) : (
    <Redirect to="/404" {...rest} />
  );
});
