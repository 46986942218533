import { Box, Flex, Text } from "@chakra-ui/react";
import Button from "components/button/Button";
import { BasicCard } from "components/container/BasicCard";
import Dropdown from "components/dropdown/Dropdown";
import FeedbackRecommendation from "components/feedbackRecommendation/FeedbackRecommendation";
import Icon, { Icons } from "components/icon/Icon";
import { Modal, ModalButtons } from "components/modal/Modal";
import { Toggle } from "components/selectors/Toggle";
import Tabs from "components/tabs/Tabs";
import dayjs from "dayjs";
import { observer } from "mobx-react";
import { useState } from "react";
import { DynamicConfig, Statsig } from "statsig-react";
import { useStores } from "stores";
import { ThemeColors } from "theme/foundations/colors";
import { PROD } from "utils/config";
import {
  ALL_EXPERIMENTS,
  ExperimentConfig,
  PARAMETERS,
} from "utils/featuresExperiments";
import { ALL_GATES } from "utils/featuresGates";
import { ENV } from "utils/hostEnv";

// A little rough-and-ready, but it works.
const ExpiresTag = ({ date }: { date: string }) => {
  const formattedDate = dayjs(date).format("MMM D, YYYY");

  let tagText = "Running";
  let tagColor: ThemeColors = "success.900";
  let tagIcon: Icons = "status/Check/Circle";

  if (dayjs().diff(date, "day") > 0) {
    // Older than today
    tagText = "Expired";
    tagColor = "error.900";
    tagIcon = "status/Color/Alert";
  } else if (dayjs().diff(date, "day") > -7) {
    // Less than 7 days til now
    tagText = "Ending";
    tagColor = "warning.900";
    tagIcon = "status/Color/Information";
  }

  return (
    <Flex color={tagColor} fontWeight={500} alignItems="center">
      <Icon name={tagIcon} fill={tagColor} mr="6px" />
      {tagText}: {formattedDate}
    </Flex>
  );
};

const GatesTab = () => {
  return (
    <Box mt="24px">
      {ALL_GATES.map((expGate) => {
        const gate = expGate.GATE;
        return (
          <BasicCard
            key={gate}
            textAlign="left"
            gap="4px"
            marginBottom="8px"
            padding="12px 18px 12px 14px"
            borderRadius="4px"
            borderLeft="4px solid"
            borderLeftColor={
              Statsig.checkGateWithExposureLoggingDisabled(gate)
                ? "primary.900"
                : "grayscale.300"
            }
          >
            <Flex>
              <Toggle
                id={gate}
                data-cy={gate}
                isChecked={Statsig.checkGateWithExposureLoggingDisabled(gate)}
                whiteSpace="nowrap"
                textAlign="left"
                onChange={() => {
                  Statsig.overrideGate(
                    gate,
                    !Statsig.checkGateWithExposureLoggingDisabled(gate),
                  );
                }}
              >
                <Text as="span" fontWeight={700}>
                  {expGate.NAME}
                </Text>
              </Toggle>
              <Box marginLeft="auto" whiteSpace="nowrap">
                <ExpiresTag date={expGate.EXPIRATION} />
              </Box>
            </Flex>
            <Box fontSize="12px">
              [{gate}]: {expGate.DESCRIPTION}
            </Box>
            {Boolean(Object.keys(expGate.EVENTS || {}).length) ? (
              <Box fontSize="12px">
                Events:{" "}
                {String(Object.keys(expGate.EVENTS || {}).map((e) => ` ${e}`))}
              </Box>
            ) : (
              <></>
            )}
          </BasicCard>
        );
      })}
    </Box>
  );
};

const variantDefaultValue = (value: string) => [
  {
    label: value,
    value: value,
  },
];

const getExperimentVariants = (exp: ExperimentConfig, paramKey: ParameterKey) =>
  (exp.PARAMS as any)![paramKey]?.VARIANTS?.map((v: string) => ({
    label: v,
    value: v,
  }));

type ParameterKey = keyof typeof PARAMETERS;

const ExperimentsTab = () => {
  const [toggleExperiments, setToggleExperiments] = useState<{
    [key: string]: boolean;
  }>({});

  const handleToggleChange = (
    id: string,
    currentValue: boolean,
    parameter: any,
  ) => {
    setToggleExperiments((prevState) => ({
      ...prevState,
      [id]: !currentValue,
    }));

    Statsig.overrideConfig(id, { [parameter]: !currentValue });
  };

  return (
    <Box mt="24px">
      {ALL_EXPERIMENTS.map((exp) => {
        const experimentName = exp.ID;
        const experimentConfig: DynamicConfig =
          Statsig.getExperimentWithExposureLoggingDisabled(experimentName);
        const isInExperimentVariant = experimentConfig.getValue(
          exp.PARAMS.SHOW_FEATURE,
          false,
        )
          ? true
          : false;

        const isChecked =
          toggleExperiments[experimentName] ?? isInExperimentVariant;

        return (
          <BasicCard
            key={experimentName}
            textAlign="left"
            gap="4px"
            marginBottom="8px"
            padding="12px 18px 12px 14px"
            borderRadius="4px"
            borderLeft="4px solid"
            borderLeftColor={
              isInExperimentVariant ? "primary.900" : "grayscale.300"
            }
          >
            <Flex>
              <Toggle
                id={experimentName}
                data-cy={experimentName}
                isChecked={isChecked}
                whiteSpace="nowrap"
                textAlign="left"
                onChange={() => {
                  handleToggleChange(
                    experimentName,
                    isChecked,
                    exp.PARAMS.SHOW_FEATURE,
                  );
                }}
              >
                <Text as="span" fontWeight={700}>
                  {exp.NAME}
                </Text>
              </Toggle>
              <Box marginLeft="auto" whiteSpace="nowrap">
                <ExpiresTag date={exp.EXPIRATION} />
              </Box>
            </Flex>
            <Box fontSize="12px">
              [{experimentName}]: {exp.DESCRIPTION}
            </Box>
            {Boolean(Object.keys(exp.EVENTS || {}).length) ? (
              <Box fontSize="12px">
                Events:{" "}
                {String(Object.keys(exp.EVENTS || {}).map((e) => ` ${e}`))}
              </Box>
            ) : (
              <></>
            )}
            {exp.PARAMS && isChecked ? (
              Object.keys(exp.PARAMS)
                .filter(
                  // Only get parameters that have variant
                  // options (so, filter out the e.g.
                  // SHOW_PARAMETERS parameter).
                  (key: any) => typeof (exp.PARAMS as any)[key] === "object",
                )
                .map((_paramKey) => {
                  const paramKey = _paramKey as ParameterKey;
                  const parameterVariant = experimentConfig?.value?.[
                    PARAMETERS[paramKey]
                  ] as string;
                  const currentParameterVariant =
                    parameterVariant && parameterVariant;

                  return (
                    <Box fontSize="12px" key={paramKey}>
                      Variant parameter: {paramKey}
                      <Dropdown
                        width="70%"
                        onChange={(option) => {
                          Statsig.overrideConfig(experimentName, {
                            ...experimentConfig.value,
                            [(exp.PARAMS as any)[paramKey]?.ID]: option.value,
                          });
                        }}
                        options={getExperimentVariants(exp, paramKey)}
                        defaultValue={
                          currentParameterVariant
                            ? variantDefaultValue(currentParameterVariant)
                            : variantDefaultValue(
                                (exp.PARAMS[paramKey] as any)!.DEFAULT_VALUE,
                              )
                        }
                      />
                    </Box>
                  );
                })
            ) : (
              <></>
            )}
          </BasicCard>
        );
      })}
    </Box>
  );
};

export const ExperimentsModal = observer(() => {
  const { userStore } = useStores();
  const { currentEmail } = userStore;

  const showFeatures =
    ENV.NAME !== PROD || currentEmail?.endsWith("@timescale.com");
  const [showModal, setShowModal] = useState(false);

  const handleActiveFeatures = () => {
    setShowModal(false);
    window.location.reload();
  };

  const handleCancelModal = () => {
    setShowModal(false);
  };

  if (!showFeatures) return null;

  return (
    <>
      <Button
        onClick={() => setShowModal(true)}
        size="sm"
        variant="ghost"
        position="fixed"
        bottom="3px"
        right="70px"
        zIndex="1400"
        background="grayscale.0"
        data-cy="experiments-button"
      >
        🧪 Experiments
      </Button>

      <Modal
        title="🧪 Experiments"
        isOpen={showModal}
        onClose={() => handleCancelModal()}
        width="800px"
      >
        <>
          <Flex
            data-cy="experiment-list"
            direction="column"
            maxH="65vh"
            overflowY="auto"
            gap="5px"
            pb="60px"
          >
            <FeedbackRecommendation
              styleVariant="info"
              isBackgroundOn={true}
              show={true}
              iconName="status/Megaphone"
              marginY="10px"
            >
              Override experiment enrollment locally to test. Changes are saved
              automatically.
            </FeedbackRecommendation>
            <Tabs
              isFitted={false}
              tabList={[{ tab: "Gates" }, { tab: "Experiments" }]}
              tabPanel={[<GatesTab />, <ExperimentsTab />]}
              mb="56px"
            />
          </Flex>
        </>
        <ModalButtons
          primaryCTA={{
            text: "Reload",
            onClick: () => handleActiveFeatures(),
            dataCy: "ff-modal-button",
          }}
        />
      </Modal>
    </>
  );
});
