import { ComponentStyleConfig } from "@chakra-ui/react";

export const tableStyle = {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    flex: "0 1 auto",
    overflowY: "hidden",
    ".table": {
      textStyle: "base",
      display: "flex",
      flexDirection: "column",
      overflowY: "hidden",
      ".tr": {
        display: "flex",
        justifyContent: "flex-start",
        gap: "20px",
        px: "24px",
        "&:last-child": {
          borderBottom: "none",
        },
      },
      ".thead": {
        flex: "0 0 auto",
        ".tr": {
          height: "fit-content",
          bg: "grayscale.200",
          alignItems: "center",
          textStyle: "baseBold",
          ".th": {
            paddingY: "2px", // extra space for tooltips
            wordBreak: "normal",
          },
        },
      },
      ".tbody": {
        flex: "0 1 auto",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        ".tr": {
          height: "fit-content",
          paddingY: "10px",
          alignItems: "center",
          borderBottom: "1px solid",
          borderColor: "primary.400",
          cursor: "default",
          ".td": {
            minHeight: "40px",
            justifyContent: "center",
          },
        },
      },
      ".tr:last-child": {
        borderBottom: "none",
      },
    },
  },
};

export const Table: ComponentStyleConfig = tableStyle;
