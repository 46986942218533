export const Drawer = {
  baseStyle: {
    dialog: {
      p: "40px",
    },
    body: {
      p: 0,
    },
    footer: {
      p: 0,
    },
  },
};
