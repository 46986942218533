import authStore from "./authStore";
import commonStore from "./commonStore";
import notificationStore from "./notificationStore";
import userStore from "./userStore";
import projectsStore from "./projectsStore";
import productStore from "./productStore";
import serviceStore from "./serviceStore";
import paymentMethodsStore from "./paymentMethodsStore";
import featuresStore from "./featuresStore";
import emailStore from "./emailStore";
import databaseParametersStore from "./databaseParametersStore";
import serviceResizeStore from "./serviceResizeStore";
import exporterStore from "./exporterStore";
import queryStatsStore from "./queryStatsStore";
import { useContext } from "react";
import { MobXProviderContext } from "mobx-react";

export { AuthStore } from "./authStore";
export { CommonStore } from "./commonStore";
export { NotificationStore } from "./notificationStore";
export { UserStore } from "./userStore";
export { ProjectsStore } from "./projectsStore";
export { ServiceStore } from "./serviceStore";
export { ProductStore } from "./productStore";
export { EmailStore } from "./emailStore";
export { DatabaseParametersStore } from "./databaseParametersStore";
export { ServiceResizeStore } from "./serviceResizeStore";
export { ExporterStore } from "./exporterStore";
export { QueryStatsStore } from "./queryStatsStore";

export const stores = {
  authStore,
  commonStore,
  notificationStore,
  userStore,
  projectsStore,
  productStore,
  serviceStore,
  paymentMethodsStore,
  featuresStore,
  emailStore,
  databaseParametersStore,
  serviceResizeStore,
  exporterStore,
  queryStatsStore,
} as const;

export default stores;

// NOTE: We are in a transition to typed stores. `useStores` is fully-typed,
// `useStoresUntyped` is gradually typed: individual stores are typed, all
// others are typed as `any`. Most components use `useStoresUntyped` today.
// With `useStores` and the `GradualStores` type we have two migration options:
// 1. Component-wise switch from `useStoresUntyped` to `useStores`.
// 2. Store-wise switch to strong typing within `GradualStores`.

export type Stores = typeof stores;

export function useStores() {
  return useContext<Stores>(MobXProviderContext as any);
}

// Stores, all keys typed as any
type AnyStores = {
  [property in keyof typeof stores]: any;
};

// An enumeration of all stores which are strongly typed in `GradualStores`
type typedStores =
  | "commonStore"
  | "databaseParametersStore"
  | "notificationStore"
  | "paymentMethodsStore"
  | "productStore"
  | "serviceResizeStore";

// Gradually-typed `Stores`, stores named in `typedStores` are typed
export type GradualStores = Omit<AnyStores, typedStores> &
  Pick<Stores, typedStores>;

/**
 * @deprecated use `useStores` instead
 */
export function useStoresUntyped() {
  return useContext<GradualStores>(MobXProviderContext as any);
}

export { READY, LOADING, ERROR, STALE, NOT_INITIATED } from "./constants";
