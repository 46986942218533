import {
  ApolloClient,
  InMemoryCache,
  from,
  HttpLink,
  ServerParseError,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import ApolloLinkTimeout from "apollo-link-timeout";
import { SentryLink } from "apollo-link-sentry";
import authStore from "stores/authStore";
import serviceStore from "stores/serviceStore";
import projectsStore from "stores/projectsStore";
import history from "utils/history";
import Logger from "utils/logger";
import {
  TS_API_DEBUG_GATE,
  TS_API_DEBUG_OBJECT,
  TS_API_DEBUG_VALUE,
} from "utils/localStorage";

const UNAUTHORIZED = /unauthorized/i;
const INVALID_TOKEN = /invalid token/i;
const PROJECT_DOESNT_EXIST = /no project with that id exists/i;
const INVALID_USERNAME_PASSWORD = /invalid username\/password combination/i;
const NO_TOKEN = /no token/i;

const debugAPI = async (operationName: string) => {
  // Debug object will look something like:
  // {
  //    "GetUser":        -1,    // -1 means it will always fail.
  //    "GetAllServices":  0,    // 0 means it will always succeed.
  //    "OrbCost":         1000, // >0  means it will always succeed after [X] milliseconds.
  // }
  const debugApi = JSON.parse(
    localStorage.getItem(TS_API_DEBUG_OBJECT) || "{}",
  );

  debugApi[operationName] = debugApi[operationName] || 0; // Save or add a new value.
  localStorage.setItem(TS_API_DEBUG_OBJECT, JSON.stringify(debugApi));
  if (operationName in debugApi) {
    if (debugApi[operationName] === -1) {
      // Just crash the call.
      throw new Error(`[DEBUG] Simulate '${operationName}' failure.`);
    }
    if (debugApi[operationName] > 0) {
      // You can simulate delays as well.
      console.log(
        `%c[DEBUG] Delaying '${operationName}' by ${debugApi[operationName]}ms.`,
        "color: #08F",
      );
      await new Promise((r) => setTimeout(r, debugApi[operationName]));
    }
  }
};

const customFetch = async (_uri: RequestInfo, options: RequestInit) => {
  const { operationName } = JSON.parse((options as any).body);

  // Saves ~400ms on first call when `getItem` is done here instead of inside the debugAPI function.
  if (localStorage.getItem(TS_API_DEBUG_GATE) === TS_API_DEBUG_VALUE) {
    await debugAPI(operationName);
  }

  return fetch(`${process.env.REACT_APP_API_HOSTNAME || ""}/api/query`, {
    ...options,
    credentials: "include",
  });
};

const httpLink = new HttpLink({ fetch: customFetch });
const timeoutLink = new ApolloLinkTimeout(120000).concat(httpLink);
const sentryLink = new SentryLink({
  attachBreadcrumbs: {
    includeVariables: true,
  },
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    const { service } = serviceStore;
    const { projectId } = projectsStore;

    if (graphQLErrors) {
      const { location } = history;
      for (const error of graphQLErrors) {
        const { message, path, extensions } = error;
        //Register page runs GetUser and doesn't default to login page
        if (operation.operationName === "GetUser" && message?.match(NO_TOKEN)) {
          return;
        }
        if (
          (message?.match(INVALID_TOKEN) || message?.match(NO_TOKEN)) &&
          extensions?.status === 401
        ) {
          authStore.setError({ message: "Logged out due to inactivity" });
          authStore.logout();
          break;
        }

        // IDP failure to log in
        if (
          operation.operationName === "GetUser" &&
          location.pathname === "/idp"
        ) {
          window.location.replace("/idpError");
        }
        if (
          // e.g. "identity provider must be used for this email domain: https://example.com/idp/startSSO"
          message?.includes("provider must be used for this email domain: ")
        ) {
          try {
            // The user must login with an identity provider; redirect to the provided login URL
            const idpUrlString = message.split(
              "provider must be used for this email domain: ",
            )[1]; // e.g. "https://example.com/idp/startSSO"

            const idpUrl = new URL(idpUrlString);

            // don't redirect to URLs like "javascript:alert(1)"
            if (idpUrl?.protocol === "https:" || idpUrl?.protocol === "http:") {
              window.location.href = idpUrlString;
            }
          } catch (e) {
            // ignore URL parsing errors
          }
        }

        //ignore getUserPersona operation failures due to not having a persona stored
        if (
          ["GetUser", "UpdateUserPersona"].includes(operation.operationName)
        ) {
          return;
        }
        if (operation.operationName === "GetPostgresParameters") {
          return forward(operation); //Retry once on failure
        }

        // Exception to ignore pg param restart error
        if (
          (
            [
              [500, "could not read parameters"],
              [400, "instance must be running to read parameters"],
              [404, "no Endpoint for that service id exists"],
            ] as Array<[number, string]>
          ).some(
            ([status, err]) =>
              extensions?.status === status && message?.includes(err),
          )
        ) {
          continue;
        }
        if (
          !location?.pathname?.includes("/operations/resources") &&
          extensions?.status === 404
        ) {
          history.push("/404");
          break;
        }

        // We expect this error when a user tries to login with a MFA token,
        // but the user typed the incorrect digits.
        if (message?.match(INVALID_USERNAME_PASSWORD)) {
          authStore.setError(graphQLErrors);
          continue;
        }

        if (
          message?.match(UNAUTHORIZED) ||
          message?.match(PROJECT_DOESNT_EXIST)
        ) {
          authStore.setError({ message: "Unauthorized" });
          // to be on the safe side, we switch the user to a project they own &
          // if they don't own any projects, we log them out
          const allProjects = projectsStore.allProjects;
          if (allProjects?.length > 0) {
            const ownedProject = projectsStore.ownedProjects[0];
            projectsStore.switchProjects(
              ownedProject?.id ?? projectsStore.allProjects[0]?.id,
            );
            projectsStore.getAllProjects().then(() => {
              history.push("/dashboard/services");
            });
            break;
          } else {
            authStore.logout();
            break;
          }
        }

        console.error(
          `[GraphQL]: (${extensions?.status}) Operation: ${path} | Message: ${message}`,
        );

        Logger.error(
          `[GraphQL]: (${extensions?.status}) Operation: ${path} | Message: ${message}`,
          {
            source: "client",
            status: extensions?.status,
            operation: operation.operationName,
            serviceId: service.id,
            projectId: projectId,
          },
        );

        authStore.setError(graphQLErrors);
      }
    } else if (networkError) {
      // Strange Typescript bug where it won't find ServerParseError even though
      // You can clearly see it in the @apollo/client/errors file:
      //
      // export type NetworkError = Error | ServerParseError | ServerError | null;
      //
      // Adding "timeout" because of it's added via ApolloLinkTimeout.
      const { statusCode, message, timeout } =
        networkError as ServerParseError & { timeout: number };

      console.error(
        `[Network]: (${statusCode}) Operation: ${operation.operationName} | Message: ${message}`,
      );
      Logger.error(
        `[Network]: (${statusCode}) Operation: ${operation.operationName} | Message: ${message}`,
        {
          source: "network",
          statusCode: statusCode,
          operation: operation.operationName,
          serviceId: service.id,
          projectId: projectId,
          timeout: timeout,
        },
      );
    } else {
      console.error(`[Unknown]: Operation: ${operation.operationName}`);
      Logger.error(`[Unknown]: Operation: ${operation.operationName}`, {
        source: "unknown",
        operation: operation.operationName,
        serviceId: service.id,
        projectId: projectId,
      });
    }

    return;
  },
);

export const client = new ApolloClient({
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
  link: from([errorLink, sentryLink, timeoutLink]),
  cache: new InMemoryCache({
    possibleTypes: { ObjectSizeID: ["TableID", "ContAggID"] },
    typePolicies: {
      Query: {
        fields: {
          getRecommendations: {
            // merge: true, // Throws a new error.
            merge(_existing, incoming) {
              return incoming;
            },
          },
        },
      },
    },
  }),
});

export default client;
