import { reaction, makeAutoObservable, runInAction, observable } from "mobx";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { client } from "api/client";
import { merge, mergeWith } from "lodash";
import projectsStore from "./projectsStore";
import serviceStore from "./serviceStore";
import notificationStore from "./notificationStore";
import {
  DeployStatus,
  GetServiceConfigHistoryQuery,
  GetServiceResizeInfoQuery,
  ResourceNode,
  ServiceConfigHistory,
} from "graphql/generated";
import { ApolloQueryResult } from "@apollo/client";
import {
  getFromStorage,
  LOCAL_STORAGE_USER_DATA,
  REQUESTED_RESOURCES_CHANGES_BY_NODE,
  LAST_FETCHED_RESOURCES_BY_NODE,
  PENDING_RESOURCES_CHANGES_BY_NODE,
} from "utils/localStorage";
import { GET_SERVICE_CONFIG_HISTORY, GET_SERVICE_RESIZE_INFO } from "api/query";
import { RESIZE_INSTANCE } from "api/mutations";
import {
  reactionStoreLocal,
  QueryWatcher,
  checkServiceDeletedError,
} from "./storeUtils";
import { findById } from "../utils/utilFunctions";

dayjs.extend(duration);

type ResourcesByNode = {
  [serviceId: string]: ResourceNode;
};

export class ServiceResizeStore {
  lastFetchedResources: ResourcesByNode =
    getFromStorage(LAST_FETCHED_RESOURCES_BY_NODE, LOCAL_STORAGE_USER_DATA) ||
    {};
  pendingResourceChanges: ResourcesByNode =
    getFromStorage(
      PENDING_RESOURCES_CHANGES_BY_NODE,
      LOCAL_STORAGE_USER_DATA,
    ) || {};
  requestedResourceChanges: ResourcesByNode =
    getFromStorage(REQUESTED_RESOURCES_CHANGES_BY_NODE) || {};
  configHistory: { [serviceId: string]: ServiceConfigHistory } = {};
  requestedStorageHistory: { [serviceId: string]: string[] } = {};

  queryWatchers: { [serviceId: string]: QueryWatcher } = {};

  constructor() {
    makeAutoObservable(this, {
      // lastFetchedResources: observable.struct,
      // pendingResourceChanges: observable.struct,
      requestedResourceChanges: observable.struct,
      // configHistory: observable.struct,
      requestedStorageHistory: observable.struct,
    });

    reaction(
      () => this.lastFetchedResources,
      reactionStoreLocal(LAST_FETCHED_RESOURCES_BY_NODE),
    );
    reaction(
      () => this.pendingResourceChanges,
      reactionStoreLocal(PENDING_RESOURCES_CHANGES_BY_NODE),
    );
    reaction(
      () => this.requestedResourceChanges,
      reactionStoreLocal(REQUESTED_RESOURCES_CHANGES_BY_NODE),
    );
  }

  _deleteQueryWatchers = (serviceId: string) => {
    if (this.queryWatchers?.[serviceId]) {
      this.queryWatchers[serviceId]?.watcher?.stopPolling();
      this.queryWatchers[serviceId]?.subscription?.unsubscribe();
      runInAction(() => {
        const { [serviceId]: _, ...rest } = this.queryWatchers;
        this.queryWatchers = rest;
      });
    }
  };

  setPendingChanges = ({
    serviceId = serviceStore.serviceId,
    config,
  }: {
    serviceId: string;
    config: Partial<Omit<ResourceNode, "__typename">>;
  }) => {
    runInAction(() => {
      const {
        milliCPU = 0,
        memoryGB = 0,
        storageGB = 0,
      } = this.lastFetchedResources[serviceId] ||
      serviceStore.serviceById(serviceId)?.resources?.[0]?.spec ||
      {};
      const newPending = merge(
        { milliCPU, memoryGB, storageGB },
        this.pendingResourceChanges[serviceId],
        config,
      );

      this.pendingResourceChanges = {
        ...this.pendingResourceChanges,
        [serviceId]: newPending,
      };
    });
  };

  resetPendingChanges = (serviceId = serviceStore.serviceId) => {
    runInAction(() => {
      const {
        milliCPU = 0,
        memoryGB = 0,
        storageGB = 0,
        replicaCount = 0,
        syncReplicaCount = 0,
      } = this.lastFetchedResources[serviceId];
      this.pendingResourceChanges = {
        ...this.pendingResourceChanges,
        [serviceId]: {
          ...serviceStore.serviceById(serviceId)?.resources?.[0]?.spec,
          milliCPU,
          memoryGB,
          storageGB, //slightly redundant, but this may have been fetched more recently
          replicaCount,
          syncReplicaCount,
        },
      };
    });
  };

  fetchServiceConfigHistory = async ({
    serviceId = serviceStore.serviceId,
    projectId = projectsStore.currentProject?.id,
  }) => {
    try {
      const { data, errors }: ApolloQueryResult<GetServiceConfigHistoryQuery> =
        await client.query({
          variables: { serviceId, projectId },
          query: GET_SERVICE_CONFIG_HISTORY,
          fetchPolicy: "no-cache",
        });
      if (errors) {
        throw Error;
      }
      if (data) {
        runInAction(() => {
          this.configHistory = {
            ...this.configHistory,
            [serviceId]: data.getServiceConfigHistory,
          };
        });
      }
    } catch {
      notificationStore.showErrorToaster(
        "There was an error retrieving the config history for one of the nodes",
      );
    }
  };

  fetchLatestResources = async ({
    serviceId = serviceStore.serviceId,
    projectId = projectsStore?.currentProject?.id,
  }) => {
    try {
      const { data, errors } = await client.query({
        variables: { serviceId: serviceId, projectId },
        query: GET_SERVICE_RESIZE_INFO,
      });
      this._handleFetchedResources(serviceId)({ data, errors });
    } catch {
      notificationStore.showErrorToaster("error fetching current resources");
    }
  };

  requestResourceChanges = async ({
    serviceId = serviceStore.serviceId,
    projectId = projectsStore?.currentProject?.id,
  }) => {
    try {
      if (this.pendingResourceChanges[serviceId]) {
        const currentResources = this.lastFetchedResources[serviceId];
        const requestedConfig = this.pendingResourceChanges[serviceId];
        //resize config input requires that unchanged vals are set to 0
        const { milliCPU, memoryGB, storageGB } = mergeWith(
          {},
          currentResources,
          this.pendingResourceChanges[serviceId],
          (current: number, pen: number) => (current === pen ? 0 : pen),
        );
        //guard against some nodes in multi-node not needing to resize
        if ([milliCPU, memoryGB, storageGB].some((param) => param !== 0)) {
          const { errors } = await client.mutate({
            variables: {
              serviceId,
              projectId,
              config: { milliCPU, memoryGB, storageGB },
            },
            mutation: RESIZE_INSTANCE,
          });
          if (!errors) {
            runInAction(() => {
              if (typeof storageGB === "number" && storageGB !== 0) {
                this.requestedStorageHistory = {
                  ...this.requestedStorageHistory,
                  [serviceId]: [
                    dayjs().format(),
                    ...(this.requestedStorageHistory[serviceId] || []),
                  ],
                };
              }
              this.requestedResourceChanges = {
                ...this.requestedResourceChanges,
                [serviceId]: requestedConfig,
              };
            });
            this._subscribeToResourceChanges({ serviceId });
            notificationStore.showInfoToaster("Instance resize in progress");
          } else {
            throw new Error("Resize request failed");
          }
        }
      }
    } catch (err) {
      notificationStore.showErrorToaster(
        "There was a problem with the resize, please retry",
      );
      throw err;
    }
  };

  requestedResourcesChangesById = (id: string) => {
    return findById({ items: Object.keys(this.requestedResourceChanges), id });
  };

  _handleFetchedResources =
    (serviceId: string) =>
    async ({
      data,
      errors,
    }: Partial<ApolloQueryResult<GetServiceResizeInfoQuery>>) => {
      if (checkServiceDeletedError(errors)) {
        runInAction(() => {
          const { [serviceId]: _, ...rest } = this.requestedResourceChanges;
          this.requestedResourceChanges = rest;
        });
        this._deleteQueryWatchers(serviceId);
      } else {
        await this.fetchServiceConfigHistory({ serviceId });
        if (errors) {
          notificationStore.showErrorToaster(
            "Sorry, there was an error reading service resources. If this does not resolve, please contact us through support",
          );
        } else if (data) {
          //Update local resources
          const {
            milliCPU,
            memoryGB,
            storageGB,
            replicaCount,
            syncReplicaCount,
          } = data.getService.resources[0].spec;
          runInAction(() => {
            this.lastFetchedResources = {
              ...this.lastFetchedResources,
              [serviceId]: {
                ...{
                  milliCPU,
                  memoryGB,
                  storageGB,
                  replicaCount,
                  syncReplicaCount,
                },
              },
            };
          });
          //Check if resource change request matches received (request complete)
          if (this.requestedResourceChanges[serviceId]) {
            const requestedResources: ResourceNode =
              this.requestedResourceChanges[serviceId];
            const lastFetched: ResourceNode = data.getService.resources[0].spec;
            if (data?.getService?.status === DeployStatus.Ready) {
              let doesRequestMatchLatest = true;
              for (const param in { memoryGB, milliCPU, storageGB }) {
                const requestedVal =
                  requestedResources[param as keyof ResourceNode];
                if (!requestedVal) {
                  continue;
                }
                if (requestedVal !== lastFetched[param as keyof ResourceNode]) {
                  doesRequestMatchLatest = false;
                  break;
                }
              }
              if (doesRequestMatchLatest) {
                runInAction(() => {
                  const { [serviceId]: _, ...rest } =
                    this.requestedResourceChanges;
                  this.requestedResourceChanges = rest;
                });
                this._deleteQueryWatchers(serviceId);
                this.resetPendingChanges(serviceId);
              }
            }
          }
        }
      }
    };

  _subscribeToResourceChanges = async ({
    serviceId = serviceStore.serviceId,
    projectId = projectsStore?.currentProject?.id,
  }) => {
    try {
      this._deleteQueryWatchers(serviceId);
      const watcher = client.watchQuery({
        variables: { serviceId, projectId },
        query: GET_SERVICE_RESIZE_INFO,
        fetchPolicy: "no-cache",
        pollInterval: 5000,
      });
      const subscription = watcher?.subscribe(
        this._handleFetchedResources(serviceId),
      );
      runInAction(() => {
        this.queryWatchers = {
          ...this.queryWatchers,
          [serviceId]: {
            watcher,
            subscription,
          },
        };
      });
    } catch {
      notificationStore.showErrorToaster("error fetching current resources");
    }
  };
}

const serviceResizeStore = new ServiceResizeStore();

export default serviceResizeStore;
