import { SampleDataType } from "types/sampleData";
import { SampleDataConfig } from "./config";

export const sampleDataTypeFromServiceName = (
  serviceName: string,
): SampleDataType | undefined => {
  for (const key in SampleDataConfig) {
    if (
      SampleDataConfig[key as keyof typeof SampleDataType].serviceName ===
      serviceName
    ) {
      return SampleDataType[key as keyof typeof SampleDataType];
    }
  }
  return undefined;
};

export const isSampleDataService = (serviceName: string): boolean => {
  return !!sampleDataTypeFromServiceName(serviceName);
};
