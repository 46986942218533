import { Flex, Text } from "@chakra-ui/react";
import Illustration from "components/illustration/Illustration";
import Modal, { ModalButtons } from "components/modal/Modal";

export const NewVersionModal = ({ onClose, isOpen }: any) => (
  <Modal
    title={
      <Flex width="100%" justifyContent="center">
        <Illustration name="success/Checkmark" />
      </Flex>
    }
    onClose={onClose}
    isOpen={isOpen}
  >
    <Flex direction="column" gap="24px">
      <Text textStyle="h4">New version available!</Text>
      <Text>Update to the latest version of Timescale.</Text>
    </Flex>
    <ModalButtons
      primaryCTA={{
        text: "Update",
        onClick: () => {
          const win: Window = window;
          win.location = window.location.href;
          onClose();
        },
      }}
      secondaryCTA={undefined}
    />
  </Modal>
);

export default NewVersionModal;
