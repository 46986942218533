import { AlertStatus } from "@chakra-ui/react";
import { Data, Time } from "convert";
import { ValueOf } from "./extensions";
import {
  DeployStatus as DeployStatusGraphQL,
  Type as TypeGraphQL,
  Status as StatusGraphQL,
  Maybe,
  Role,
  GenericExporter,
  MetricExporter,
  CompressionStats as _CompressionStats,
  ParameterUnit,
  User,
  Service,
  MfaMethod,
} from "../graphql/generated";
import { ExporterDataType } from "pages/integrations/integrationTypes";

export type ServiceStatus = ValueOf<typeof DeployStatusGraphQL>;

export type ServiceSpec = Service["resources"][0]["spec"];

export const ServiceType = {
  Postgres: TypeGraphQL.Postgres,
  Timescaledb: TypeGraphQL.Timescaledb,
  Vector: TypeGraphQL.Vector,
};

export type ProductID =
  | "product_pg"
  | "product_pg_storage"
  | "product_tsdb"
  | "product_tsdb_storage"
  | "product_data_tiering"
  | "product_vpc_attachment"
  | "product_elastic_storage"
  | "product_pg_elastic_storage"
  | "product_connection_pooler"
  | "product_vector"
  | "product_vector_elastic_storage";

export const ServiceTypeProductId = {
  [ServiceType.Timescaledb]: {
    Config: "product_tsdb",
    Storage: "product_elastic_storage",
    DataTiering: "product_data_tiering",
    ConnectionPooler: "product_connection_pooler",
  },
  [ServiceType.Postgres]: {
    Config: "product_pg",
    Storage: "product_pg_elastic_storage",
    DataTiering: "product_data_tiering",
    ConnectionPooler: "product_connection_pooler",
  },
  [ServiceType.Vector]: {
    Config: "product_vector",
    Storage: "product_vector_elastic_storage",
    DataTiering: "product_data_tiering",
    ConnectionPooler: "product_connection_pooler",
  },
};

export type ReplicaRoleType = 0 | 1 | null;

export const ToggleServiceStatus = StatusGraphQL;

export const VPC_SIMPLE_CONNECTION_STATUS = {
  READY: "READY",
  IN_PROGRESS: "IN_PROGRESS",
  ERROR: "ERROR",
} as const;

export const CONNECTION = {
  ONLINE: "online",
  OFFLINE: "offline",
} as const;

export enum RegionCode {
  US_EAST_1 = "us-east-1",
  US_EAST_2 = "us-east-2",
  US_WEST_2 = "us-west-2",
  EU_WEST_1 = "eu-west-1",
  EU_WEST_2 = "eu-west-2",
  EU_CENTRAL_1 = "eu-central-1",
  AP_SOUTHEAST_2 = "ap-southeast-2",
  AP_SOUTHEAST_1 = "ap-southeast-1",
  AP_NORTHEAST_1 = "ap-northeast-1",
  CA_CENTRAL_1 = "ca-central-1",
  SA_EAST_1 = "sa-east-1",
}

// These are the regions used by "Intl" JS module. You can see them in action
// by running "Intl.DateTimeFormat().resolvedOptions().timeZone" or
// "Intl.supportedValuesOf('timeZone')" in your browser.
export enum TimezoneRegion {
  Africa = "Africa",
  America = "America",
  Antarctica = "Antarctica",
  Arctic = "Arctic",
  Asia = "Asia",
  Atlantic = "Atlantic",
  Australia = "Australia",
  Europe = "Europe",
  Indian = "Indian",
  Pacific = "Pacific",
}

export enum NodeType {
  ACCESS_NODE = "access_node",
  DATA_NODE = "data_node",
}

export enum NodeTypeCreate {
  ACCESS_NODE = "ACCESS",
  DATA_NODE = "DATA",
}

// An SVG typing for fill on top of CSS.
export type Path = {
  path: {
    fill: string;
  };
};

export enum SupportEmailDestinations {
  CUSTOMER_SUCCESS = "customer-success@timescale.com",
  SUPPORT = "support@timescale.com",
  SALES = "sales@timescale.com",
}

export type Member = {
  userId: Maybe<string>;
  inviteId: Maybe<string>;
  joined: Maybe<string>;
  created: Maybe<string>;
  email: string;
  declined: Maybe<string>;
  role: Role;
  name: Maybe<string>;
  mfaMethods: MfaMethod[];
};

export type Exporter =
  | GenericExporter
  | (MetricExporter & { dataType?: ExporterDataType });

export type HubspotField = {
  name: string;
  value: any;
};

export type HubspotUserProps = {
  is_invited: boolean;
  wants_subscription: boolean;
  utm_params: Record<string, string>;
};

export type HubspotFormProps = Pick<User, "name" | "email" | "id"> & {
  isVerified?: boolean;
};

// re-declaring from "zen-observable-ts" because it's a peer instead of direct dependency
export type Subscription = {
  closed: boolean;
  unsubscribe(): void;
};

const CHAKRA_SIZES = ["xs", "sm", "md", "lg", "xl", "2xl"] as const;
export type ChakraSizes = (typeof CHAKRA_SIZES)[number];
export type ChakraAlertStatus = Exclude<AlertStatus, "loading">;

export enum UTM_CAMPAIGNS {
  VECTOR_LAUNCH = "vectorlaunch", // 2023-09-15
  TIME_SERIES = "time-series",
}

// Removing deprecated "ratio" property.
// See original `CompressionStats` in `/graphql/generated.ts`
export type CompressionStats = Omit<_CompressionStats, "ratio"> & {
  ratio?: _CompressionStats["ratio"];
};

type ParamOption = {
  value: ParameterUnit[keyof ParameterUnit];
  label: string;
  rate: Time | Data;
};

export const TIME: ParamOption[] = [
  { value: "MICROSECONDS", label: "𝝁s", rate: "μs" },
  { value: "MILLISECONDS", label: "ms", rate: "ms" },
  { value: "SECONDS", label: "sec", rate: "s" },
  { value: "MINUTES", label: "min", rate: "min" },
  { value: "HOURS", label: "hours", rate: "h" },
  { value: "DAYS", label: "days", rate: "d" },
];

// The values are labeled incorrectly (should be KIBIBYTES, MEBIBYTES) but have to be set this way to match the backend
export const SIZE: ParamOption[] = [
  { value: "BYTES", label: "bytes", rate: "B" },
  { value: "KILOBYTES", label: "KiB", rate: "KiB" },
  { value: "MEGABYTES", label: "MiB", rate: "MiB" },
];

// Types to use with reducers/useReducer

/**
 * @typeParam *P* - the type for all the payloads that are to be
 * expected. The keys should be the names of the actions,
 * and the values should be the types of the payloads.
 * Specify a type of "never" for actions that don't have a payload.
 *
 * @example
  * type PayloadTypes = {
      SELECT_ATTRIBUTE: Attribute;
      DESELECT_ALL_ATTRIBUTES: never;
      DESELECT_ATTRIBUTE: { value: string };
    }
 *
 * @typeParam *T* - the name of the action, which should exist
 * as a key in P, the payloads type.
 *
 * @example
 * "SELECT_ATTRIBUTE"
 */
export interface Action<T extends keyof P, P extends { [K in keyof P]: P[K] }> {
  type: T;
  payload: P[T];
}

export type ActionWithoutPayload<
  T extends keyof P,
  P extends { [K in keyof P]: P[K] },
> = Omit<Action<T, P>, "payload">;
