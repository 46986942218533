import { CSSObject, StyleProps, Tag } from "@chakra-ui/react";
import Icon, { Icons } from "components/icon/Icon";
import { SIZE_CONFIG } from "components/tags/tagStyles";
import { ThemeColors } from "theme/foundations/colors";

type TagInfoVariant = "default" | "light" | "gradient";

export interface TagInfoProps extends StyleProps {
  label: string;
  variant?: TagInfoVariant;
  isLarge?: boolean;
  iconName?: Icons;
  sx?: CSSObject;
  [key: string]: any;
}

const tagInfoStyles = {
  borderRadius: "4px",
  border: "none",
  padding: "2px 8px",
};

const TAG_INFO_CONFIG = (theme: TagInfoVariant) => {
  switch (theme) {
    case "light":
      return {
        background: "primary.400",
        color: "grayscale.700",
      };
    case "gradient":
      return {
        background: "mediumGradient.90",
        color: "grayscale.700",
      };

    default:
      return {
        background: "primary.900",
        color: "grayscale.0",
      };
  }
};

const TagInfo = ({
  label,
  variant = "default",
  isLarge = false,
  iconName,
  sx,
  ...customStyles
}: TagInfoProps) => {
  return (
    <Tag
      sx={{
        ...tagInfoStyles,
        ...TAG_INFO_CONFIG(variant),
        ...(isLarge ? SIZE_CONFIG["large"] : {}),
        ...sx,
      }}
      {...customStyles}
    >
      {iconName && (
        <Icon
          name={iconName}
          fill={
            sx?.color
              ? (sx?.color as ThemeColors)
              : variant === "default"
                ? "grayscale.0"
                : "grayscale.700"
          }
          mr={2}
        />
      )}

      {label}
    </Tag>
  );
};

export default TagInfo;
