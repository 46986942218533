import { useQuery } from "@apollo/client";
import { ExecuteQueryEngine } from "@timescale/popsql-query-widget";
import {
  OnQueryCompleteArgs,
  QueryWidgetProps,
} from "@timescale/popsql-query-widget/ts-dist/src/QueryWidget";
import client from "api/client";
import { TOGGLE_POPSQL_INTEGRATION } from "api/mutations";
import { GET_POPSQL_INTEGRATION_STATUS } from "api/query";
import { GetPopSqlIntegrationStatusQuery } from "graphql/generated";
import { observer } from "mobx-react";
import {
  useEffect,
  useCallback,
  useContext,
  createContext,
  type FC,
  ReactNode,
  useMemo,
} from "react";
import { useStores } from "stores";
import { PROD } from "utils/config";
import { ENV } from "utils/hostEnv";

export interface ServiceQueryEngineContextProps {
  enableIntegration: () => void;
  getExecuteQueryData: QueryWidgetProps["getExecuteQueryData"];
  integrationEnabled: boolean;
  ready: boolean;
  refreshStatus: () => void;
  trackQueryComplete: (args: OnQueryCompleteArgs) => void;
}

export const ServiceQueryEngineContext =
  createContext<ServiceQueryEngineContextProps>({
    enableIntegration: () => {},
    getExecuteQueryData: () => null,
    integrationEnabled: false,
    ready: false,
    refreshStatus: () => {},
    trackQueryComplete: () => {},
  });

export const useServiceQueryEngine = ({
  serviceId,
}: {
  serviceId: string;
}): ServiceQueryEngineContextProps => {
  const { notificationStore, projectsStore, serviceStore } = useStores();
  const { projectId } = projectsStore;
  const { serviceById } = serviceStore;
  const service = serviceById(serviceId);
  const integrationEnabled = !!service?.spec.popSQLIntegrationEnabled;
  const isEnabled = !!projectId && !!serviceId && integrationEnabled;

  const {
    data,
    refetch: refreshStatus,
    startPolling,
    stopPolling,
  } = useQuery<GetPopSqlIntegrationStatusQuery>(GET_POPSQL_INTEGRATION_STATUS, {
    variables: {
      projectId,
      serviceId,
    },
    skip: !isEnabled,
  });
  const isActive = data?.getPopSQLIntegrationStatus === "ACTIVE";
  const ready = isEnabled && isActive;

  useEffect(() => {
    if (!isEnabled || isActive) return;

    // Poll until the integration is active
    startPolling(5000);
    return () => stopPolling();
  }, [isEnabled, isActive, startPolling, stopPolling]);

  // Refetch the integration status when the setting is toggled
  useEffect(() => {
    if (!isEnabled) return;
    refreshStatus();
  }, [isEnabled, refreshStatus, serviceId, projectId]);

  const enableIntegration = useCallback(() => {
    client
      .mutate({
        variables: {
          projectId: projectId,
          serviceId,
          enable: true,
        },
        mutation: TOGGLE_POPSQL_INTEGRATION,
      })
      .then(({ data, errors }) => {
        if (errors || data?.togglePopSQLIntegration !== "success") {
          notificationStore.showErrorToaster(
            "There was a problem enabling the SQL editor. Please try again.",
          );
        } else {
          notificationStore.showSuccessToaster("SQL editor enabled!");
          refreshStatus();
          serviceStore.getAllServices();
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }, [serviceId, projectId, notificationStore, refreshStatus, serviceStore]);

  const getExecuteQueryData: QueryWidgetProps["getExecuteQueryData"] =
    useCallback(
      ({ runId, query }) => ({
        engine: ExecuteQueryEngine.timescaleQuery,
        params: {
          projectId,
          query,
          runId,
          serviceId,
        },
      }),
      [projectId, serviceId],
    );

  const trackQueryComplete = useCallback(
    (args: OnQueryCompleteArgs) => {
      if (ENV.NAME === PROD) {
        window?.heap.track("Executed SQL query", {
          ...args,
          serviceId,
          projectId,
        });
      }
    },
    [projectId, serviceId],
  );

  // Memoize the context value to prevent unnecessary re-renders
  const context: ServiceQueryEngineContextProps = useMemo(
    () => ({
      enableIntegration,
      getExecuteQueryData,
      integrationEnabled,
      ready,
      refreshStatus,
      trackQueryComplete,
    }),
    [
      enableIntegration,
      getExecuteQueryData,
      integrationEnabled,
      ready,
      refreshStatus,
      trackQueryComplete,
    ],
  );

  return context;
};

// Uses the service implied by the current service selection
export const CurrentServiceQueryEngineContextProvider: FC<{
  children: ReactNode;
}> = observer(({ children }) => {
  const { serviceStore } = useStores();
  const serviceId = serviceStore.service?.id;
  const context = useServiceQueryEngine({ serviceId });

  return (
    <ServiceQueryEngineContext.Provider value={context}>
      {children}
    </ServiceQueryEngineContext.Provider>
  );
});

export const useCurrentServiceQueryEngine = () =>
  useContext(ServiceQueryEngineContext);
