// libraries
import { ObservableQuery } from "@apollo/client";
import { client } from "api/client";
import dayjs from "dayjs";
import flagsmith from "flagsmith";
import { GraphQLError } from "graphql/error/GraphQLError";
import { concat, isEmpty } from "lodash";
import { autorun, makeAutoObservable, reaction, runInAction, toJS } from "mobx";
import { Statsig } from "statsig-react";
// stores
import commonStore from "./commonStore";
import featuresStore from "./featuresStore";
import notificationStore from "./notificationStore";
import serviceStore from "./serviceStore";
import userStore from "./userStore";
// api
import {
  CREATE_BILLING_EMAIL,
  CREATE_PAT_RECORD,
  CREATE_PAT_RECORD__TYPE,
  CREATE_PROJECT,
  CREATE_PROJECT_AI_SECRET,
  DELETE_BILLING_EMAIL,
  DELETE_PAT_RECORD,
  DELETE_PROJECT_AI_SECRET,
  RENAME_PAT_RECORD,
  RENAME_PROJECT,
  RESEND_VERIFY_BILLING_EMAIL,
  SET_ADDRESS_INFO,
  SET_ADDRESS_INFO__TYPE,
  SET_VIRTUAL_PLAN_FOR_PROJECT,
  TRANSFER_PROJECT_OWNERSHIP,
  UPDATE_PROJECT_AI_SECRET,
} from "api/mutations";
import {
  GET_ALL_PROJECTS,
  GET_BILLING_ACCOUNT,
  GET_BILLING_ACCOUNT__TYPE,
  GET_BILLING_EMAILS,
  GET_COSTED_PROJECT_DATA_TIERING_USAGE_DISTRIBUTION,
  GET_COSTED_PROJECT_DATA_TIERING_USAGE_DISTRIBUTION__TYPE,
  GET_DATA_TIERING_SUMMARY,
  GET_DATA_TIERING_SUMMARY__TYPE,
  GET_ORB_COST,
  GET_ORB_COST__TYPE,
  GET_PAT_RECORDS,
  GET_PAT_RECORDS__TYPE,
  GET_PLAN_DOWNGRADE_CHECK,
  GET_PROJECT,
  GET_PROJECT_INVITES,
  GET_PROJECT_MEMBERS,
  GET_PROJECT_SECRETS,
  GET_PROJECT_SECRETS__TYPE,
  GET_VIRTUAL_PLANS,
} from "api/query";
// utils
import { PROD } from "utils/config";
import { VECTOR_FEATURE } from "utils/featureFlags";
import { ENV } from "utils/hostEnv";
import {
  CURRENT_PROJECT,
  getFromLocalStorage,
  LOCAL_STORAGE_USER_DATA,
  NEW_PROJECT_USER_INIT,
  storeDirectlyIntoLocalStorage,
} from "utils/localStorage";
import { convertUnit, matchesRegexps } from "utils/utilFunctions";
import { generateRandomName } from "../utils/utilFunctions";
// constants
import { EXP_ORB, FEAT_AI_EMBEDDINGS } from "../utils/featuresGates";
import {
  ERROR,
  HOURS_PER_MONTH,
  LOADING,
  NOT_INITIATED,
  PROJECT_IDS,
  READY,
  StoreStatus,
} from "./constants";
// types
import {
  AddressInfo,
  BillingAccount,
  BillingAccountCostInfo,
  BillingEmailAddress,
  ClientCredentials,
  EntitlementSpec,
  Maybe,
  ProductCostInfo,
  Project,
  ProjectInvite,
  ProjectMember,
  Role,
  Secret,
  ServiceCostInfo,
  TieringUsage,
  VirtualPlanType,
} from "graphql/generated";
import { TieringUsageSummaryName } from "pages/project/service/DataTieringSummary";
import { EXP_CUSTOM_ONBOARDING } from "utils/featuresExperiments";
import { Subscription } from "zen-observable-ts";
import { Member } from "../types";

const DEFAULT_TRIAL_DAYS = 30;

const projectMemberRole = {
  ADMIN: "ADMIN",
  MANAGER: "MANAGER",
  USER: "USER",
};

export type DataTieringSummary = {
  total?: { value: number; unit: string };
  breakdown?: TieringUsageSummaryName;
  tieredPerServiceBytes?: {
    [key: string]: number;
  };
  savingsPerService?: {
    [key: string]: number;
  };
};

export const localCurrentProject = getFromLocalStorage(
  CURRENT_PROJECT,
  LOCAL_STORAGE_USER_DATA,
);

export class ProjectsStore {
  allProjects: Project[] = [];
  ownedProjects: Project[] = [];
  currentProject: Maybe<Project> = localCurrentProject ?? null;
  clientCredentials: ClientCredentials[] = [];
  allMembers: Member[] = [];
  projectId: Maybe<string> = localCurrentProject?.id ?? null;
  billingAccount: Maybe<BillingAccount> =
    localCurrentProject?.billingAccount ?? null;
  billingEmails: BillingEmailAddress[] = [];
  createDefaultProjectAttempts: number = 0;
  storeStatus: StoreStatus = NOT_INITIATED;
  errors: GraphQLError[] = [];
  costToDate: Maybe<number> = null;
  currentUsageCost: Maybe<number> = null;
  estimatedGrossCost: Maybe<number> = null;
  estimatedTotalCost: Maybe<number> = null;
  productCostInfos: ProductCostInfo[] = [];
  serviceCostInfos: { [key: string]: ServiceCostInfo } = {};
  trialEndDate: Maybe<string> = null;
  billingCycleStart: Maybe<string> = null;
  billingCycleEnd: Maybe<string> = null;
  querySubscription: Maybe<ObservableQuery<any, { projectId: Maybe<string> }>> =
    null;
  refreshSubscription?: Subscription | null = null;
  showtrialServiceBanner: boolean = true;
  dataTieringSummary: Maybe<DataTieringSummary> = null;
  projectSecrets: Secret[] = [];

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.currentProject,
      async (currentProject) => {
        try {
          storeDirectlyIntoLocalStorage(
            CURRENT_PROJECT,
            toJS(this.currentProject),
            LOCAL_STORAGE_USER_DATA,
          );

          if (currentProject) {
            this.setDataTieringSummary({});
            this.projectId = currentProject?.id;
            await this.getProjectMembersAndInvites();
            await this.getDataTieringSummary({
              projectId: this.projectId,
            });
            await this.getProjectSecrets();
            this._setStoreStatus(READY);
          } else {
            this._setStoreStatus(NOT_INITIATED);
          }

          runInAction(() => {
            this.billingAccount = currentProject?.billingAccount || null;

            const perServiceCosts: { [key: string]: ServiceCostInfo } = {};
            currentProject?.billingAccount?.costInfo?.serviceCostInfos?.forEach(
              (serviceCostInfo) => {
                perServiceCosts[serviceCostInfo.serviceID] = serviceCostInfo;
              },
            );
            this.serviceCostInfos = perServiceCosts;
          });
        } catch {
          console.warn("Error updating projectsStore");
        }
      },
    );
    reaction(
      () => this.billingAccount,
      async (billingAccount) => {
        await this.getBillingEmails();
        this.unsubscribeBillingAccount();

        // with the tier subscription model we are getting all the costs from Orb except the per-service breakdown
        // per-service breakdown is not yet supported
        const isOrbFeatureAvailable = Statsig.checkGate(EXP_ORB.GATE);
        let orbCostInfo: BillingAccountCostInfo;
        if (
          isOrbFeatureAvailable &&
          billingAccount?.id &&
          this.currentProject?.id
        ) {
          orbCostInfo = await this.getOrbCost(
            this.currentProject.id,
            billingAccount.id,
          );
        }
        runInAction(() => {
          if (orbCostInfo || billingAccount?.costInfo) {
            const costInfo = orbCostInfo || billingAccount?.costInfo;
            this.costToDate = !isNaN(costInfo?.costToDate)
              ? Number(costInfo.costToDate.toFixed(2))
              : null;
            this.currentUsageCost = !isNaN(costInfo?.costToDateGross)
              ? Number(costInfo.costToDateGross.toFixed(2))
              : null;
            this.estimatedGrossCost = !isNaN(costInfo?.estimatedTotalCostGross)
              ? Number(costInfo.estimatedTotalCostGross.toFixed(2))
              : null;
            this.estimatedTotalCost = !isNaN(costInfo?.estimatedTotalCost)
              ? Number(costInfo.estimatedTotalCost.toFixed(2))
              : null;
            this.productCostInfos = costInfo.productCostInfos;
            this.trialEndDate = billingAccount?.trialEndDate;
            this.billingCycleStart = costInfo?.billingPeriodStart?.replace(
              "0Z",
              "",
            );
            this.billingCycleEnd = costInfo?.billingPeriodEnd?.replace(
              "0Z",
              "",
            );
          } else {
            this.subscribeBillingAccount();
          }
        });
      },
    );

    reaction(
      () => this.allProjects,
      async () => {
        await this.getOwnedProjects();
      },
    );

    autorun(() => {
      // Add tracking for trial end date for filtering in Heap
      if (this.trialEndDate && ENV.NAME === PROD) {
        window?.heap.addEventProperties({ trialDaysLeft: this.trialDaysLeft });
      }
    });
  }

  get trialDaysLeft() {
    if (this.trialEndDate) {
      const days = dayjs(this.trialEndDate).diff(dayjs(), "days");
      return days > 0 ? days : 0;
    }
    return null;
  }

  get isFreeTrial() {
    if (this.trialEndDate) {
      return dayjs().isBefore(dayjs(this.trialEndDate));
    }
    return false;
  }

  get trialDaysLeftDateFormatted() {
    return this.trialEndDate
      ? dayjs(this.trialEndDate).format("MMM D, YYYY")
      : "";
  }

  get currentProjectName() {
    return this.currentProject?.name ?? "";
  }

  get maxVpcs() {
    return this.currentProject?.maxVPCs ?? 3;
  }

  get projectAdmin() {
    return this.allMembers.find(
      (member) => member?.role === projectMemberRole["ADMIN"],
    );
  }

  get isCurrentProjectAdmin() {
    return this.projectAdmin?.userId === userStore.currentId?.toString();
  }

  get hasAnyPaymentMethod() {
    return (
      this.billingAccount?.isUsingInvoicing ||
      !isEmpty(this.billingAccount?.paymentMethods)
    );
  }

  _setStoreStatus = (status: StoreStatus) => {
    runInAction(() => {
      this.storeStatus = status;
    });
  };

  clearStore = () => {
    runInAction(() => {
      this.allProjects = [];
      this.currentProject = null;
      this.projectId = null;
      this.billingAccount = null;
      this.unsubscribeBillingAccount();
      this.currentUsageCost = null;
      this.estimatedGrossCost = null;
      this.estimatedTotalCost = null;
      this.trialEndDate = null;
      this.billingCycleStart = null;
      this.billingCycleEnd = null;
      this.createDefaultProjectAttempts = 0;
      this.storeStatus = NOT_INITIATED;
    });
  };

  _reportProjectToHubspot = async (projectId: string) => {
    if (
      //Block CI reporting
      matchesRegexps(userStore.currentEmail || "", [
        /timescale-ci\+.+@timescale\.com/,
        /test-forge-integration.+@timescale\.com/,
      ])
    ) {
      return;
    } else {
      try {
        await userStore.addHubspot("project", [
          { name: "email", value: userStore.currentEmail },
          {
            name: "project_id",
            value: projectId,
          },
        ]);
      } catch {
        console.log("unable to register project ID");
      }
    }
  };

  createProject = async (
    props: {
      name?: string;
      trialDays?: number;
    } = {},
  ) => {
    const { name = "Timescale Project", trialDays = DEFAULT_TRIAL_DAYS } =
      props;

    this._setStoreStatus(LOADING);
    try {
      const { data } = await client.mutate({
        variables: {
          name,
          trialDays,
        },
        mutation: CREATE_PROJECT,
      });
      if (data) {
        const projectId = data.createProject.id;

        await this._reportProjectToHubspot(projectId);
        await userStore.mergeUiState({ [NEW_PROJECT_USER_INIT]: false });

        // Adding a 10 attempt poll for billing account because we get a:
        // > billing_account not found (id: )
        // error by calling CreateService too quickly.
        let billingAccountAttempts = 0;
        await new Promise((resolve) => {
          const intervalId = setInterval(async () => {
            try {
              const { data: billingData } = await client.query({
                query: GET_BILLING_ACCOUNT,
                variables: { projectId },
              });
              if (
                billingData?.[GET_BILLING_ACCOUNT__TYPE]?.billingAccount?.id ||
                billingAccountAttempts > 10
              ) {
                clearInterval(intervalId);
                resolve(true);
              }
            } catch {}
            billingAccountAttempts += 1;
          }, 1000);
        });

        /**
         * allProjects is directly modified here to avoid running getAllProjects,
         * and as a result having all of those side effects ran. This is for the sake
         * of the createProject called in LeaveProjectModal. I simply want allProjects
         * to be updated after project creation, to make switching projects afterwards possible.
         */
        let allProjects = toJS(this.allProjects);
        let updatedAllProjects = concat(allProjects, data.createProject);

        runInAction(() => {
          this.allProjects = updatedAllProjects;
        });
        this._setStoreStatus(READY);
        return projectId;
      }
    } catch (e) {
      console.error("createProject: Network error ", e);
      this._setStoreStatus(ERROR);
      return null;
    }
  };

  changeProjectName = async (name: string) => {
    try {
      const { data, errors } = await client.mutate({
        variables: {
          projectId: this.projectId,
          name,
        },
        mutation: RENAME_PROJECT,
      });

      if (data) {
        runInAction(() => {
          if (this.currentProject) {
            this.currentProject = {
              ...this.currentProject,
              name,
            };
          }
        });
        notificationStore.showSuccessToaster(
          "Your project has been successfully renamed.",
        );
        this.getAllProjects();
      }

      if (errors) {
        notificationStore.showErrorToaster(
          `Something went wrong. Please try again.`,
        );
      }
    } catch (e) {
      console.error("changeProjectName: Network error", e);
    }
  };

  getAllProjects = async (currentProjectId?: string) => {
    try {
      const { data } = await client.query({
        query: GET_ALL_PROJECTS,
      });

      if (data) {
        const allProjectsExcludingCurrent = data.getAllProjects.filter(
          (project: Project) => project.id !== currentProjectId,
        );
        const flagState = await flagsmith.getState();
        if (flagState.identity) {
          flagsmith
            .setTrait(
              PROJECT_IDS,
              data.getAllProjects.map(({ id }: { id: string }) => id).join(),
            )
            .catch((e) =>
              console.log("flagsmith.setTrait failed in projectStore", e),
            );
        }
        if (!isEmpty(allProjectsExcludingCurrent)) {
          runInAction(() => {
            this.createDefaultProjectAttempts = 0;
            this.allProjects = data.getAllProjects;
          });

          // The current project should persist on page refresh by storing it within localStorage.
          // If the user is still a member of the stored current project, use it. If not, (arbitrarily)
          // set current project to be the first project returned.

          const isAMemberOfCurrentProject = data.getAllProjects?.some(
            (project: Project) =>
              localCurrentProject
                ? project.id === localCurrentProject.id
                : false,
          );

          runInAction(() => {
            !isAMemberOfCurrentProject
              ? (this.currentProject = data.getAllProjects?.[0])
              : (this.storeStatus = READY);
          });
        } else if (
          isEmpty(data.getAllProjects) &&
          userStore.userOrigin !== "organic"
        ) {
          await this.createProject({});
          await this.getAllProjects();
        } else if (
          isEmpty(data.getAllProjects) && //this is implied, but less confusing to make explicit
          this.createDefaultProjectAttempts === 0
        ) {
          runInAction(() => {
            this.createDefaultProjectAttempts = 1;
          });

          const projectId = await this.createProject({
            trialDays: DEFAULT_TRIAL_DAYS,
          });
          await this.getAllProjects();

          // Only pre-create a service for vector feature users
          // in the control group of the Onboarding experiment.
          // This is because the control group will continue to
          // see the old create a service flow, so we want to keep
          // the user's experience the same (pre-creating a service
          // for them if they have the vector ff on).
          if (
            !Statsig.getExperiment(EXP_CUSTOM_ONBOARDING.ID).getValue(
              EXP_CUSTOM_ONBOARDING.PARAMS.SHOW_FEATURE,
            ) &&
            featuresStore.isActiveFeature(VECTOR_FEATURE)
          ) {
            const { service, initialPassword } =
              await serviceStore.createService({ projectId });

            // Matches the form that is expected in CreateServiceComplete.
            const serviceData = {
              ...service,
              initialPassword,
            };

            // Because we kick off the service creation early, and show the
            // survey, we need to store the service data in sessionStorage
            // and grab it back in CreateServiceComplete, namely for
            // the password.
            sessionStorage.setItem(
              "preCreateService",
              JSON.stringify(serviceData),
            );
            // We set this flag to know when to show the very first
            // preCreateService, but clear it out after the user has been to
            // Create Service Complete page.
            sessionStorage.setItem("showPreCreateService", "true");
          }
        } else {
          this._setStoreStatus(ERROR);
        }
      }
    } catch (e) {
      commonStore.setIsDataAvailable(false);
      this._setStoreStatus(ERROR);
    }
  };

  /* plural because currently a user can be an admin of multiple projects */
  getOwnedProjects = async () => {
    try {
      let ownedProjects: Project[] = [];
      for (let i = 0; i < toJS(this.allProjects)?.length; i++) {
        const project = toJS(this.allProjects)[i];
        const projectMembers = await this.getProjectMembersAndInvites(
          project.id,
          true, // don't show error toasts for this call
        );

        const projectAdmins = projectMembers?.filter(
          (member) => member.role === Role.Admin,
        );

        const currentUserOwnsProject = projectAdmins?.some(
          (admin) =>
            admin.userId?.toString() === userStore.currentId.toString(),
        );

        if (currentUserOwnsProject) {
          ownedProjects.push(project);
        }
      }
      runInAction(() => {
        this.ownedProjects = ownedProjects;
      });
    } catch (err) {
      // all users should have at least *one* project!
      // if they have none, something is very wrong

      // todo: maybe show support banner?
      console.error("err: getOwnedProjects", err);
      runInAction(() => {
        this.ownedProjects = [];
      });
    }
  };

  switchProjects = async (projectId: string) => {
    runInAction(
      () =>
        (this.currentProject =
          this.allProjects.find((project) => project.id === projectId) || null),
    );

    // userID is actually the projectID for research reasons.
    await Statsig.updateUser({
      userID: projectId,
      email: userStore.currentEmail,
      customIDs: { timescaleUserId: userStore.currentId },
      custom: { project_created: this.currentProject?.created },
    });

    // add clarity identify calls
    if (window.clarity) {
      const projectId = projectsStore?.currentProject?.id ?? null;
      window.clarity("set", "userID", userStore.currentId);
      window.clarity("set", "projectID", projectId);
    }
  };

  getProjectMembersAndInvites = async (
    id: Maybe<string> = null,
    hideErrors: boolean = false,
  ): Promise<void | Member[] | null> => {
    const projectId = id ?? this.currentProject?.id;

    try {
      const { data: projectMembersData, errors: projectMembersErrors } =
        await client.query({
          variables: { projectId },
          query: GET_PROJECT_MEMBERS,
        });

      const { data: projectInvitesData, errors: projectInvitesErrors } =
        await client.query({
          variables: { projectId },
          query: GET_PROJECT_INVITES,
        });

      if (projectMembersData && projectInvitesData) {
        const projectMembers = projectMembersData.getProjectMembers.map(
          (member: ProjectMember) => {
            return {
              userId: member.userId,
              inviteId: null,
              joined: member.joined,
              created: null,
              email: member.user.email,
              declined: null,
              role: member.role,
              name: member.user.name,
              mfaMethods: member.user.mfaMethods,
            };
          },
        );

        let pendingMembers = projectInvitesData.getProjectInvites.map(
          (member: ProjectInvite) => {
            return {
              userId: null,
              inviteId: member.id,
              joined: null,
              created: member.created,
              email: member.email,
              declined: member.declined,
              role: member.role,
              name: null,
            };
          },
        );

        if (id) {
          return [...projectMembers, ...pendingMembers];
        } else {
          runInAction(() => {
            this.allMembers = [...projectMembers, ...pendingMembers];
          });
          return null;
        }
      } else if (
        !hideErrors &&
        (projectMembersErrors || projectInvitesErrors)
      ) {
        notificationStore.showErrorToaster(
          `There was a problem retrieving project members. Please try again.`,
        );
      }
    } catch (e) {
      console.error("getProjectMembersAndInvites: Network error", e);
    }
  };

  transferProjectOwnership = async ({
    password,
    authCode: mfaCode,
    newOwnerId,
    projectId = this.currentProject?.id,
  }: {
    password: string;
    authCode: string;
    newOwnerId: string;
    projectId: string | undefined;
  }) => {
    try {
      if (!projectId) {
        console.warn("Request aborted: Project ID is null");
      }
      const { data } = await client.mutate({
        mutation: TRANSFER_PROJECT_OWNERSHIP,
        variables: {
          projectId,
          newOwnerId,
          mfaCode,
          password,
        },
      });
      if (data) {
        notificationStore.showSuccessToaster(
          "Project ownership was transferred successfully",
        );
        await this.getProjectMembersAndInvites();
        return true;
      }
      throw Error();
    } catch (e) {
      notificationStore.showErrorToaster(
        "Something went wrong on our end!  Please try the transfer again.  If problems continue, please contact support",
      );
      return false;
    }
  };

  getBillingEmails = async () => {
    if (this.billingAccount) {
      try {
        if (!this.billingAccount.id) {
          console.warn("Request aborted: Billing account is null");
        }
        const { data, errors } = await client.query({
          query: GET_BILLING_EMAILS,
          variables: {
            billingAccountId: this.billingAccount?.id,
          },
        });

        if (data && this.projectAdmin) {
          const allEmails = [
            {
              id: 1,
              email: this.projectAdmin.email,
              billingAccountId: this.billingAccount.id,
              created: 0,
              activated: 123,
            },
            ...data.getBillingEmailAddresses,
          ];

          runInAction(() => {
            this.billingEmails = allEmails;
          });
        } else if (errors || !userStore.currentEmail) {
          notificationStore.showErrorToaster(
            "There was a problem retrieving your billing emails. Please try again.",
          );
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  async createBillingEmail({
    email,
    billingAccountId = this.billingAccount?.id,
  }: {
    email: string;
    billingAccountId?: string;
  }): Promise<readonly GraphQLError[] | void> {
    try {
      if (!billingAccountId) {
        console.warn("Request aborted: Billing account is null");
      }
      const { data, errors } = await client.mutate({
        variables: {
          email,
          billingAccountId,
        },
        mutation: CREATE_BILLING_EMAIL,
      });

      if (data) {
        this.getBillingEmails();
      } else if (errors) {
        notificationStore.showErrorToaster(
          "There was a problem adding the billing email. Please try again.",
        );
        return errors;
      }
    } catch (e) {
      console.error(e);
    }
  }

  async resendVerifyBillingEmail({
    billingEmailId,
    billingAccountId = this.currentProject?.billingAccount?.id,
  }: {
    billingEmailId: string;
    billingAccountId?: string;
  }) {
    try {
      if (!billingAccountId) {
        console.warn("Request aborted: Billing account is null");
      }
      const { data, errors } = await client.mutate({
        variables: {
          billingEmailId,
          billingAccountId,
        },
        mutation: RESEND_VERIFY_BILLING_EMAIL,
      });

      if (data) {
        notificationStore.showSuccessToaster(
          "The verification request has been successfully resent.",
        );
        return data;
      } else if (errors) {
        notificationStore.showErrorToaster(
          "There was a problem resending the verification email. Please try again.",
        );
      }
    } catch (e) {
      console.error(e);
    }
  }

  async deleteBillingEmail({
    email,
    billingEmailId,
  }: {
    email: string;
    billingEmailId: string;
  }): Promise<readonly GraphQLError[] | void> {
    try {
      if (!this.billingAccount?.id) {
        console.warn("Request aborted: Billing account is null");
      }

      const { data, errors } = await client.mutate({
        variables: {
          billingEmailId,
          billingAccountId: this.billingAccount?.id,
        },
        mutation: DELETE_BILLING_EMAIL,
      });

      if (data) {
        notificationStore.showSuccessToaster(
          `${email} has been successfully deleted.`,
        );

        await this.getBillingEmails();
      } else if (errors) {
        notificationStore.showErrorToaster(
          "There was a problem deleting the billing email. Please try again.",
        );

        return errors;
      }
    } catch (e) {
      console.error(e);
    }
  }

  setAddress = async ({ companyAddress }: { companyAddress: AddressInfo }) => {
    try {
      if (!this.billingAccount?.id) {
        console.warn("Request aborted: Billing account is null");
      }
      const { data, errors } = await client.mutate({
        variables: {
          billingAccountId: this.billingAccount?.id,
          companyAddress,
        },
        mutation: SET_ADDRESS_INFO,
      });
      if (data) {
        runInAction(() => {
          if (this.billingAccount) {
            this.billingAccount = {
              ...this.billingAccount,
              addressDetails: {
                ...data[SET_ADDRESS_INFO__TYPE],
              },
            };
          }
        });
      }
      return { data, errors };
    } catch (e) {
      console.error("setAddress: Network error ", e);
      return false;
    }
  };

  subscribeBillingAccount = () => {
    if (!this.projectId) return;

    try {
      this.unsubscribeBillingAccount();
      runInAction(() => {
        this.querySubscription = client.watchQuery({
          query: GET_BILLING_ACCOUNT,
          variables: { projectId: this.projectId },
          pollInterval: 60000,
        });

        this.refreshSubscription = this.querySubscription?.subscribe(
          ({ data }) => {
            data &&
              runInAction(() => {
                this.billingAccount =
                  data[GET_BILLING_ACCOUNT__TYPE].billingAccount;
              });
          },
        );
      });
    } catch (e) {
      console.error("getCurrentBillingAccount: Network error ", e);
    }
  };

  unsubscribeBillingAccount = () => {
    runInAction(() => {
      this.querySubscription?.stopPolling();
      this.querySubscription = null;
      this.refreshSubscription?.unsubscribe();
      this.refreshSubscription = null;
    });
  };

  fetchClientCredentials = async () => {
    const projectId = this.projectId;
    const { data, errors } = await client.query({
      variables: { projectId },
      query: GET_PAT_RECORDS,
    });

    if (data) {
      runInAction(() => {
        this.clientCredentials = data[GET_PAT_RECORDS__TYPE];
      });
    }

    if (errors) {
      notificationStore.showErrorToaster(
        "There was a problem retrieving your personal access tokens. Please try again.",
      );
    }
  };

  createClientCredential = async () => {
    const { data, errors } = await client.mutate({
      variables: {
        name: generateRandomName("tscloud-credentials"),
        projectId: this.projectId,
      },
      mutation: CREATE_PAT_RECORD,
    });

    if (data) {
      notificationStore.showSuccessToaster(
        "Client credentials successfully added.",
      );
      return data[CREATE_PAT_RECORD__TYPE];
    }

    if (errors) {
      notificationStore.showErrorToaster(
        "There was a problem creating your personal access token. Please try again.",
      );
    }
  };

  deleteClientCredential = async (accessKey: string) => {
    const { data, errors } = await client.mutate({
      variables: { accessKey },
      mutation: DELETE_PAT_RECORD,
    });

    if (data) {
      await this.fetchClientCredentials();
      notificationStore.showSuccessToaster(
        "Client credentials successfully deleted.",
      );
    }

    if (errors) {
      notificationStore.showErrorToaster(
        "There was a problem deleting your personal access token. Please try again.",
      );
    }
  };

  renameClientCredential = async ({
    accessKey,
    newName,
  }: {
    accessKey: string;
    newName: string;
  }) => {
    const { data, errors } = await client.mutate({
      variables: { accessKey, newName },
      mutation: RENAME_PAT_RECORD,
    });

    if (data) {
      await this.fetchClientCredentials();
      notificationStore.showSuccessToaster(
        "Client credentials successfully renamed.",
      );
    }

    if (errors) {
      notificationStore.showErrorToaster(
        "There was a problem renaming your personal access token. Please try again.",
      );
    }
  };

  getDataTieringSummary = async ({ projectId = this?.currentProject?.id }) => {
    try {
      const { data: dataTieringSummaryData } = await client.query({
        variables: {
          projectId: projectId,
        },
        query: GET_DATA_TIERING_SUMMARY,
      });

      const { data: dataTieringUsageDistributionData } = await client.query({
        variables: {
          projectId: projectId,
        },
        query: GET_COSTED_PROJECT_DATA_TIERING_USAGE_DISTRIBUTION,
      });

      const tieredPerServiceBytes: { [key: string]: number } = {};
      const savingsPerService: { [key: string]: number } = {};
      const dataTieringSummary =
        dataTieringSummaryData[GET_DATA_TIERING_SUMMARY__TYPE];
      const dataTieringUsageDistribution =
        dataTieringUsageDistributionData[
          GET_COSTED_PROJECT_DATA_TIERING_USAGE_DISTRIBUTION__TYPE
        ];

      dataTieringUsageDistribution.forEach((serviceStat: any) => {
        serviceStat.serviceIds.forEach((serviceId: string) => {
          tieredPerServiceBytes[serviceId] = tieredPerServiceBytes[serviceId]
            ? tieredPerServiceBytes[serviceId] + serviceStat.tieringUsageInBytes
            : serviceStat.tieringUsageInBytes;

          savingsPerService[serviceId] = savingsPerService[serviceId]
            ? savingsPerService[serviceId] +
              (serviceStat.estimatedEbsUsageCost -
                serviceStat.tieringUsageCost) *
                HOURS_PER_MONTH
            : (serviceStat.estimatedEbsUsageCost -
                serviceStat.tieringUsageCost) *
              HOURS_PER_MONTH;
        });
      });

      this.setDataTieringSummary({
        total:
          dataTieringSummary.length === 0
            ? undefined
            : convertUnit({
                value: dataTieringSummary.reduce(
                  (acc: Number, { tieredBytes }: TieringUsage) =>
                    Number(acc) + Number(tieredBytes),
                  0,
                ),
                from: "B",
              }),
        breakdown: dataTieringSummary,
        tieredPerServiceBytes,
        savingsPerService,
      });

      return dataTieringSummary;
    } catch (error) {
      console.error("getDataTieringSummary" + error);
    }
  };

  setDataTieringSummary = async (dataTierSummary: DataTieringSummary) => {
    runInAction(() => {
      this.dataTieringSummary = dataTierSummary;
    });
  };

  getVirtualPlans = async () => {
    try {
      const { data } = await client.query({
        query: GET_VIRTUAL_PLANS,
      });

      if (data) {
        return data.getVirtualPlans.plans;
      } else {
        this._setStoreStatus(ERROR);
        return [];
      }
    } catch (e) {
      commonStore.setIsDataAvailable(false);
      this._setStoreStatus(ERROR);
      return e;
    }
  };

  getPlanDowngradeCheck = async (
    projectId: string,
    downgradeTo: VirtualPlanType,
  ) => {
    try {
      const { data } = await client.query({
        query: GET_PLAN_DOWNGRADE_CHECK,
        variables: { projectId, downgradeTo },
      });

      if (data) {
        return data.downgradeCheck.dependencies;
      } else {
        this._setStoreStatus(ERROR);
        return [];
      }
    } catch (e) {
      commonStore.setIsDataAvailable(false);
      this._setStoreStatus(ERROR);
      return e;
    }
  };

  setVirtualPlanForProject = async (
    projectId: string,
    virtualPlanKind: VirtualPlanType,
  ) => {
    try {
      const { data, errors } = await client.mutate({
        variables: {
          projectId,
          virtualPlanKind,
        },
        mutation: SET_VIRTUAL_PLAN_FOR_PROJECT,
      });
      if (data.setVirtualPlanForProject === "success") {
        runInAction(async () => {
          if (this.currentProject) {
            try {
              const { data } = await client.query({
                query: GET_PROJECT,
                variables: { id: projectId },
              });
              this.currentProject = {
                ...this.currentProject,
                entitlementSpec: {
                  ...data.getProject.entitlementSpec,
                } as EntitlementSpec,
              };
            } catch (e) {
              console.error("setVirtualPlanForProject: Network error", e);
            }
          }
        });
        return { data };
      }
      return { data: { setVirtualPlanForProject: "error" }, errors };
    } catch (e) {
      return { data: { setVirtualPlanForProject: "error" }, errors: e };
    }
  };

  getOrbCost = async (projectId: string, billingAccountId: string) => {
    try {
      const { data, errors } = await client.query({
        query: GET_ORB_COST,
        variables: {
          projectId,
          billingAccountId,
        },
      });
      if (data) {
        return data[GET_ORB_COST__TYPE];
      }
      return { data: { getOrbCost: "error" }, errors };
    } catch (e) {
      return { data: { getOrbCost: "error" }, errors: e };
    }
  };

  getProjectSecrets = async (projectId = this.currentProject?.id) => {
    if (Statsig.checkGate(FEAT_AI_EMBEDDINGS.GATE)) {
      try {
        const { data } = await client.query({
          query: GET_PROJECT_SECRETS,
          variables: {
            projectId,
          },
        });
        if (data) {
          runInAction(() => {
            this.projectSecrets = data[GET_PROJECT_SECRETS__TYPE];
          });
        }
      } catch (e) {
        console.error("getProjectSecrets: Network error", e);
      }
    }
  };

  createProjectSecret = async ({
    projectId = this.currentProject?.id,
    name,
    value,
  }: {
    projectId?: string;
    name: string;
    value: string;
  }) => {
    try {
      const { data, errors } = await client.mutate({
        mutation: CREATE_PROJECT_AI_SECRET,
        variables: {
          projectId,
          name,
          value,
        },
      });
      if (data) {
        notificationStore.showSuccessToaster(
          "Congratulations! Your AI APi Key was successfully added to your Timescale Project.",
        );

        this.getProjectSecrets();
        return data.createProjectSecret;
      }
      if (errors) {
        notificationStore.showErrorToaster(
          `On no! We couldn’t add your AI API Key. Error: ${errors[0].message}.`,
        );
        return errors;
      }
    } catch (e) {
      console.error("createProjectSecret: Network error", e);
    }
  };

  updateProjectSecret = async ({
    projectId = this.currentProject?.id,
    name,
    value,
  }: {
    projectId?: string;
    name: string;
    value: string;
  }) => {
    try {
      const { data, errors } = await client.mutate({
        mutation: UPDATE_PROJECT_AI_SECRET,
        variables: {
          projectId,
          name,
          value,
        },
      });
      if (data) {
        notificationStore.showSuccessToaster(
          "Congratulations! Your AI API Credential was successfully edited.",
        );
        this.getProjectSecrets();
      }
      if (errors) {
        notificationStore.showErrorToaster(
          `On no! We couldn’t update your AI API Key. Error: ${errors[0].message}.`,
        );
      }
    } catch (e) {
      console.error("updateProjectSecret: Network error", e);
    }
  };

  deleteProjectSecret = async ({
    projectId = this.currentProject?.id,
    name,
  }: {
    projectId?: string;
    name: string;
  }) => {
    try {
      const { data, errors } = await client.mutate({
        mutation: DELETE_PROJECT_AI_SECRET,
        variables: {
          projectId,
          name,
        },
      });
      if (data) {
        notificationStore.showSuccessToaster(
          "Your AI API Credential was successfully deleted from your Timescale project.",
        );
        this.getProjectSecrets();
      }
      if (errors) {
        notificationStore.showErrorToaster(
          `On no! We couldn’t delete your AI API Key. Error: ${errors[0].message}`,
        );
      }
    } catch (e) {
      console.error("deleteProjectSecret: Network error", e);
    }
  };
}

const projectsStore = new ProjectsStore();

export default projectsStore;
