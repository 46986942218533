import Button from "components/button/Button";
import { Image } from "@chakra-ui/react";
import Rocket from "assets/illustrations/plans/rocket.svg";
import { GradientText } from "components/gradients/GradientText";
import Hover from "components/tooltip/Hover/Hover";

export const UpgradeBillingPlanButton = ({
  onClick,
  isLeftNavMinified,
}: {
  onClick: () => void;
  isLeftNavMinified: boolean;
}) => {
  return (
    <Hover
      theme="gradient"
      placement="right"
      content={
        <GradientText gradient="purpleToPink.90400">
          Upgrade to Scale
        </GradientText>
      }
      disabled={!isLeftNavMinified}
    >
      <Button
        sx={{
          "&:hover": {
            bg: "whiteToPink.90100",
            "&>p": {
              textStyle: "baseBold",
            },
          },
          "&:focus": {
            bg: "whiteToPink.90200",
            "&>p": {
              textStyle: "baseBold",
            },
          },
          "&:active": {
            bg: "whiteToPink.90200",
            "&>p": {
              textStyle: "baseBold",
            },
          },
        }}
        p="8px"
        variant="ghost"
        borderRadius="8px"
        justifyContent="unset"
        width="100%"
        minWidth="unset"
        icon={<Image src={Rocket} />}
        onClick={onClick}
      >
        {!isLeftNavMinified ? (
          <GradientText gradient="purpleToPink.90400" textStyle="base">
            Upgrade to Scale
          </GradientText>
        ) : (
          ""
        )}
      </Button>
    </Hover>
  );
};
