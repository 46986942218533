import {
  Box,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Flex,
  StyleProps,
  SystemStyleObject,
} from "@chakra-ui/react";
import Button from "components/button/Button";
import IconButton from "components/button/IconButton";
import { CSSProperties } from "react";
import { ChakraSizes } from "types";
import { ModalCTA } from "../modal/modalTypes";

export type RightDrawerButtonsProps = StyleProps & {
  primaryCTA: ModalCTA;
  secondaryCTA?: ModalCTA;
  buttonProps?: Record<string, any>;
};

export const RightDrawerButtons = ({
  primaryCTA,
  secondaryCTA,
  buttonProps,
  ...rest
}: RightDrawerButtonsProps) => {
  return (
    <DrawerFooter gap="8px" {...rest}>
      {secondaryCTA && (
        <Button
          variant="secondary"
          onClick={secondaryCTA.onClick}
          isLoading={secondaryCTA.isLoading}
          isDisabled={secondaryCTA.isDisabled}
          w="100%"
          maxW="unset"
          {...buttonProps}
        >
          {secondaryCTA.text}
        </Button>
      )}
      <Button
        onClick={primaryCTA.onClick}
        isLoading={primaryCTA.isLoading}
        isDisabled={primaryCTA.isDisabled}
        data-cy={primaryCTA.dataCy}
        w="100%"
        maxW="unset"
        {...buttonProps}
      >
        {primaryCTA.text}
      </Button>
    </DrawerFooter>
  );
};

export const RightDrawer = ({
  title,
  image,
  children,
  isOpen,
  size = "lg",
  dataCy,
  closeButtonDataCy,
  onClose = () => {},
  closeOnOverlayClick = true,
  styles,
  closeButtonStyles,
}: {
  title: string | JSX.Element;
  image?: JSX.Element;
  children: JSX.Element | JSX.Element[] | string;
  isOpen: boolean;
  size?: ChakraSizes;
  dataCy?: string;
  closeButtonDataCy?: string;
  onClose?: () => void;
  closeOnOverlayClick?: boolean;
  styles?: CSSProperties;
  closeButtonStyles?: SystemStyleObject;
}) => {
  return (
    <Drawer
      isOpen={isOpen}
      closeOnOverlayClick={closeOnOverlayClick}
      placement="right"
      onClose={onClose}
      size={size}
    >
      <DrawerOverlay />
      <DrawerContent overflowY="auto" style={styles} data-cy={dataCy ?? ""}>
        <Flex ml="auto" mb="16px" sx={closeButtonStyles}>
          <IconButton
            variant="ghostIcon"
            label="Close"
            iconName={"actions/Close/Large"}
            color="grayscale.800"
            onClick={onClose}
            data-cy={closeButtonDataCy ?? "close-btn"}
          />
        </Flex>
        <Flex direction="column" gap="16px" h="100%">
          <Flex gap="8px" alignItems="center">
            {image && <Box>{image}</Box>}
            <Box as="span" textStyle="h3" width="100%">
              {title}
            </Box>
          </Flex>

          {children}
        </Flex>
      </DrawerContent>
    </Drawer>
  );
};
