import { ThemeColors } from "../../theme/foundations/colors";
import Icon from "../icon/Icon";
import { CSSObject, StyleProps } from "@chakra-ui/react";

export interface CloseButtonProps extends StyleProps {
  fill?: ThemeColors;
  size?: "Large" | "Small";
  onClick?: () => void;
  sx?: CSSObject;
}

const CloseButton = ({
  fill = "grayscale.800",
  size = "Small",
  onClick,
  sx,
  ...customStyles
}: CloseButtonProps) => (
  <Icon
    name={`actions/Close/${size}`}
    fill={fill}
    onClick={onClick}
    _hover={{ cursor: "pointer" }}
    sx={sx}
    {...customStyles}
  />
);
export default CloseButton;
