import { StyleProps } from "@chakra-ui/react";
import { CurrencyCode } from "utils/currencyCodeTypes";
import { DEFAULT_TRIAL_DAYS } from "stores/constants";

type PriceBoxVariant = "standard" | "freetrial" | "noprice";

export interface PriceBoxProps extends StyleProps {
  showTotal?: boolean;
  variant?: PriceBoxVariant;
  lineItems: PriceInfo[];
  totalMonthly: number;
  totalHourly: number;
  currency?: CurrencyCode;
}

export interface PriceInfo {
  id: keyof typeof LineItem;
  label: string;
  amountInUSD?: number;
  show?: boolean;
}

export const strings = {
  monthly: "Monthly estimates are based on an average of 730 hours per month.",
  hourly:
    "Based on the current snapshot of compute, storage and features enabled for the service.",
};

export const NUM_PADDING = 3;

export const strikePriceStyle = (variant: PriceBoxVariant) => {
  switch (true) {
    case variant === "freetrial":
      return {
        color: "grayscale.400",
        textDecoration: "line-through",
        fontWeight: "medium",
      };
    default:
      return {};
  }
};

export const TrialDaysLeftLiteral = ({
  trialDaysLeft,
}: {
  trialDaysLeft: number;
}) => {
  return (
    <span>
      {trialDaysLeft} day{trialDaysLeft > 1 ? "s" : ""} left of your{" "}
      {DEFAULT_TRIAL_DAYS}-day trial
    </span>
  );
};

export const LineItem = {
  COMPUTE: "COMPUTE",
  STORAGE: "STORAGE",
  DATATIERING: "DATATIERING",
  VPC: "VPC",
  POOLER: "POOLER",
} as const;
