import notificationStore from "./notificationStore";
import {
  GET_ALL_GENERIC_EXPORTERS,
  GET_ALL_GENERIC_EXPORTERS__TYPE,
  GET_ALL_METRIC_EXPORTERS,
  GET_ALL_METRIC_EXPORTERS__TYPE,
} from "../api/query";
import { Exporter } from "../types";
import { makeAutoObservable, reaction, runInAction } from "mobx";
import { generateRandomName } from "../utils/utilFunctions";
import {
  ExporterDataType,
  ExporterType,
} from "../pages/integrations/integrationTypes";
import {
  GenericExporterConfigInput,
  MetricExporterConfigInput,
} from "../graphql/generated";
import {
  CREATE_GENERIC_EXPORTER,
  CREATE_METRIC_EXPORTER,
  DELETE_GENERIC_EXPORTER,
  DELETE_METRIC_EXPORTER,
  UPDATE_GENERIC_EXPORTER,
  UPDATE_METRIC_EXPORTER,
} from "../api/mutations";
import client from "../api/client";
import { EXPORTER_TYPES } from "./constants";
import projectsStore from "./projectsStore";

const providerMap = {
  [EXPORTER_TYPES.DATADOG]: "configDatadog",
  [EXPORTER_TYPES.CLOUDWATCH]: "configCloudWatch",
  [EXPORTER_TYPES.PROMETHEUS]: "configPrometheus",
};

export class ExporterStore {
  allExporters: Exporter[] = [];

  constructor() {
    makeAutoObservable(this);
    reaction(
      () => projectsStore.currentProject,
      (currentProject) => {
        if (currentProject?.id) {
          this.fetchAllExporters();
        }
      },
    );
  }

  fetchAllExporters = async ({
    projectId = projectsStore.currentProject?.id,
  } = {}) => {
    try {
      const results = await Promise.all([
        client.query({
          query: GET_ALL_METRIC_EXPORTERS,
          variables: {
            projectId,
          },
        }),
        client.query({
          query: GET_ALL_GENERIC_EXPORTERS,
          variables: {
            projectId,
          },
        }),
      ]);

      const allExporters = results.map((res) => res.data);

      const metricsExporters = (
        structuredClone(allExporters[0]?.[GET_ALL_METRIC_EXPORTERS__TYPE]) || []
      ).map((exporter: Exporter) => ({ ...exporter, dataType: "METRICS" }));

      const genericExporters = (
        structuredClone(allExporters[1]?.[GET_ALL_GENERIC_EXPORTERS__TYPE]) ||
        []
      ).map((exporter: Exporter) => ({ ...exporter, dataType: "LOGS" }));

      runInAction(
        () =>
          (this.allExporters = [...metricsExporters, ...genericExporters].sort(
            (exporter) => exporter.created,
          )),
      );

      if (results.find((res) => res.errors)) {
        notificationStore.showErrorToaster("Unable to fetch exporters");
      }
    } catch (e) {
      console.error("Network error", e);
    }
  };

  createMetricExporter = async ({
    projectId = projectsStore.currentProject?.id,
    name = generateRandomName("exporter"),
    provider,
    regionCode,
    config,
  }: {
    projectId?: string;
    name?: string;
    provider: ExporterType;
    regionCode: string;
    config: MetricExporterConfigInput;
  }) => {
    try {
      const { errors } = await client.mutate({
        mutation: CREATE_METRIC_EXPORTER,
        variables: {
          projectId,
          name,
          regionCode,
          config: { [providerMap[provider]]: config },
        },
      });
      if (errors?.[0]?.message) {
        notificationStore.showErrorToaster(
          "Error creating metrics exporter.  Please try again",
        );
        return false;
      } else {
        notificationStore.showSuccessToaster(
          "Your exporter has been successfully created.",
        );
        await this.fetchAllExporters();
        return true;
      }
    } catch {
      notificationStore.showErrorToaster(
        "Error creating metrics exporter. Please try again",
      );
      return false;
    }
  };

  createGenericExporter = async ({
    projectId = projectsStore.currentProject?.id,
    name = generateRandomName("exporter"),
    regionCode,
    config,
  }: {
    projectId?: string;
    name: string;
    regionCode: string;
    config: GenericExporterConfigInput;
  }) => {
    try {
      // @ts-ignore
      delete config.namespace;
      const { errors } = await client.mutate({
        mutation: CREATE_GENERIC_EXPORTER,
        variables: {
          projectId,
          name,
          region: regionCode,
          type: "CLOUDWATCH",
          dataType: "LOG",
          config: { configCloudWatch: config },
        },
      });
      if (errors?.[0]?.message) {
        notificationStore.showErrorToaster(
          "Error creating logs exporter.  Please try again",
        );
        return false;
      } else {
        notificationStore.showSuccessToaster(
          "Your exporter has been successfully created",
        );
        await this.fetchAllExporters();
        return true;
      }
    } catch {
      notificationStore.showErrorToaster(
        "Error creating logs exporter. Please try again",
      );
      return false;
    }
  };

  updateExporter = async ({
    projectId = projectsStore.currentProject?.id,
    name,
    provider,
    config,
    exporterId,
    dataType,
  }: {
    projectId?: string;
    name: string;
    provider: ExporterType;
    config: MetricExporterConfigInput | GenericExporterConfigInput;
    exporterId: string;
    dataType: ExporterDataType;
  }) => {
    const isGeneric = dataType === "LOGS";
    try {
      const { errors } = await client.mutate({
        mutation: isGeneric ? UPDATE_GENERIC_EXPORTER : UPDATE_METRIC_EXPORTER,
        variables: {
          projectId,
          exporterId,
          name,
          config: { [providerMap[provider]]: config },
        },
      });
      if (errors) {
        notificationStore.showErrorToaster(
          "Error updating exporter. Please try again",
        );
        return false;
      } else {
        notificationStore.showSuccessToaster(
          "Your exporter has been successfully updated.",
        );
        await this.fetchAllExporters();
        return true;
      }
    } catch {
      notificationStore.showErrorToaster(
        "Error updating exporter. Please try again",
      );
      return false;
    }
  };

  deleteExporter = async ({
    projectId = projectsStore.currentProject?.id,
    exporter,
  }: {
    projectId?: string;
    exporter: Exporter;
  }) => {
    const isGeneric = exporter.dataType === "LOGS";
    try {
      const { errors } = await client.mutate({
        mutation: isGeneric ? DELETE_GENERIC_EXPORTER : DELETE_METRIC_EXPORTER,
        variables: {
          projectId,
          exporterId: exporter.id,
        },
      });
      this.fetchAllExporters();
      if (errors) {
        notificationStore.showErrorToaster(
          `Error deleting ${isGeneric ? "LOGS" : "METRICS"} exporter`,
        );
      } else {
        notificationStore.showSuccessToaster("Exporter successfully deleted.");
      }
    } catch {
      notificationStore.showErrorToaster("Error deleting exporter");
    }
  };
}

const exporterStore = new ExporterStore();

export default exporterStore;
