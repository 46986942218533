import { StyleProps, Text } from "@chakra-ui/react";
import type { AnimationStyleType } from "./types";
import { ThemeColors } from "theme/foundations/colors";

export const GradientText = ({
  gradient = "grayscale.200",
  children,
  animationStyle = "bounce-bg",
  animationDuration = "8s",
  dataCy = "gradient-text",
  ...customStyles
}: StyleProps & {
  gradient: ThemeColors;
  children: String;
  animationStyle?: AnimationStyleType;
  animationDuration?: string;
  dataCy?: string;
}) => {
  return (
    <Text
      style={{
        backgroundClip: "text",
        WebkitBackgroundClip: "text",
        color: "transparent",
        animation: `${animationStyle} ${animationDuration} infinite linear`,
      }}
      background={gradient}
      data-cy={dataCy}
      {...customStyles}
    >
      {children}
    </Text>
  );
};
