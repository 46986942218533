import { ComponentStyleConfig } from "@chakra-ui/react";
import { merge } from "lodash";
import { inputStyle } from "./input";

const fieldProps = inputStyle.variants.outline.field; //tricksy...

export const textareaStyle = merge(
  {},
  inputStyle,
  {
    variants: {
      outline: { ...fieldProps },
    },
  },

  {
    defaultProps: {
      resize: "vertical",
    },
    variants: {
      outline: {
        height: "94px",
      },
    },
  },
);

export const Textarea: ComponentStyleConfig = textareaStyle;
