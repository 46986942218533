import {
  observable,
  reaction,
  toJS,
  makeAutoObservable,
  runInAction,
} from "mobx";
import { isEmpty } from "lodash";
import {
  GetPostgresParametersQuery,
  NumericParameter,
  StringParameter,
  SetNumericParameter,
  SetStringParameter,
} from "graphql/generated";
import { DatabaseParameter } from "pages/project/service/operations/databaseParameters/databaseParametersTypes";
import { client } from "api/client";
import projectsStore from "./projectsStore";
import notificationStore from "./notificationStore";
import serviceStore from "./serviceStore";
import {
  RESET_POSTGRES_PARAMETERS,
  SET_POSTGRES_PARAMETERS,
} from "api/mutations";
import { GET_POSTGRES_PARAMETERS } from "api/query";
import {
  LAST_FETCHED_DATABASE_PARAMETERS_BY_NODE,
  REQUESTED_DATABASE_PARAMETERS_BY_NODE,
  PENDING_DATABASE_PARAMETERS_BY_NODE,
  getFromLocalStorage,
  storeDirectlyIntoLocalStorage,
  LOCAL_STORAGE_USER_DATA,
} from "utils/localStorage";
import { STATUS } from "utils/config";
import { ApolloQueryResult } from "@apollo/client";
import { isEqual } from "lodash";
import { StoreStatus } from "./constants";
import { TIME, SIZE } from "types";
import { convertUnit } from "../utils/utilFunctions";
import { Data, Time } from "convert";

type OptionRateMap = {
  [x: string]: Time | Data;
};

const [timeRate, sizeRate] = [TIME, SIZE].map((options) =>
  options.reduce((map: OptionRateMap, option) => {
    map[option.value as string] = option.rate;
    return map;
  }, {}),
);

const chooseOptionsMap = (paramValue: string) => {
  return SIZE.map(({ value }) => value).includes(paramValue)
    ? sizeRateMap
    : timeRateMap;
};

export const timeRateMap = timeRate;
export const sizeRateMap = sizeRate;

export type DatabaseParametersCollection = {
  [serviceId: string]: Array<DatabaseParameter>;
};

export type RequestedDatabaseParameters = {
  [serviceId: string]: {
    params?: {
      [name: string]: DatabaseParameter;
    };
    isRestartRequired?: boolean;
    resetToDefault?: boolean;
  };
};

export type PendingDatabaseParameters = {
  [serviceId: string]: {
    [name: string]: DatabaseParameter;
  };
};

export type IdSet = {
  serviceId?: string;
  projectId?: string;
};

export const outOfRange = (param?: DatabaseParameter) => {
  if (param && param.__typename === "NumericParameter") {
    if (
      param.max_allowed_value &&
      param.current_value > param.max_allowed_value
    ) {
      return "out of range";
    } else if (
      param.min_allowed_value &&
      param.current_value < param.min_allowed_value
    ) {
      return "out of range";
    }
  }
  return "";
};

//This is the list of parameters that don't show the correct values in the UI
//https://github.com/timescale/Support-Dev-Collab/issues/1185#issuecomment-1672085035
export const mismatchedParameters = [
  "log_min_duration_statement",
  "log_statement",
  "pg_stat_statements.track",
];

export class DatabaseParametersStore {
  //last parameters pulled from API query
  lastFetchedDatabaseParameters: DatabaseParametersCollection =
    getFromLocalStorage(
      LAST_FETCHED_DATABASE_PARAMETERS_BY_NODE,
      LOCAL_STORAGE_USER_DATA,
    ) || {};
  //last parameters sent with API mutation request that haven't yet been fulfilled
  requestedDatabaseParameters: RequestedDatabaseParameters =
    getFromLocalStorage(
      REQUESTED_DATABASE_PARAMETERS_BY_NODE,
      LOCAL_STORAGE_USER_DATA,
    ) || {};
  //parameter values according to the console UI, but nowhere else
  pendingDatabaseParameters: PendingDatabaseParameters =
    getFromLocalStorage(
      PENDING_DATABASE_PARAMETERS_BY_NODE,
      LOCAL_STORAGE_USER_DATA,
    ) || {};

  storeStatus: StoreStatus = "NOT_INITIATED";

  queryWatchers: { [serviceId: string]: any } = {};
  constructor() {
    makeAutoObservable(this, {
      lastFetchedDatabaseParameters: observable.struct,
      requestedDatabaseParameters: observable.struct,
      pendingDatabaseParameters: observable.struct,
    });

    reaction(
      () => this.lastFetchedDatabaseParameters,
      (params) => {
        storeDirectlyIntoLocalStorage(
          LAST_FETCHED_DATABASE_PARAMETERS_BY_NODE,
          toJS(params),
          LOCAL_STORAGE_USER_DATA,
        );
      },
    );
    reaction(
      () => this.requestedDatabaseParameters,
      (params) => {
        storeDirectlyIntoLocalStorage(
          REQUESTED_DATABASE_PARAMETERS_BY_NODE,
          toJS(params),
          LOCAL_STORAGE_USER_DATA,
        );
      },
    );
    reaction(
      () => this.pendingDatabaseParameters,
      (params) => {
        storeDirectlyIntoLocalStorage(
          PENDING_DATABASE_PARAMETERS_BY_NODE,
          toJS(params),
          LOCAL_STORAGE_USER_DATA,
        );
      },
    );
    reaction(
      () => projectsStore?.currentProject?.id,
      (id) => {
        Object.keys(this.requestedDatabaseParameters || {})?.forEach(
          (serviceId) => {
            this.subscribeToDatabaseParameters({
              serviceId,
              projectId: id,
            });
          },
        );
      },
    );
  }

  deleteQueryWatcher = (serviceId: string = "") => {
    if (this.queryWatchers?.[serviceId]) {
      this.queryWatchers[serviceId]?.watcher?.stopPolling();
      this.queryWatchers[serviceId]?.subscription?.unsubscribe();
      const { [serviceId]: _, ...rest } = this.queryWatchers;
      runInAction(() => {
        this.queryWatchers = rest;
      });
    }
  };

  getParamFromLastFetched = ({
    serviceId,
    paramName,
  }: {
    serviceId: string;
    paramName: string;
  }) => {
    return (this.lastFetchedDatabaseParameters[serviceId] || []).find(
      (param) => param.info.name === paramName,
    );
  };

  setPendingDatabaseParameters = ({
    params,
    serviceId,
  }: {
    params: PendingDatabaseParameters[string];
    serviceId: string;
  }) => {
    runInAction(() => {
      this.pendingDatabaseParameters = {
        ...this.pendingDatabaseParameters,
        [serviceId]: params,
      };
    });
  };

  setSinglePendingDatabaseParameter = ({
    param,
    serviceId,
  }: {
    param: DatabaseParameter;
    serviceId: string;
  }) => {
    if (
      isEqual(
        param,
        this.getParamFromLastFetched({
          serviceId,
          paramName: param.info.name,
        }),
      )
    ) {
      //param set to current --> no longer pending
      const { [param.info.name]: _, ...exceptPending } =
        this.pendingDatabaseParameters[serviceId] || {};
      runInAction(() => {
        this.pendingDatabaseParameters = {
          ...this.pendingDatabaseParameters,
          [serviceId]: exceptPending,
        };
      });
    } else {
      runInAction(() => {
        this.pendingDatabaseParameters = {
          ...this.pendingDatabaseParameters,
          [serviceId]: {
            ...this.pendingDatabaseParameters[serviceId],
            [param.info.name]: param,
          },
        };
      });
    }
  };

  cancelPendingDatabaseParameters = ({
    serviceId = serviceStore?.serviceId,
  }: IdSet = {}) => {
    runInAction(() => {
      this.pendingDatabaseParameters = {
        ...this.pendingDatabaseParameters,
        [serviceId]: {},
      };
    });
  };

  fetchDatabaseParameters = async ({
    serviceId = serviceStore?.serviceId,
    projectId = projectsStore?.currentProject?.id,
  } = {}) => {
    if (serviceStore.serviceById(serviceId)?.status === STATUS.READY) {
      try {
        runInAction(() => {
          this.storeStatus = "LOADING";
        });

        const { data, errors } = await client.query({
          variables: { serviceId, projectId },
          query: GET_POSTGRES_PARAMETERS,
        });
        this._handleFetchedDatabaseParameters(serviceId)({ data, errors });
      } catch {
        notificationStore.showErrorToaster("Error fetching parameters");
      }
    }
  };

  _handleFetchedDatabaseParameters =
    (serviceId: string = serviceStore.serviceId) =>
    async ({
      data,
      errors,
    }: Partial<ApolloQueryResult<GetPostgresParametersQuery>>) => {
      if (errors && /no service.+id.+exists/i.test(errors[0].message)) {
        runInAction(() => {
          //handle deleted service
          this.requestedDatabaseParameters = {
            ...this.requestedDatabaseParameters,
            [serviceId]: {},
          };
        });
        this.deleteQueryWatcher(serviceId);
      } else if (errors) {
        const service = await serviceStore.fetchService({ serviceId });
        if (errors && service?.status === STATUS.READY) {
          notificationStore.showErrorToaster(
            "Sorry, there was an error reading service settings. If this does not resolve, please contact us through support",
          );
        }
        this.storeStatus = "ERROR";
      } else {
        //update last fetched parameters
        const lastFetched = [
          ...(data?.getPostgresParameters?.string_parameters || []),
          ...(data?.getPostgresParameters?.numeric_parameters || []),
        ];
        runInAction(() => {
          this.lastFetchedDatabaseParameters = {
            ...this.lastFetchedDatabaseParameters,
            [serviceId]: lastFetched,
          };
          this.storeStatus = "READY";
        });

        //remove requested changes if they have been fulfilled
        const requestedChanges = this.requestedDatabaseParameters[serviceId];
        if (!isEmpty(requestedChanges)) {
          let allParamsMatch = true;
          for (let name in requestedChanges?.params) {
            const requestParam = requestedChanges.params[name];
            const lastFetchedParam = lastFetched.find(
              (param) => param.info.name === name,
            );
            if (
              requestParam.__typename === "NumericParameter" &&
              lastFetchedParam?.__typename === "NumericParameter" &&
              requestParam.unit !== lastFetchedParam.unit
            ) {
              const optionsMap = chooseOptionsMap(requestParam.unit);
              const convertedRequest = convertUnit({
                value: requestParam.current_value,
                from: optionsMap[requestParam.unit],
                to: optionsMap[lastFetchedParam.unit],
              });
              if (convertedRequest.value !== lastFetchedParam.current_value) {
                allParamsMatch = false;
              }
            } else if (!isEqual(lastFetchedParam, requestParam)) {
              allParamsMatch = false;
              break;
            }
          }
          if (allParamsMatch === !requestedChanges.resetToDefault) {
            runInAction(() => {
              this.requestedDatabaseParameters = {
                ...this.requestedDatabaseParameters,
                [serviceId]: {},
              };
              this.deleteQueryWatcher(serviceId);
              //reset pending parameters
              this.pendingDatabaseParameters = {
                ...this.pendingDatabaseParameters,
                [serviceId]: {},
              };
            });
            notificationStore.showSuccessToaster(
              `Settings for ${
                serviceStore.serviceById(serviceId)?.name || "service"
              } updated`,
            );
          }
        }
      }
    };

  subscribeToDatabaseParameters = async ({
    serviceId = serviceStore.serviceId,
    projectId = projectsStore?.currentProject?.id,
  }: IdSet = {}) => {
    try {
      this.deleteQueryWatcher(serviceId);
      const watcher = client.watchQuery({
        variables: { serviceId, projectId },
        query: GET_POSTGRES_PARAMETERS,
        pollInterval: 5000,
      });
      const subscription = watcher.subscribe(
        this._handleFetchedDatabaseParameters(serviceId),
      );
      runInAction(() => {
        this.queryWatchers = {
          ...this.queryWatchers,
          [serviceId]: { watcher, subscription },
        };
      });
    } catch {
      notificationStore.showErrorToaster("Error fetching parameters");
    }
  };

  requestDatabaseParametersChange = async ({
    serviceId = serviceStore.serviceId,
    projectId = projectsStore?.currentProject?.id,
  }: IdSet) => {
    //Sets a list of parameters for mutation, and a separate list to keep track of requested changes
    try {
      const matchedPendingParameters: { [key: string]: DatabaseParameter } = {};
      const numericParameters: SetNumericParameter[] = [];
      const stringParameters: SetStringParameter[] = [];
      let isRestartRequired = false;
      for (let name in this.pendingDatabaseParameters?.[serviceId] || {}) {
        const pendingParam = this.pendingDatabaseParameters?.[serviceId]?.[
          name
        ] as DatabaseParameter;
        if (
          pendingParam.__typename === "NumericParameter" &&
          !outOfRange(pendingParam)
        ) {
          numericParameters.push({
            name,
            value: pendingParam.current_value,
            unit: pendingParam.unit,
          });
        } else if (pendingParam.__typename === "StringParameter") {
          stringParameters.push({ name, value: pendingParam.current_value });
        }
        if (pendingParam.info.requires_restart) {
          isRestartRequired = true;
        }
        if (mismatchedParameters.every((mismatched) => mismatched !== name)) {
          matchedPendingParameters[name] = pendingParam;
        }
      }
      const { data, errors } = await client.mutate({
        variables: {
          projectId,
          serviceId,
          numericParameters,
          stringParameters,
        },
        mutation: SET_POSTGRES_PARAMETERS,
      });
      if (data) {
        //check for errors on individual parameters
        if (isEmpty(data.setPostgresParameters)) {
          notificationStore.showInfoToaster("Updating in progress... ");
          //move a copy of pending parameters into requested parameters
          runInAction(() => {
            //Only store parameters that show up properly in the UI
            this.pendingDatabaseParameters = {
              ...this.pendingDatabaseParameters,
              [serviceId]: matchedPendingParameters,
            };
            this.requestedDatabaseParameters = {
              ...this.requestedDatabaseParameters,
              [serviceId]: {
                params: toJS(this.pendingDatabaseParameters[serviceId]),
                isRestartRequired,
                resetToDefault: false,
              },
            };
          });
          await this.subscribeToDatabaseParameters({ serviceId, projectId });
          notificationStore.showInfoToaster("Updating in progress... ");
        } else {
          throw Error;
        }
      } else if (errors) {
        notificationStore.showErrorToaster(
          "Sorry! There was an error while initiating your update.  Please try again, or contact us through support",
        );
      }
    } catch {
      notificationStore.showErrorToaster(
        "Sorry! There was an error while initiating your update.  Please try again, or contact us through support",
      );
    }
  };

  requestDatabaseParametersReset = async ({
    serviceId = serviceStore?.serviceId,
    projectId = projectsStore?.currentProject?.id,
  }: IdSet) => {
    try {
      const { data, errors } = await client.mutate({
        variables: {
          serviceId,
          projectId,
        },
        mutation: RESET_POSTGRES_PARAMETERS,
      });
      if (data) {
        // create a comparison object from last fetched
        let comparison: { [name: string]: NumericParameter | StringParameter } =
          {};
        this.lastFetchedDatabaseParameters[serviceId].forEach((param) => {
          comparison[param.info.name] = param;
        });
        runInAction(() => {
          this.requestedDatabaseParameters = {
            ...this.requestedDatabaseParameters,
            [serviceId]: {
              resetToDefault: true,
              params: toJS(comparison),
            },
          };
        });
        notificationStore.showInfoToaster("Resetting service configuration.");
      } else if (errors) {
        notificationStore.showErrorToaster(
          "Sorry! We are unable to reset your instance parameters at the moment. Please try again later or contact us through support.",
        );
      }
    } catch {
      notificationStore.showErrorToaster(
        "Sorry! We are unable to reset your instance parameters at the moment. Please try again later or contact us through support.",
      );
    }
  };
}

const databaseParametersStore = new DatabaseParametersStore();

export default databaseParametersStore;
