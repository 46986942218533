import { useState } from "react";
import { useStores } from "stores";

import { useLocation } from "react-router-dom";
import { Flex, Text } from "@chakra-ui/react";

import Modal, { ModalButtons } from "components/modal/Modal";
import TextareaGroup from "components/input/TextareaGroup";
import Filler from "components/filler/Filler";

export const ModalFeedback = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { projectsStore, productStore, userStore } = useStores();

  const location = useLocation();
  const [comments, setComments] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleRequestFeature = async () => {
    const data = {
      blocks: [
        {
          type: "header",
          text: {
            type: "plain_text",
            text: ":featuretiger: New feature request",
          },
        },
        {
          type: "section",
          fields: [
            {
              type: "mrkdwn",
              text: `*Email:*\n${userStore.currentEmail}`,
            },
            {
              type: "mrkdwn",
              text: `*Project ID:*\n${projectsStore.currentProject?.id}`,
            },
          ],
        },
        {
          type: "section",
          fields: [
            {
              type: "mrkdwn",
              text: `*Requested by:*\n${userStore.currentUser}`,
            },
          ],
        },
        {
          type: "section",
          fields: [
            {
              type: "mrkdwn",
              text: `*Comments:*\n${comments}`,
            },
          ],
        },
        {
          type: "section",
          fields: [
            {
              type: "mrkdwn",
              text: `*URL:*\n${location.pathname}`,
            },
          ],
        },
      ],
    };

    const res = productStore.postSlackWebhook(JSON.stringify(data), false);
    res.then((res: boolean) => {
      if (res) setSubmitted(true);
    });
  };

  const handleClose = () => {
    setComments("");
    setSubmitted(false);
    onClose();
  };

  return (
    <Modal
      title={!submitted ? "We would love to hear your thoughts!" : ""}
      size="md"
      isOpen={isOpen}
      onClose={handleClose}
    >
      {submitted ? (
        <Filler.SVGFiller
          variant="other"
          headline="Thank you!"
          imagePath="success/Checkmark.svg"
          mt="0px"
          mb="12px"
        >
          Your insights will directly shape the future of our platform. Thank
          you for being a valuable part of our community!
        </Filler.SVGFiller>
      ) : (
        <>
          <Flex direction="column" gap="24px">
            <Text mt="12px">
              Your feedback is incredibly valuable to us as we continuously
              strive to improve our platform. Please, tell us your thoughts!
            </Text>
            <TextareaGroup
              textAlign="left"
              height="200px"
              placeholder="Write your feedback here"
              data-cy="request-feature-comments"
              onChange={(e) => setComments(e.target.value)}
            />
          </Flex>
          <ModalButtons
            primaryCTA={{
              text: "Submit",
              isDisabled: !Boolean(comments),
              onClick: () => handleRequestFeature(),
            }}
            secondaryCTA={{
              text: "Maybe later",
              onClick: handleClose,
            }}
          />
        </>
      )}
    </Modal>
  );
};
