export const transitionEasing = {
  easeIn: [0.4, 0, 1, 1],
  easeOut: [0, 0, 0.2, 1],
  easeInOut: [0.4, 0, 0.2, 1],
};

const easing = {
  easeIn: `cubic-bezier(${transitionEasing.easeIn.toString()})`,
  easeOut: `cubic-bezier(${transitionEasing.easeOut.toString()})`,
  easeInOut: `cubic-bezier(${transitionEasing.easeInOut.toString()})`,
};

const duration: { [key: string]: number } = {
  extraFast: 100,
  fast: 200,
  normal: 300,
  slow: 400,
  extraSlow: 500,
  ultraSlow: 2000,
};

const durationMs: { [key: string]: string } = Object.keys(duration).reduce(
  (_attrs, key) => ({
    ..._attrs,
    [key]: duration[key] + "ms",
  }),
  {},
);

const transition = {
  easing,
  duration,
  durationMs,
};

export default transition;
