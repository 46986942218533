import { ComponentStyleConfig } from "@chakra-ui/react";

export const textStyle = {
  variants: {
    dashedUnderline: {
      border: "0px",
      borderBottom: "1px dashed",
      paddingBottom: "2px",
      borderColor: "grayscale.900",
      color: "grayscale.900",
    },
  },
};

export const Text: ComponentStyleConfig = textStyle;
