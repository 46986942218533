// libraries
import axios from "axios";
import { client } from "api/client";
import { Statsig } from "statsig-react";
import { makeAutoObservable, reaction, runInAction } from "mobx";
import { isEmpty, uniq } from "lodash";
// stores
import notificationStore from "./notificationStore";
import projectsStore from "./projectsStore";
// api
import { GET_ORB_PRODUCTS, GET_PRODUCTS } from "api/query";
// utils
import { PROD } from "utils/config";
import { ENV } from "utils/hostEnv";
import { EXP_ORB, FEAT_SKIP_REGION_VALIDATION } from "utils/featuresGates";
import {
  getCPUBandFromMaxMilliCPU,
  makeRandomDBName,
} from "utils/utilFunctions";
// constants
import { HOURS_PER_MONTH } from "./constants";
import regionLookup from "data/aws_region.json";
import devRegionData from "data/dev_region.json";
import productionRegionData from "data/production_region.json";
import serviceCreationData from "data/service_creation.json";
// types
import { ExperimentGroup, Maybe, Plan, Product, Type } from "graphql/generated";
import {
  RegionList,
  WORKLOAD_OPTIONS,
} from "pages/project/service/create/createServiceTypes";
import {
  ProductID,
  RegionCode,
  ServiceType,
  ServiceTypeProductId,
  TimezoneRegion,
} from "types";
import {
  EXP_CUSTOM_ONBOARDING,
  ONBOARDING_TYPE_VARIANT,
} from "utils/featuresExperiments";

const DEFAULT_AWS_REGION =
  (ENV.FALLBACK_REGION as RegionCode) ?? RegionCode.US_EAST_1;

export type Configuration = {
  value?: Maybe<number>;
  name: string;
  label: string;
  label2: string;
  cpu?: Maybe<number>;
  memory?: Maybe<number>;
  regionCode?: string;
  productId?: string;
};

type StorageHourlyConfig = Pick<Plan, "storageGB" | "regionCode" | "productId">;
type CPUHourlyConfig = Pick<Plan, "milliCPU" | "regionCode" | "productId">;
type ConnectionPoolerHourlyConfig = Pick<Plan, "regionCode" | "productId">;
type ConfiguredService = {
  name: string;
  milliCPU: number;
  memoryGB: number;
  storageGB: number;
  serviceType: Type;
  vpc?: string;
  regionCode: string;
  replicaCount: number;
  vpcId?: string;
  enableConnectionPooler?: boolean;
};

const defaultConfiguredService: ConfiguredService = {
  // this is so that when switching back
  // & forth between default config & adv,
  // the service name field stays (otherwise it'd be blank)
  name: makeRandomDBName(),
  serviceType: ServiceType.Timescaledb,
  ...serviceCreationData.forms.defaultConfiguration,
};

export class ProductStore {
  products: Product[] = [];
  storagePlans: Plan[] = [];
  configPlans: Plan[] = [];
  vpcPlans: Plan[] = [];
  dataTieringPlans: Plan[] = [];
  connectionPoolerPlans: Plan[] = [];
  disk = serviceCreationData.forms.disk;
  configuration: Configuration[] = [];
  configuredService = defaultConfiguredService;
  storageGBMax = this.disk?.list?.[this.disk?.list.length - 1];
  regionList: RegionList[] = [];
  vpcSupportedRegionList = ["us-east-1"];

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.storagePlans,
      (storagePlans) => {
        this.storageGBMax = Math.max(
          ...storagePlans.map((plan) => plan.storageGB || 0),
        );
      },
    );
  }

  // Assigns a region based on the browser reported timezone, and a
  // lookup table that roughly matches to AWS Regions. The goal is to recommend
  // a region that is close-ish to the customer, while spreading the
  // distribution for services.
  //
  // This solution trades accuracy for speed and simplicity.
  get autoAssignedRegion() {
    // Grabs the timezone reported by the browser.
    const browserTimezone =
      Intl.DateTimeFormat().resolvedOptions().timeZone || null;

    if (!browserTimezone) return DEFAULT_AWS_REGION;

    // Given a timezone, return a "good enough" AWS Region Code.
    // Overrides are in the form of "fallthrough" cases. Add a case:
    //
    //   case timezone === "America/New_York":
    //     return RegionCode.US_EAST_1;
    //
    // to the switch above the return on how you want to match to override the
    // larger geoArea. Be sure it is *above* the TimezoneRegion you are
    // overriding (this standard switch behavior).
    const findRegion = (timezone: string) => {
      if (!timezone) {
        return DEFAULT_AWS_REGION;
      }
      // ex: Takes 'America/Los_Angeles' and grabs 'America`. You can see the
      // full list in enum TimezoneRegion.
      const geoArea = timezone.split("/")[0];

      switch (true) {
        case timezone === "Brazil/East":
          return RegionCode.SA_EAST_1;
        case timezone === "Europe/London":
          return RegionCode.EU_WEST_2;
        case timezone === "Canada/Eastern":
        case timezone === "Canada/Central":
          return RegionCode.CA_CENTRAL_1;
        case timezone === "America/New_York":
          return RegionCode.US_EAST_2;
        case timezone === "America/Los_Angeles":
        case geoArea === TimezoneRegion.America:
          return RegionCode.US_WEST_2;
        case geoArea === TimezoneRegion.Europe:
          return RegionCode.EU_CENTRAL_1;
        case geoArea === TimezoneRegion.Africa:
          return RegionCode.EU_WEST_1;
        case geoArea === TimezoneRegion.Australia:
          return RegionCode.AP_SOUTHEAST_2;
        case geoArea === TimezoneRegion.Asia:
          return RegionCode.AP_NORTHEAST_1;
        case geoArea === TimezoneRegion.Indian:
        case geoArea === TimezoneRegion.Pacific:
          return RegionCode.AP_SOUTHEAST_1;
        default:
          return DEFAULT_AWS_REGION;
      }
    };

    const matchedRegion = findRegion(browserTimezone);
    // Check to be sure that we are allowing regions to be shown and used.
    // Lower environments might restrict which regions we can test in (to save
    // on costs) or we may be testing a new region in production for only
    // select customers.
    if (this.regionList.map((region) => region.id).includes(matchedRegion)) {
      return matchedRegion;
    } else {
      return DEFAULT_AWS_REGION;
    }
  }

  getProducts = async () => {
    try {
      // GET_ORB_PRODUCTS is a new endpoint that will replace GET_PRODUCTS
      // But at the moment it's not ready to be used in production due to inefficiencies
      // I will leave the code commented out for now, but we should switch to it once it's ready

      const isOrbFeatureAvailable = Statsig.checkGate(EXP_ORB.GATE);
      const onboardingExperimentGroup = Statsig.getExperiment(
        EXP_CUSTOM_ONBOARDING.ID,
      ).getValue(
        EXP_CUSTOM_ONBOARDING.PARAMS.ONBOARDING_TYPE?.ID,
        EXP_CUSTOM_ONBOARDING.PARAMS.ONBOARDING_TYPE?.DEFAULT_VALUE,
      );

      // Experiment Group A gets the old Dynamic Postgres prices,
      // Experiment Group B gets the new Static Postgres prices
      const groupB =
        onboardingExperimentGroup === ONBOARDING_TYPE_VARIANT.NEW ||
        onboardingExperimentGroup === ONBOARDING_TYPE_VARIANT.OLD;
      // const groupB = onboardingExperimentGroup === ONBOARDING_TYPE_VARIANT.OLD_CONTROL

      const showAllRegionsGate = Statsig.checkGate(
        FEAT_SKIP_REGION_VALIDATION.GATE,
      );

      let data = { products: [], orbProducts: [] };

      if (isOrbFeatureAvailable) {
        const { data: orbData } = await client.query({
          query: GET_ORB_PRODUCTS,
          variables: {
            projectId: projectsStore.currentProject?.id,
            experimentGroup: groupB ? ExperimentGroup.B : ExperimentGroup.A,
          },
        });
        data = orbData;
      } else {
        const { data: deprecatedData } = await client.query({
          query: GET_PRODUCTS,
        });
        data = deprecatedData;
      }

      if (data) {
        let configPlans: Plan[] = [];
        let storeagePlans: Plan[] = [];
        let vpcPlans: Plan[] = [];
        let dataTieringPlans: Plan[] = [];
        let connectionPoolerPlans: Plan[] = [];
        const productsPricing: Product[] = isOrbFeatureAvailable
          ? data.orbProducts
          : data.products;

        const regionPreppedList = productsPricing.map(({ id, plans }) => {
          // We check that every product has a region to correctly display
          // pricing and avoid errors deploying. If a product has no region,
          // completely skip it as it will take down the UI.
          // Platform will test new regions in production before they are GA.
          const validatedRegionPlans = plans
            .map(({ regionCode, ...rest }) => {
              // Completely skips region checks to facilitate Platform testing.
              // Expect the UI to be unstable if this is on.
              if (showAllRegionsGate) {
                return { ...rest, regionCode };
              }

              if (ENV.NAME === PROD) {
                return productionRegionData.productionSupportedRegions.includes(
                  regionCode,
                )
                  ? { ...rest, regionCode }
                  : null;
              } else {
                return devRegionData.devSupportedRegions.includes(regionCode)
                  ? { ...rest, regionCode }
                  : null;
              }
            })
            .filter((x) => x);

          if (validatedRegionPlans.length === 0) {
            throw new Error(
              "No region plans are available. The UI may be unusable.",
            );
          }

          const storageProductIds: ProductID[] = [
            "product_tsdb_storage",
            "product_pg_storage",
            "product_elastic_storage",
            "product_pg_elastic_storage",
            "product_vector_elastic_storage",
          ];

          if (["product_tsdb", "product_pg", "product_vector"].includes(id)) {
            configPlans = [...configPlans, ...(validatedRegionPlans as Plan[])];
          } else if (storageProductIds.includes(id as ProductID)) {
            storeagePlans = [
              ...storeagePlans,
              ...(validatedRegionPlans as Plan[]),
            ];
          } else if (["product_vpc_attachment"].includes(id)) {
            vpcPlans = [...vpcPlans, ...(validatedRegionPlans as Plan[])];
          } else if (["product_data_tiering"].includes(id)) {
            dataTieringPlans = [
              ...dataTieringPlans,
              ...(validatedRegionPlans as Plan[]),
            ];
          } else if (["product_connection_pooler"].includes(id)) {
            connectionPoolerPlans = [
              ...connectionPoolerPlans,
              ...(validatedRegionPlans as Plan[]),
            ];
          }
          return validatedRegionPlans;
        });

        this.setConfigPlans(configPlans);
        this.setStoragePlans(storeagePlans);
        this.setVPCPlans(vpcPlans);
        this.setDataTieringPlans(dataTieringPlans);
        this.setConnectionPoolerPlans(connectionPoolerPlans);
        this.setRegionList(regionPreppedList[0] as Plan[]);

        runInAction(() => {
          this.products = productsPricing;
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  setRegionList = (plans: Plan[]) => {
    runInAction(() => {
      this.regionList = Array.from(
        plans
          .filter((item) => item.regionCode !== "UNKNOWN")
          .reduce((map, item) => map.set(item.regionCode, item), new Map())
          .values(),
      )
        .filter((x) => x)
        .map(
          (item) =>
            (regionLookup as { [key: string]: RegionList })[item.regionCode],
        )
        .sort((a, b) => (a.id > b.id ? 1 : -1));
    });
  };

  setConfigPlans = (plans: Plan[]) => {
    runInAction(() => {
      this.configPlans = plans?.sort((a, b) =>
        Number(a.milliCPU) > Number(b.milliCPU) ? 1 : -1,
      );

      this.configuration = isEmpty(plans)
        ? serviceCreationData.forms.configuration
        : plans
            .map(({ milliCPU, memoryGB, regionCode, productId }) => ({
              productId,
              value: Number(milliCPU) / 1000,
              cpu: milliCPU,
              memory: memoryGB,
              regionCode,
              name: "milliCPU",
              label: `${Number(milliCPU) / 1000} CPU`,
              label2: `${memoryGB} GiB Memory`,
            }))
            .filter(({ cpu }) => cpu !== 250); // DEPRECATED: Filter out 0.25 while it's still in Products.
    });
  };

  setStoragePlans = (plans: Plan[]) => {
    runInAction(() => {
      this.storagePlans = plans?.sort((a, b) =>
        Number(a.storageGB) > Number(b.storageGB) ? 1 : -1,
      );
      if (isEmpty(plans)) {
        this.disk = serviceCreationData.forms.disk;
      } else {
        const list = uniq(plans.map(({ storageGB }) => storageGB)) as number[];
        this.disk = {
          label: "Disk",
          max: String(list.length - 1),
          min: "0",
          name: "storageGB",
          step: "1",
          list,
        };
      }
    });
  };

  setVPCPlans = (plans: Plan[]) => {
    runInAction(() => {
      this.vpcPlans = isEmpty(plans) ? [] : plans;
    });
  };

  setDataTieringPlans = (plans: Plan[]) => {
    runInAction(() => {
      this.dataTieringPlans = isEmpty(plans) ? [] : plans;
    });
  };

  setConnectionPoolerPlans = (plans: Plan[]) => {
    runInAction(() => {
      this.connectionPoolerPlans = isEmpty(plans) ? [] : plans;
    });
  };

  // A pure function that accepts a deconstructed service, and returns pricing.
  getPurePriceFromService = ({
    serviceId = "",
    serviceType,
    milliCPU,
    workloadType,
    storageGB,
    regionCode,
    replicaCount = 0,
    vpc = "",
    currentStorageUsageGB = 1,
    dataTieringUsageGB = 0,
    compressionSavings,
    connectionPoolerEnabled = false,
  }: {
    serviceId?: string;
    serviceType: Type;
    milliCPU?: number;
    workloadType?: keyof typeof WORKLOAD_OPTIONS;
    storageGB?: number;
    regionCode: string;
    replicaCount?: number;
    vpc?: string;
    currentStorageUsageGB?: number;
    dataTieringUsageGB?: number;
    compressionSavings?: number;
    connectionPoolerEnabled?: boolean;
  }) => {
    let totalStorageHourlyPrice = 0;
    let totalComputeHourlyPrice = 0;

    if (
      [
        ServiceType.Timescaledb,
        ServiceType.Postgres,
        ServiceType.Vector,
      ].includes(serviceType)
    ) {
      totalStorageHourlyPrice = this.calculatePriceWithReplicas(
        Number(
          this.getStorageHourlyPrice({
            productId: ServiceTypeProductId[serviceType].Storage,
            storageGB,
            regionCode,
          }),
        ),
        replicaCount,
      );

      // Include compression savings
      if (compressionSavings) {
        totalStorageHourlyPrice =
          totalStorageHourlyPrice * (1 - compressionSavings);
      }

      totalComputeHourlyPrice = this.calculatePriceWithReplicas(
        Number(
          this.getComputedHourlyPrice({
            productId: ServiceTypeProductId[serviceType].Config,
            milliCPU,
            regionCode,
          }),
        ),
        replicaCount,
      );
    }

    /** For estimating the hourly cost of a service with dynamic compute.
     *
     *  This spreadsheet is used as reference:
     *  https://docs.google.com/spreadsheets/d/1qoVfpXO3fGpAIba6cz83G62YVV-Tv0LDVNQHGCMPSVY/edit?usp=sharing
     */
    const getHourlyDynamicCPUCost = (
      workloadType: keyof typeof WORKLOAD_OPTIONS,
    ) => {
      let [_minCPU, _maxCPU] = getCPUBandFromMaxMilliCPU(milliCPU).split("-");

      const minCPU = Number(_minCPU);
      const maxCPU = Number(_maxCPU);

      switch (workloadType) {
        // The service uses 20% above the min CPU the entire time
        case WORKLOAD_OPTIONS.STEADY:
          return minCPU * 1.2 * totalComputeHourlyPrice;

        // The service is at min CPU usage 90% of the time and occasionally
        // hits the peak 10% of the time
        case WORKLOAD_OPTIONS.BURSTY:
          const timeAtPeak = 0.1;
          const timeAtBase = 0.9;

          const cpuHoursUsedMonthly = minCPU * timeAtBase + maxCPU * timeAtPeak;

          return cpuHoursUsedMonthly * totalComputeHourlyPrice;

        // Average CPU usage is 30% above the min CPU the entire time
        case WORKLOAD_OPTIONS.CYCLICAL:
          return minCPU * 1.3 * totalComputeHourlyPrice;
      }
    };

    const totalVPCHourlyPrice = this.calculatePriceWithReplicas(
      Number(this.getVpcHourlyPrice(vpc)),
      replicaCount,
    );
    const totalDataTieringHourlyPrice = this.getDataTieringHourlyPrice();
    const totalConnectionPoolerPrice = connectionPoolerEnabled
      ? this.getConnectionPoolerHourlyPrice({
          productId: ServiceTypeProductId[serviceType].ConnectionPooler,
          regionCode,
        })
      : 0;

    const grandTotalHourlyCost =
      (workloadType
        ? getHourlyDynamicCPUCost(workloadType)
        : totalComputeHourlyPrice) +
      totalStorageHourlyPrice * currentStorageUsageGB +
      totalDataTieringHourlyPrice * dataTieringUsageGB +
      totalVPCHourlyPrice +
      totalConnectionPoolerPrice;

    const grandTotalMonthlyCost =
      (grandTotalHourlyCost +
        (workloadType ? getHourlyDynamicCPUCost(workloadType!) : 0)) *
      HOURS_PER_MONTH;
    const serviceCostInfos = projectsStore?.serviceCostInfos;

    const { costToDateGross = 0, estimatedTotalCostGross = 0 } =
      serviceCostInfos[serviceId] || {};

    return {
      storageHourlyPrice: Number(totalStorageHourlyPrice),
      computeHourlyPrice: Number(totalComputeHourlyPrice.toFixed(3)),
      vpcHourlyPrice: Number(totalVPCHourlyPrice.toFixed(3)),
      hourlyPrice: Number(grandTotalHourlyCost.toFixed(3)),
      monthlyPrice: Number(grandTotalMonthlyCost.toFixed(0)),
      costToDateGross: Number(costToDateGross.toFixed(3)),
      estimatedTotalCostGross: Number(estimatedTotalCostGross.toFixed(3)),
      connectionPooler: Number(totalConnectionPoolerPrice.toFixed(3)),
      dataTieringHourlyPrice: Number(totalDataTieringHourlyPrice.toFixed(6)),
    };
  };

  calculatePriceWithReplicas = (price: number, replicaCount: number) =>
    price * (replicaCount + 1);

  getStorageHourlyPrice({
    storageGB: selectStorageGB,
    regionCode: selectRegion,
    productId: selectProductId,
  }: StorageHourlyConfig) {
    if (selectStorageGB && selectProductId && this.storagePlans) {
      const price = this.storagePlans.find(
        ({
          regionCode: planRegion,
          productId: planProductId,
        }: StorageHourlyConfig) =>
          String(planRegion) === String(selectRegion) &&
          String(planProductId) === String(selectProductId),
      )?.price;

      if (price) {
        return price;
      } else {
        notificationStore.showErrorToaster(
          "Pricing could not be found for this storage.",
        );
        return null;
      }
    }
    return null;
  }

  getComputedHourlyPrice({
    milliCPU: selectCPU,
    regionCode: selectRegion,
    productId: selectProductId,
  }: CPUHourlyConfig) {
    if (selectCPU && selectProductId && this.configPlans) {
      const costFound = this.configPlans.find(
        ({ milliCPU, regionCode, productId: planProductId }: CPUHourlyConfig) =>
          Number(milliCPU) === Number(selectCPU) &&
          String(regionCode) === String(selectRegion) &&
          String(planProductId) === String(selectProductId),
      );
      if (costFound) {
        return costFound.price;
      } else {
        notificationStore.showErrorToaster(
          "Pricing could not be found for this compute configuration.",
        );
        return null;
      }
    }

    return null;
  }

  getVpcHourlyPrice = (vpc: string) => {
    return (vpc && this.vpcPlans?.[0]?.price) ?? 0;
  };

  getDataTieringHourlyPrice = () => {
    return this.dataTieringPlans?.[0]?.price ?? 0;
  };

  getConnectionPoolerHourlyPrice({
    productId: selectProductId,
    regionCode: selectRegion,
  }: ConnectionPoolerHourlyConfig) {
    if (selectProductId && this.connectionPoolerPlans) {
      const price = this.connectionPoolerPlans.find(
        ({
          regionCode: planRegion,
          productId: planProductId,
        }: StorageHourlyConfig) =>
          String(planRegion) === String(selectRegion) &&
          String(planProductId) === String(selectProductId),
      )?.price;
      if (price !== undefined) {
        return price;
      } else {
        notificationStore.showErrorToaster(
          "Pricing could not be found for connection pooler.",
        );
        return 0;
      }
    }
    return 0;
  }

  getCompressionRate = (value: number) => {
    const savedRate = Math.ceil((value * 16.666667) / 10) * 10;
    return String(savedRate).length <= 3
      ? `${savedRate} GiB`
      : `${(savedRate / 1024).toFixed(1)} TiB`;
  };

  savedServices = (service: ConfiguredService) => {
    const {
      name,
      milliCPU,
      memoryGB,
      storageGB,
      serviceType,
      vpc,
      regionCode,
      replicaCount,
      ...rest
    } = service;

    let newConfiguredService = {
      ...this.configuredService,
      serviceType,
      ...rest,
    };
    if (name || typeof name === "string") {
      newConfiguredService = {
        ...newConfiguredService,
        name,
      };
    }
    if (milliCPU || memoryGB) {
      newConfiguredService = {
        ...newConfiguredService,
        milliCPU,
        memoryGB,
      };
    }
    if (regionCode) {
      newConfiguredService = {
        ...newConfiguredService,
        regionCode,
      };
    }
    if (typeof replicaCount === "number") {
      newConfiguredService = {
        ...newConfiguredService,
        replicaCount,
      };
    }
    newConfiguredService = {
      ...newConfiguredService,
      storageGB: storageGB ?? newConfiguredService.storageGB,
      vpc,
    };
    runInAction(() => {
      this.configuredService = newConfiguredService;
    });
  };

  resetServices = () => {
    runInAction(() => {
      this.configuredService = defaultConfiguredService;
    });
  };

  // The default Slack webhook is to //#feed-feature-requests,
  // but you can provide your own
  postSlackWebhook = async (
    data: string,
    toast: boolean,
    webHookUrl: string = "https://hooks.slack.com/services/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",
  ) => {
    try {
      const res = await axios.post(webHookUrl, data, {
        withCredentials: false,
        transformRequest: [
          (data, headers) => {
            delete headers.post["Content-Type"];
            return data;
          },
        ],
      });
      if (res) {
        if (toast)
          notificationStore.showSuccessToaster(
            "Your request has been successfully submitted!",
          );
        return true;
      }
      return false;
    } catch (e) {
      notificationStore.showErrorToaster(
        "Something went wrong, and we are unable to process your request. Please, try again later.",
      );
      return false;
    }
  };
}

const productStore = new ProductStore();

export default productStore;
