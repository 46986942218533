import { ComponentStyleConfig } from "@chakra-ui/theme";
import type {
  SystemStyleFunction,
  StyleFunctionProps,
} from "@chakra-ui/theme-tools";
import { merge } from "lodash";
import { ButtonSize } from "../../components/button/buttonTypes";

const minWidthSizeVariants: { [key in ButtonSize]: string } = {
  sm: "24px",
  md: "36px",
  lg: "48px",
};

const linkSizeVariants: (theme: any) => { [key in ButtonSize]: any } = (
  theme: any,
) => ({
  sm: theme.textStyles.captionLink,
  md: theme.textStyles.buttonLinkS,
  lg: theme.textStyles.buttonLinkL,
});

const variantPrimary: SystemStyleFunction = () => ({
  color: "grayscale.0",
  bg: "grayscale.900",
  _hover: {
    bg: "grayscale.800",
    _disabled: {
      bg: "grayscale.200",
    },
  },
  _active: {
    bg: "grayscale.700",
  },
  _disabled: {
    color: "grayscale.300",
    bg: "grayscale.200",
  },
});

const variantDanger: SystemStyleFunction = (props) =>
  merge(variantPrimary(props), {
    bg: "error.900",
    _hover: {
      bg: "error.800",
    },
    _active: {
      bg: "error.600",
    },
    _disabled: {
      bg: "grayscale.200",
      color: "grayscale.300",
    },
  });

const variantOutline: SystemStyleFunction = ({ size }) => ({
  bg: "grayscale.0",
  border: size === ("sm" as ButtonSize) ? "1px solid" : "2px solid",
  // To center icon for expanding button,
  // otherwise border shifts icon off center
  "& > *": {
    position: "relative",
    left: size === "sm" ? "-1px" : "-2px",
  },
  color: "grayscale.900",
  _hover: {
    bg: "primary.200",
    _disabled: {
      bg: "grayscale.0",
    },
  },
  _active: {
    bg: "primary.300",
  },
  _disabled: {
    borderColor: "grayscale.300",
    color: "grayscale.300",
  },
});

const variantPasswordVisiblity: SystemStyleFunction = (props) =>
  merge(variantOutline(props), {
    color: "grayscale.900",
    bg: "grayscale.0",
    border: "1px solid",
    borderColor: "grayscale.500",
  });

const variantGhost: SystemStyleFunction = () => ({
  color: "primary.900",
  _hover: {
    bg: "primary.200",
  },
  _active: {
    bg: "primary.300",
  },
  _disabled: {
    color: "grayscale.300",
  },
});

const variantLink: SystemStyleFunction = ({
  theme,
  size = "sm",
}: {
  theme: any;
  size?: ButtonSize;
}) => ({
  padding: "8px",
  color: "primary.900",
  _hover: {
    bg: "primary.200",
    _disabled: {
      textDecorationLine: "underline",
    },
  },
  _active: {
    color: "primary.900",
    bg: "primary.300",
    _disabled: {
      color: "grayscale.300",
    },
  },
  _disabled: {
    color: "grayscale.300",
  },
  minW: minWidthSizeVariants[size],
  ...linkSizeVariants(theme)[size],
});

const iconSizes = {
  lg: {
    h: "48px",
    w: "48px",
    minW: "48px",
    maxW: "48px",
  },
  md: {
    h: "36px",
    w: "36px",
    maxW: "36px",
    minW: "36px",
  },
  sm: {
    h: "24px",
    w: "24px",
    maxW: "24px",
    minW: "24px",
    border: "none",
  },
};

const variantIconSmall: SystemStyleFunction = ({
  size = "sm",
}: StyleFunctionProps & { size?: ButtonSize }) => {
  if (size === "sm") {
    return {
      color: "grayscale.800",
      bg: "transparent",
      _hover: {
        color: "primary.900",
        bg: "primary.300",
        _disabled: {
          color: "grayscale.500",
          bg: "transparent",
        },
      },
      _active: {
        color: "primary.900",
        bg: "primary.300",
      },
      _disabled: {
        color: "grayscale.500",
        bg: "transparent",
      },
    };
  }
  return {};
};

const variantGhostIcon: SystemStyleFunction = (props) =>
  merge(variantOutline(props), {
    "& > *": {
      position: "static",
    },
    bg: "transparent",
    border: "1px primary",
    borderColor: "transparent",
  });

const iconOption: (variant: SystemStyleFunction) => SystemStyleFunction =
  (variant) => (props) => {
    return merge(
      variant(props),
      iconSizes[props.size as "lg" | "md" | "sm"],
      variantIconSmall(props),
      //related to icons shifting in x-direction from border width
      {
        "& > *": {
          position: null,
          left: null,
        },
      },
    );
  };

const buttonBaseStyle: ComponentStyleConfig = {
  baseStyle: {
    pl: "24px",
    pr: "24px",
    _hover: {
      opacity: 1,
      _disabled: {
        opacity: 1,
      },
    },
    _active: {
      opacity: 1,
      transform: "translateY(1px)",
      transition: (theme) =>
        `transform ${theme.transition.duration.fast} ${theme.transition.easing.easeOut}`,
      _disabled: {
        transform: "translateY(0)",
      },
    },
    _focus: {
      boxShadow: "none",
    },
    _disabled: {
      opacity: 1,
      cursor: "default",
    },
    borderRadius: "99px",
    width: "fit-content",
  },
};
const buttonSizes: ComponentStyleConfig = {
  sizes: {
    lg: ({ theme }) => ({
      h: "48px",
      minH: "48px",
      minW: "160px",
      maxW: "300px",
      ...theme.textStyles.buttonL,
    }),
    md: ({ theme }) => ({
      h: "36px",
      minH: "36px",
      minW: "160px",
      maxW: "260px",
      ...theme.textStyles.buttonS,
    }),
    sm: ({ theme }) => ({
      h: "24px",
      minH: "24px",
      paddingInlineStart: "8px", //This Chakra attribute normally has a default, and overwrites normal padding
      paddingInlineEnd: "8px",
      pl: 0,
      pr: 0,
      minW: "60px",
      maxW: "100px",
      ...theme.textStyles.caption,
    }),
  },
};

export const Button: ComponentStyleConfig = {
  ...buttonBaseStyle,
  ...buttonSizes,
  variants: {
    primary: variantPrimary,
    danger: variantDanger,
    secondary: variantOutline,
    ghost: variantGhost,
    link: variantLink,
    primaryIcon: iconOption(variantPrimary),
    secondaryIcon: iconOption(variantOutline),
    ghostIcon: iconOption(variantGhostIcon),
    passwordVisiblityIcon: iconOption(variantPasswordVisiblity),
  },
};
