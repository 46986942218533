import { Flex, StyleProps, Text } from "@chakra-ui/react";
import { Progress } from "../progress/Progress";
import { ThemeColors } from "../../theme/foundations/colors";

export const STRENGTH = {
  WEAK: "weak",
  STRONG: "strong",
} as const;

export type Strength = (typeof STRENGTH)[keyof typeof STRENGTH];

export interface PasswordStrengthProps extends StyleProps {
  strength: Strength | number;
  threshold?: number;
  validationRuleText?: string;
}

const passwordStrengthConfig = {
  [STRENGTH.WEAK]: {
    text: "Weak",
    color: "error.900" as ThemeColors,
    filledWidth: 40,
  },
  [STRENGTH.STRONG]: {
    text: "Strong!",
    color: "success.900" as ThemeColors,
    filledWidth: 82.5,
  },
};

export const PasswordStrength = ({
  strength,
  threshold = 80,
  validationRuleText,
  ...customStyles
}: PasswordStrengthProps) => {
  const { text, color, filledWidth } =
    typeof strength === "number"
      ? strength < threshold
        ? passwordStrengthConfig[STRENGTH.WEAK]
        : passwordStrengthConfig[STRENGTH.STRONG]
      : passwordStrengthConfig?.[strength];
  return (
    <Flex alignItems="center" data-cy="password-strength" {...customStyles}>
      <Progress
        value={typeof strength === "number" ? strength : filledWidth}
        color={color}
        width="125px"
      />
      <Text color={color} textStyle="caption" ml="8px">
        {text}
        {validationRuleText &&
          strength < threshold &&
          ` (${validationRuleText})`}
      </Text>
    </Flex>
  );
};

export default PasswordStrength;
