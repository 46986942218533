import { ComponentStyleConfig } from "@chakra-ui/theme";
import { SystemStyleObject } from "@chakra-ui/react";
import { SystemStyleFunction } from "@chakra-ui/theme-tools";

const getColorStyles = (color: string) => ({
  background: color,
  borderColor: color,
});

const baseStyleContainer: SystemStyleObject = {
  cursor: "pointer",
  _disabled: {
    cursor: "default",
  },
};

const baseStyleTrack: SystemStyleObject = {
  ...getColorStyles("grayscale.500"),
  _checked: {
    ...getColorStyles("primary.900"),
  },
  _focus: {
    boxShadow: "none",
  },
  _disabled: {
    opacity: 1,
    cursor: "default",
    ...getColorStyles("grayscale.400"),
    _checked: {
      ...getColorStyles("grayscale.400"),
    },
  },
};

const baseStyleThumb: SystemStyleFunction = ({ isDisabled }) => ({
  background: `${isDisabled ? "grayscale.200" : "white"}`,
  transitionProperty: "transform",
  transitionDuration: (theme) => theme.transition.duration.extraFast,
  transitionTimingFunction: (theme) => theme.transition.easing.easeOut,
});

// @ts-expect-error this should be migrated to use `defineStyleConfig`
export const Switch: ComponentStyleConfig = {
  baseStyle: (props) => ({
    container: baseStyleContainer,
    track: baseStyleTrack,
    thumb: baseStyleThumb(props),
  }),
  sizes: {
    sm: {
      width: "1.5rem",
    },
  },
};
