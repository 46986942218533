import { Box, Flex, Grid, Text } from "@chakra-ui/react";
import Icon from "components/icon/Icon";
import { Icons } from "components/icon/iconTypes";
import { Type } from "graphql/generated";
import { observer } from "mobx-react";
import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ConnectionProgressContext } from "routes/Layout";
import { Statsig } from "statsig-react";
import { ServiceStore, useStores } from "stores";
import { ThemeColors } from "theme/foundations/colors";
import { EXP_CUSTOM_ONBOARDING } from "utils/featuresExperiments";
import { sampleDataTypeFromServiceName } from "utils/sampleData";

export enum StepState {
  CURRENT = "current",
  COMPLETE = "complete",
  INCOMPLETE = "incomplete",
}

export interface StepConfig {
  id: string;
  name: string;
  slug: string;
  state: StepState;
}

export const defaultStepConfig: StepConfig[] = [
  {
    id: "1_createService",
    name: "Create a service",
    slug: "/create_services",
    state: StepState.INCOMPLETE,
  },
  {
    id: "2_saveConfig",
    name: "Save the config",
    slug: "/config",
    state: StepState.INCOMPLETE,
  },
  {
    id: "3_connectService",
    name: "Connect to service",
    slug: "/connect",
    state: StepState.INCOMPLETE,
  },
  {
    id: "4_importData",
    name: "Import your data",
    slug: "/import",
    state: StepState.INCOMPLETE,
  },
];

export const hypertableStepConfig: StepConfig[] = [
  {
    id: "1_createService",
    name: "Create a service",
    slug: "/create_services",
    state: StepState.INCOMPLETE,
  },
  {
    id: "2_saveConfig",
    name: "Save the config",
    slug: "/config",
    state: StepState.INCOMPLETE,
  },
  {
    id: "3_connectService",
    name: "Connect to service",
    slug: "/connect",
    state: StepState.INCOMPLETE,
  },
  {
    id: "4_createHypertable",
    name: "Create hypertable",
    slug: "/hypertable",
    state: StepState.INCOMPLETE,
  },
  {
    id: "5_importData",
    name: "Import your data",
    slug: "/import",
    state: StepState.INCOMPLETE,
  },
];

export const learnMoreStepConfig: StepConfig[] = [
  {
    id: "1_createService",
    name: "Create a service",
    slug: "/create_services",
    state: StepState.INCOMPLETE,
  },
  {
    id: "2_saveConfig",
    name: "Save the config",
    slug: "/config",
    state: StepState.INCOMPLETE,
  },
  {
    id: "3_connectService",
    name: "Connect to service",
    slug: "/connect",
    state: StepState.INCOMPLETE,
  },
  {
    id: "3_createHypertable",
    name: "Create hypertable",
    slug: "/hypertable",
    state: StepState.INCOMPLETE,
  },
  {
    id: "4_learnMore",
    name: "Learn more",
    slug: "/learnMore",
    state: StepState.INCOMPLETE,
  },
  {
    id: "5_importData",
    name: "Import your data",
    slug: "/import",
    state: StepState.INCOMPLETE,
  },
];

export const sampleDataStepConfig: StepConfig[] = [
  {
    id: "1_createService",
    name: "Create a service",
    slug: "/create_services",
    state: StepState.INCOMPLETE,
  },
  {
    id: "2_examineSampleData",
    name: "Examine sample data",
    slug: "/examineSampleData",
    state: StepState.INCOMPLETE,
  },
  {
    id: "3_testSampleData",
    name: "Test sample data",
    slug: "/testSampleData",
    state: StepState.INCOMPLETE,
  },
];

const mapStateToStyles: {
  [key in StepState]: {
    textColor: ThemeColors;
    iconColor: ThemeColors;
    bgColor: ThemeColors;
    icon: Icons | null;
  };
} = {
  [StepState.COMPLETE]: {
    textColor: "grayscale.700",
    iconColor: "grayscale.700",
    bgColor: "grayscale.200",
    icon: "status/Check/Check",
  },
  [StepState.CURRENT]: {
    textColor: "primary.900",
    iconColor: "primary.900",
    bgColor: "primary.300",
    icon: null,
  },
  [StepState.INCOMPLETE]: {
    textColor: "grayscale.600",
    iconColor: "warning.500",
    bgColor: "grayscale.200",
    icon: null,
  },
};

const ConnectionStep = (props: {
  name: string;
  slug: string;
  state: StepState;
  stepId: string;
  serviceId: string;
  pathname: string;
  index: number;
  fontSize?: string;
}) => {
  const { name, slug, state, serviceId, pathname, index, fontSize } = props;
  const history = useHistory();
  const allowRoute =
    slug !== "/create_services" && pathname !== "/dashboard/create_services";

  return (
    <Flex
      fontSize={fontSize ? fontSize : "18px"}
      fontWeight={700}
      gap="8px"
      color={mapStateToStyles[state].textColor}
      cursor={allowRoute ? "pointer" : ""}
      onClick={() => {
        if (allowRoute) {
          history.push(`/dashboard/services/${serviceId}${slug}`);
        }
      }}
    >
      <Grid
        bg={mapStateToStyles[state].bgColor}
        borderRadius="10rem"
        h="28px"
        w="28px"
        placeItems="center"
      >
        {state === StepState.COMPLETE ? (
          <Icon
            name={mapStateToStyles[state].icon!}
            fill={mapStateToStyles[state].iconColor}
          />
        ) : (
          String(index)
        )}
      </Grid>
      <Box>
        <Text lineHeight={1.5}>{name}</Text>
      </Box>
    </Flex>
  );
};

export const showConnectionProgressUrls = (serviceId: string, params: any) => {
  const connectType = params.connectType ? `/${params.connectType}` : "";
  const importType = params.importType ? `/${params.importType}` : "";
  return [
    "/dashboard/create_services",
    `/dashboard/services/${serviceId}/import${importType}`,
    `/dashboard/services/${serviceId}/connect${connectType}`,
    `/dashboard/services/${serviceId}/config`,
    `/dashboard/services/${serviceId}/learnMore`,
    `/dashboard/services/${serviceId}/hypertable`,
    `/dashboard/services/${serviceId}/examineSampleData`,
    `/dashboard/services/${serviceId}/testSampleData`,
  ];
};

export const ConnectionProgress = observer(
  ({
    serviceId,
    pathname,
    search,
  }: {
    serviceId: string;
    pathname: string;
    search: string;
  }) => {
    const urlParams = new URLSearchParams(search);

    const { serviceStore } = useStores();
    const { service, isVector } = serviceStore as ServiceStore;

    const { stepConfig, setStepConfig } = useContext(ConnectionProgressContext);
    const fontSize = stepConfig.length > 4 ? "16px" : "18px";

    const inCreateServicePage = pathname.includes("create_services");
    const isSampleData = stepConfig.find(
      (o: StepConfig) => o.id === "2_examineSampleData",
    );

    /** We determine if vector is selected by:
     
        A) adding a ?isVector=true URL param every time 
           the "AI and Vector" service type box is toggled.
       We use this chceck in the /create_services page/
       Create a service step.

       B) checking the last_service_onboarding_selections 
       stored in localStorage uiState > profile. There's a 
       vector_selected property that's a boolean.
    */
    const isVectorSelected =
      Statsig.getExperiment(EXP_CUSTOM_ONBOARDING.ID).getValue(
        EXP_CUSTOM_ONBOARDING.PARAMS.SHOW_FEATURE,
      ) &&
      (urlParams.get("isVector") || (!inCreateServicePage && isVector()));

    const isTimescale =
      service?.type === Type.Timescaledb || urlParams.get("isTimescale");

    let isCurrent = false;
    for (const step of stepConfig) {
      if (isCurrent) {
        step.state = StepState.INCOMPLETE;
      } else {
        step.state = StepState.COMPLETE;
      }
      if (pathname.includes(step.slug)) {
        isCurrent = true;
        step.state = StepState.CURRENT;
      }
    }

    useEffect(() => {
      const sampleDataType = sampleDataTypeFromServiceName(service?.name);

      if (sampleDataType) {
        setStepConfig(sampleDataStepConfig);
      } else if (isVectorSelected) {
        const showHypertableStep = isTimescale;
        const learnMoreStepNoHypertableNoConnect = learnMoreStepConfig.filter(
          (step) => step.slug !== "/hypertable" && step.slug !== "/connect",
        );

        setStepConfig(
          showHypertableStep
            ? learnMoreStepConfig
            : learnMoreStepNoHypertableNoConnect,
        );
      } else if (isTimescale) {
        // Still in create service flow
        setStepConfig(hypertableStepConfig);
      } else {
        setStepConfig(defaultStepConfig);
      }
    }, [service, setStepConfig, isVectorSelected, isTimescale]);

    return (
      <Flex
        gap="12px"
        justifyContent="space-between"
        p="24px 32px"
        maxW={isSampleData ? "900px" : "1200px"}
      >
        {stepConfig.map((step: any, i: any) => (
          <ConnectionStep
            name={step.name}
            state={step.state}
            slug={step.slug}
            index={i + 1}
            stepId={step.id}
            serviceId={serviceId}
            pathname={pathname}
            fontSize={fontSize}
            key={i}
          />
        ))}
      </Flex>
    );
  },
);
