import { Illustrations } from "./illustrationTypes";
import { Box, HTMLChakraProps } from "@chakra-ui/react";

export const ILLUSTRATION_SIZE = {
  icon: "20",
  large: "250",
  medium: "200",
  small: "150",
};
export type IllustrationSize = keyof typeof ILLUSTRATION_SIZE | number;

const getSize = (sizeName: IllustrationSize) => {
  if (
    typeof sizeName !== "number" &&
    !/decorative\/Lines/.test(sizeName) &&
    sizeName in ILLUSTRATION_SIZE
  ) {
    return ILLUSTRATION_SIZE[sizeName as keyof typeof ILLUSTRATION_SIZE] + "px";
  } else {
    return sizeName + "px";
  }
};

export interface IllustrationProps
  extends Omit<HTMLChakraProps<"div">, "width" | "height"> {
  name: Illustrations;
  size?: IllustrationSize;
}

const Illustration: (props: IllustrationProps) => JSX.Element | null = ({
  name,
  size = "medium",
  ...customStyles
}) => {
  const ImportedIllustration = require(
    `!!@svgr/webpack?-svgo,+titleProp,+ref!../../assets/illustrations/${name}.svg`,
  ).default;

  return (
    <Box height={getSize(size)} {...customStyles}>
      <ImportedIllustration width={getSize(size)} height={getSize(size)} />
    </Box>
  );
};

export default Illustration;

export type { Illustrations } from "./illustrationTypes";
