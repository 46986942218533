import { Heading } from "./heading";
import { Accordion } from "./accordion";
import { Alert } from "./alert";
import { Avatar } from "./avatar";
import { Radio } from "./radio";
import { Checkbox } from "./checkbox";
import { Switch } from "./switch";
import { Button } from "./button";
import { Link } from "./link";
import { Menu } from "./menu";
import { Slider } from "./slider";
import { Input } from "./input";
import { Textarea } from "./textarea";
import { CreditCardInput } from "./cardInput";
import { Table } from "./table";
import { Tabs } from "./tabs";
import { Text } from "./text";
import { Tag } from "./tag";
import { Progress } from "./progress";
import { Drawer } from "./drawer";
import { Popover } from "./popover";

const components = {
  Heading,
  Accordion,
  Alert,
  Avatar,
  Radio,
  Checkbox,
  Switch,
  Button,
  Link,
  Menu,
  Slider,
  Input,
  Textarea,
  CreditCardInput,
  Table,
  Tabs,
  Tag,
  Text,
  Progress,
  Drawer,
  Popover,
};

export default components;
