import { Progress as ChakraProgress, StyleProps } from "@chakra-ui/react";
import { ThemeColors } from "../../theme/foundations/colors";

export interface ProgressProps extends StyleProps {
  value: number;
  color: ThemeColors;
  isIndeterminate?: boolean;
}

export const Progress = ({
  value = 0,
  color = "primary.900",
  isIndeterminate,
  ...customStyles
}: ProgressProps) => (
  <ChakraProgress
    size="xs"
    value={value}
    color={color}
    bg={isIndeterminate ? "primary.400" : "grayscale.200"}
    isIndeterminate={isIndeterminate}
    borderRadius="4px"
    {...customStyles}
  />
);
