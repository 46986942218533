import { mode, Styles } from "@chakra-ui/theme-tools";
import { osanoStyles } from "./osanoStyles";

type V2Styles = {
  styles: Styles;
};

const styles: V2Styles = {
  styles: {
    global: (props) => ({
      body: {
        color: mode("grayscale.800", "grayscale.0")(props),
        ...props.theme.textStyles.base,
        overflow: "auto",
        transition: "background .30s linear, color .25s linear",
        scrollbarColor:
          "var(--chakra-colors-grayscale-300) var(--chakra-colors-grayscale-200)",
        scrollbarWidth: "thin",
      },
      "&::-webkit-scrollbar": {
        width: "6px",
        height: "6px",
      },
      "&::-webkit-scrollbar-track": {
        background: "grayscale.200",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "grayscale.300",
        borderRadius: "4px",
      },
      userSelect: "text",
      pre: {
        all: "revert",
        fontFamily: props.theme.fonts.mono,
      },
      code: {
        fontFamily: props.theme.fonts.mono,
      },
      img: {
        maxWidth: "unset",
      },
      ".chakra-image": {
        maxWidth: "100%",
      },
      "@keyframes spinning": {
        from: {
          transform: "rotate(0deg)",
        },
        to: {
          transform: "rotate(360deg)",
        },
      },
      "@keyframes gradient": {
        "0%": {
          backgroundPosition: "0% 50%",
        },
        "50%": {
          backgroundPosition: "100% 50%",
        },
        "100%": {
          backgroundPosition: "0% 50%",
        },
      },
      "@keyframes bounce-bg": {
        to: {
          backgroundPosition: "400% 0",
        },
      },

      "#progress-animated": {
        animation: "spinning 1s linear infinite",
      },
      ...osanoStyles,
    }),
  },
};

export default styles;
