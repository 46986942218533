import React from "react";
import { Flex, Box, SlideFade, StyleProps, Text } from "@chakra-ui/react";
import Icon, { Icons } from "../icon/Icon";
import transition from "theme/foundations/transition";
import { ThemeColors } from "theme/foundations/colors";

export const FEEDBACK_STYLE = ["recommendation", "info"] as const;

export type FeedbackStyle = (typeof FEEDBACK_STYLE)[number];

export interface FeedbackRecommendationProps extends StyleProps {
  styleVariant?: FeedbackStyle;
  isBackgroundOn?: boolean;
  hasStartingWord?: boolean;
  startingWord?: string;
  show?: boolean;
  iconName?: Icons;
  hasIcon?: boolean;
  children: React.ReactElement | string | Array<React.ReactElement | string>;
}

const stylesConfig: Record<
  FeedbackStyle,
  Pick<StyleProps, "color" | "bg"> & {
    iconComponent: JSX.Element;
    startingText: string;
  }
> = {
  recommendation: {
    color: "warning.900",
    bg: "warning.100",
    iconComponent: <Icon name="status/Color/Information" fill="warning.900" />,
    startingText: "Recommendation:",
  },
  info: {
    color: "primary.900",
    bg: "primary.200",
    iconComponent: <Icon name="status/Color/Information" fill="primary.900" />,
    startingText: "Note:",
  },
};

export const FeedbackRecommendation = ({
  styleVariant = "recommendation",
  isBackgroundOn,
  show = false,
  hasStartingWord = true,
  startingWord,
  children,
  iconName,
  hasIcon = true,
  ...customStyles
}: FeedbackRecommendationProps) => {
  const { color, bg, iconComponent, startingText } = stylesConfig[styleVariant];
  const padding = isBackgroundOn ? "8px " : "4px ";
  return (
    <SlideFade in={show} unmountOnExit={true}>
      <Flex
        alignItems="flex-start"
        p={`${padding.repeat(3)} ${isBackgroundOn ? padding : "0px"}`}
        bg={isBackgroundOn ? bg : ""}
        borderRadius="8px"
        transitionDuration={transition.durationMs.fast}
        transitionTimingFunction={transition.easing.easeOut}
        {...customStyles}
      >
        {hasIcon && (
          <Box width="16px" paddingTop="2px">
            {iconName ? (
              <Icon name={iconName} fill={color as ThemeColors} />
            ) : (
              iconComponent
            )}
          </Box>
        )}
        <Flex ml="8px" color={isBackgroundOn ? "" : color}>
          <Text
            as="span"
            textAlign={"left"}
            textStyle={isBackgroundOn ? "base" : "baseBold"}
          >
            {isBackgroundOn && (hasStartingWord || startingWord) && (
              <Text as="span" textStyle="baseBold" color="grayscale.900">
                {startingWord || startingText}&nbsp;
              </Text>
            )}
            {children}
          </Text>
        </Flex>
      </Flex>
    </SlideFade>
  );
};

export default FeedbackRecommendation;
