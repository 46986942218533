import { ComponentStyleConfig } from "@chakra-ui/theme";

export const Heading: ComponentStyleConfig = {
  sizes: {
    h1: ({ theme }) => ({
      ...theme.textStyles.h1,
    }),
    h2: ({ theme }) => ({
      ...theme.textStyles.h2,
    }),
    h3: ({ theme }) => ({
      ...theme.textStyles.h3,
    }),
    h4: ({ theme }) => ({
      ...theme.textStyles.h4,
    }),
  },
};
