import { Box, Flex, keyframes, useDisclosure } from "@chakra-ui/react";
import Button from "components/button/Button";
import Divider from "components/divider/Divider";
import { Dropdown } from "components/dropdown/Dropdown";
import { OptionType } from "components/dropdown/dropdownTypes";
import { RightDrawer } from "components/rightDrawer/RightDrawer";
import { Toggle } from "components/selectors/Toggle";
import { observer } from "mobx-react";
import { useState } from "react";
import { useStores } from "stores";
import { colors } from "theme/foundations/colors";
import transition from "theme/foundations/transition";
import { PROD } from "utils/config";
import { ENV } from "utils/hostEnv";
import {
  TS_API_DEBUG_GATE,
  TS_API_DEBUG_OBJECT,
  TS_API_DEBUG_VALUE,
} from "utils/localStorage";

const options: OptionType[] = [
  {
    label: "No delay",
    value: 0,
    iconLeft: "shapes/Circle",
    iconLeftCustomStyles: {
      path: {
        fill: "success.900",
      },
    },
  },
  {
    label: "500ms delay",
    value: 500,
    iconLeft: "status/ProgressAnimated",
    iconLeftCustomStyles: {
      path: {
        fill: "warning.900",
      },
    },
  },
  {
    label: "1s delay",
    value: 1000,
    iconLeft: "status/ProgressAnimated",
    iconLeftCustomStyles: {
      path: {
        fill: "warning.900",
      },
    },
  },
  {
    label: "10s delay",
    value: 10000,
    iconLeft: "status/ProgressAnimated",
    iconLeftCustomStyles: {
      path: {
        fill: "warning.900",
      },
    },
  },
  {
    label: "30s delay",
    value: 30000,
    iconLeft: "status/ProgressAnimated",
    iconLeftCustomStyles: {
      path: {
        fill: "warning.900",
      },
    },
  },
  {
    label: "Fail call",
    value: -1,
    iconLeft: "status/Color/Alert",
    iconLeftCustomStyles: {
      path: {
        fill: "error.900",
      },
    },
  },
];

const pulse = keyframes` 
  from {box-shadow: 0 0 0 0px rgba(255, 0, 0, 0.4);} 
  to {box-shadow: 0 0 0 16px rgba(255, 0, 0, 0);} 
`;

export const RecordToggle = ({ record, setRecord }: any) => (
  <Flex
    alignItems="center"
    gap="12px"
    px="16px"
    py="12px"
    minH="50px"
    maxW="170px"
    borderRadius="8px"
    border={`1px solid ${record ? colors.primary[900] : colors.grayscale[300]}`}
    cursor="pointer"
    onClick={(evt) => {
      evt.stopPropagation();
      evt.preventDefault();
      setRecord((prev: boolean) => !prev);
    }}
  >
    <Flex
      textStyle="subtitle3"
      gap="8px"
      color="grayscale.800"
      alignItems="center"
    >
      {record ? (
        <Box
          w="15px"
          h="15px"
          bg="error.900"
          borderRadius="50px"
          animation={`${pulse} ${transition.durationMs.ultraSlow} infinite`}
        />
      ) : (
        <Box
          w="15px"
          h="15px"
          outline={`1px solid ${colors.grayscale[400]}`}
          borderRadius="50px"
        />
      )}
      Record
    </Flex>
    <Toggle size="md" isChecked={record} top="3px" />
  </Flex>
);

export const ApiBlocker = observer(() => {
  const { userStore } = useStores();
  const { currentEmail } = userStore;

  const showFeatures =
    ENV.NAME !== PROD || currentEmail?.endsWith("@timescale.com");

  const { onOpen, onClose, isOpen } = useDisclosure();
  const [record, setRecord] = useState(
    localStorage.getItem(TS_API_DEBUG_GATE) === TS_API_DEBUG_VALUE,
  );

  const [debugApi, setDebugApi] = useState(
    JSON.parse(localStorage.getItem(TS_API_DEBUG_OBJECT) || "{}"),
  );

  const apiKeys = Object.keys(debugApi);

  if (!showFeatures) return null;

  return (
    <>
      <Button
        onClick={() => onOpen()}
        size="sm"
        variant="ghost"
        position="fixed"
        bottom="3px"
        right="172px"
        zIndex="1400"
        color={record ? "error.900" : ""}
        background="grayscale.0"
        outline={record ? `1px solid ${colors.error[900]}` : ""}
        data-cy="api-blocker-button"
      >
        {record ? (
          <Box
            w="12px"
            h="12px"
            bg="error.900"
            borderRadius="50px"
            mr="4px"
            animation={`${pulse} ${transition.durationMs.ultraSlow} infinite`}
          />
        ) : (
          "🧱 "
        )}
        API Blocker
      </Button>

      <RightDrawer title="🧱 API Blocker" isOpen={isOpen} onClose={onClose}>
        <Flex flexDir="column">
          <Flex
            gap="12px"
            mt="12px"
            alignItems="center"
            justifyContent="space-between"
          >
            <RecordToggle record={record} setRecord={setRecord} />
            <Button
              variant="secondary"
              onClick={() => {
                if (record) {
                  localStorage.setItem(TS_API_DEBUG_GATE, TS_API_DEBUG_VALUE);
                  localStorage.setItem(
                    TS_API_DEBUG_OBJECT,
                    JSON.stringify(debugApi),
                  );
                  window.location.reload();
                } else {
                  localStorage.removeItem(TS_API_DEBUG_GATE);
                  localStorage.removeItem(TS_API_DEBUG_OBJECT);
                  setDebugApi({});
                  onClose();
                }
              }}
            >
              Save & Close
            </Button>
          </Flex>

          <Divider color="primary.400" mt="16px" mb="16px" />

          <Box mb="200px">
            {!apiKeys.length && (
              <Flex display="block" textAlign="center" width="100%">
                No GraphQL requests yet.
                <br />
                <br />
                Hit Record and Save to get started.
              </Flex>
            )}
            {apiKeys.map((key, index) => (
              <Flex
                key={key}
                bg={(index + 1) % 2 ? "primary.300" : "grayscale.0"}
                borderRadius="6px"
                alignItems="center"
                paddingLeft="8px"
              >
                <Box textStyle="baseBold" w="400px">
                  {key}{" "}
                </Box>
                <Flex alignItems="center" gap="12px">
                  <Dropdown
                    onChange={(option) => {
                      setDebugApi({ ...debugApi, [key]: option.value });
                    }}
                    options={options}
                    value={options.find(
                      (option) => option.value === debugApi[key],
                    )}
                    width="180px"
                  />
                </Flex>
              </Flex>
            ))}
          </Box>
        </Flex>
      </RightDrawer>
    </>
  );
});
