import Logger from "utils/logger";
import ErrorPage from "pages/ErrorPage";
import NewVersionModal from "pages/NewVersionModal";

type ErrorCatchProps = {
  error: Error;
  componentStack: string | null;
  resetError?: () => void;
};

export const ErrorCatch = ({ error, componentStack }: ErrorCatchProps) => {
  Logger.error("ErrorBoundary catch", {
    source: "client",
    error: error,
    errorInfo: componentStack,
  });

  if (error.name === "ChunkLoadError") {
    return (
      <>
        <NewVersionModal isOpen={true} />
        <ErrorPage />
      </>
    );
  }
  return null;
};
