import { HTMLChakraProps, Tag, TagLabel } from "@chakra-ui/react";
import { MouseEventHandler } from "react";
import { typography } from "theme/foundations/typography";

import { TAG_COLOR, TAG_FONT_STYLE } from "../tagTypes";
import { COLOR_CONFIG, STYLE_CONFIG } from "../tagStyles";
import Icon, { Icons } from "components/icon/Icon";
import { ThemeColors } from "theme/foundations/colors";

const tagStatusStyles = {
  ...typography.textStyles.base,
  alignItems: "flex-start",
  borderColor: "transparent",
  borderRadius: "12px",
  backgroundColor: "grayscale.0",
  "& > svg": {
    marginRight: "8px",
  },
};

export interface TagStatusProps {
  label: string;
  color: TAG_COLOR;
  fontStyle?: TAG_FONT_STYLE;
  iconName?: Icons;
  onClick?: MouseEventHandler<HTMLSpanElement>;
  labelProps?: HTMLChakraProps<"div">;
  hasBackground?: boolean;
  [x: string]: any;
}

const TagStatus = ({
  label,
  color,
  fontStyle,
  iconName,
  onClick,
  hasBackground = true,
  labelProps = {},
  simpleStatus,
  ...rest
}: TagStatusProps) => {
  const colorConfig = COLOR_CONFIG(false)[color];
  return (
    <Tag
      sx={{
        ...colorConfig,
        ...tagStatusStyles,
        alignItems: "center",
        ...(!hasBackground ? { backgroundColor: "transparent" } : {}),
      }}
      onClick={onClick}
      {...rest}
    >
      {iconName && (
        <Icon name={iconName} fill={colorConfig.color as ThemeColors} mr={2} />
      )}
      <TagLabel
        sx={
          fontStyle
            ? { ...STYLE_CONFIG(colorConfig.borderColor)[fontStyle] }
            : {}
        }
        color={colorConfig.color}
        {...labelProps}
      >
        {label}
      </TagLabel>
    </Tag>
  );
};

export default TagStatus;
