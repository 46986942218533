import { observer } from "mobx-react";
import { useStores } from "stores";
import { ENV } from "utils/hostEnv";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useLatest } from "react-use";
import Modal from "components/modal/Modal";
import CircularProgress from "components/progress/CircularProgress";
import { Heading, Link, Text } from "@chakra-ui/react";
import { LeftNavContext } from "./navigation/NavigationProvider";
import { Project } from "graphql/generated";

// TODO: share these types with PopSQL
export enum NavbarPanel {
  aiChat = "aiChat",
  dbt = "dbt",
  files = "files",
  schemas = "schemas",
}

export type MessageToPopSQL =
  | { activePanel: NavbarPanel; type: "navbar" }
  | { minified: boolean; type: "navbar" }
  | { context: string; type: "open" }
  | { projects: Project[]; type: "projects" }
  | { type: "services"; count: number }
  | { target: string; type: "route" };

export type MessageFromPopSQL =
  | { type: "close" }
  | { type: "navbar"; minified: boolean }
  | { type: "ready" }
  | { type: "missingToken" }
  | { replace?: boolean; target: string; type: "route" }
  | { type: "switchProject"; projectId: string }
  | { type: "localStorageError" }
  | { type: "logout" };

const noop = () => {};

export const PopSQLFrameContext = createContext<{
  hide: () => void;
  loading: boolean;
  ready: boolean;
  sendMessage: (message: MessageToPopSQL) => void;
  show: (context: string) => void;
  visible: boolean;
}>({
  hide: noop,
  ready: false,
  loading: false,
  sendMessage: noop,
  show: noop,
  visible: false,
});

interface Props {
  children: ReactNode;
}

export const PopSQLFrame = observer(({ children }: Props) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [iframeKey, setIframeKey] = useState("initial");
  const [isLoading, setIsLoading] = useState(true);
  const [ready, setReady] = useState(false);
  const [localStorageError, setLocalStorageError] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const search = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );
  const latestSearch = useLatest(search);
  const lastRouteRef = useRef("/");
  const { authStore, projectsStore, userStore, serviceStore } = useStores();
  const { allProjects } = projectsStore;
  const projectId = projectsStore?.currentProject?.id;
  const popsqlUrl = `${ENV.POPSQL_REDIRECT}${projectId}`;
  const visible = !!projectId && ready && search.has("popsql");
  const loading = !!projectId && isLoading && !ready && search.has("popsql");
  const { isLeftNavMinified, setLeftNavMinified } = useContext(LeftNavContext);

  const reloadIframe = useCallback(() => {
    setIframeKey(Date.now().toString());
    setIsLoading(true);
    setReady(false);
  }, []);
  const latestReloadIframe = useLatest(reloadIframe);

  useEffect(() => {
    latestReloadIframe.current();
  }, [latestReloadIframe, projectId]);

  const setSearchParams = useCallback(
    (params: Record<string, string | null>, replace = false) => {
      const newSearch = new URLSearchParams(latestSearch.current);
      Object.entries(params).forEach(([key, value]) => {
        if (value == null) {
          newSearch.delete(key);
        } else {
          newSearch.set(key, value);
        }
      });
      history[replace ? "replace" : "push"]({
        search: newSearch.toString(),
      });
    },
    [history, latestSearch],
  );
  const latestSetSearchParams = useLatest(setSearchParams);

  const sendMessage = useCallback(
    (message: MessageToPopSQL) => {
      if (!ready) {
        throw new Error("PopSQL is not ready to receive messages");
      }
      iframeRef.current?.contentWindow?.postMessage(
        message,
        ENV.POPSQL_APP_ORIGIN,
      );
    },
    [ready],
  );

  // Register a listener for messages from PopSQL
  // Avoid rebinding the listener on every render - use refs for unstable functions/values
  useEffect(() => {
    const listener = (event: MessageEvent<MessageFromPopSQL>) => {
      if (event.origin !== ENV.POPSQL_APP_ORIGIN) return;
      const type = event.data?.type;
      if (!type) return;
      switch (type) {
        case "close": {
          // The button within PopSQL was clicked to close the iframe
          latestSetSearchParams.current({ popsql: null });
          break;
        }
        case "missingToken": {
          // The iframe is telling us that it didn't properly receive the timescale-connect token
          // This is usually unexpected, but there have been some reports of this happening
          // We can reload the iframe to go through the flow again and get a new token
          // We cannot directly reload the iframe due to cross-origin constraints
          // However, changing the key forces react to re-render the iframe
          latestReloadIframe.current();
          break;
        }
        case "navbar": {
          const { minified } = event.data;
          setLeftNavMinified(minified);
          break;
        }
        case "ready": {
          // The PopSQL iframe is ready to receive messages
          const target = latestSearch.current.get("popsql");
          if (target) {
            // ensure that PopSQL is synced to the correct route
            event.source?.postMessage(
              { type: "route", target },
              event.origin as any,
            );
          }
          setReady(true);
          break;
        }
        case "route": {
          // PopSQL has navigated to a new route
          // Update the URL/history to reflect the new route
          const { target, replace } = event.data;
          lastRouteRef.current = target;
          if (
            latestSearch.current.has("popsql") &&
            latestSearch.current.get("popsql") !== target
          ) {
            latestSetSearchParams.current({ popsql: target }, replace);
          }
          break;
        }
        case "switchProject": {
          projectsStore.switchProjects(event.data.projectId);
          userStore.mergeUiState({
            selectedProjectId: event.data.projectId,
          });
          latestReloadIframe.current();
          break;
        }
        case "localStorageError": {
          // The iframe is telling us that it can't access localStorage (which
          // is required to run the PopSQL app). This typically happens because
          // the user has disabled third-party cookies/storage in their browser.
          // In this case, show an explanation to the user along with a link to
          // open PopSQL in a separate tab. Also remove the iframe from the DOM.
          setLocalStorageError(true);
          break;
        }
        case "logout": {
          authStore.logout();
          break;
        }
        default: {
          console.error(
            // @ts-expect-error exhaustive cases above
            `Unknown message from PopSQL of type '${type?.toString()}'`,
          );
          break;
        }
      }
    };
    window.addEventListener("message", listener);
    return () => {
      window.removeEventListener("message", listener);
    };
  }, [
    authStore,
    latestReloadIframe,
    latestSearch,
    latestSetSearchParams,
    projectsStore,
    setLeftNavMinified,
    userStore,
  ]);

  // notify PopSQL when the route changes
  // handles deep linking to a PopSQL page, and history navigation
  useEffect(() => {
    if (!ready) return;
    const target = search.get("popsql");
    if (!target || lastRouteRef.current === target) return;

    sendMessage({ type: "route", target });
    lastRouteRef.current = target;
  }, [ready, search, sendMessage]);

  // notify PopSQL when services are added/removed
  useEffect(() => {
    if (!ready) return;
    const count = serviceStore?.allServices.length;
    if (count == null) return;

    sendMessage({ type: "services", count });
  }, [ready, serviceStore?.allServices.length, sendMessage]);

  // notify PopSQL when the left nav is expanded/minified
  useEffect(() => {
    if (!ready) return;
    sendMessage({ type: "navbar", minified: isLeftNavMinified });
  }, [ready, isLeftNavMinified, sendMessage]);

  useEffect(() => {
    if (!ready) return;
    sendMessage({
      projects: JSON.parse(JSON.stringify(allProjects)),
      type: "projects",
    });
  }, [allProjects, ready, sendMessage]);

  // We set ready to true via the listener above, which triggers the next render,
  // and then this effect and all others run after that render, and then we trigger
  // actually showing the frame on the next render.
  useEffect(() => {
    if (!ready) return;
    setIsLoading(false);
  }, [ready]);

  const show = useCallback(
    (context: string) => {
      latestSetSearchParams.current({ popsql: lastRouteRef.current || "/" });
      if (ready) {
        // notify PopSQL that it is being shown
        sendMessage({ context, type: "open" });
      }
    },
    [sendMessage, latestSetSearchParams, ready],
  );

  const hide = useCallback(() => {
    latestSetSearchParams.current({ popsql: null });
  }, [latestSetSearchParams]);

  return (
    <PopSQLFrameContext.Provider
      value={{
        hide,
        loading,
        ready,
        sendMessage,
        show,
        visible,
      }}
    >
      {children}
      <Modal isOpen={loading} onClose={hide} size="lg">
        {localStorageError ? (
          <>
            <Heading as="h4" size="h4" mb="12px" mt="12px">
              There was a problem loading your SQL editor.
            </Heading>
            <Text mb="24px">
              Please enable third-party cookies/storage to access PopSQL from
              within the Timescale Console, or{" "}
              <Link href={popsqlUrl} isExternal>
                open PopSQL in a new tab
              </Link>{" "}
              instead.
            </Text>
          </>
        ) : (
          <>
            <CircularProgress size="lg" />
            <Heading as="h3" size="h3" mb="12px" mt="24px">
              Loading...
            </Heading>
            {"Just a moment while we prepare Data mode!"}
          </>
        )}
      </Modal>
      {projectId && !localStorageError ? (
        <iframe
          // Changing the key forces the iframe to reload
          key={iframeKey}
          allow={`clipboard-write self ${ENV.POPSQL_APP_ORIGIN}`}
          ref={iframeRef}
          src={`${ENV.POPSQL_REDIRECT}${projectId}`}
          title="PopSQL"
          style={{
            display: visible ? "block" : "none",
            backgroundColor: "white",
            position: "fixed",
            inset: 0,
            zIndex: 10000,
          }}
          width="100%"
          height="100%"
        />
      ) : null}
    </PopSQLFrameContext.Provider>
  );
});
