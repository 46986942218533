import { typography } from "./foundations/typography";

export const osanoStyles = {
  ".osano-cm-widget": {
    display: "none",
  },
  ".osano-cm-window__dialog": {
    ...typography.textStyles.base,
    color: "grayscale.900",
    backgroundColor: "primary.500",
    padding: "16px 40px",
    overflowY: "hidden",
    justifyContent: "space-between",
    gap: { sm: "0", md: "32px" },
  },
  ".osano-cm-link": {
    color: "grayscale.900",
  },
  ".osano-cm-button": {
    borderRadius: "99px",
    border: "transparent",
  },
  ".osano-cm-button:hover": {
    backgroundColor: "primary.800",
  },
  ".osano-cm-buttons__button": {
    ...typography.textStyles.baseBold,
    maxWidth: { sm: "unset !important", md: "300px" },
    width: "100% !important",
    margin: "0 !important",
    color: "grayscale.0",
    backgroundColor: "grayscale.900",
    outline: "0",
    border: "none",
    borderRadius: "99px",
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: "grayscale.800",
    },
  },
  ".osano-cm-buttons__button:last-child": {
    border: "0px",
    "&:hover": {
      backgroundColor: "grayscale.400",
    },
  },
  ".osano-cm-dialog--type_bar": {
    ".osano-cm-dialog__content": {
      marginBottom: 0,
      maxWidth: "100%",
    },
  },
  ".osano-cm-dialog__buttons": {
    justifyContent: "center",
    flexWrap: "nowrap",
    gap: "10px",
    maxWidth: "unset !important",
    marginLeft: "32px !important",
    flexDirection: "column",
    minWidth: { md: "unset !important", lg: "initial" },
    flex: { md: "unset", lg: "default" },
    width: { sm: "100% !important", md: "initial" },
    alignItems: { sm: "center", md: "initial" },
    margin: { sm: "unset !important", md: "initial" },
    borderRadius: "99px",
  },
  ".osano-cm-dialog__content": {
    maxWidth: { sm: "100%", md: "65%" },
    flex: { sm: "initial", md: "3 !important" },
    paddingBottom: { sm: "16px", md: "initial" },
    textAlign: { sm: "center", md: "start" },
    "& > span": {
      display: { sm: "block", md: "initial" },
      padding: { sm: "0", md: "initial" },
      margin: { sm: "0", md: "initial" },
    },
  },
  ".osano-cm-window__widget": {
    display: "none",
  },
  ".osano-cm-dialog__close": {
    transform: "none",
    transition: "none",
    marginRight: "8px",
    marginTop: "8px",
    stroke: "grayscale.900",
    "&:focus": {
      stroke: "grayscale.800",
      backgroundColor: "transparent !important",
      borderColor: "transparent !important",
      strokeWidth: "1px",
    },
    "&:hover": {
      stroke: "var(--chakra-colors-grayscale-800) !important",
      strokeWidth: "1px",
    },
  },
  ".osano-cm-info-dialog-header__close": {
    transform: "none",
    transition: "none",
    "&:focus": {
      backgroundColor: "transparent",
      borderColor: "transparent",
      stroke: "grayscale.800",
      strokeWidth: "1px",
    },
    "&:hover": {
      stroke: "var(--chakra-colors-grayscale-800) !important",
      strokeWidth: "1px",
    },
  },
  ".osano-cm-toggle__switch": {
    backgroundColor: "primary.900",
    "&::after": {
      backgroundColor: "grayscale.0",
    },
    "&:focus": {
      border: "none",
    },
  },
  ".osano-cm-toggle__input:disabled:checked + .osano-cm-toggle__switch": {
    backgroundColor: "primary.900",
    borderColor: "primary.900",
    "&::after": {
      backgroundColor: "primary.600",
    },
  },
  ".osano-cm-toggle__input:disabled:checked + .osano-cm-toggle__switch, .osano-cm-toggle__input:disabled:checked:hover + .osano-cm-toggle__switch, .osano-cm-toggle__input:disabled:checked:active + .osano-cm-toggle__switch, .osano-cm-toggle__input:disabled:checked:focus + .osano-cm-toggle__switch":
    {
      background: "primary.900",
      borderColor: "primary.900",
    },
  ".osano-cm-toggle__input:checked + .osano-cm-toggle__switch, .osano-cm-toggle__input:checked:hover + .osano-cm-toggle__switch, .osano-cm-toggle__input:checked:active + .osano-cm-toggle__switch, .osano-cm-toggle__input:checked:focus + .osano-cm-toggle__switch":
    {
      background: "primary.900",
      borderColor: "primary.900",
    },
  ".osano-cm-toggle__input:checked + .osano-cm-toggle__switch": {
    backgroundColor: "primary.900",
    borderColor: "primary.900",
  },
  ".osano-cm-toggle__input:disabled:checked:focus + .osano-cm-toggle__switch": {
    "&::after": {
      backgroundColor: "primary.600",
    },
  },
  ".osano-cm-toggle__input:disabled:checked:hover + .osano-cm-toggle__switch": {
    "&::after": {
      backgroundColor: "primary.600",
    },
  },
  ".osano-cm-toggle__input + .osano-cm-toggle__switch": {
    backgroundColor: "grayscale.500",
    borderColor: "grayscale.500",
  },
  ".osano-cm-toggle__input:focus + .osano-cm-toggle__switch::before": {
    borderColor: "transparent !important",
  },
  ".osano-cm-toggle__label": {
    ...typography.textStyles.baseBold,
  },
  ".osano-cm-view__button": {
    ...typography.textStyles.baseBold,
    background: "grayscale.900",
    color: "grayscale.0",
    "&:hover": {
      background: "grayscale.800",
    },
  },
  ".osano-cm-description": {
    ...typography.textStyles.base,
    color: "grayscale.700",
  },
  ".osano-cm-info-dialog-header__header": {
    fontSize: "20px",
    lineHeight: "24px",
    color: "grayscale.900",
    fontWeight: "700",
  },
  ".osano-cm-disclosure__toggle": {
    color: "primary.900",
    "&:focus": {
      color: "primary.900",
      "osano-cm-disclosure__toggle": {
        "&:hover": {
          color: "primary.800",
        },
      },
    },
    "&:hover": {
      color: "primary.800",
    },
    "&:active": {
      color: "primary.800",
    },
  },
  ".osano-cm-expansion-panel__toggle": {
    "&:hover": {
      color: "primary.800",
    },
    "&:active": {
      color: "primary.800",
    },
  },
};
