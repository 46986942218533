import { Box as ChakraBox, HTMLChakraProps } from "@chakra-ui/react";

export const BORDER_RADIUS = [
  "0px",
  "4px",
  "8px",
  "16px",
  "24px",
  "40px",
] as const;
export const BOX_SHADOW = ["none", "sm", "lg", "tooltip", "table"] as const;

export interface ContainerProps extends HTMLChakraProps<"div"> {
  borderRadius?:
    | (typeof BORDER_RADIUS)[number]
    | Array<(typeof BORDER_RADIUS)[number]>
    | {
        base?: (typeof BORDER_RADIUS)[number];
        lg?: (typeof BORDER_RADIUS)[number];
        md?: (typeof BORDER_RADIUS)[number];
        sm?: (typeof BORDER_RADIUS)[number];
      };
  boxShadow?: (typeof BOX_SHADOW)[number];
}

export const BOX_SHADOW_VARIANT = {
  none: "none",
  sm: "1px 3px 4px rgba(0, 0, 0, 0.06), 1px 5px 4px -1px rgba(0, 0, 0, 0.06)",
  lg: "6px 8px 4px rgba(0, 0, 0, 0.12), 0px 6px 4px -1px rgba(0, 0, 0, 0.06)",
  tooltip: "3px 4px 3px rgba(0, 0, 0, 0.08)",
  table: "3px 0px 2px rgba(0, 0, 0, 0.06)",
  recommendation:
    "2px 3px 4px 0px rgba(88, 63, 245, 0.15), 3px 4px 4px -1px rgba(255, 120, 122, 0.16)",
  entitlement:
    "1px 3px 4px 0px rgba(0, 0, 0, 0.06), 1px 5px 4px -1px rgba(0, 0, 0, 0.06)",
};

export const Container = ({
  children,
  borderRadius = "0px",
  boxShadow = "none",
  ...customStyles
}: ContainerProps) => {
  return (
    <ChakraBox
      borderRadius={borderRadius}
      boxShadow={BOX_SHADOW_VARIANT[boxShadow]}
      bg="grayscale.0"
      {...customStyles}
    >
      {children}
    </ChakraBox>
  );
};

export default Container;
