import { useState, useEffect } from "react";
import {
  Input as ChakraInput,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import Icon from "../icon/Icon";
import { useDebounce } from "react-use";

export interface InputSearchProps {
  onChange: (value: string | undefined) => void;
  value: string | undefined;
  debounceTimeMs?: number;
  variant?: string;
  isDisabled?: boolean;
  placeholder?: string;
  [x: string]: any;
}

export const InputSearch = ({
  onChange,
  value,
  debounceTimeMs = 60,
  variant = "outline",
  isDisabled = false,
  placeholder = "Search",
  ...rest
}: InputSearchProps) => {
  const [internalValue, setInternalValue] = useState<string | undefined>("");
  useDebounce(
    () => {
      onChange(internalValue);
    },
    debounceTimeMs,
    [internalValue],
  );

  useEffect(() => {
    setInternalValue(value);
  }, [value, setInternalValue]);

  const handleChange = (e: any) => {
    setInternalValue(e?.target?.value);
  };
  return (
    <InputGroup variant={variant} {...rest}>
      <ChakraInput
        placeholder={placeholder}
        value={internalValue}
        onChange={handleChange}
        data-cy="search-input"
        name="search-input"
      />
      <InputLeftElement
        pointerEvents="none"
        children={<Icon name="actions/Search" fill="null" />}
        padding="0px"
      />
      <InputRightElement
        cursor="pointer"
        children={
          internalValue ? (
            <Icon
              name="actions/Close/Small"
              onClick={() => setInternalValue("")}
              fill="grayscale.800"
              data-cy="search-close-icon"
            />
          ) : null
        }
      />
    </InputGroup>
  );
};
