import { PostgresExtension } from "graphql/generated";
import { satisfies } from "compare-versions";

export const getAiExtensionState = (
  extensions: PostgresExtension[] | undefined | null,
) => {
  const pgAiExtension = (extensions || []).find(
    (e: PostgresExtension) => e.name === "ai",
  );
  return {
    isPgAiInstalled: !!pgAiExtension?.installedVersion,
    isPgAiInstalledWithVectorizer: pgAiExtension?.installedVersion
      ? satisfies(pgAiExtension.installedVersion, ">=0.4.0")
      : false,
    isVectorizerAvailable: pgAiExtension?.defaultVersion
      ? satisfies(pgAiExtension.defaultVersion, ">=0.4.0")
      : false,
  };
};
