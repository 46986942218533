import { useEffect, useMemo, useState } from "react";
import authStore from "stores/authStore";
import { MfaChallenge, modalContent } from "pages/account/mfa/mfaComponents";
import { ChallengeProps, InputType } from "pages/account/mfa/mfaTypes";

export const useChallenge = ({
  verify,
  authCode,
  authVars,
  shouldVerify = false,
  inputType = "digits",
}: Pick<ChallengeProps, "verify" | "authCode" | "shouldVerify" | "authVars"> & {
  inputType: InputType;
}) => {
  const [hasError, setHasError] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const inputLength = inputType === "digits" ? 6 : 10;
  const isComplete = useMemo(() => {
    return authCode.replace(/[\s-]/g, "").length === inputLength;
  }, [authCode, inputLength]);

  useEffect(() => {
    let unmounting = false;
    async function challengeVerify() {
      setHasError(false);
      if (
        authCode.replace(/[\s-]/g, "").length === inputLength &&
        shouldVerify
      ) {
        setIsSubmitting(true);
        setIsVerified(false);
        const hasOutsideVerification = await verify({
          authCode,
          ...(authVars ? authVars : {}),
        });
        if (!unmounting) {
          setIsSubmitting(false);
          setIsVerified(hasOutsideVerification);
          setHasError(!hasOutsideVerification);
        }
      }
    }
    challengeVerify();
    return () => {
      unmounting = true;
    };
  }, [authCode, shouldVerify, inputLength]); //eslint-disable-line react-hooks/exhaustive-deps

  return { hasError, setHasError, isVerified, isSubmitting, isComplete };
};

export const useMfaModal = ({
  children,
  title,
  onClose,
}: {
  children: any;
  title: string;
  onClose: () => void;
}) => {
  const [modalTitle, setModalTitle] = useState("");
  const [content, setContent] = useState<React.ReactElement>();
  const hasMFA = () => authStore.needsMfaChallenge;
  const trustToken = authStore.fetchMfaTrustToken;

  const closeModalAndCleanUp = () => {
    authStore.resetError();
    authStore.clearMfaChallenge();
    onClose();
  };

  useEffect(() => {
    function renderMFA() {
      if (hasMFA()) {
        setContent(
          <MfaChallenge
            verify={trustToken}
            onCancel={closeModalAndCleanUp}
            canUseRecoveryCode
          />,
        );
        setModalTitle(modalContent[3].title);
      } else {
        setContent(children);
        setModalTitle(title);
      }
    }

    try {
      renderMFA();
    } catch (err) {
      console.log(err);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return [content, modalTitle];
};
