import { Highlight, Language } from "prism-react-renderer";
import theme from "./theme";
import { Box, Flex, StyleProps } from "@chakra-ui/react";
import Button from "components/button/Button";
import Icon from "components/icon/Icon";
import copy from "copy-to-clipboard";
import { useHoverDirty } from "react-use";
import { useRef, useState } from "react";
import { SystemStyleObject } from "@chakra-ui/styled-system";

export interface CodeProps extends StyleProps {
  code: string;
  language?: Language;
  withLines?: boolean;
  prompt?: string;
  copyFn?: boolean;
  "data-cy"?: string;
  copyOnClick?: (e?: Event) => unknown;
  configOnClick?: (e?: Event) => unknown;
  deleteOnClick?: (e?: Event) => unknown;
  sx?: SystemStyleObject;
}

export const Code = ({
  code = "",
  language = "jsx",
  withLines = true,
  prompt,
  copyFn = false,
  "data-cy": dataCy,
  copyOnClick,
  configOnClick,
  deleteOnClick,
  ...rest
}: CodeProps) => {
  const hoverRef = useRef(null);
  const isHovered = useHoverDirty(hoverRef);
  const configOptions = configOnClick || deleteOnClick;
  const [wasCopied, setWasCopied] = useState(false);

  const { p, padding, ...customStyles } = rest;

  return (
    <Box
      display="grid"
      position="relative"
      maxWidth="100%"
      ref={hoverRef}
      onMouseLeave={() => {
        setWasCopied(false);
      }}
      data-cy={dataCy}
      {...customStyles}
    >
      {configOptions && (
        <Flex
          bgColor="grayscale.700"
          h="48px"
          alignItems="center"
          justifyContent="end"
        >
          <Flex gap="12px" mr="16px">
            {configOnClick && (
              <Button
                onClick={configOnClick}
                icon={<Icon name="elements/Slider" />}
                variant="secondary"
                sx={{ color: "grayscale.900" }}
                size="sm"
              >
                Configure
              </Button>
            )}
            {deleteOnClick && (
              <Button
                onClick={deleteOnClick}
                icon={<Icon name="actions/Delete/Lines" />}
                variant="danger"
                size="sm"
              >
                Delete
              </Button>
            )}
          </Flex>
        </Flex>
      )}
      <Highlight theme={theme} code={code} language={language}>
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <Box
            as="pre"
            textAlign="left"
            w="auto"
            p={p || padding || "8px"}
            m="0"
            position="relative"
            textStyle="codeS"
            overflow="auto"
            borderRadius={configOptions ? "0 0 4px 4px" : "4px"}
            className={className}
            style={style}
          >
            {tokens.map((line, i) =>
              prompt ? (
                <Box
                  as="span"
                  display="table-row"
                  key={i}
                  {...getLineProps({ line })}
                >
                  <Box
                    as="span"
                    display="table-cell"
                    textAlign="right"
                    pr="1em"
                    userSelect="none"
                  >
                    {prompt}
                  </Box>
                  <Box as="span" display="table-cell">
                    {line.map((token, key) => (
                      <span key={key} {...getTokenProps({ token })} />
                    ))}
                  </Box>
                </Box>
              ) : withLines ? (
                <Box
                  as="span"
                  display="table-row"
                  key={i}
                  {...getLineProps({ line })}
                >
                  <Box
                    as="span"
                    display="table-cell"
                    textAlign="right"
                    pr="1em"
                    userSelect="none"
                  >
                    {i + 1}
                  </Box>
                  <Box as="span" display="table-cell">
                    {line.map((token, key) => (
                      <span key={key} {...getTokenProps({ token })} />
                    ))}
                  </Box>
                </Box>
              ) : (
                <div key={i} {...getLineProps({ line })}>
                  {line.map((token, key) => (
                    <span key={key} {...getTokenProps({ token })} />
                  ))}
                </div>
              ),
            )}
          </Box>
        )}
      </Highlight>
      {copyFn && (
        <Button
          icon={<Icon name="actions/Copy" />}
          size="sm"
          variant="secondary"
          position="absolute"
          top={configOptions ? "56px" : "8px"}
          right="8px"
          fontFamily="body"
          display={isHovered ? "inline-flex" : "none"}
          onClick={() => {
            copy(code);
            setWasCopied(true);
            copyOnClick && copyOnClick();
          }}
          data-cy={dataCy ? `${dataCy}-copy-button` : undefined}
        >
          {wasCopied ? "Copied!" : "Copy"}
        </Button>
      )}
    </Box>
  );
};
