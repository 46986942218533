import {
  Box,
  Flex,
  forwardRef,
  Menu,
  MenuButton,
  MenuList,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { NotificationCardContainer } from "./NotificationCardContainer";
import { VerticalNavMenuItem } from "components/navigation/mainMenu/VerticalNavMenuItem";
import Icon from "components/icon/Icon";
import Divider from "components/divider/Divider";
import { ReactComponent as EmptyNotifications } from "assets/illustrations/neutral/Click.svg";

export type Invitation = {
  id: string;
  hash: string;
  created: string;
  inviter: string;
  inviterEmail: string;
  projectName: string;
};

const NotificationCenterMenuButton = forwardRef((props, ref) => {
  const {
    isActive,
    notifications,
    "aria-expanded": isExpanded,
    ...rest
  } = props;
  return (
    <Flex ref={ref}>
      <VerticalNavMenuItem
        isActive={isActive}
        item={{
          id: "notifications",
          label: "Notifications",
          icon: (
            <Icon
              name={
                notifications.length
                  ? "status/Bell/Notification"
                  : "status/Bell/On"
              }
              fill={notifications.length ? "primary.900" : undefined}
              size="20"
              data-cy={
                notifications.length
                  ? "notification-bell-on"
                  : "notification-bell-off"
              }
            />
          ),
        }}
        data-cy={
          notifications.length
            ? "notification-bell-on"
            : "notification-bell-off"
        }
        {...rest}
      />
    </Flex>
  );
});

export const NotificationCenter = ({
  notifications,
}: {
  notifications: Invitation[];
}) => {
  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  return (
    <Menu placement={isSmallScreen ? "top" : "right"} gutter={16}>
      {({ isOpen, onClose }) => (
        <>
          <MenuButton
            as={NotificationCenterMenuButton}
            isActive={isOpen}
            notifications={notifications}
            data-cy="notification-center-menu-item"
          />
          <MenuList w="360px" data-cy="notification-center">
            <Text textStyle="baseBold" color="grayscale.700" p="12px">
              Notifications
            </Text>

            {notifications.length > 0 ? (
              notifications.map((invite, index) => (
                <NotificationCardContainer
                  invite={invite}
                  onClose={onClose}
                  key={index}
                />
              ))
            ) : (
              <>
                <Divider color="primary.400" />
                <Flex alignItems="center" px="12px" gap="8px" py="12px">
                  <EmptyNotifications height="70px" width="90px" />
                  <Box>
                    <Text color="grayscale.900">All clear!</Text>
                    <Text color="grayscale.700">
                      There are no notifications
                    </Text>
                  </Box>
                </Flex>
              </>
            )}
          </MenuList>
        </>
      )}
    </Menu>
  );
};
