import { useStores } from "stores";
import {
  Flex,
  forwardRef,
  Menu,
  MenuButton,
  MenuList,
  Text,
  useDisclosure,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Avatar } from "components/avatar/Avatar";
import { AVATAR_BG } from "pages/account/ChangeAvatarTooltip";
import { useHistory } from "react-router-dom";
import { Icons } from "components/icon/Icon";
import { observer } from "mobx-react";
import { ModalFeedback } from "./ModalFeedback";
import { VerticalNavMenuItem } from "components/navigation/mainMenu/VerticalNavMenuItem";
import { Fragment, useContext, useState } from "react";
import { MenuItem, MenuItemLinkProps, SmallScreenMenu } from "./menuComponents";
import Divider from "components/divider/Divider";
import { LeftNavContext } from "./NavigationProvider";

const UserMenuButton = forwardRef(
  ({ userName, bgColor, isLeftNavMinified, ...rest }, ref) => (
    <Flex ref={ref}>
      <VerticalNavMenuItem
        isChevron={true}
        item={{
          id: "user",
          label: isLeftNavMinified ? "Account" : userName,
          icon: <Avatar bgColor={bgColor} size="xs" />,
        }}
        {...rest}
      />
    </Flex>
  ),
);

const CurrentUserDetails = ({
  userName,
  userEmail,
  bgColor,
  ...rest
}: {
  userName: string;
  userEmail: string;
  bgColor: string;
}) => (
  <Flex
    alignItems="center"
    gap="8px"
    p="8px"
    textColor="grayscale.700"
    {...rest}
  >
    <Avatar bgColor={bgColor} size="md" />
    <Flex direction="column">
      <Text textStyle="baseBold">{userName}</Text>
      <Text textStyle="caption">{userEmail}</Text>
    </Flex>
  </Flex>
);

export const UserMenu = observer(() => {
  const { userStore, authStore } = useStores();
  const { setIsMenuOpen, isLeftNavMinified } = useContext(LeftNavContext);
  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  const [showSmallScreenMenu, setShowSmallScreenMenu] = useState(false);

  const { [AVATAR_BG]: bgColor } = userStore?.uiState;
  const history = useHistory();

  const {
    isOpen: isRequestFeatureOpen,
    onOpen: onRequestFeatureOpen,
    onClose: onRequestFeatureClose,
  } = useDisclosure();

  const menuItems = [
    {
      id: "account",
      label: "Account",
      iconName: "status/Feeling/Happy" as Icons,
      onClick: () => {
        history.push("/dashboard/account");
        setIsMenuOpen(false);
      },
    },
    {
      id: "feedback",
      label: "Send feedback",
      iconName: "elements/Star" as Icons,
      onClick: () => onRequestFeatureOpen(),
    },
    {
      id: "logout",
      label: "Log out",
      iconName: "actions/Logout" as Icons,
      onClick: () => authStore.logout(),
    },
  ];

  return (
    <>
      <ModalFeedback
        isOpen={isRequestFeatureOpen}
        onClose={() => {
          onRequestFeatureClose();
        }}
      />
      {isSmallScreen ? (
        <>
          <UserMenuButton
            userName={userStore?.currentUser}
            bgColor={bgColor}
            onClick={() => setShowSmallScreenMenu(!showSmallScreenMenu)}
          />
          {showSmallScreenMenu && (
            <SmallScreenMenu
              title="Account"
              onTitleClick={() => setShowSmallScreenMenu(false)}
            >
              <UserMenuList items={menuItems} />
            </SmallScreenMenu>
          )}
        </>
      ) : (
        <Menu placement="right" gutter={16} autoSelect={false}>
          {({ isOpen }) => (
            <>
              <MenuButton
                as={UserMenuButton}
                userName={userStore?.currentUser}
                bgColor={bgColor}
                isActive={isOpen}
                isLeftNavMinified={isLeftNavMinified}
                data-cy="user-menu-item"
              />
              {
                <MenuList>
                  <CurrentUserDetails
                    userName={userStore?.currentUser}
                    userEmail={userStore?.currentEmail}
                    bgColor={bgColor}
                  />
                  <Divider />
                  <UserMenuList items={menuItems} />
                </MenuList>
              }
            </>
          )}
        </Menu>
      )}
    </>
  );
});

const UserMenuList = ({ items }: { items: MenuItemLinkProps[] }) => (
  <>
    {items.map((item, i) => (
      <Fragment key={i}>
        {i === items.length - 1 && <Divider />}
        <MenuItem data-cy={`navigation-menu-${item.id}`} {...item} />
      </Fragment>
    ))}
  </>
);
