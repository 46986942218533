import { Input, Flex, Text, InputProps } from "@chakra-ui/react";
import useDigitInput from "react-digit-input";
import { typography } from "theme/foundations/typography";

type DigitInputGroupProps = Omit<InputProps, "size" | "onChange"> & {
  value: string;
  length: number;
  size?: "sm" | "lg";
  hasError?: boolean;
  isDisabled?: boolean;
  onChange?: (value: string) => void;
};

const DigitInputGroup = ({
  value,
  hasError,
  length,
  isDisabled = false,
  size = "sm",
  onChange = () => {},
}: DigitInputGroupProps) => {
  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length,
    value,
    onChange,
  });
  const sizeProps: Record<
    typeof size,
    { width: string; height: string; [x: string]: any }
  > = {
    sm: {
      width: "38px",
      height: "44px",
      ...typography.textStyles["h4"], //can't use 'textStyle' directly, alas
    },
    lg: {
      width: "44px",
      height: "52px",
      ...typography.textStyles["h3"],
    },
  };
  return (
    <Flex direction="column" gap="16px" alignItems="center">
      <Flex gap="8px" data-cy="digit-input-group">
        {[...Array(length)].map((_, i) => (
          <Input
            key={i}
            inputMode="decimal"
            isInvalid={hasError}
            autoFocus={i === 0}
            isDisabled={isDisabled}
            {...digits[i]}
            sx={{ ...sizeProps[size], color: "grayscale.800" }}
            textAlign="center"
            bg="grayscale.0"
          />
        ))}
      </Flex>
      {hasError && (
        <Text
          as="span"
          color="error.900"
          textStyle="caption"
          data-cy="digit-input-error"
        >
          The code you entered is incorrect
        </Text>
      )}
    </Flex>
  );
};

export default DigitInputGroup;
