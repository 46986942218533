import { useEffect } from "react";
import { observer } from "mobx-react";
import { useEffectOnce, useEvent } from "react-use";
import { useStores } from "stores";
import { CONNECTION } from "types";
import { Userpilot } from "userpilot";
import { useLocation } from "react-router-dom";
import { Grid, Flex } from "@chakra-ui/react";
import CircularProgress from "components/progress/CircularProgress";
import { APP_STATE } from "stores/commonStore";
export interface DashboardLayoutProps {
  children: React.ReactElement[] | React.ReactElement;
}

export const DashboardLayout = observer(
  ({ children }: DashboardLayoutProps) => {
    const { commonStore, serviceStore } = useStores();
    const { appState, isPageIdle, isModalOpen, connectionStatus } = commonStore;
    const { ONLINE, OFFLINE } = CONNECTION;
    const handlePageIdle = (status: boolean) => () => {
      commonStore.setIsPageIdle(
        Number(process.env.REACT_APP_IDLE_CHECK) === 0 ? false : status,
      );
    };
    const handleConnectionStatus =
      (status: (typeof CONNECTION)[keyof typeof CONNECTION]) => () =>
        commonStore.setConnectionStatus(status);

    const handleTabVisibility = () => {
      commonStore.setVisibilityState(document.visibilityState);
    };

    useEvent(ONLINE, handleConnectionStatus(ONLINE));
    useEvent(OFFLINE, handleConnectionStatus(OFFLINE));

    useEffectOnce(() => {
      window.addEventListener("focus", handlePageIdle(true));
      window.addEventListener("blur", handlePageIdle(false));
      document.addEventListener("visibilitychange", handleTabVisibility);

      return () => {
        document.removeEventListener("visibilitychange", handleTabVisibility);
      };
    });

    let location = useLocation();
    useEffect(() => {
      if (location) {
        Userpilot.reload();
      }
    }, [location]);

    useEffect(() => {
      serviceStore.setPollStatus(30000);
    }, [isPageIdle, isModalOpen, connectionStatus, serviceStore]);

    return (
      <Grid
        templateAreas={{
          base: `"notificationBanner" "main"`,
          md: `
          "notificationBanner notificationBanner"
          "leftNav main"
        `,
        }}
        templateRows={{
          base: "auto minmax(0, 1fr)",
          md: "auto minmax(0, 1fr)",
        }}
        templateColumns={{
          base: "1fr",
          md: "auto minmax(0, 1fr)",
          lg: "auto minmax(0, 1fr)",
        }}
        height="100vh"
        width="100%"
        data-cy="dashboard-layout"
        overflow="hidden"
      >
        {appState === APP_STATE.READY ? (
          children
        ) : (
          <Flex w="100vw" h="100vh" alignItems="center" justifyContent="center">
            <CircularProgress size="lg" />
          </Flex>
        )}
      </Grid>
    );
  },
);
