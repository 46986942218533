export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Success: any;
  Time: any;
  TimescaleVersion: any;
};

export type AddAuthenticator = {
  /** Code generated from the secret */
  code: Scalars["String"];
  /** Base32 encoded, 160bits recommended https://www.rfc-editor.org/rfc/rfc4226#section-4 */
  secret: Scalars["String"];
};

export type AddCompressionPolicyInput = {
  contAggName?: InputMaybe<Scalars["ID"]>;
  olderThan: Scalars["String"];
  projectId: Scalars["ID"];
  regionCode?: InputMaybe<Scalars["String"]>;
  serviceId: Scalars["ID"];
  tableName?: InputMaybe<Scalars["ID"]>;
  tableSchema: Scalars["ID"];
};

export type AddMfaMethodInput = {
  authenticator?: InputMaybe<AddAuthenticator>;
};

export type AddMfaMethodResponse = {
  __typename?: "AddMfaMethodResponse";
  method: MfaMethod;
  /** Recovery codes nullable because if we support multiple factors it will only be returned for first */
  recoveryCodes?: Maybe<Array<Scalars["String"]>>;
};

export type AddonIoBoostEntitlement = {
  __typename?: "AddonIOBoostEntitlement";
  enabled: Scalars["Boolean"];
};

/** All details on a `BillingAccount`'s address information. */
export type AddressDetails = {
  __typename?: "AddressDetails";
  /** The company address for the associated billing account. */
  companyAddress?: Maybe<AddressInfo>;
};

/** Address info. */
export type AddressInfo = {
  __typename?: "AddressInfo";
  /** City, district, suburb, town, or village. */
  city: Scalars["String"];
  /** Company name. */
  companyName?: Maybe<Scalars["String"]>;
  /** Two-letter country code (ISO 3166-1 alpha-2). */
  country: Scalars["String"];
  /** Address line 1 (e.g., street, PO Box, or company name). */
  line1: Scalars["String"];
  /** Address line 2 (e.g., apartment, suite, unit, or building). */
  line2: Scalars["String"];
  /** ZIP or postal code. */
  postalCode: Scalars["String"];
  /** State, county, province, or region. */
  state: Scalars["String"];
};

/** Address info input. */
export type AddressInfoInput = {
  /** City, district, suburb, town, or village. */
  city: Scalars["String"];
  /** Company name. */
  companyName?: InputMaybe<Scalars["String"]>;
  /** Two-letter country code (ISO 3166-1 alpha-2). */
  country: Scalars["String"];
  /** Address line 1 (e.g., street, PO Box, or company name). */
  line1: Scalars["String"];
  /** Address line 2 (e.g., apartment, suite, unit, or building). */
  line2: Scalars["String"];
  /** ZIP or postal code. */
  postalCode: Scalars["String"];
  /** State, county, province, or region. */
  state: Scalars["String"];
};

export type AttachServiceToGenericExporterInput = {
  /** The internal ID of the generic exporter to attach with. */
  exporterId: Scalars["String"];
  /** The project ID of the service. */
  projectId: Scalars["ID"];
  /** The ID of the service. */
  serviceId: Scalars["ID"];
};

export type AttachServiceToMetricExporterInput = {
  /** The internal ID of the metric exporter to attach with. */
  exporterId?: InputMaybe<Scalars["ID"]>;
  /** The UUID of the metric exporter to attach with */
  exporterUuid?: InputMaybe<Scalars["ID"]>;
  /** The project ID of the service. */
  projectId: Scalars["ID"];
  /** The ID of the service. */
  serviceId: Scalars["ID"];
};

export type AttachServiceToVpcInput = {
  /** The project ID of the service. */
  projectId: Scalars["ID"];
  /** The ID of the service. */
  serviceId: Scalars["ID"];
  /** The internal forge ID of the shell VPC that we are attaching the service to. */
  vpcId: Scalars["ID"];
};

export type AuthenticatorMethod = {
  __typename?: "AuthenticatorMethod";
  id: Scalars["ID"];
};

export type AutoscaleSettings = {
  __typename?: "AutoscaleSettings";
  /** Is autoscaling enabled. */
  enabled: Scalars["Boolean"];
  /** Upper limit measured in GB. */
  upper_limit_gb: Scalars["Int"];
};

/** A billing account record. */
export type BillingAccount = {
  __typename?: "BillingAccount";
  /**
   * Address info associated with the billing account.
   *
   *     This field will be `null` if no address information has ever been configured for this
   *     `BillingAccount`.
   */
  addressDetails: AddressDetails;
  /** All permissions which have been granted on this billing account. */
  allPermissions: Array<BillingPermission>;
  /** All cost information for this billing account. */
  costInfo: BillingAccountCostInfo;
  /** The date this record was created. */
  created: Scalars["Time"];
  /** The ID of the billing account. */
  id: Scalars["ID"];
  /** Does this billing account use corporate invoicing instead of cards. */
  isUsingInvoicing: Scalars["Boolean"];
  /** The display name of this billing account. */
  name: Scalars["String"];
  /** All payment methods currently configured on this billing account. */
  paymentMethods: Array<PaymentMethod>;
  /** The caller's permissions on this billing account. */
  permission: BillingPermission;
  /** A timestamp indicating when this billing account's trial period will end. */
  trialEndDate: Scalars["Time"];
};

/** All cost information for a specific billing account. */
export type BillingAccountCostInfo = {
  __typename?: "BillingAccountCostInfo";
  /** The end of the current billing period (exclusive), as a unix timestamp. */
  billingPeriodEnd: Scalars["Time"];
  /** The beginning of the current billing period (inclusive), as a unix timestamp. */
  billingPeriodStart: Scalars["Time"];
  /** The cost-to-date for the current billing period, minus any trials or credits. */
  costToDate: Scalars["Float"];
  /** The cost-to-date for the current billing period. */
  costToDateGross: Scalars["Float"];
  /** The estimated total cost for the current billing period, minus any trials or credits. */
  estimatedTotalCost: Scalars["Float"];
  /** The estimated total cost for the current billing period. */
  estimatedTotalCostGross: Scalars["Float"];
  /** The breakdown on the per-product usage */
  productCostInfos: Array<ProductCostInfo>;
  /** The breakdown on the per-service usage */
  serviceCostInfos: Array<ServiceCostInfo>;
};

/** A billing email addr (email tied to a billing account that receives billing emails). */
export type BillingEmailAddress = {
  __typename?: "BillingEmailAddress";
  /** The date at which this email has been activated */
  activated?: Maybe<Scalars["Time"]>;
  /** The billing account to which this record applies. */
  billingAccountId: Scalars["ID"];
  /** The date of creation of this email. */
  created: Scalars["Time"];
  /** The email. */
  email: Scalars["String"];
  /** The ID of the `BillingEmailAddress`. */
  id: Scalars["ID"];
};

/** A billing permission record. */
export type BillingPermission = {
  __typename?: "BillingPermission";
  /** The billing account this permission is granted on. */
  billingAccountId: Scalars["ID"];
  /** The datetime this object was created, as a unix timestamp. */
  created: Scalars["Time"];
  /** The role associated with this permissions grant. */
  role: BillingRole;
  /** The user this permission is granted to. */
  userId: Scalars["ID"];
};

/** A role which a user may be granted on a Billing Account. */
export enum BillingRole {
  /** The omnipotent admin role for Billing Accounts. All actions allowed. */
  Admin = "ADMIN",
  /** The read-only viewer role for Billing Accounts. */
  Viewer = "VIEWER",
}

export type BindIpAllowListInput = {
  listId: Scalars["Int"];
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
};

export type CancelPendingPaymentDeletionInput = {
  billingAccountId: Scalars["ID"];
  paymentMethodId: Scalars["ID"];
};

/** Card expiration input info. */
export type CardExpInput = {
  /** Two-digit number representing the card’s expiration month. */
  expMonth: Scalars["Int"];
  /** Four-digit number representing the card’s expiration year. */
  expYear: Scalars["Int"];
};

/** Information on a `PaymentMethod`'s card. */
export type CardInfo = {
  __typename?: "CardInfo";
  /** The brand of the card. */
  brand: Scalars["String"];
  /** The country where this card was issued. */
  country: Scalars["String"];
  /** The month this card will expire. */
  expMonth: Scalars["Int"];
  /** The year this card will expire. */
  expYear: Scalars["Int"];
  /** The last 4 digits of this card. */
  last4: Scalars["String"];
};

export type ChangePasswordInput = {
  newPassword: Scalars["String"];
  newPasswordConfirm: Scalars["String"];
  oldPassword: Scalars["String"];
  /** Required if there are MFA methods active for the user  */
  trustChallenge?: InputMaybe<Scalars["String"]>;
  trustToken?: InputMaybe<Scalars["String"]>;
};

export type Chunk = {
  __typename?: "Chunk";
  compressedSize: Scalars["Int"];
  dataNodes?: Maybe<Array<Scalars["String"]>>;
  endTime: Scalars["String"];
  isCompressed: Scalars["Boolean"];
  name: Scalars["ID"];
  precompressedSize: Scalars["Int"];
  rangeEnd?: Maybe<Scalars["String"]>;
  rangeStart?: Maybe<Scalars["String"]>;
  size: Scalars["Int"];
  startTime: Scalars["String"];
};

export type ClientCredentials = {
  __typename?: "ClientCredentials";
  /** ID of the Client Credentials */
  accessKey: Scalars["String"];
  /** Secret - it filled only on creation */
  secretKey?: Maybe<Scalars["String"]>;
};

export enum CloudProvider {
  Aws = "AWS",
}

export type CloudWatchConfig = {
  __typename?: "CloudWatchConfig";
  awsAccessKey: Scalars["String"];
  awsRegion: Scalars["String"];
  awsRoleArn?: Maybe<Scalars["String"]>;
  awsSecretKey: Scalars["String"];
  logGroupName: Scalars["String"];
  logStreamName: Scalars["String"];
};

export type CloudWatchConfigInput = {
  awsAccessKey?: InputMaybe<Scalars["String"]>;
  awsRegion: Scalars["String"];
  awsRoleArn?: InputMaybe<Scalars["String"]>;
  awsSecretKey?: InputMaybe<Scalars["String"]>;
  logGroupName: Scalars["String"];
  logStreamName: Scalars["String"];
};

export type CloudWatchMetricConfig = {
  __typename?: "CloudWatchMetricConfig";
  awsAccessKey: Scalars["String"];
  awsRegion: Scalars["String"];
  awsRoleArn?: Maybe<Scalars["String"]>;
  awsSecretKey: Scalars["String"];
  logGroupName: Scalars["String"];
  logStreamName: Scalars["String"];
  namespace: Scalars["String"];
};

export type CloudWatchMetricConfigInput = {
  awsAccessKey?: InputMaybe<Scalars["String"]>;
  awsRegion: Scalars["String"];
  awsRoleArn?: InputMaybe<Scalars["String"]>;
  awsSecretKey?: InputMaybe<Scalars["String"]>;
  logGroupName: Scalars["String"];
  logStreamName: Scalars["String"];
  namespace: Scalars["String"];
};

export type Column = {
  __typename?: "Column";
  constraints: Array<Scalars["String"]>;
  default: Scalars["String"];
  name: Scalars["ID"];
  nullable: Scalars["Boolean"];
  type: Scalars["String"];
};

export type CompressionConfig = {
  enabled: Scalars["Boolean"];
  orderBy?: InputMaybe<Scalars["String"]>;
  segmentBy?: InputMaybe<Scalars["String"]>;
};

/** CompressionRecommendationsForOrderBy provides OrderBy Recommendations for a HyperTable. */
export type CompressionRecommendationsForOrderBy = {
  __typename?: "CompressionRecommendationsForOrderBy";
  availableOrderByColumns: Array<Scalars["String"]>;
  recommendedOrderByColumns: Array<OrderByColumn>;
};

/** Get the recommended order by for a given list of segment by columns */
export type CompressionRecommendationsForOrderByInput = {
  contAggName?: InputMaybe<Scalars["ID"]>;
  projectId: Scalars["ID"];
  regionCode?: InputMaybe<Scalars["String"]>;
  schema: Scalars["ID"];
  segmentByColumns: Array<Scalars["String"]>;
  serviceId: Scalars["ID"];
  tableName?: InputMaybe<Scalars["ID"]>;
};

/** CompressionRecommendations provides Segment By Recommendations for a HyperTable. */
export type CompressionRecommendationsForSegmentBy = {
  __typename?: "CompressionRecommendationsForSegmentBy";
  availableSegmentByColumns: Array<Scalars["String"]>;
  minimumCompressionPolicyOlderThan?: Maybe<Scalars["String"]>;
  recommendedSegmentByColumns: Array<Scalars["String"]>;
};

/** Get the recommended segment by for a given hypertable or cagg */
export type CompressionRecommendationsForSegmentByInput = {
  contAggName?: InputMaybe<Scalars["ID"]>;
  projectId: Scalars["ID"];
  regionCode?: InputMaybe<Scalars["String"]>;
  schema: Scalars["ID"];
  serviceId: Scalars["ID"];
  tableName?: InputMaybe<Scalars["ID"]>;
};

export type CompressionStats = {
  __typename?: "CompressionStats";
  /** Size of compressed data. */
  compressed: Scalars["Int"];
  /** Size of data on disk, equal to uncompressed + compressed. */
  diskSize: Scalars["Int"];
  /** Size of data if compression was not applied, equal to uncompressed + precompressed. */
  originalSize: Scalars["Int"];
  /** Size of compressed data before it was compressed. */
  precompressed: Scalars["Int"];
  /** @deprecated ratio is deprecated, prefer savings instead. */
  ratio: Scalars["Float"];
  /** Amount of space saved by using compression, equal to 1.0 - (diskSize / originalSize). */
  savings: Scalars["Float"];
  /** Size of data not compressed. */
  uncompressed: Scalars["Int"];
};

export enum ComputeState {
  Applied = "APPLIED",
  Queued = "QUEUED",
  Unknown = "UNKNOWN",
}

export type ConfigureIpAllowListInput = {
  /** Pass nil to remove a list with a given ID. */
  cidrBlocks?: InputMaybe<Array<Scalars["String"]>>;
  description: Scalars["String"];
  /** Specifying a list ID will re-configure an existing list, while omitting it will create a new one. */
  listId?: InputMaybe<Scalars["Int"]>;
  projectId: Scalars["ID"];
};

export type ConfigureIpAllowListOutput = {
  __typename?: "ConfigureIPAllowListOutput";
  listId: Scalars["Int"];
};

export type ContAgg = {
  __typename?: "ContAgg";
  definition: Scalars["String"];
  materializedOnly: Scalars["Boolean"];
  name: Scalars["ID"];
  schema: Scalars["ID"];
};

export type ContAggId = {
  __typename?: "ContAggID";
  name: Scalars["ID"];
  schema: Scalars["ID"];
};

export type ContAggInfo = {
  __typename?: "ContAggInfo";
  chunks: Array<Chunk>;
  definition: Scalars["String"];
  hyperMetadata: HyperMetadata;
  indexes: Array<Index>;
  metadata: ContAggMetadata;
  policies: Array<Policy>;
  refreshPolicies: Array<ContAggRefreshPolicy>;
  schema: Array<Column>;
};

export type ContAggListResponse = {
  __typename?: "ContAggListResponse";
  contAggs: Array<ContAggId>;
  outdatedContAggs: Array<ContAggId>;
};

export type ContAggMetadata = {
  __typename?: "ContAggMetadata";
  approxRowCount: Scalars["Int"];
  finalized: Scalars["Boolean"];
  id: ContAggId;
  lastRefresh: Scalars["String"];
  materializedOnly: Scalars["Boolean"];
  nextRefresh: Scalars["String"];
  originalHypertable: TableId;
  size: Scalars["Int"];
};

export type ContAggRefreshPolicy = {
  __typename?: "ContAggRefreshPolicy";
  endOffset: Scalars["String"];
  interval: Scalars["String"];
  startOffset: Scalars["String"];
};

export type CreateBillingEmailAddressInput = {
  billingAccountId: Scalars["ID"];
  email: Scalars["String"];
};

export type CreateGenericExporterInput = {
  config: GenericExporterConfigInput;
  dataType: GenericExporterDataType;
  name: Scalars["String"];
  projectId: Scalars["ID"];
  region: Scalars["String"];
  type: GenericExporterType;
};

export type CreateMetricExporterInput = {
  config: MetricExporterConfigInput;
  name: Scalars["String"];
  projectId: Scalars["ID"];
  regionCode: Scalars["String"];
};

export type CreatePatRecordInput = {
  name: Scalars["String"];
  projectId: Scalars["ID"];
};

export type CreateProjectInput = {
  name: Scalars["String"];
  trialDays?: InputMaybe<Scalars["Int"]>;
};

export type CreateProjectSecretInput = {
  name: Scalars["String"];
  projectId: Scalars["ID"];
  value: Scalars["String"];
};

export type CreateServiceInput = {
  /** Indicates the billing tags for this service */
  billingTags?: InputMaybe<Array<Scalars["String"]>>;
  /** demoID is an optional parameter that if provided preloads an instance with data associated with this demo */
  demoID?: InputMaybe<Scalars["Int"]>;
  /** enables the connection pooler in the service */
  enableConnectionPooler?: InputMaybe<Scalars["Boolean"]>;
  /** enableStorageAutoscaling is deprecated and no longer supported */
  enableStorageAutoscaling?: InputMaybe<Scalars["Boolean"]>;
  /** Indicates if this service is used in a prod or dev environment */
  environmentTag?: InputMaybe<ServiceEnvironment>;
  /** Optional list of extensions to be pre-created on a new service */
  extensions?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** configure this service to be a fork of another one (requires a corresponding value in the type field) */
  forkConfig?: InputMaybe<ForkConfig>;
  name: Scalars["String"];
  projectId: Scalars["ID"];
  /** The region that the service will be created in. Defaults to AWS us-east-1 if not specified. */
  regionCode?: InputMaybe<Scalars["String"]>;
  resourceConfig?: InputMaybe<ResourceConfig>;
  type: Type;
  /** Optional ID of a VPC to which the new instance should be attached. */
  vpcId?: InputMaybe<Scalars["ID"]>;
};

export type CreateServiceOutput = {
  __typename?: "CreateServiceOutput";
  initialPassword: Scalars["String"];
  service: Service;
};

export type CreateUserInput = {
  email: Scalars["String"];
  inviteHash?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  password: Scalars["String"];
  passwordConfirm: Scalars["String"];
  recaptchaToken: Scalars["String"];
};

export type CreateUserPersonaInput = {
  /** JSON string that models the UI State of the persona. */
  uiState: Scalars["String"];
  /** The ID of the user. */
  user_id: Scalars["ID"];
};

export type CreateVpcInput = {
  cidr: Scalars["String"];
  cloudProvider: CloudProvider;
  name: Scalars["String"];
  projectId: Scalars["ID"];
  regionCode: Scalars["String"];
};

export type CsvImportColumnInput = {
  name: Scalars["String"];
  type: Scalars["String"];
};

export type DbCompressionStats = {
  __typename?: "DBCompressionStats";
  numCompressedContAggs: Scalars["Int"];
  numCompressedHypertables: Scalars["Int"];
  stats: CompressionStats;
};

export type DataTieringSettings = {
  __typename?: "DataTieringSettings";
  enabled: Scalars["Boolean"];
};

export type DataTieringStats = {
  __typename?: "DataTieringStats";
  tieredKB: Scalars["Int"];
};

export type DataTieringStorageSummary = {
  __typename?: "DataTieringStorageSummary";
  estimatedEbsUsageCost: Scalars["Float"];
  estimatedEbsUsageInBytes: Scalars["Int"];
  serviceIds: Array<Scalars["String"]>;
  tieringUsageCost: Scalars["Float"];
  tieringUsageInBytes: Scalars["Int"];
};

export type DatadogMetricConfig = {
  __typename?: "DatadogMetricConfig";
  apiKey: Scalars["String"];
  site?: Maybe<Scalars["String"]>;
};

export type DatadogMetricConfigInput = {
  apiKey: Scalars["String"];
  site?: InputMaybe<Scalars["String"]>;
};

export type DeleteBillingEmailAddressInput = {
  billingAccountId: Scalars["ID"];
  billingEmailId: Scalars["ID"];
};

export type DeleteCompressionPolicyInput = {
  contAggName?: InputMaybe<Scalars["ID"]>;
  projectId: Scalars["ID"];
  regionCode?: InputMaybe<Scalars["String"]>;
  serviceId: Scalars["ID"];
  tableName?: InputMaybe<Scalars["ID"]>;
  tableSchema: Scalars["ID"];
};

export type DeleteMfaMethodInput = {
  code: Scalars["String"];
  id: Scalars["ID"];
};

export type DeletePatRecordInput = {
  accessKey: Scalars["String"];
};

export type DeletePeeringConnectionInput = {
  /** The ID of the peering connection. */
  id: Scalars["ID"];
  /** The project ID the VPC is associated with. */
  projectId: Scalars["ID"];
  /** The internal forge ID of the shell VPC that we are detaching the user's VPC from. */
  vpcId: Scalars["ID"];
};

export type DeleteProjectSecretInput = {
  name: Scalars["String"];
  projectId: Scalars["ID"];
};

export type DeleteServiceInput = {
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
};

export type DeleteVpcInput = {
  /** The project ID of the service. */
  projectId: Scalars["ID"];
  /** The internal forge ID of the shell VPC that we are attaching the service to. */
  vpcId: Scalars["ID"];
};

export enum DeployStatus {
  Configuring = "CONFIGURING",
  Deleted = "DELETED",
  Deleting = "DELETING",
  Optimizing = "OPTIMIZING",
  Paused = "PAUSED",
  Pausing = "PAUSING",
  Queued = "QUEUED",
  Ready = "READY",
  Resuming = "RESUMING",
  Unstable = "UNSTABLE",
  Upgrading = "UPGRADING",
}

export type DetachServiceFromGenericExporterInput = {
  /** The internal ID of the generic exporter to attach with. */
  exporterId: Scalars["String"];
  /** The project ID of the service. */
  projectId: Scalars["ID"];
  /** The ID of the service. */
  serviceId: Scalars["ID"];
};

export type DetachServiceFromMetricExporterInput = {
  /** The internal ID of the metric exporter to detach. */
  exporterId?: InputMaybe<Scalars["ID"]>;
  /** The UUID of the metric exporter to detach. */
  exporterUuid?: InputMaybe<Scalars["ID"]>;
  /** The project ID of the service. */
  projectId: Scalars["ID"];
  /** The ID of the service. */
  serviceId: Scalars["ID"];
};

export type DetachServiceFromVpcInput = {
  /** The project ID of the service. */
  projectId: Scalars["ID"];
  /** The ID of the service. */
  serviceId: Scalars["ID"];
  /** The internal forge ID of the shell VPC that we are detaching the service from. */
  vpcId: Scalars["ID"];
};

export type Dimension = SpaceDimension | TimeDimension;

export type Endpoint = {
  __typename?: "Endpoint";
  /**
   * The hostname to use for VPC instance connections.
   * @deprecated service.Endpoints contains this field
   */
  host: Scalars["String"];
  /**
   * The port to use for VPC instance connections.
   * @deprecated service.Endpoints contains this field
   */
  port: Scalars["Int"];
  /** The project ID the Endpoint is associated with. */
  projectId: Scalars["ID"];
  /** The service ID the Endpoint is associated with. */
  serviceId: Scalars["ID"];
  /** The id of the VPC that the Endpoint is associated with. */
  vpcId: Scalars["ID"];
};

export type EndpointAddress = {
  __typename?: "EndpointAddress";
  /** The hostname to use for connections. */
  host: Scalars["String"];
  /** The port to use for connections. */
  port: Scalars["Int"];
};

export type EntitlementDowngradeCheckInput = {
  downgradeTo: VirtualPlanType;
  projectID: Scalars["ID"];
};

export type EntitlementDowngradeResponse = {
  __typename?: "EntitlementDowngradeResponse";
  dependencies?: Maybe<Array<EntitlementKind>>;
};

export enum EntitlementKind {
  AddonIoBoost = "ADDON_IO_BOOST",
  Exporter = "EXPORTER",
  HaReplica = "HA_REPLICA",
  IpAllowList = "IP_ALLOW_LIST",
  ReadReplica = "READ_REPLICA",
  ServiceMaxCpu = "SERVICE_MAX_CPU",
  ServiceMaxInstances = "SERVICE_MAX_INSTANCES",
  ServiceMaxMemory = "SERVICE_MAX_MEMORY",
  TieredStorage = "TIERED_STORAGE",
  VpcMaxInstances = "VPC_MAX_INSTANCES",
}

export type EntitlementSpec = {
  __typename?: "EntitlementSpec";
  addon_io_boost: AddonIoBoostEntitlement;
  exporter: ExporterEntitlement;
  ha_replica: HaReplicaEntitlement;
  insights: InsightsEntitlement;
  invoice_billing: InvoiceBillingEntitlement;
  ip_allow_list: IpAllowListEntitlement;
  pitr: PitrEntitlement;
  plan_type: VirtualPlanType;
  production_support: ProductionSupportEntitlement;
  read_replica: ReadReplicaEntitlement;
  saml: SamlEntitlement;
  services: ServiceEntitlement;
  tiered_storage: TieredStorageEntitlement;
  vpc: VpcAttachmentEntitlement;
};

export enum ExperimentGroup {
  A = "A",
  B = "B",
  Unknown = "UNKNOWN",
}

export type Explorer = {
  __typename?: "Explorer";
  job: Job;
  jobs: JobItems;
  regionCode: Scalars["String"];
};

export type ExplorerJobArgs = {
  jobId: Scalars["ID"];
};

export type ExporterEntitlement = {
  __typename?: "ExporterEntitlement";
  enabled: Scalars["Boolean"];
};

/** ExtendedObjectMetadata includes an object's ID, the object type's metadata, and, if applicable, hyper object metadata. */
export type ExtendedObjectMetadata = {
  __typename?: "ExtendedObjectMetadata";
  hyperMetadata?: Maybe<HyperMetadata>;
  metadata: ObjectMetadata;
};

export type FileImport = {
  __typename?: "FileImport";
  failure_reason: Scalars["String"];
  id: Scalars["String"];
  source?: Maybe<FileImportSourceOutput>;
  state: ImportState;
  url?: Maybe<Scalars["String"]>;
};

export type FileImportInternalSourceOutput = {
  __typename?: "FileImportInternalSourceOutput";
  key: Scalars["String"];
};

export type FileImportS3Source = {
  bucket: Scalars["String"];
  id?: InputMaybe<Scalars["String"]>;
  key: Scalars["String"];
  role_arn?: InputMaybe<Scalars["String"]>;
};

export type FileImportS3SourceOutput = {
  __typename?: "FileImportS3SourceOutput";
  bucket: Scalars["String"];
  id?: Maybe<Scalars["String"]>;
  key: Scalars["String"];
  roleARN?: Maybe<Scalars["String"]>;
};

export type FileImportSource = {
  s3?: InputMaybe<FileImportS3Source>;
  type: FileImportSourceType;
};

export type FileImportSourceOutput =
  | FileImportInternalSourceOutput
  | FileImportS3SourceOutput;

export enum FileImportSourceType {
  S3 = "S3",
}

export type ForeignKey = {
  __typename?: "ForeignKey";
  keyCols: Scalars["String"];
  name: Scalars["ID"];
  refCols: Scalars["String"];
  refName: Scalars["String"];
  refSchema: Scalars["String"];
};

export type ForkConfig = {
  /** Is this fork a standby service? */
  isStandby?: InputMaybe<Scalars["Boolean"]>;
  /** Project ID of the origin */
  projectID: Scalars["ID"];
  /** Service ID of the origin */
  serviceID: Scalars["ID"];
  /** Produce a fork of the parent at a given timestamp (PITR) */
  targetTime?: InputMaybe<Scalars["Time"]>;
};

export type ForkSpec = {
  __typename?: "ForkSpec";
  isStandby?: Maybe<Scalars["Boolean"]>;
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
  targetTime?: Maybe<Scalars["Time"]>;
};

/** A generic exporter */
export type GenericExporter = {
  __typename?: "GenericExporter";
  config: GenericExporterConfig;
  created: Scalars["Time"];
  dataType: GenericExporterDataType;
  id: Scalars["ID"];
  name: Scalars["String"];
  projectId: Scalars["ID"];
  regionCode: Scalars["String"];
  type: GenericExporterType;
};

export type GenericExporterConfig = CloudWatchConfig;

/** The configuration for a generic exporter. Exactly one field must be set. */
export type GenericExporterConfigInput = {
  configCloudWatch?: InputMaybe<CloudWatchConfigInput>;
};

export enum GenericExporterDataType {
  Log = "LOG",
  Unspecified = "UNSPECIFIED",
}

export type GenericExporterIdInput = {
  exporterId: Scalars["String"];
  projectId: Scalars["ID"];
};

export enum GenericExporterType {
  Cloudwatch = "CLOUDWATCH",
  Unspecified = "UNSPECIFIED",
}

/** Get the full info of a table in an instance */
export type GetContAggInfoInput = {
  contAggName: Scalars["ID"];
  contAggSchema: Scalars["ID"];
  projectId: Scalars["ID"];
  regionCode?: InputMaybe<Scalars["String"]>;
  serviceId: Scalars["ID"];
};

/** Get the continuous aggs in a user's instance */
export type GetContAggListInput = {
  projectId: Scalars["ID"];
  regionCode?: InputMaybe<Scalars["String"]>;
  serviceId: Scalars["ID"];
};

/** Get an instance's TimescaleDB overview */
export type GetDatabaseOverviewInput = {
  projectId: Scalars["ID"];
  regionCode?: InputMaybe<Scalars["String"]>;
  serviceId: Scalars["ID"];
};

export type GetDatabaseOverviewOutput = {
  __typename?: "GetDatabaseOverviewOutput";
  compressionV2?: Maybe<DbCompressionStats>;
  objectCounts: ObjectCounts;
  policyCounts?: Maybe<Array<PolicyCount>>;
};

export type GetFileImportInput = {
  id: Scalars["String"];
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
};

export type GetInitialExtensionsInput = {
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
};

export type GetInvocationDetailsInput = {
  /** Unique identifier for the invocation on that specific project and service. */
  invocationId: Scalars["ID"];
  /** Project ID of the invocation. */
  projectId: Scalars["ID"];
  /** The region code of the service where invocations happened */
  regionCode?: InputMaybe<Scalars["String"]>;
  /** Service ID of the invocation. */
  serviceId: Scalars["ID"];
  /** Unique identifier for the vectorizer on that specific project and service. */
  vectorizerId: Scalars["ID"];
};

export type GetJwtForClientCredentialsInput = {
  accessKey: Scalars["String"];
  secretKey: Scalars["String"];
};

/** Get the running and upcoming jobs in a user's instance */
export type GetJobListInput = {
  projectID: Scalars["ID"];
  regionCode?: InputMaybe<Scalars["String"]>;
  serviceId: Scalars["ID"];
};

/** Get a list of the largest objects in an instance. */
export type GetLargestObjectsInput = {
  projectId: Scalars["ID"];
  regionCode?: InputMaybe<Scalars["String"]>;
  serviceId: Scalars["ID"];
};

export type GetLastServiceResourceMetricsInput = {
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
  serviceOrdinal?: InputMaybe<Scalars["Int"]>;
};

/** Get a list of objects' metadata in the database */
export type GetObjectsMetadataInput = {
  excludeContAggs?: InputMaybe<Scalars["Boolean"]>;
  excludeHypertables?: InputMaybe<Scalars["Boolean"]>;
  excludePGTables?: InputMaybe<Scalars["Boolean"]>;
  projectId: Scalars["ID"];
  regionCode?: InputMaybe<Scalars["String"]>;
  serviceId: Scalars["ID"];
};

export type GetPopSqlIntegrationStatusInput = {
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
};

/** Get an instance's PG and timescaledb installed and available versions */
export type GetPostgresAndTimescaleDbVersionInput = {
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
};

export type GetPostgresAvailableExtensionsInput = {
  projectId: Scalars["ID"];
  regionCode?: InputMaybe<Scalars["String"]>;
  serviceId: Scalars["ID"];
};

/** Get the last known status for a major PostgreSQL Upgrade */
export type GetPostgresMajorVersionUpgradeStatusInput = {
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
};

/** Get postgres parameters for an instance. */
export type GetPostgresParametersInput = {
  /** ProjectID that the service belongs to. */
  projectId: Scalars["ID"];
  /** Service ID of the service in question. */
  serviceId: Scalars["ID"];
};

export type GetProjectSecretsInput = {
  projectId: Scalars["ID"];
};

export type GetQueryDetailsInput = {
  projectId: Scalars["ID"];
  queryHash: Scalars["String"];
  serviceId: Scalars["ID"];
  serviceOrdinal: Scalars["Int"];
};

export type GetQueryDetailsResponse = {
  __typename?: "GetQueryDetailsResponse";
  queryDetails: QueryDetails;
};

export type GetQueryMetricGraphsInput = {
  projectId: Scalars["ID"];
  queryHash: Scalars["String"];
  queryRange: QueryRange;
  serviceId: Scalars["ID"];
  serviceOrdinal: Scalars["Int"];
};

/** Defines all available options for requesting the list of query performance stats */
export type GetQueryStatsInput = {
  constraints?: InputMaybe<Array<QueryStatConstraint>>;
  limit?: Scalars["Int"];
  projectId: Scalars["ID"];
  queryRange?: InputMaybe<QueryRange>;
  serviceId: Scalars["ID"];
  serviceOrdinal?: InputMaybe<Scalars["Int"]>;
  sortBy?: QueryStatSortFieldOption;
  sortOrder?: QueryStatSortOrderOption;
};

export type GetRecommendationsInput = {
  projectId: Scalars["ID"];
  recommendationType?: InputMaybe<RecommendationType>;
  serviceId?: InputMaybe<Scalars["ID"]>;
};

export type GetServiceDeploymentStatusInput = {
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
};

export type GetServiceInput = {
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
};

export type GetServiceLogsInput = {
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
  serviceOrdinal?: InputMaybe<Scalars["Int"]>;
};

export type GetServiceResourceMetricsInput = {
  bucketSeconds: Scalars["Int"];
  end: Scalars["Time"];
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
  serviceOrdinal?: InputMaybe<Scalars["Int"]>;
  start: Scalars["Time"];
};

/** Get the full info of a table in an instance */
export type GetTableInfoInput = {
  projectId: Scalars["ID"];
  regionCode?: InputMaybe<Scalars["String"]>;
  serviceId: Scalars["ID"];
  tableName: Scalars["ID"];
  tableSchema: Scalars["ID"];
};

/** Get the tables in an instance */
export type GetTableListInput = {
  projectId: Scalars["ID"];
  regionCode?: InputMaybe<Scalars["String"]>;
  serviceId: Scalars["ID"];
};

/** Get an instance's TimescaleDB version */
export type GetTimescaleVersionInput = {
  projectId: Scalars["ID"];
  regionCode?: InputMaybe<Scalars["String"]>;
  serviceId: Scalars["ID"];
};

export type GetVpcByNameInput = {
  /** The project ID of the vpc. */
  projectId: Scalars["ID"];
  /** The name of the shell VPC. */
  vpcName: Scalars["String"];
};

export type GetVirtualPlansResponse = {
  __typename?: "GetVirtualPlansResponse";
  plans: Array<VirtualPlan>;
};

/**
 * GraphDataPoint represents a data point in time for a graph.
 * It also holds a QueryStatMetrics with the aggregated metrics for that point in time.
 */
export type GraphDataPoint = {
  __typename?: "GraphDataPoint";
  bucket: Scalars["Time"];
  metrics: QueryStatMetrics;
  queryHash: Scalars["String"];
};

export type HaReplicaEntitlement = {
  __typename?: "HAReplicaEntitlement";
  enabled: Scalars["Boolean"];
  max_instances_per_service: Scalars["Int"];
  replication_modes: Array<HaReplicaReplicationMode>;
};

export enum HaReplicaReplicationMode {
  Async = "ASYNC",
  None = "NONE",
  Sync = "SYNC",
}

/** HyperMetadata is additional metadata associated with hyper objects like hypertables and continuous aggregates. */
export type HyperMetadata = {
  __typename?: "HyperMetadata";
  compression: Scalars["Boolean"];
  compressionStats?: Maybe<CompressionStats>;
  dimensions?: Maybe<Array<Dimension>>;
  integerInterval: Scalars["Int"];
  numChunks: Scalars["Int"];
  timeInterval: Scalars["String"];
};

export type HypertableChangeChunkTimeIntervalMetadata = {
  __typename?: "HypertableChangeChunkTimeIntervalMetadata";
  currentIntervalSeconds: Scalars["Int"];
  recommendedIntervalSeconds: Scalars["Int"];
  schemaName: Scalars["String"];
  tableName: Scalars["String"];
};

export type IpAllowList = {
  __typename?: "IPAllowList";
  associatedServices?: Maybe<Array<Scalars["String"]>>;
  cidrBlocks: Array<Scalars["String"]>;
  created: Scalars["Time"];
  description: Scalars["String"];
  listId: Scalars["Int"];
};

export type IpAllowListEntitlement = {
  __typename?: "IPAllowListEntitlement";
  enabled: Scalars["Boolean"];
  max_list_count: Scalars["Int"];
};

export enum ImportState {
  Failure = "FAILURE",
  InQueue = "IN_QUEUE",
  Running = "RUNNING",
  Success = "SUCCESS",
  WaitingForUpload = "WAITING_FOR_UPLOAD",
}

export type Index = {
  __typename?: "Index";
  definition: Scalars["String"];
  name: Scalars["ID"];
};

/** An Input version of the PeerVPC type. */
export type InputPeerVpc = {
  /** The account ID issues by the cloud provider to the user. */
  accountId: Scalars["ID"];
  /** The CIDR block of the user-provided VPC. */
  cidr?: InputMaybe<Scalars["String"]>;
  /** The ID of the user-provided VPC. */
  id: Scalars["ID"];
  /** The region that the user-provided VPC exists in. */
  regionCode: Scalars["String"];
};

export type InsightsEntitlement = {
  __typename?: "InsightsEntitlement";
  enabled: Scalars["Boolean"];
  max_hours: Scalars["Int"];
};

export type InviteProjectMemberInput = {
  email: Scalars["String"];
  projectId: Scalars["ID"];
};

export type InvocationDetails = {
  __typename?: "InvocationDetails";
  /** Configuration for the invocation execution. */
  configuration: Scalars["String"];
  /** Duration of the invocation in milliseconds. */
  durationMs?: Maybe<Scalars["Int"]>;
  /** Error during the execution of the invocation. */
  executionError?: Maybe<Scalars["String"]>;
  /** Indicates if the invocation was successful. */
  isSuccess: Scalars["Boolean"];
  /** Logs for the invocation execution. */
  logs?: Maybe<Scalars["String"]>;
  /** Timestamp when the invocation was run. */
  runAt?: Maybe<Scalars["Time"]>;
  /** Source of the embeddings. */
  source: Scalars["String"];
  /** Target of the embeddings. */
  target: Scalars["String"];
  /** Unique identifier for the vectorizer config on that specific project and service. */
  vectorizerId: Scalars["ID"];
  /** View of the embeddings. */
  view: Scalars["String"];
};

/** An invoice. */
export type Invoice = {
  __typename?: "Invoice";
  /** The billing account to which this record applies. */
  billingAccountId: Scalars["ID"];
  /** The date this record was generated in the system. */
  created: Scalars["Time"];
  /** It explicit identifies when the invoice comes from our own billing platform, or from Orb, which is the current 3rd party billing platform. */
  fromInternalBilling?: Maybe<Scalars["Boolean"]>;
  /** The ID of the `Invoice`. */
  id: Scalars["ID"];
  /** This invoice's current state. */
  paymentState?: Maybe<Scalars["String"]>;
  /** This invoice's pdf Url (on orb) */
  pdfUrl?: Maybe<Scalars["String"]>;
  /** An exclusive timestamp of the end of this records billing period. */
  periodEnd?: Maybe<Scalars["Time"]>;
  /** An inclusive timestamp of the beginning of this records billing period. */
  periodStart?: Maybe<Scalars["Time"]>;
  /** The total amount charged for the invoice, in USD. */
  totalCharged?: Maybe<Scalars["Float"]>;
  /** The date of the last time this record was updated. */
  updated?: Maybe<Scalars["Time"]>;
};

export type InvoiceBillingEntitlement = {
  __typename?: "InvoiceBillingEntitlement";
  enabled: Scalars["Boolean"];
};

/** List of invoices with additional general info about invoices */
export type Invoices = {
  __typename?: "Invoices";
  /** The list of invoices */
  invoices: Array<Invoice>;
  /** Oldest available year (querying period_start) */
  oldestYear?: Maybe<Scalars["Int"]>;
};

export type Invoicing = {
  __typename?: "Invoicing";
  billingAccountId: Scalars["Int"];
  netTermDays?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  paymentOrder?: Maybe<Scalars["String"]>;
  region?: Maybe<Scalars["String"]>;
};

export type Job = {
  __typename?: "Job";
  currentStart?: Maybe<Scalars["Time"]>;
  id: Scalars["ID"];
  lastNExecutions: Array<JobExecution>;
  /** @deprecated use 'type' instead */
  name: Scalars["String"];
  nextStart?: Maybe<Scalars["Time"]>;
  /** proc is the Postgres procedure that the job runs. */
  proc: ProcedureId;
  stats: JobStats;
  status: JobStatus;
  /** target is the continuous aggregate or hypertable that this job affects, used for built-in jobs */
  target?: Maybe<ObjectId>;
  type: Scalars["String"];
};

export type JobLastNExecutionsArgs = {
  n: Scalars["Int"];
};

export type JobStatsArgs = {
  timeWindowDays?: InputMaybe<Scalars["Int"]>;
};

export type JobExecution = {
  __typename?: "JobExecution";
  finishTime: Scalars["Time"];
  startTime: Scalars["Time"];
  succeeded?: Maybe<Scalars["Boolean"]>;
};

export type JobFailedNTimesMetadata = {
  __typename?: "JobFailedNTimesMetadata";
  applicationName: Scalars["String"];
  earliestFailure: Scalars["Time"];
  lastFailure: Scalars["Time"];
  numFailures: Scalars["Int"];
  procName: Scalars["String"];
  procSchema: Scalars["String"];
};

export type JobItem = {
  __typename?: "JobItem";
  item: Job;
};

export type JobItems = {
  __typename?: "JobItems";
  items: Array<JobItem>;
};

export type JobListResponse = {
  __typename?: "JobListResponse";
  runningJobs: Array<Job>;
  upcomingJobs: Array<Job>;
};

export type JobStats = {
  __typename?: "JobStats";
  avgDurationSeconds: Scalars["Int"];
  consecutiveFailures: Scalars["Int"];
  maxDurationSeconds: Scalars["Int"];
  medianDurationSeconds: Scalars["Int"];
};

export enum JobStatus {
  Paused = "PAUSED",
  Running = "RUNNING",
  Upcoming = "UPCOMING",
}

export type JoinProjectInput = {
  hash: Scalars["String"];
  inviteId: Scalars["ID"];
};

export type ListVectorizerInput = {
  /** Project ID of the vectorizer. */
  projectId: Scalars["ID"];
  /** The region code of the service where vectorizers are running */
  regionCode?: InputMaybe<Scalars["String"]>;
  /** Service ID of the vectorizer. */
  serviceId: Scalars["ID"];
};

export type LoginBasicAuth = {
  email: Scalars["String"];
  password: Scalars["String"];
};

export type LoginInput = {
  noJWT?: InputMaybe<Scalars["Boolean"]>;
};

export type LoginMfa = {
  code: Scalars["String"];
  twoFactorId: Scalars["String"];
};

export type LoginMfaInput = {
  /** Details for initial request with email/password */
  basic?: InputMaybe<LoginBasicAuth>;
  /** Code and id for the second request when Mfa is enabled */
  mfa?: InputMaybe<LoginMfa>;
  /** Whether a JWT should be returned in the response body in addition to the cookie */
  noJWT?: InputMaybe<Scalars["Boolean"]>;
};

export type LoginMfaRequired = {
  __typename?: "LoginMfaRequired";
  mfaMethods: Array<MfaMethod>;
  twoFactorId: Scalars["String"];
};

export type LoginMfaResponse = LoginMfaRequired | LoginSuccess;

export type LoginResponse = {
  __typename?: "LoginResponse";
  user: User;
  userToken: Scalars["String"];
};

export type LoginSuccess = {
  __typename?: "LoginSuccess";
  user: User;
  userToken?: Maybe<Scalars["String"]>;
};

export enum ManagedByIdpReason {
  IdpLogin = "IDP_LOGIN",
  MandatoryIdp = "MANDATORY_IDP",
}

/** A metric exporter */
export type MetricExporter = {
  __typename?: "MetricExporter";
  config: MetricExporterConfig;
  created: Scalars["Time"];
  exporterUuid: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
  projectId: Scalars["ID"];
  regionCode: Scalars["String"];
  type: MetricExporterType;
};

export type MetricExporterConfig =
  | CloudWatchMetricConfig
  | DatadogMetricConfig
  | PrometheusMetricConfig;

/** The configuration for a metric exporter. Exactly one field must be set. */
export type MetricExporterConfigInput = {
  configCloudWatch?: InputMaybe<CloudWatchMetricConfigInput>;
  configDatadog?: InputMaybe<DatadogMetricConfigInput>;
  configPrometheus?: InputMaybe<PrometheusMetricConfigInput>;
};

export type MetricExporterIdInput = {
  exporterId?: InputMaybe<Scalars["ID"]>;
  exporterUuid?: InputMaybe<Scalars["String"]>;
  projectId: Scalars["ID"];
};

export enum MetricExporterType {
  Cloudwatch = "CLOUDWATCH",
  Datadog = "DATADOG",
  Prometheus = "PROMETHEUS",
  Unknown = "UNKNOWN",
}

/** MetricUnit defines which metrics units are available */
export enum MetricUnit {
  Average = "AVERAGE",
  Byte = "BYTE",
  Count = "COUNT",
  Microsecond = "MICROSECOND",
  Millisecond = "MILLISECOND",
  Nanosecond = "NANOSECOND",
  Percentage = "PERCENTAGE",
  RateBytesPerMicrosecond = "RATE_BYTES_PER_MICROSECOND",
  Second = "SECOND",
}

export type MetricValue = {
  __typename?: "MetricValue";
  unit: MetricUnit;
  value: Scalars["String"];
};

export type MfaChallengeInput = {
  /** MFA code from authenticator */
  code: Scalars["String"];
  /** this should be sent during the password reset flow when there is no auth token available */
  resetToken?: InputMaybe<Scalars["String"]>;
  /** This string should be a random value which then need to be passed in when the returned trustToken is used */
  trustChallenge: Scalars["String"];
};

export type MfaChallengeResponse = {
  __typename?: "MfaChallengeResponse";
  trustToken: Scalars["String"];
};

export type MfaMethod = AuthenticatorMethod;

export type MinimumSpend = {
  __typename?: "MinimumSpend";
  /** The minimum spend for this project */
  minimumSpend: Scalars["Float"];
  /** The ID of the project. */
  projectId: Scalars["ID"];
};

/** The root mutation object. */
export type Mutation = {
  __typename?: "Mutation";
  addCompressionPolicy: Scalars["Success"];
  /** Add an Mfa method, only authenticator is supported currently */
  addMfaMethod: AddMfaMethodResponse;
  /** Attaches a service to a generic exporter. */
  attachServiceToGenericExporter: Scalars["Success"];
  /** Attaches a service to a metric exporter. */
  attachServiceToMetricExporter: Scalars["Success"];
  /** Attaches a service to a VPC. */
  attachServiceToVpc: Scalars["Success"];
  /** Create an association between an IP allow list and a service. */
  bindIPAllowList: Scalars["Success"];
  /** Cancel a pending payment deletion for the payment method on the target billing account. */
  cancelPendingPaymentDeletion: Scalars["Success"];
  /** Cancel Project invite */
  cancelProjectInvite: Scalars["Success"];
  /** Change a (logged in) user's password, given their current password. */
  changePassword: Scalars["String"];
  /** Charges current payment means with invoice. */
  chargeInvoice: Scalars["Success"];
  /** Create or replace an IP allow list at the project level. */
  configureIPAllowList: ConfigureIpAllowListOutput;
  /** Create a new billing email. */
  createBillingEmailAddress: BillingEmailAddress;
  /** Create a new generic exporter. */
  createGenericExporter: GenericExporter;
  /** Create a new metric exporter. */
  createMetricExporter: MetricExporter;
  createPATRecord: PatRecord;
  /** Add a payment method for the target billing account. */
  createPaymentMethod: Scalars["String"];
  /** Create a new project. */
  createProject: Project;
  /** Creates a project secret. */
  createProjectSecret: Scalars["Success"];
  /** Create a new service. */
  createService: CreateServiceOutput;
  /**
   * Creates a support ticket
   *
   *     As of 2023-07, support tickets have been modified to use salesforce API so this method is only used
   *     for limit trial spend increase request
   */
  createSupportTicket: Scalars["Success"];
  /** Create a new user. */
  createUser: User;
  /** Creates a user persona. */
  createUserPersona: UserPersona;
  /** Creates a new shell VPC for users to peer with. */
  createVpc: Vpc;
  /** Decline Project invite */
  declineProjectInvite: Scalars["Success"];
  /** Delete a billing email. */
  deleteBillingEmailAddress: Scalars["Success"];
  deleteCompressionPolicy: Scalars["Success"];
  /** Delete a generic exporter. */
  deleteGenericExporter: Scalars["Success"];
  /** Delete a metric exporter. */
  deleteMetricExporter: Scalars["Success"];
  /** Removes the Mfa method if there is one */
  deleteMfaMethod: Scalars["Success"];
  deletePATRecord: Scalars["Success"];
  /** Delete a payment method from the target `BillingAccount`. */
  deletePaymentMethod: PaymentMethod;
  /** Deletes a peering connection between a VPC and a shell VPC. */
  deletePeeringConnection: Scalars["Success"];
  /**
   * Deletes a project secret.
   * @deprecated setCompressionHypertable is deprecated. Use setCompression which supports hypertables and caggs.
   */
  deleteProjectSecret: Scalars["Success"];
  /** Delete a service. */
  deleteService: Service;
  /** Deletes a VPC provided that it has no attached services. */
  deleteVpc: Scalars["Success"];
  /** Detaches a service from a generic exporter. */
  detachServiceFromGenericExporter: Scalars["Success"];
  /** Detaches a service from a metric exporter. */
  detachServiceFromMetricExporter: Scalars["Success"];
  /** Detaches a service from a VPC and places it back in the public internet. */
  detachServiceFromVpc: Scalars["Success"];
  /** Disable a project. */
  disableProject: Project;
  /** Disable the authenticated user's account. */
  disableUser: User;
  /** Marks a single recommendation as dismissed in order to hide it */
  dismissRecommendation: Scalars["Success"];
  /** Invite Project member */
  inviteProjectMember: Scalars["Success"];
  /** Join Project */
  joinProject: Scalars["ID"];
  /** Leave Project */
  leaveProject: Scalars["Success"];
  /** Begin a new file import operation. Currently supports csv and parquet file types */
  newFileImport: FileImport;
  /** Opens up a peer request to link a VPC to a shell VPC. */
  openPeerRequest: Scalars["Success"];
  /** Starts processing the file. Accepts either ProcessCsvImportInput or ProcessParquetImportInput but not both */
  processFileImport: FileImport;
  /** Invalidates previous codes and returns 10 new recovery codes */
  regenerateRecoveryCodes: Array<Scalars["String"]>;
  renamePATRecord: Scalars["Success"];
  /** Rename a project. Only a project owner can do this */
  renameProject: Project;
  /** Rename a service. */
  renameService: Scalars["Success"];
  /** Renames the VPC provided. */
  renameVpc: Scalars["Success"];
  /** Resend the email invite to become a project member */
  resendInviteProjectMember: Scalars["Success"];
  /** Resends the initial verification email for a billing email. */
  resendVerifyBillingEmailAddress: Scalars["String"];
  /** Resends the initial verification email for a user. */
  resendVerifyEmail: Scalars["String"];
  /** Reset user's password based on the given password reset token. */
  resetPassword: Scalars["String"];
  /** Reset cluster, tsdb database, tsdbadmin user postgres parameters on an instance. */
  resetPostgresParameters: Scalars["Success"];
  /** Reset a service's password. */
  resetServicePassword: Scalars["String"];
  /** Resize an instance's CPU, RAM, and Storage. */
  resizeInstance: Scalars["Success"];
  /** Revoke Project member */
  revokeProjectMember: Scalars["Success"];
  /**
   * Send a password reset token to the user bearing the given email address.
   *
   *     The return value from this mutation is a simple success message from the API.
   */
  sendPasswordReset: Scalars["String"];
  /** Sends an email to the support team. */
  sendSupportEmail: Scalars["Success"];
  /** Set the address info for the target `BillingAccount`. */
  setAddressInfo: AddressDetails;
  setCompression: Scalars["Success"];
  setCompressionHypertable: Scalars["Success"];
  setHypertableConf: Scalars["Success"];
  /** Set active window. */
  setMaintenanceWindow: Scalars["Success"];
  /** Sets postgres parameters. For example work_mem and array_nulls */
  setPostgresParameters: Array<ParamErrorMessages>;
  /** Update the primary payment method for the target `BillingAccount`. */
  setPrimaryPaymentMethod: Scalars["String"];
  /** Adds or removes a replica. */
  setReplicaCount: Scalars["Success"];
  setServiceEnvironmentTag: Scalars["Success"];
  setVirtualPlanForProject: Scalars["Success"];
  /** Switchover an instance to any available replica. */
  switchoverInstance: Scalars["Success"];
  /** Enable or disable the connection pooler on a service. */
  toggleConnectionPooler: Scalars["Success"];
  /** Enable or disable data tiering on a service. */
  toggleDataTiering: Scalars["Success"];
  /** Enable or disable the IOBoost on a service. */
  toggleIOBoost: Scalars["Success"];
  /** Enable or disable the PopSQL integration in a service. */
  togglePopSQLIntegration: Scalars["Success"];
  /** Toggle a service on or off */
  toggleService: Service;
  /** Transfer project ownership to another member of provided project. Errors if the caller is not the project's owner. */
  transferProjectOwnership: Scalars["Success"];
  /** Remove an association between an IP allow list and a service. */
  unbindIPAllowList: Scalars["Success"];
  /** Update a generic exporter's configuration. */
  updateGenericExporter: Scalars["Success"];
  /** Update a metric exporter's configuration. */
  updateMetricExporter: Scalars["Success"];
  /** Update the target `BillingAccount` with the given info. */
  updatePaymentMethod: PaymentMethod;
  /** Updates a project secret. */
  updateProjectSecret: Scalars["Success"];
  /** Set the tax ID for the target `BillingAccount`. */
  updateTaxID: Scalars["Success"];
  /** Update the authenticated user's info. */
  updateUser: User;
  /**
   * "Update a user's persona.
   *
   *     Note that this acts as a PUT request for the ui_state field.
   *     The user's ID will not be updated.
   * @deprecated Use UpdateUser
   */
  updateUserPersona: UserPersona;
  /** Initiate a major PostgreSQL upgrade. */
  upgradePostgresMajorVersion: Scalars["Success"];
  /**
   * Validate the given address info.
   *
   *     This endpoint will validate the given address, returning an array of addresses with the closest
   *     match to the provided address.
   */
  validateAddress: Array<AddressInfo>;
  /** Verify new user billing email. */
  verifyBillingEmailAddress: Scalars["String"];
  /** Verify new user email. */
  verifyEmail: VerifyEmailOutput;
};

/** The root mutation object. */
export type MutationAddCompressionPolicyArgs = {
  data: AddCompressionPolicyInput;
};

/** The root mutation object. */
export type MutationAddMfaMethodArgs = {
  data: AddMfaMethodInput;
};

/** The root mutation object. */
export type MutationAttachServiceToGenericExporterArgs = {
  data: AttachServiceToGenericExporterInput;
};

/** The root mutation object. */
export type MutationAttachServiceToMetricExporterArgs = {
  data: AttachServiceToMetricExporterInput;
};

/** The root mutation object. */
export type MutationAttachServiceToVpcArgs = {
  data: AttachServiceToVpcInput;
};

/** The root mutation object. */
export type MutationBindIpAllowListArgs = {
  data: BindIpAllowListInput;
};

/** The root mutation object. */
export type MutationCancelPendingPaymentDeletionArgs = {
  data: CancelPendingPaymentDeletionInput;
};

/** The root mutation object. */
export type MutationCancelProjectInviteArgs = {
  inviteId: Scalars["ID"];
};

/** The root mutation object. */
export type MutationChangePasswordArgs = {
  data: ChangePasswordInput;
};

/** The root mutation object. */
export type MutationChargeInvoiceArgs = {
  invoiceId: Scalars["ID"];
};

/** The root mutation object. */
export type MutationConfigureIpAllowListArgs = {
  data: ConfigureIpAllowListInput;
};

/** The root mutation object. */
export type MutationCreateBillingEmailAddressArgs = {
  data: CreateBillingEmailAddressInput;
};

/** The root mutation object. */
export type MutationCreateGenericExporterArgs = {
  data: CreateGenericExporterInput;
};

/** The root mutation object. */
export type MutationCreateMetricExporterArgs = {
  data: CreateMetricExporterInput;
};

/** The root mutation object. */
export type MutationCreatePatRecordArgs = {
  createPATRecordInput: CreatePatRecordInput;
};

/** The root mutation object. */
export type MutationCreatePaymentMethodArgs = {
  billingAccountId: Scalars["ID"];
  isPrimary: Scalars["Boolean"];
};

/** The root mutation object. */
export type MutationCreateProjectArgs = {
  data: CreateProjectInput;
};

/** The root mutation object. */
export type MutationCreateProjectSecretArgs = {
  data: CreateProjectSecretInput;
};

/** The root mutation object. */
export type MutationCreateServiceArgs = {
  data: CreateServiceInput;
};

/** The root mutation object. */
export type MutationCreateSupportTicketArgs = {
  data: SupportTicketInput;
};

/** The root mutation object. */
export type MutationCreateUserArgs = {
  data: CreateUserInput;
};

/** The root mutation object. */
export type MutationCreateUserPersonaArgs = {
  data: CreateUserPersonaInput;
};

/** The root mutation object. */
export type MutationCreateVpcArgs = {
  data: CreateVpcInput;
};

/** The root mutation object. */
export type MutationDeclineProjectInviteArgs = {
  inviteId: Scalars["ID"];
};

/** The root mutation object. */
export type MutationDeleteBillingEmailAddressArgs = {
  data: DeleteBillingEmailAddressInput;
};

/** The root mutation object. */
export type MutationDeleteCompressionPolicyArgs = {
  data: DeleteCompressionPolicyInput;
};

/** The root mutation object. */
export type MutationDeleteGenericExporterArgs = {
  data: GenericExporterIdInput;
};

/** The root mutation object. */
export type MutationDeleteMetricExporterArgs = {
  data: MetricExporterIdInput;
};

/** The root mutation object. */
export type MutationDeleteMfaMethodArgs = {
  data: DeleteMfaMethodInput;
};

/** The root mutation object. */
export type MutationDeletePatRecordArgs = {
  deletePATRecordInput: DeletePatRecordInput;
};

/** The root mutation object. */
export type MutationDeletePaymentMethodArgs = {
  billingAccountId: Scalars["ID"];
  paymentMethodId: Scalars["ID"];
};

/** The root mutation object. */
export type MutationDeletePeeringConnectionArgs = {
  data: DeletePeeringConnectionInput;
};

/** The root mutation object. */
export type MutationDeleteProjectSecretArgs = {
  data: DeleteProjectSecretInput;
};

/** The root mutation object. */
export type MutationDeleteServiceArgs = {
  data: DeleteServiceInput;
};

/** The root mutation object. */
export type MutationDeleteVpcArgs = {
  data: DeleteVpcInput;
};

/** The root mutation object. */
export type MutationDetachServiceFromGenericExporterArgs = {
  data: DetachServiceFromGenericExporterInput;
};

/** The root mutation object. */
export type MutationDetachServiceFromMetricExporterArgs = {
  data: DetachServiceFromMetricExporterInput;
};

/** The root mutation object. */
export type MutationDetachServiceFromVpcArgs = {
  data: DetachServiceFromVpcInput;
};

/** The root mutation object. */
export type MutationDisableProjectArgs = {
  id: Scalars["ID"];
};

/** The root mutation object. */
export type MutationDismissRecommendationArgs = {
  data: SingleRecommendationInput;
};

/** The root mutation object. */
export type MutationInviteProjectMemberArgs = {
  data: InviteProjectMemberInput;
};

/** The root mutation object. */
export type MutationJoinProjectArgs = {
  data: JoinProjectInput;
};

/** The root mutation object. */
export type MutationLeaveProjectArgs = {
  projectId: Scalars["ID"];
};

/** The root mutation object. */
export type MutationNewFileImportArgs = {
  data: NewFileImportInput;
};

/** The root mutation object. */
export type MutationOpenPeerRequestArgs = {
  data: OpenPeerRequestInput;
};

/** The root mutation object. */
export type MutationProcessFileImportArgs = {
  data: ProcessFileImportInput;
};

/** The root mutation object. */
export type MutationRegenerateRecoveryCodesArgs = {
  data: RegenerateRecoveryCodesInput;
};

/** The root mutation object. */
export type MutationRenamePatRecordArgs = {
  renamePATRecordInput: RenamePatRecordInput;
};

/** The root mutation object. */
export type MutationRenameProjectArgs = {
  data: RenameProjectInput;
};

/** The root mutation object. */
export type MutationRenameServiceArgs = {
  data: RenameServiceInput;
};

/** The root mutation object. */
export type MutationRenameVpcArgs = {
  data: RenameVpcInput;
};

/** The root mutation object. */
export type MutationResendInviteProjectMemberArgs = {
  inviteId: Scalars["ID"];
};

/** The root mutation object. */
export type MutationResendVerifyBillingEmailAddressArgs = {
  data: ResendVerifyBillingEmailAddressInput;
};

/** The root mutation object. */
export type MutationResendVerifyEmailArgs = {
  email: Scalars["String"];
};

/** The root mutation object. */
export type MutationResetPasswordArgs = {
  data: ResetPasswordInput;
};

/** The root mutation object. */
export type MutationResetPostgresParametersArgs = {
  data: ResetPostgresParametersInput;
};

/** The root mutation object. */
export type MutationResetServicePasswordArgs = {
  data: ResetServicePasswordInput;
};

/** The root mutation object. */
export type MutationResizeInstanceArgs = {
  data: ResizeInstanceInput;
};

/** The root mutation object. */
export type MutationRevokeProjectMemberArgs = {
  data: RevokeProjectMemberInput;
};

/** The root mutation object. */
export type MutationSendPasswordResetArgs = {
  email: Scalars["String"];
};

/** The root mutation object. */
export type MutationSendSupportEmailArgs = {
  data: SupportEmailInput;
};

/** The root mutation object. */
export type MutationSetAddressInfoArgs = {
  billingAccountId: Scalars["ID"];
  companyAddress: AddressInfoInput;
};

/** The root mutation object. */
export type MutationSetCompressionArgs = {
  data: SetCompressionInput;
};

/** The root mutation object. */
export type MutationSetCompressionHypertableArgs = {
  data: SetCompressionHypertableInput;
};

/** The root mutation object. */
export type MutationSetHypertableConfArgs = {
  data: SetHypertableConfInput;
};

/** The root mutation object. */
export type MutationSetMaintenanceWindowArgs = {
  data: SetMaintenanceWindowInput;
};

/** The root mutation object. */
export type MutationSetPostgresParametersArgs = {
  data: SetPostgresParametersInput;
};

/** The root mutation object. */
export type MutationSetPrimaryPaymentMethodArgs = {
  data: SetPrimaryPaymentMethodInput;
};

/** The root mutation object. */
export type MutationSetReplicaCountArgs = {
  data: SetReplicaCountInput;
};

/** The root mutation object. */
export type MutationSetServiceEnvironmentTagArgs = {
  data: SetServiceEnvironmentTagInput;
};

/** The root mutation object. */
export type MutationSetVirtualPlanForProjectArgs = {
  data: SetVirtualPlanForProjectRequest;
};

/** The root mutation object. */
export type MutationSwitchoverInstanceArgs = {
  data: SwitchoverInstanceInput;
};

/** The root mutation object. */
export type MutationToggleConnectionPoolerArgs = {
  data: ToggleConnectionPoolerInput;
};

/** The root mutation object. */
export type MutationToggleDataTieringArgs = {
  data: ToggleDataTieringInput;
};

/** The root mutation object. */
export type MutationToggleIoBoostArgs = {
  data: ToggleIoBoostInput;
};

/** The root mutation object. */
export type MutationTogglePopSqlIntegrationArgs = {
  data: TogglePopSqlIntegrationInput;
};

/** The root mutation object. */
export type MutationToggleServiceArgs = {
  data: ToggleServiceInput;
};

/** The root mutation object. */
export type MutationTransferProjectOwnershipArgs = {
  data: TransferProjectOwnershipInput;
};

/** The root mutation object. */
export type MutationUnbindIpAllowListArgs = {
  data: BindIpAllowListInput;
};

/** The root mutation object. */
export type MutationUpdateGenericExporterArgs = {
  data: UpdateGenericExporterInput;
};

/** The root mutation object. */
export type MutationUpdateMetricExporterArgs = {
  data: UpdateMetricExporterInput;
};

/** The root mutation object. */
export type MutationUpdatePaymentMethodArgs = {
  addressInfo?: InputMaybe<AddressInfoInput>;
  billingAccountId: Scalars["ID"];
  cardExp: CardExpInput;
  paymentMethodId: Scalars["ID"];
};

/** The root mutation object. */
export type MutationUpdateProjectSecretArgs = {
  data: UpdateProjectSecretInput;
};

/** The root mutation object. */
export type MutationUpdateTaxIdArgs = {
  data: UpdateTaxIdInput;
};

/** The root mutation object. */
export type MutationUpdateUserArgs = {
  data: UpdateUserInput;
};

/** The root mutation object. */
export type MutationUpdateUserPersonaArgs = {
  data: UpdateUserPersonaInput;
};

/** The root mutation object. */
export type MutationUpgradePostgresMajorVersionArgs = {
  data: UpgradePostgresMajorVersionInput;
};

/** The root mutation object. */
export type MutationValidateAddressArgs = {
  address: AddressInfoInput;
};

/** The root mutation object. */
export type MutationVerifyBillingEmailAddressArgs = {
  data: VerifyBillingEmailAddressInput;
};

/** The root mutation object. */
export type MutationVerifyEmailArgs = {
  data: VerifyEmailInput;
};

export type NewFileImportInput = {
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
};

export type NextMaintenance = {
  __typename?: "NextMaintenance";
  /** The end of the next maintenance run */
  end: Scalars["Time"];
  /** Indicates if a disruptive maintenance event is expected */
  isMaintenanceScheduled: Scalars["Boolean"];
  /** The beginning of the next maintenance run */
  start: Scalars["Time"];
};

export type NextMaintenanceInput = {
  /** ProjectID that the service belongs to. */
  projectId: Scalars["ID"];
  /** Service ID of the service. */
  serviceId: Scalars["ID"];
};

export type NumericParameter = {
  __typename?: "NumericParameter";
  current_value: Scalars["Float"];
  info: ParameterInfo;
  max_allowed_value?: Maybe<Scalars["Float"]>;
  min_allowed_value?: Maybe<Scalars["Float"]>;
  unit: ParameterUnit;
};

export type ObjectCounts = {
  __typename?: "ObjectCounts";
  contAggs: Scalars["Int"];
  hypertables: Scalars["Int"];
  pgTables: Scalars["Int"];
  policies: Scalars["Int"];
  realtimeContAggs: Scalars["Int"];
};

export type ObjectId = ContAggId | TableId;

/** ObjectMetadata is used to represent metadata from either a table (regular or hyper) or a continuous aggregate. */
export type ObjectMetadata = ContAggMetadata | TableMetadata;

export type ObjectSizeId = ContAggId | TableId;

export type ObjectSizeInfo = {
  __typename?: "ObjectSizeInfo";
  approxRowCount: Scalars["Int"];
  id: ObjectSizeId;
  size: Scalars["Int"];
};

/** The input type for opening a peer request. */
export type OpenPeerRequestInput = {
  /** The cloud provider that the VPCs are hosted on. */
  cloudProvider: CloudProvider;
  /** The internal table ID of the VPC row. */
  forgeVpcId: Scalars["ID"];
  /** The VPC that the user wants to peer with Forge. */
  peerVpc: InputPeerVpc;
  /** The project ID the VPC is associated with. */
  projectId: Scalars["ID"];
};

export type OrbCostInput = {
  billingAccountId?: InputMaybe<Scalars["ID"]>;
  projectId?: InputMaybe<Scalars["ID"]>;
};

export type OrderByColumn = {
  __typename?: "OrderByColumn";
  descending: Scalars["Boolean"];
  name: Scalars["String"];
  nullsFirst: Scalars["Boolean"];
};

export type PatRecord = {
  __typename?: "PATRecord";
  clientCredentials: ClientCredentials;
  created: Scalars["Time"];
  name: Scalars["String"];
  projectId: Scalars["ID"];
};

export type PitrEntitlement = {
  __typename?: "PITREntitlement";
  enabled: Scalars["Boolean"];
  max_days: Scalars["Int"];
};

export type ParamErrorMessages = {
  __typename?: "ParamErrorMessages";
  /** Error message. */
  errorMessage: Scalars["String"];
  /** Parameter to update. */
  name: Scalars["String"];
};

export type ParameterInfo = {
  __typename?: "ParameterInfo";
  description: Scalars["String"];
  is_pending_restart: Scalars["Boolean"];
  is_tune_overridable: Scalars["Boolean"];
  is_user_editable: Scalars["Boolean"];
  name: Scalars["String"];
  requires_restart: Scalars["Boolean"];
  ui_priority: Scalars["Int"];
};

/**
 * ParameterUnit defines the Postgres parameter mapping for us to use when reading or setting parameters.
 * Units are applied to numeric parameters, for example work_mem could be 10 KB
 * Valid memory units are B (bytes), kB (kilobytes), MB (megabytes), GB (gigabytes), and TB (terabytes).
 * Valid time units are us (microseconds), ms (milliseconds), s (seconds), min (minutes), h (hours), and d (days).
 */
export enum ParameterUnit {
  Bytes = "BYTES",
  Days = "DAYS",
  Gigabytes = "GIGABYTES",
  Hours = "HOURS",
  Kilobytes = "KILOBYTES",
  Megabytes = "MEGABYTES",
  Microseconds = "MICROSECONDS",
  Milliseconds = "MILLISECONDS",
  Minutes = "MINUTES",
  Seconds = "SECONDS",
  Terabytes = "TERABYTES",
  Undefined = "UNDEFINED",
}

export enum PasswordType {
  Invalid = "INVALID",
  Md5 = "MD5",
  Scram = "SCRAM",
}

/** A payment method. */
export type PaymentMethod = {
  __typename?: "PaymentMethod";
  /** The address info for this `PaymentMethod`. */
  addressInfo?: Maybe<AddressInfo>;
  /** The card information for this `PaymentMethod`. */
  cardInfo: CardInfo;
  /** The ID of the `PaymentMethod`. */
  id: Scalars["ID"];
  /** Whether the `PaymentMethod` is the primary. */
  isPrimary?: Maybe<Scalars["Boolean"]>;
};

/** The VPC that the user wants to peer with (i.e. their personal VPC, *not* the shell). */
export type PeerVpc = {
  __typename?: "PeerVPC";
  /** The account ID issues by the cloud provider to the user. */
  accountId: Scalars["ID"];
  /** The CIDR block of the user-provided VPC. */
  cidr: Scalars["String"];
  /** The ID of the user-provided VPC. */
  id: Scalars["ID"];
  /** The region that the user-provided VPC exists in. */
  regionCode: Scalars["String"];
};

/** Model of the peering connection between a user's VPC and an allocated shell VPC. */
export type PeeringConnection = {
  __typename?: "PeeringConnection";
  /** Indicates an error with the peering connection. */
  errorMessage?: Maybe<Scalars["String"]>;
  /** The ID of the peering connection. Currently nullable, soon will be required. */
  id?: Maybe<Scalars["ID"]>;
  /** The user-provided VPC that the connection points to. */
  peerVpc: PeerVpc;
  /** The provisioned ID of the Peering Connection as given by the cloud provider. */
  provisionedId?: Maybe<Scalars["ID"]>;
  /** The current status of the PeeringConnection. */
  status: PeeringConnectionStatus;
  /** The ID of the shell VPC this connection points to (i.e. the Id property of a VPC object). */
  vpcId: Scalars["ID"];
};

/** Enum that represents the status of a PeeringConnection. */
export enum PeeringConnectionStatus {
  Active = "ACTIVE",
  Approved = "APPROVED",
  Deleting = "DELETING",
  Disabled = "DISABLED",
  Invalid = "INVALID",
  Pending = "PENDING",
  Timeout = "TIMEOUT",
  Unknown = "UNKNOWN",
}

export type PerQueryGraphDataPoint = {
  __typename?: "PerQueryGraphDataPoint";
  dataPoints: Array<GraphDataPoint>;
  queryHash: Scalars["String"];
};

/** A billing plan for Timescale. */
export type Plan = {
  __typename?: "Plan";
  /** The plan's ID. */
  id: Scalars["ID"];
  /** Amount of memory/RAM in GB. */
  memoryGB?: Maybe<Scalars["Int"]>;
  /** Amount of CPU in thousandths; take this number over 1000 to get a decimal in whole CPUs. */
  milliCPU?: Maybe<Scalars["Int"]>;
  /** CPU limit in thousandths. A value of 0 means limits is equal to requested amount. */
  milliCPULimit?: Maybe<Scalars["Int"]>;
  /** The cost of this plan. */
  price: Scalars["Float"];
  /** The product which this plan is associated with. */
  productId: Scalars["ID"];
  /** The region of the plan. */
  regionCode: Scalars["String"];
  /**
   * Amount of storage in GB.
   * @deprecated deprecated
   */
  storageGB?: Maybe<Scalars["Int"]>;
};

export type Policy = {
  __typename?: "Policy";
  fullInfo: Scalars["String"];
  id: Scalars["Int"];
  interval: Scalars["String"];
  target: Scalars["String"];
  type: Scalars["String"];
};

export type PolicyCount = {
  __typename?: "PolicyCount";
  count: Scalars["Int"];
  name: Scalars["String"];
};

export type PostgresAndTimescaleVersions = {
  __typename?: "PostgresAndTimescaleVersions";
  hasBackupInfo: Scalars["Boolean"];
  postgresVersion: VersionInfo;
  timescaleDBVersion: VersionInfo;
};

export type PostgresExtension = {
  __typename?: "PostgresExtension";
  comment: Scalars["String"];
  defaultVersion: Scalars["String"];
  installedVersion?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
};

export enum PostgresMajorVersionUpgradeStage {
  Error = "ERROR",
  Finished = "FINISHED",
  Initiated = "INITIATED",
  Uninitiated = "UNINITIATED",
  Unknown = "UNKNOWN",
  Verifying = "VERIFYING",
}

/** A status of a major PostgreSQL Upgrade. */
export type PostgresMajorVersionUpgradeStatus = {
  __typename?: "PostgresMajorVersionUpgradeStatus";
  /** The error message of a failed major PostgreSQL upgrade */
  error?: Maybe<Scalars["String"]>;
  /** The last known stage of the major PostgreSQL upgrade */
  stage: PostgresMajorVersionUpgradeStage;
};

export type PostgresParameters = {
  __typename?: "PostgresParameters";
  numeric_parameters: Array<NumericParameter>;
  string_parameters: Array<StringParameter>;
};

export type ProcedureId = {
  __typename?: "ProcedureID";
  name: Scalars["String"];
  schema: Scalars["String"];
};

export type ProcessCsvImportInput = {
  columns: Array<CsvImportColumnInput>;
  delimiter: Scalars["String"];
  id: Scalars["String"];
  projectId: Scalars["ID"];
  schema_name: Scalars["String"];
  serviceId: Scalars["ID"];
  skip_header: Scalars["Boolean"];
  table_name: Scalars["String"];
  time_column: Scalars["String"];
};

export type ProcessFileImportInput = {
  csv?: InputMaybe<ProcessCsvImportInput>;
  parquet?: InputMaybe<ProcessParquetImportInput>;
  source?: InputMaybe<FileImportSource>;
};

export type ProcessParquetImportInput = {
  columns: Array<CsvImportColumnInput>;
  id: Scalars["String"];
  projectId: Scalars["ID"];
  schema_name: Scalars["String"];
  serviceId: Scalars["ID"];
  table_name: Scalars["String"];
  time_column: Scalars["String"];
};

/** A billing product of Timescale. */
export type Product = {
  __typename?: "Product";
  /** A description of the product. */
  description: Scalars["String"];
  /** Product ID, as it appears in Stripe as well. */
  id: Scalars["ID"];
  /** A display name for the product. */
  name: Scalars["String"];
  /** All plans associated with this product. */
  plans: Array<Plan>;
};

/** All cost information for a specific billing account for a specific product. */
export type ProductCostInfo = {
  __typename?: "ProductCostInfo";
  /** The cost-to-date for the current billing period, minus any trials or credits. */
  costToDate: Scalars["Float"];
  /** The cost-to-date for the current billing period. */
  costToDateGross: Scalars["Float"];
  /** The estimated total cost for the current billing period, minus any trials or credits. */
  estimatedTotalCost: Scalars["Float"];
  /** The estimated total cost for the current billing period. */
  estimatedTotalCostGross: Scalars["Float"];
  /** The id of the product we have informatiion for */
  productID: Scalars["ID"];
};

export enum ProductTier {
  Basic = "BASIC",
  Enterprise = "ENTERPRISE",
}

export type ProductionSupportEntitlement = {
  __typename?: "ProductionSupportEntitlement";
  enabled: Scalars["Boolean"];
};

export type Project = {
  __typename?: "Project";
  billingAccount?: Maybe<BillingAccount>;
  created?: Maybe<Scalars["Time"]>;
  /** Entitlement spec shows the set of features, and limits a given project has. */
  entitlementSpec?: Maybe<EntitlementSpec>;
  id: Scalars["ID"];
  maxVPCs: Scalars["Int"];
  name: Scalars["String"];
  productTier: ProductTier;
};

/** A project invitation. */
export type ProjectInvite = {
  __typename?: "ProjectInvite";
  /** The date this invite has been activated. */
  activated?: Maybe<Scalars["Time"]>;
  /** The date this invite has been created. */
  created: Scalars["Time"];
  /** The date this invite has been declined. */
  declined?: Maybe<Scalars["Time"]>;
  /** The date this invite has been deleted. */
  deleted?: Maybe<Scalars["Time"]>;
  /** The invited email. */
  email: Scalars["String"];
  /** The invite unique hash */
  hash?: Maybe<Scalars["String"]>;
  /** The invite ID */
  id: Scalars["ID"];
  /** The user that sent the invitation. */
  inviter?: Maybe<User>;
  /** The project the invitation is about. */
  project: Project;
  /** The project role. */
  role: Role;
  /** The user ID */
  userId: Scalars["ID"];
};

/** A project member. */
export type ProjectMember = {
  __typename?: "ProjectMember";
  /** Date the user joined the project */
  joined: Scalars["Time"];
  /** The project id */
  projectId: Scalars["ID"];
  /** The project role. */
  role: Role;
  /** The user of the `ProjectMember`. */
  user: User;
  /** The member user id */
  userId: Scalars["ID"];
};

export type PrometheusMetricConfig = {
  __typename?: "PrometheusMetricConfig";
  foo: Scalars["String"];
};

export type PrometheusMetricConfigInput = {
  foo: Scalars["String"];
};

/** The root Query object of this API. */
export type Query = {
  __typename?: "Query";
  GetContAggListV2: ContAggListResponse;
  downgradeCheck: EntitlementDowngradeResponse;
  explorer: Explorer;
  /** Get all generic exporters for this project. */
  getAllGenericExporters: Array<GenericExporter>;
  /** Get all metric exporters for this project. */
  getAllMetricExporters: Array<MetricExporter>;
  getAllNextMaintenance: Array<ServiceNextMaintenance>;
  /** Get all projects which the authenticated user has access to. */
  getAllProjects: Array<Project>;
  /** Get all services which the authenticated user has access to for the project specified by ID. */
  getAllServices: Array<Service>;
  /** Get a list of VPCs for a user's project. */
  getAllVpcs: Array<Vpc>;
  /** Get billing emails for the target billing account */
  getBillingEmailAddresses: Array<BillingEmailAddress>;
  getCompressionRecommendationsForOrderBy: CompressionRecommendationsForOrderBy;
  getCompressionRecommendationsForSegmentBy: CompressionRecommendationsForSegmentBy;
  getContAggInfo: ContAggInfo;
  getContAggList: Array<ContAggId>;
  /** Get the hourly cost of data tiering vs. EBS */
  getCostedProjectDataTieringUsageDistribution: Array<DataTieringStorageSummary>;
  getDataTieringSummary?: Maybe<Array<Maybe<TieringUsage>>>;
  getDatabaseOverview: GetDatabaseOverviewOutput;
  /** Get one generic exporter. */
  getGenericExporter: GenericExporter;
  /** Get IP allow lists for a project. */
  getIPAllowLists: Array<IpAllowList>;
  /** Get the file-import information */
  getImportState: FileImport;
  /** Get initial extensions that were specified during service creation */
  getInitialExtensions?: Maybe<Array<Scalars["String"]>>;
  /** Get invocation details */
  getInvocationDetails: InvocationDetails;
  /** Get Invoices for the target billing account, 6 most recent ones by default or all for optionnally provided year (querying for period_start) */
  getInvoices: Invoices;
  /** Get corporate invoicing info for a project */
  getInvoicing: Invoicing;
  /** Get JWT for CC */
  getJWTForClientCredentials: Scalars["String"];
  /** @deprecated getJobList is deprecated, use explorer.jobs instead. */
  getJobList: JobListResponse;
  getLargestObjects: Array<ObjectSizeInfo>;
  /** Get the last resource usage of a service. */
  getLastServiceResourceMetrics: Array<ResourceMetrics>;
  /** Get one metric exporter. */
  getMetricExporter: MetricExporter;
  /** Get minimum spend for a project */
  getMinimumSpend: MinimumSpend;
  /** Get the next maintenance run of the instance */
  getNextMaintenance: NextMaintenance;
  getObjectsMetadata: Array<ExtendedObjectMetadata>;
  getOrbInvoices: Invoices;
  /** Get all PAT records for the specified project */
  getPATRecords: Array<Maybe<PatRecord>>;
  /** Get all payment methods for the target billing account. */
  getPaymentMethods: Array<PaymentMethod>;
  /** Get pending payment method deletion for the target billing account. */
  getPendingPaymentDeletion?: Maybe<PaymentMethod>;
  /** Get the current status from the PopSQL integration of a service. */
  getPopSQLIntegrationStatus: Status;
  /** Get version information for an instance */
  getPostgresAndTimescaleDBVersion: PostgresAndTimescaleVersions;
  /** Get the list of available extensions for the specified instance */
  getPostgresAvailableExtensions: Array<PostgresExtension>;
  /** Get the last known status for a major PostgreSQL upgrade */
  getPostgresMajorVersionUpgradeStatus: PostgresMajorVersionUpgradeStatus;
  /** Get the currently configured postgres parameters for an instance. */
  getPostgresParameters: PostgresParameters;
  /**
   * Get the project specified by ID.
   *
   *     Permissions are checked based on the authenticated user's ID.
   */
  getProject: Project;
  /** Get pending project invites for a project */
  getProjectInvites: Array<ProjectInvite>;
  /** Get pending project invites for user */
  getProjectInvitesForUser: Array<ProjectInvite>;
  /** Get list of project members for a project */
  getProjectMembers: Array<ProjectMember>;
  /** Get all project secrets. */
  getProjectSecrets: Array<Secret>;
  /** getQueryDetails returns the normalized query for a given query hash */
  getQueryDetails: GetQueryDetailsResponse;
  /** getQueryMetricGraphs returns 5 minutes data points for a given query hash over time */
  getQueryMetricGraphs: QueryMetricGraphResponse;
  /** Get query stats for a given instance */
  getQueryStatsAndMetadata: QueryStatResponse;
  /** Fetches a single recommendation by ID */
  getRecommendation: Recommendation;
  /** Lists all recommendations for a project and service */
  getRecommendations: Array<Recommendation>;
  /** Get recovery progress for replication-based services */
  getRecoveryProgress: Array<RecoveryProgress>;
  /**  Get the recovery time windows (earliest and latest timestamp) for PITR. Multiple windows can be returned */
  getRecoveryWindows: Array<RecoveryWindow>;
  /**
   * Get the service specified by ID.
   *
   *     Permissions are checked based on the authenticated user's ID.
   */
  getService: Service;
  /** Get the history of a service's configuration. */
  getServiceConfigHistory: ServiceConfigHistory;
  /** Get the current deployment status of a service. */
  getServiceDeploymentStatus: DeployStatus;
  /**
   * Get a payload of logs from the target service.
   *
   *     This call will pull the most recent payload of logs from the current 24hr window, returning
   *     a maximum of 500 log entries.
   */
  getServiceLogs: Array<Scalars["String"]>;
  /** Get the resource usage of a service over time. */
  getServiceResourceMetrics: Array<ResourceMetrics>;
  getTableInfo: Table;
  /** Get the list of table identifiers in a user's instance */
  getTableList: Array<TableId>;
  /** Get the version of TimescaleDB in a user's instance */
  getTimescaleVersion: Scalars["TimescaleVersion"];
  /**
   * Get details on the logged in user.
   *
   *     This field will use the JWT associated with the call to determine the user to look-up.
   */
  getUser: User;
  /**
   * Get signup origin of the logged in user.
   *
   *     This field will use the JWT associated with the call to determine the user to look-up.
   * @deprecated use getUser
   */
  getUserOrigin: Scalars["String"];
  /**
   * Get a user's persona.
   *
   *     This field will use the JWT associated with the call to determine the user to look-up.
   * @deprecated use getUser
   */
  getUserPersona: UserPersona;
  getVirtualPlans: GetVirtualPlansResponse;
  /** Get a VPC by ID. This will verify the authenticated user has access to said vpc before returning. */
  getVpc: Vpc;
  /** Get a VPC by ID. This will verify the authenticated user has access to said vpc before returning. */
  getVpcByName: Vpc;
  /** List all vectorizers */
  listVectorizers: Array<Vectorizer>;
  /**
   * Login to Timescale.
   *
   *     When calling this endpoint credentials must be provided via the `Authorization` header as
   *     Basic Auth credentials. If `noJwt`is specified the userToken will not be returned in the
   *     response and only set as a cookie. To avoid breaking the interface a string will still
   *     be returned but will not be valid.
   *     The response object includes a `userToken` JWT for use throughout the system.
   * @deprecated use loginMfa
   */
  login: LoginResponse;
  /**
   * Login to Timescale with potential Mfa step up.
   *
   *     This can return an MfaRequired object which requires the user to call the endpoint again with
   *     a code from the users Mfa device. The returned twoFactorId should be passed back again with
   *     a valid code, the email/password does not need to be passed in again.
   */
  loginMfa: LoginMfaResponse;
  /**
   * Logout of Timescale.
   *
   *     This serves to remove the HTTPonly cookies set by login
   */
  logout: Scalars["Success"];
  /**
   * Perform a MFA challenge returning a trust token to use with later API calls.
   *
   *     This is required for actions that require step up authentication such as changing password
   *     We leverage the trustToken and trustChallenge provided in https://fusionauth.io/docs/v1/tech/apis/two-factor
   *
   *     This endpoint supports a password reset token in place of a standard auth token
   *     passed in via the auth header as a bearer token "Bearer <Token>"
   */
  mfaChallenge: MfaChallengeResponse;
  /** Cost from Orb data. */
  orbCost: BillingAccountCostInfo;
  /** All products and their associated plans available in Orb. */
  orbProducts: Array<Product>;
  /** All products and their associated plans available in the system. */
  products: Array<Product>;
  /** Validate the given password reset token to ensure it can be used. */
  validateResetToken: Scalars["String"];
  /** Validate the given password reset token to ensure it can be used. */
  validateResetTokenMfa: ValidateResetTokenMfaResponse;
};

/** The root Query object of this API. */
export type QueryGetContAggListV2Args = {
  data: GetContAggListInput;
};

/** The root Query object of this API. */
export type QueryDowngradeCheckArgs = {
  data: EntitlementDowngradeCheckInput;
};

/** The root Query object of this API. */
export type QueryExplorerArgs = {
  projectId: Scalars["String"];
  regionCode?: InputMaybe<Scalars["String"]>;
  serviceId: Scalars["String"];
};

/** The root Query object of this API. */
export type QueryGetAllGenericExportersArgs = {
  projectId: Scalars["ID"];
};

/** The root Query object of this API. */
export type QueryGetAllMetricExportersArgs = {
  projectId: Scalars["ID"];
};

/** The root Query object of this API. */
export type QueryGetAllNextMaintenanceArgs = {
  projectId: Scalars["ID"];
};

/** The root Query object of this API. */
export type QueryGetAllServicesArgs = {
  projectId: Scalars["ID"];
};

/** The root Query object of this API. */
export type QueryGetAllVpcsArgs = {
  projectId: Scalars["ID"];
};

/** The root Query object of this API. */
export type QueryGetBillingEmailAddressesArgs = {
  billingAccountId: Scalars["ID"];
};

/** The root Query object of this API. */
export type QueryGetCompressionRecommendationsForOrderByArgs = {
  data: CompressionRecommendationsForOrderByInput;
};

/** The root Query object of this API. */
export type QueryGetCompressionRecommendationsForSegmentByArgs = {
  data: CompressionRecommendationsForSegmentByInput;
};

/** The root Query object of this API. */
export type QueryGetContAggInfoArgs = {
  data: GetContAggInfoInput;
};

/** The root Query object of this API. */
export type QueryGetContAggListArgs = {
  data: GetContAggListInput;
};

/** The root Query object of this API. */
export type QueryGetCostedProjectDataTieringUsageDistributionArgs = {
  projectID: Scalars["ID"];
};

/** The root Query object of this API. */
export type QueryGetDataTieringSummaryArgs = {
  projectId: Scalars["ID"];
};

/** The root Query object of this API. */
export type QueryGetDatabaseOverviewArgs = {
  data: GetDatabaseOverviewInput;
};

/** The root Query object of this API. */
export type QueryGetGenericExporterArgs = {
  data: GenericExporterIdInput;
};

/** The root Query object of this API. */
export type QueryGetIpAllowListsArgs = {
  projectID: Scalars["ID"];
};

/** The root Query object of this API. */
export type QueryGetImportStateArgs = {
  data: GetFileImportInput;
};

/** The root Query object of this API. */
export type QueryGetInitialExtensionsArgs = {
  data: GetInitialExtensionsInput;
};

/** The root Query object of this API. */
export type QueryGetInvocationDetailsArgs = {
  data: GetInvocationDetailsInput;
};

/** The root Query object of this API. */
export type QueryGetInvoicesArgs = {
  billingAccountId: Scalars["ID"];
  year?: InputMaybe<Scalars["Int"]>;
};

/** The root Query object of this API. */
export type QueryGetInvoicingArgs = {
  billingAccountId: Scalars["ID"];
};

/** The root Query object of this API. */
export type QueryGetJwtForClientCredentialsArgs = {
  data: GetJwtForClientCredentialsInput;
};

/** The root Query object of this API. */
export type QueryGetJobListArgs = {
  data: GetJobListInput;
};

/** The root Query object of this API. */
export type QueryGetLargestObjectsArgs = {
  data: GetLargestObjectsInput;
};

/** The root Query object of this API. */
export type QueryGetLastServiceResourceMetricsArgs = {
  data: GetLastServiceResourceMetricsInput;
};

/** The root Query object of this API. */
export type QueryGetMetricExporterArgs = {
  data: MetricExporterIdInput;
};

/** The root Query object of this API. */
export type QueryGetMinimumSpendArgs = {
  projectID: Scalars["ID"];
};

/** The root Query object of this API. */
export type QueryGetNextMaintenanceArgs = {
  data: NextMaintenanceInput;
};

/** The root Query object of this API. */
export type QueryGetObjectsMetadataArgs = {
  data: GetObjectsMetadataInput;
};

/** The root Query object of this API. */
export type QueryGetOrbInvoicesArgs = {
  billingAccountId: Scalars["ID"];
  year?: InputMaybe<Scalars["Int"]>;
};

/** The root Query object of this API. */
export type QueryGetPatRecordsArgs = {
  projectID: Scalars["ID"];
};

/** The root Query object of this API. */
export type QueryGetPaymentMethodsArgs = {
  billingAccountId: Scalars["ID"];
};

/** The root Query object of this API. */
export type QueryGetPendingPaymentDeletionArgs = {
  billingAccountId: Scalars["ID"];
};

/** The root Query object of this API. */
export type QueryGetPopSqlIntegrationStatusArgs = {
  data: GetPopSqlIntegrationStatusInput;
};

/** The root Query object of this API. */
export type QueryGetPostgresAndTimescaleDbVersionArgs = {
  data: GetPostgresAndTimescaleDbVersionInput;
};

/** The root Query object of this API. */
export type QueryGetPostgresAvailableExtensionsArgs = {
  data: GetPostgresAvailableExtensionsInput;
};

/** The root Query object of this API. */
export type QueryGetPostgresMajorVersionUpgradeStatusArgs = {
  data: GetPostgresMajorVersionUpgradeStatusInput;
};

/** The root Query object of this API. */
export type QueryGetPostgresParametersArgs = {
  data: GetPostgresParametersInput;
};

/** The root Query object of this API. */
export type QueryGetProjectArgs = {
  id: Scalars["ID"];
};

/** The root Query object of this API. */
export type QueryGetProjectInvitesArgs = {
  projectId: Scalars["ID"];
};

/** The root Query object of this API. */
export type QueryGetProjectMembersArgs = {
  projectId: Scalars["ID"];
};

/** The root Query object of this API. */
export type QueryGetProjectSecretsArgs = {
  data: GetProjectSecretsInput;
};

/** The root Query object of this API. */
export type QueryGetQueryDetailsArgs = {
  data: GetQueryDetailsInput;
};

/** The root Query object of this API. */
export type QueryGetQueryMetricGraphsArgs = {
  data: GetQueryMetricGraphsInput;
};

/** The root Query object of this API. */
export type QueryGetQueryStatsAndMetadataArgs = {
  data: GetQueryStatsInput;
};

/** The root Query object of this API. */
export type QueryGetRecommendationArgs = {
  data: SingleRecommendationInput;
};

/** The root Query object of this API. */
export type QueryGetRecommendationsArgs = {
  data: GetRecommendationsInput;
};

/** The root Query object of this API. */
export type QueryGetRecoveryProgressArgs = {
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
};

/** The root Query object of this API. */
export type QueryGetRecoveryWindowsArgs = {
  data: RecoveryWindowsInput;
};

/** The root Query object of this API. */
export type QueryGetServiceArgs = {
  data: GetServiceInput;
};

/** The root Query object of this API. */
export type QueryGetServiceConfigHistoryArgs = {
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
  serviceOrdinal?: InputMaybe<Scalars["Int"]>;
};

/** The root Query object of this API. */
export type QueryGetServiceDeploymentStatusArgs = {
  data: GetServiceDeploymentStatusInput;
};

/** The root Query object of this API. */
export type QueryGetServiceLogsArgs = {
  data: GetServiceLogsInput;
};

/** The root Query object of this API. */
export type QueryGetServiceResourceMetricsArgs = {
  data: GetServiceResourceMetricsInput;
};

/** The root Query object of this API. */
export type QueryGetTableInfoArgs = {
  data: GetTableInfoInput;
};

/** The root Query object of this API. */
export type QueryGetTableListArgs = {
  data: GetTableListInput;
};

/** The root Query object of this API. */
export type QueryGetTimescaleVersionArgs = {
  data: GetTimescaleVersionInput;
};

/** The root Query object of this API. */
export type QueryGetVpcArgs = {
  vpcId: Scalars["ID"];
};

/** The root Query object of this API. */
export type QueryGetVpcByNameArgs = {
  data: GetVpcByNameInput;
};

/** The root Query object of this API. */
export type QueryListVectorizersArgs = {
  data?: InputMaybe<ListVectorizerInput>;
};

/** The root Query object of this API. */
export type QueryLoginArgs = {
  data?: InputMaybe<LoginInput>;
};

/** The root Query object of this API. */
export type QueryLoginMfaArgs = {
  data: LoginMfaInput;
};

/** The root Query object of this API. */
export type QueryMfaChallengeArgs = {
  data: MfaChallengeInput;
};

/** The root Query object of this API. */
export type QueryOrbCostArgs = {
  data: OrbCostInput;
};

/** The root Query object of this API. */
export type QueryOrbProductsArgs = {
  experimentGroup?: InputMaybe<ExperimentGroup>;
  projectId: Scalars["ID"];
};

/** The root Query object of this API. */
export type QueryValidateResetTokenArgs = {
  token: Scalars["String"];
};

/** The root Query object of this API. */
export type QueryValidateResetTokenMfaArgs = {
  token: Scalars["String"];
};

export type QueryDetails = {
  __typename?: "QueryDetails";
  normalizedQuery: Scalars["String"];
};

export type QueryMetricGraphResponse = {
  __typename?: "QueryMetricGraphResponse";
  dataPoints: Array<GraphDataPoint>;
  total: Scalars["Int"];
};

/** Query Range is used to provide a range to get query stats from. */
export type QueryRange = {
  from: Scalars["Time"];
  to: Scalars["Time"];
};

/**
 * The QueryStat is the response received for a request to list queries.
 * Each QueryStat represents a single query row, which consists of a fingerprint (identifier),
 * the raw query string, and a collection of aggregated metrics.
 */
export type QueryStat = {
  __typename?: "QueryStat";
  metrics: QueryStatMetrics;
  query: Scalars["String"];
  queryHash: Scalars["String"];
  userName: Scalars["String"];
};

export type QueryStatConstraint = {
  field: QueryStatConstraintFieldOption;
  search?: InputMaybe<Scalars["String"]>;
  threshold?: InputMaybe<Scalars["Float"]>;
};

export enum QueryStatConstraintFieldOption {
  ExecutionsGreaterThan = "EXECUTIONS_GREATER_THAN",
  ExecutionsLessThan = "EXECUTIONS_LESS_THAN",
  FindByQueryPart = "FIND_BY_QUERY_PART",
  FindByUser = "FIND_BY_USER",
  HasAggregates = "HAS_AGGREGATES",
  HasCompression = "HAS_COMPRESSION",
  HasHypertables = "HAS_HYPERTABLES",
  MaxTimeGreaterThan = "MAX_TIME_GREATER_THAN",
  OnlyDeleteQueries = "ONLY_DELETE_QUERIES",
  OnlyInsertQueries = "ONLY_INSERT_QUERIES",
  OnlySelectQueries = "ONLY_SELECT_QUERIES",
  OnlyUpdateQueries = "ONLY_UPDATE_QUERIES",
  OnlyUserQueries = "ONLY_USER_QUERIES",
  ShowBackgroundQueries = "SHOW_BACKGROUND_QUERIES",
}

/** The set of query performance metrics available */
export type QueryStatMetrics = {
  __typename?: "QueryStatMetrics";
  bytesReadRate?: Maybe<MetricValue>;
  cacheHitRatio?: Maybe<MetricValue>;
  cacheMemoryRate?: Maybe<MetricValue>;
  caggsCount?: Maybe<MetricValue>;
  compressionCount?: Maybe<MetricValue>;
  executions?: Maybe<MetricValue>;
  hypertablesCount?: Maybe<MetricValue>;
  medianBytesRead?: Maybe<MetricValue>;
  medianChunkAccessed?: Maybe<MetricValue>;
  medianMemory?: Maybe<MetricValue>;
  medianRows?: Maybe<MetricValue>;
  p05Time?: Maybe<MetricValue>;
  p50PlanTime?: Maybe<MetricValue>;
  p50Time?: Maybe<MetricValue>;
  p95BytesRead?: Maybe<MetricValue>;
  p95Memory?: Maybe<MetricValue>;
  p95PlanTime?: Maybe<MetricValue>;
  p95Time?: Maybe<MetricValue>;
  p99Time?: Maybe<MetricValue>;
  plans?: Maybe<MetricValue>;
  rows?: Maybe<MetricValue>;
  totalTime?: Maybe<MetricValue>;
};

export enum QueryStatRange {
  Custom = "CUSTOM",
  TwentyFourHours = "TWENTY_FOUR_HOURS",
}

/**
 * The QueryStatResponse is a wrapper for the list of query stats.
 * It's used to send alongside the stats others helpful information.
 */
export type QueryStatResponse = {
  __typename?: "QueryStatResponse";
  queriesDataPoints: Array<PerQueryGraphDataPoint>;
  stats: Array<Maybe<QueryStat>>;
  statsAsOf?: Maybe<Scalars["Time"]>;
};

export enum QueryStatSortFieldOption {
  CacheHitRatio = "CACHE_HIT_RATIO",
  CaggsCount = "CAGGS_COUNT",
  CompressionCount = "COMPRESSION_COUNT",
  Executions = "EXECUTIONS",
  HypertablesCount = "HYPERTABLES_COUNT",
  MedianBytesRead = "MEDIAN_BYTES_READ",
  MedianChunksAccessed = "MEDIAN_CHUNKS_ACCESSED",
  MedianMemory = "MEDIAN_MEMORY",
  MedianRows = "MEDIAN_ROWS",
  P05Time = "P05_TIME",
  P50PlanTime = "P50_PLAN_TIME",
  P50Time = "P50_TIME",
  P95BytesRead = "P95_BYTES_READ",
  P95Memory = "P95_MEMORY",
  P95PlanTime = "P95_PLAN_TIME",
  P95Time = "P95_TIME",
  P99Time = "P99_TIME",
  Rows = "ROWS",
  TotalTime = "TOTAL_TIME",
}

export enum QueryStatSortOrderOption {
  Asc = "ASC",
  Desc = "DESC",
}

export type ReadReplicaEntitlement = {
  __typename?: "ReadReplicaEntitlement";
  enabled: Scalars["Boolean"];
};

export type Recommendation = {
  __typename?: "Recommendation";
  created: Scalars["Time"];
  id: Scalars["ID"];
  message: Scalars["String"];
  metadata?: Maybe<RecommendationMetadata>;
  projectId: Scalars["ID"];
  resourceId: Scalars["ID"];
  serviceId: Scalars["ID"];
  type: RecommendationType;
  updated: Scalars["Time"];
};

export type RecommendationMetadata =
  | HypertableChangeChunkTimeIntervalMetadata
  | JobFailedNTimesMetadata;

export enum RecommendationType {
  HypertableChangeChunkTimeInterval = "HYPERTABLE_CHANGE_CHUNK_TIME_INTERVAL",
  JobFailedNTimes = "JOB_FAILED_N_TIMES",
}

export enum RecoveryMethod {
  Basebackup = "BASEBACKUP",
  Snapshot = "SNAPSHOT",
  Unknown = "UNKNOWN",
}

export type RecoveryProgress = {
  __typename?: "RecoveryProgress";
  lag: Scalars["Int"];
  ordinal: Scalars["Int"];
  recorded: Scalars["Time"];
};

export type RecoveryWindow = {
  __typename?: "RecoveryWindow";
  endTime: Scalars["Time"];
  method: RecoveryMethod;
  startTime: Scalars["Time"];
};

export type RecoveryWindowsInput = {
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
};

export type RegenerateRecoveryCodesInput = {
  code: Scalars["String"];
};

/** The region that a given service lives in. */
export enum Region {
  AwsEuWest_1 = "AWS_EU_WEST_1",
  AwsUsEast_1 = "AWS_US_EAST_1",
  AwsUsWest_2 = "AWS_US_WEST_2",
  Unknown = "UNKNOWN",
}

export type RenamePatRecordInput = {
  accessKey: Scalars["String"];
  newName: Scalars["String"];
};

export type RenameProjectInput = {
  name: Scalars["String"];
  projectId: Scalars["ID"];
};

export type RenameServiceInput = {
  newName: Scalars["String"];
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
};

/** The input type for renaming a vpc. */
export type RenameVpcInput = {
  /** The internal table ID of the VPC row. */
  forgeVpcId: Scalars["ID"];
  /** The new VPC name. */
  newName: Scalars["String"];
  /** The project ID the VPC is associated with. */
  projectId: Scalars["ID"];
};

export type ResendVerifyBillingEmailAddressInput = {
  billingAccountId: Scalars["ID"];
  billingEmailId: Scalars["ID"];
};

export type ResetPasswordInput = {
  password: Scalars["String"];
  passwordConfirm: Scalars["String"];
  token: Scalars["String"];
  /** Required if there are MFA methods active for the user  */
  trustChallenge?: InputMaybe<Scalars["String"]>;
  trustToken?: InputMaybe<Scalars["String"]>;
};

/** Reset postgres parameters for an instance. */
export type ResetPostgresParametersInput = {
  /** ProjectID that the service belongs to. */
  projectId: Scalars["ID"];
  /** Service ID of the service in question. */
  serviceId: Scalars["ID"];
};

export type ResetServicePasswordInput = {
  password?: InputMaybe<Scalars["String"]>;
  passwordType: PasswordType;
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
};

/** Resize instance input. */
export type ResizeInstanceInput = {
  /** Desired configuration changes. */
  config: ResourceConfig;
  /** Project ID that the service belongs to. */
  projectId: Scalars["ID"];
  /** Service ID of the service in question. */
  serviceId: Scalars["ID"];
};

export type Resource = {
  __typename?: "Resource";
  id: Scalars["ID"];
  serviceId: Scalars["ID"];
  spec: ResourceSpec;
  type: Scalars["String"];
};

export type ResourceConfig = {
  allReplicasAreSync?: InputMaybe<Scalars["Boolean"]>;
  memoryGB: Scalars["Int"];
  milliCPU: Scalars["Int"];
  milliCPULimit?: InputMaybe<Scalars["Int"]>;
  replicaCount?: InputMaybe<Scalars["Int"]>;
  storageGB: Scalars["Int"];
  synchronousReplicaCount?: InputMaybe<Scalars["Int"]>;
};

/** A measurement of an instance's CPU, RAM & storage usage, and the IOPs and Bandwidth consumption of it's volume at a specific point in time. */
export type ResourceMetrics = {
  __typename?: "ResourceMetrics";
  Time: Scalars["Time"];
  memoryMB?: Maybe<Scalars["Int"]>;
  milliCPU?: Maybe<Scalars["Int"]>;
  milliCPULimit?: Maybe<Scalars["Int"]>;
  readBandwidth?: Maybe<Scalars["Float"]>;
  readIOPs?: Maybe<Scalars["Float"]>;
  storageMB?: Maybe<Scalars["Int"]>;
  writeBandwidth?: Maybe<Scalars["Float"]>;
  writeIOPs?: Maybe<Scalars["Float"]>;
};

export type ResourceNode = {
  __typename?: "ResourceNode";
  memoryGB: Scalars["Int"];
  milliCPU: Scalars["Int"];
  milliCPULimit?: Maybe<Scalars["Int"]>;
  replicaCount: Scalars["Int"];
  storageGB: Scalars["Int"];
  syncReplicaCount: Scalars["Int"];
};

export type ResourceSpec = ResourceNode;

export type RevokeProjectMemberInput = {
  projectId: Scalars["ID"];
  userId: Scalars["ID"];
};

export enum Role {
  Admin = "ADMIN",
  Manager = "MANAGER",
  User = "USER",
}

export type SamlEntitlement = {
  __typename?: "SAMLEntitlement";
  enabled: Scalars["Boolean"];
};

export type Secret = {
  __typename?: "Secret";
  created: Scalars["Time"];
  name: Scalars["String"];
  projectId: Scalars["ID"];
  updated?: Maybe<Scalars["Time"]>;
  value: Scalars["String"];
};

/** Service is only a return value */
export type Service = {
  __typename?: "Service";
  autoscaleSettings?: Maybe<AutoscaleSettings>;
  created: Scalars["Time"];
  dataTieringSettings?: Maybe<DataTieringSettings>;
  /** service endpoints for the service */
  endpoints?: Maybe<ServiceEndpoints>;
  forkedFromId?: Maybe<ForkSpec>;
  id: Scalars["ID"];
  /** List of extensions that were pre-created on a service */
  initialExtensions?: Maybe<Array<Scalars["String"]>>;
  isReadOnly: Scalars["Boolean"];
  /** metadata contains service metadata e.g. environment (dev/prod) */
  metadata: ServiceMetadata;
  metrics?: Maybe<ResourceMetrics>;
  name: Scalars["String"];
  /** primaryOrdinal is the service ordinal that is currently serving as the master/primary in the pool. */
  primaryOrdinal?: Maybe<Scalars["Int"]>;
  projectId: Scalars["ID"];
  regionCode: Scalars["String"];
  /** replicaOrdinals are the service ordinal(s) that are serving the replica endpoint pool. */
  replicaOrdinals?: Maybe<Array<Scalars["Int"]>>;
  /** replicaStatus is the deploy status of the replica endpoint */
  replicaStatus?: Maybe<DeployStatus>;
  resources: Array<Resource>;
  spec: ServiceSpec;
  status: DeployStatus;
  /** syncReplicaOrdinals are the service ordinal(s) that are serving the sync replica endpoint */
  syncReplicaOrdinals?: Maybe<Array<Scalars["Int"]>>;
  type: Type;
  vpcEndpoint?: Maybe<Endpoint>;
  vpcId?: Maybe<Scalars["ID"]>;
};

/** A record of a service's CPU & RAM configuration. */
export type ServiceComputeConfig = {
  __typename?: "ServiceComputeConfig";
  created: Scalars["Time"];
  memoryGB: Scalars["Int"];
  milliCPU: Scalars["Int"];
  milliCPULimit?: Maybe<Scalars["Int"]>;
};

/** A record of a service's compute & storage configuration history. */
export type ServiceConfigHistory = {
  __typename?: "ServiceConfigHistory";
  compute: Array<ServiceComputeConfig>;
  storage: Array<ServiceStorageConfig>;
};

/** All cost information for a specific billing account for a specific service. */
export type ServiceCostInfo = {
  __typename?: "ServiceCostInfo";
  /** The cost-to-date for the current billing period, minus any trials or credits. */
  costToDate: Scalars["Float"];
  /** The cost-to-date for the current billing period. */
  costToDateGross: Scalars["Float"];
  /** The estimated total cost for the current billing period, minus any trials or credits. */
  estimatedTotalCost: Scalars["Float"];
  /** The estimated total cost for the current billing period. */
  estimatedTotalCostGross: Scalars["Float"];
  /** The id of the service we have information for */
  serviceID: Scalars["ID"];
};

/** Represents the service endpoints */
export type ServiceEndpoints = {
  __typename?: "ServiceEndpoints";
  pooler: EndpointAddress;
  primary: EndpointAddress;
  replica: EndpointAddress;
};

export type ServiceEntitlement = {
  __typename?: "ServiceEntitlement";
  enabled: Scalars["Boolean"];
  max_bandwidth_mb: Scalars["Int"];
  max_cpu: Scalars["Int"];
  max_cpu_milli: Scalars["Int"];
  max_instances: Scalars["Int"];
  max_iops: Scalars["Int"];
  max_memory: Scalars["Int"];
  max_memory_gb: Scalars["Int"];
  max_storage_gb: Scalars["Int"];
  min_bandwidth_mb: Scalars["Int"];
  min_iops: Scalars["Int"];
  unlimited: Scalars["Boolean"];
};

export enum ServiceEnvironment {
  Dev = "DEV",
  Prod = "PROD",
}

export type ServiceMetadata = {
  __typename?: "ServiceMetadata";
  environment: ServiceEnvironment;
};

export type ServiceNextMaintenance = {
  __typename?: "ServiceNextMaintenance";
  maintenance: NextMaintenance;
  serviceId: Scalars["ID"];
};

export type ServiceSpec = TimescaleDbServiceSpec;

/** A record of a service's storage configuration. */
export type ServiceStorageConfig = {
  __typename?: "ServiceStorageConfig";
  bandwidthMBs: Scalars["Int"];
  created: Scalars["Time"];
  iops: Scalars["Int"];
  storageGB: Scalars["Int"];
};

export type SetCompressionHypertableInput = {
  config: CompressionConfig;
  projectId: Scalars["ID"];
  regionCode?: InputMaybe<Scalars["String"]>;
  serviceId: Scalars["ID"];
  tableName: Scalars["ID"];
  tableSchema: Scalars["ID"];
};

export type SetCompressionInput = {
  config: CompressionConfig;
  contAggName?: InputMaybe<Scalars["ID"]>;
  projectId: Scalars["ID"];
  regionCode?: InputMaybe<Scalars["String"]>;
  schema: Scalars["ID"];
  serviceId: Scalars["ID"];
  tableName?: InputMaybe<Scalars["ID"]>;
};

export type SetHypertableConfInput = {
  chunkSizeIntervalDuration?: InputMaybe<Scalars["String"]>;
  projectId: Scalars["ID"];
  regionCode?: InputMaybe<Scalars["String"]>;
  schema: Scalars["ID"];
  serviceId: Scalars["ID"];
  tableName: Scalars["ID"];
};

export type SetMaintenanceWindowInput = {
  /** How long we're allowed to run maintenance */
  durationInSeconds: Scalars["Int"];
  /** ProjectID that the service. */
  projectId: Scalars["ID"];
  /** Service ID of the service. */
  serviceId: Scalars["ID"];
  /** Time to run maintenance, we'll run maintenance every week at the day/time defined */
  start: Scalars["Time"];
};

export type SetNumericParameter = {
  /** Parameter to update. */
  name: Scalars["String"];
  /** Unit of the value. */
  unit: ParameterUnit;
  /** Updated value for the parameter. */
  value: Scalars["Float"];
};

/** Set postgres numeric parameters for an instance. */
export type SetPostgresParametersInput = {
  /** Numeric parameters to configure. */
  numericParameters?: InputMaybe<Array<SetNumericParameter>>;
  /** ProjectID that the service belongs to. */
  projectId: Scalars["ID"];
  /** Service ID of the service in question. */
  serviceId: Scalars["ID"];
  /** String parameters to configure. */
  stringParameters?: InputMaybe<Array<SetStringParameter>>;
};

export type SetPrimaryPaymentMethodInput = {
  billingAccountId: Scalars["ID"];
  paymentMethodId: Scalars["ID"];
};

/** Sets the replica count for a single node instance */
export type SetReplicaCountInput = {
  allReplicasAreSync?: InputMaybe<Scalars["Boolean"]>;
  projectId: Scalars["ID"];
  replicaCount: Scalars["Int"];
  serviceId: Scalars["ID"];
  synchronousReplicaCount?: InputMaybe<Scalars["Int"]>;
};

export type SetServiceEnvironmentTagInput = {
  /** The env value to tag the DB instance with */
  environment: ServiceEnvironment;
  /** ProjectID that the service belongs to. */
  projectId: Scalars["ID"];
  /** Service ID of the service. */
  serviceId: Scalars["ID"];
};

export type SetStringParameter = {
  /** Parameter to update. */
  name: Scalars["String"];
  /** Updated value for the parameter. */
  value: Scalars["String"];
};

export type SetVirtualPlanForProjectRequest = {
  projectId: Scalars["ID"];
  virtualPlanKind: VirtualPlanType;
};

export type SingleRecommendationInput = {
  projectId: Scalars["ID"];
  recommendationId: Scalars["ID"];
  serviceId: Scalars["ID"];
};

export type SpaceDimension = {
  __typename?: "SpaceDimension";
  columnName: Scalars["String"];
  numPartitions: Scalars["Int"];
};

export enum Status {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}

export enum StorageState {
  Applied = "APPLIED",
  InitialApplied = "INITIAL_APPLIED",
  Pending = "PENDING",
  Queued = "QUEUED",
  Unknown = "UNKNOWN",
}

export type StringParameter = {
  __typename?: "StringParameter";
  allowed_values: Array<Scalars["String"]>;
  current_value: Scalars["String"];
  info: ParameterInfo;
};

export type SupportEmailInput = {
  /** Email destinations. If empty, uses `support@timescale.com`. */
  destinations?: InputMaybe<Array<Scalars["String"]>>;
  /** User's email. */
  email: Scalars["String"];
  /** Message as supplied by the user. */
  formBody: Scalars["String"];
  /** User's name. */
  name: Scalars["String"];
  /** Project ID that the service belongs to. */
  projectId: Scalars["ID"];
  /** Service ID of the service in question. */
  serviceId?: InputMaybe<Scalars["ID"]>;
  /** Subject as supplied by the user. */
  subject: Scalars["String"];
  /** User's ID. */
  userId: Scalars["ID"];
};

/** Service related information useful for support */
export type SupportServiceInfoInput = {
  regionCode?: InputMaybe<Scalars["String"]>;
  serviceId: Scalars["ID"];
};

export type SupportTicketInput = {
  /** Message as supplied by the user. */
  description: Scalars["String"];
  /** User's email. */
  email: Scalars["String"];
  /** Impact one-liner description */
  impact: Scalars["String"];
  /** Production service(s) are affected. */
  isProduction: Scalars["Boolean"];
  /** User's name. */
  name: Scalars["String"];
  /** Project ID that the ticket relates to. */
  projectId: Scalars["ID"];
  /** Any services relates to. */
  services?: InputMaybe<Array<SupportServiceInfoInput>>;
  /** Subject as supplied by the user. */
  subject: Scalars["String"];
  /** User's ID. */
  userId: Scalars["ID"];
};

/** Switchover instance input. */
export type SwitchoverInstanceInput = {
  /** Project ID that the service belongs to. */
  projectId: Scalars["ID"];
  /** Service ID of the service in question. */
  serviceId: Scalars["ID"];
  /** Time to wait before starting the operation. */
  waitTimeInSeconds?: InputMaybe<Scalars["Int"]>;
};

export type Table = {
  __typename?: "Table";
  chunks: Array<Chunk>;
  contAggs: Array<ContAgg>;
  foreignKeys: Array<ForeignKey>;
  hyperMetadata?: Maybe<HyperMetadata>;
  indexes: Array<Index>;
  metadata: TableMetadata;
  policies: Array<Policy>;
  schema: Array<Column>;
};

/** TableID represents an identifier for a table in a user's TimescaleDB instance. */
export type TableId = {
  __typename?: "TableID";
  isHypertable: Scalars["Boolean"];
  name: Scalars["ID"];
  schema: Scalars["ID"];
};

export type TableMetadata = {
  __typename?: "TableMetadata";
  approxRowCount: Scalars["Int"];
  id: TableId;
  size: Scalars["Int"];
};

export type TieredStorageEntitlement = {
  __typename?: "TieredStorageEntitlement";
  enabled: Scalars["Boolean"];
};

export type TieringUsage = {
  __typename?: "TieringUsage";
  serviceIds?: Maybe<Array<Scalars["ID"]>>;
  tieredBytes: Scalars["Int"];
};

export type TimeDimension = {
  __typename?: "TimeDimension";
  columnName: Scalars["String"];
  integerInterval?: Maybe<Scalars["Int"]>;
  timeInterval?: Maybe<Scalars["String"]>;
};

export type TimescaleDbServiceSpec = {
  __typename?: "TimescaleDBServiceSpec";
  IOBoostEnabled: Scalars["Boolean"];
  connectionPoolerEnabled: Scalars["Boolean"];
  defaultDBName: Scalars["String"];
  exporterID?: Maybe<Scalars["ID"]>;
  exporterSidecarEnabled: Scalars["Boolean"];
  genericExporterID?: Maybe<Scalars["ID"]>;
  /** @deprecated service.Endpoints contains this field */
  hostname: Scalars["String"];
  /** @deprecated service.Endpoints contains this field */
  poolerHostName?: Maybe<Scalars["String"]>;
  /** @deprecated service.Endpoints contains this field */
  poolerPort?: Maybe<Scalars["Int"]>;
  popSQLIntegrationEnabled: Scalars["Boolean"];
  /** @deprecated service.Endpoints contains this field */
  port: Scalars["Int"];
  /** @deprecated service.Endpoints contains this field */
  replicaHostName?: Maybe<Scalars["String"]>;
  /** @deprecated service.Endpoints contains this field */
  replicaPort?: Maybe<Scalars["Int"]>;
  status: Status;
  username: Scalars["String"];
};

export type ToggleConnectionPoolerInput = {
  /** Enable or disable connection pooler. */
  enable: Scalars["Boolean"];
  /** ProjectID that the service belongs to. */
  projectId: Scalars["ID"];
  /** Service ID of the service. */
  serviceId: Scalars["ID"];
};

export type ToggleDataTieringInput = {
  /** Enable or disable data tiering. */
  enable: Scalars["Boolean"];
  /** ProjectID that the service belongs to. */
  projectId: Scalars["ID"];
  /** Service ID of the service. */
  serviceId: Scalars["ID"];
};

export type ToggleIoBoostInput = {
  /** Enable or disable IOBoost. */
  enable: Scalars["Boolean"];
  /** ProjectID that the service belongs to. */
  projectId: Scalars["ID"];
  /** Service ID of the service. */
  serviceId: Scalars["ID"];
};

export type TogglePopSqlIntegrationInput = {
  /** Enable or disable PopSQL integration. */
  enable: Scalars["Boolean"];
  /** ProjectID that the service belongs to. */
  projectId: Scalars["ID"];
  /** Service ID of the service. */
  serviceId: Scalars["ID"];
};

export type ToggleServiceInput = {
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
  status: Status;
};

export type TransferProjectOwnershipInput = {
  mfaCode: Scalars["String"];
  newOwnerId: Scalars["ID"];
  password: Scalars["String"];
  projectId: Scalars["ID"];
};

export enum Type {
  Grafana = "GRAFANA",
  Postgres = "POSTGRES",
  Timescaledb = "TIMESCALEDB",
  Vector = "VECTOR",
}

export type UpdateGenericExporterInput = {
  config: GenericExporterConfigInput;
  exporterId: Scalars["String"];
  name: Scalars["String"];
  projectId: Scalars["ID"];
};

export type UpdateMetricExporterInput = {
  config: MetricExporterConfigInput;
  exporterId?: InputMaybe<Scalars["ID"]>;
  exporterUuid?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  projectId: Scalars["ID"];
};

export type UpdateProjectSecretInput = {
  name: Scalars["String"];
  projectId: Scalars["ID"];
  value: Scalars["String"];
};

export type UpdateTaxIdInput = {
  billingAccountId: Scalars["ID"];
  country: Scalars["String"];
  taxType?: InputMaybe<Scalars["String"]>;
  value: Scalars["String"];
};

export type UpdateUserInput = {
  name?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  uiState?: InputMaybe<Scalars["String"]>;
};

export type UpdateUserPersonaInput = {
  /** JSON string that models the UI State of the persona. */
  uiState: Scalars["String"];
  /** The ID of the user. */
  user_id: Scalars["ID"];
};

/** Initiate a major PostgreSQL Upgrade */
export type UpgradePostgresMajorVersionInput = {
  /** The major version to upgrade PostgreSQL to. */
  postgresVersion: Scalars["Int"];
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
};

export type User = {
  __typename?: "User";
  created?: Maybe<Scalars["Time"]>;
  email: Scalars["String"];
  id: Scalars["ID"];
  /** If functionality for username/password users should be hidden */
  managedByIdp: Scalars["Boolean"];
  /** Why managedByIdp is true */
  managedByIdpReason?: Maybe<ManagedByIdpReason>;
  mfaMethods?: Maybe<Array<MfaMethod>>;
  name: Scalars["String"];
  /** User origin */
  origin?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  /** JSON string that models the UI State of the persona. */
  uiState?: Maybe<Scalars["String"]>;
};

export type UserPersona = {
  __typename?: "UserPersona";
  /** JSON string that models the UI State of the persona. */
  uiState: Scalars["String"];
  /** The ID of the user. */
  user_id: Scalars["ID"];
};

/** The shell VPC that we allocate for peering, *not* the user's personal VPC. */
export type Vpc = {
  __typename?: "VPC";
  /** The CIDR block of the VPC. */
  cidr: Scalars["String"];
  /** The cloud provider that the VPC is hosted on. */
  cloudProvider: CloudProvider;
  /** Time at which the shell VPC is allocated on the cloud provider. */
  created?: Maybe<Scalars["Time"]>;
  /** Indicates an error with the VPC. */
  errorMessage?: Maybe<Scalars["String"]>;
  /** The internal table ID of the VPC row. */
  id: Scalars["ID"];
  /** The name of the shell VPC. */
  name: Scalars["String"];
  /** Any peering connections associated with the shell VPC. */
  peeringConnections: Array<PeeringConnection>;
  /** The project ID the VPC is associated with. */
  projectId: Scalars["ID"];
  /** The provisioned ID of the VPC as given by the cloud provider. */
  provisionedId: Scalars["ID"];
  /** The region that the VPC is allocated in. */
  regionCode: Scalars["String"];
  /** The current status of the VPC. */
  status: VpcStatus;
  /** Most recent time that the user has updated a property of the VPC. */
  updated?: Maybe<Scalars["Time"]>;
};

export type VpcAttachmentEntitlement = {
  __typename?: "VPCAttachmentEntitlement";
  attachment_type: VpcAttachmentType;
  enabled: Scalars["Boolean"];
  max_instances: Scalars["Int"];
};

export enum VpcAttachmentType {
  Multi = "MULTI",
  None = "NONE",
  Single = "SINGLE",
}

/** Enum that represents the status of a VPC. */
export enum VpcStatus {
  Created = "CREATED",
  Creating = "CREATING",
  Deleted = "DELETED",
  Deleting = "DELETING",
  Error = "ERROR",
  Unknown = "UNKNOWN",
}

export type ValidateResetTokenMfaResponse = {
  __typename?: "ValidateResetTokenMfaResponse";
  message: Scalars["String"];
  mfaMethods: Array<MfaMethod>;
};

export enum VectorInvocationState {
  /** The vectorizer is not running nor scheduled. */
  None = "NONE",
  /** The vectorizer is currently running. */
  Running = "RUNNING",
  /** The vectorizer is currently scheduling. */
  Scheduling = "SCHEDULING",
  /** The vectorizer is currently in an unknown state. */
  Unspecified = "UNSPECIFIED",
}

export type Vectorizer = {
  __typename?: "Vectorizer";
  /** Unique identifier for the vectorizer on that specific project and service. */
  id: Scalars["ID"];
  /** Identifier for the last completed invocation. */
  lastInvocationCompletedId?: Maybe<Scalars["String"]>;
  /** Identifier for the last failed invocation. */
  lastInvocationFailureId?: Maybe<Scalars["String"]>;
  /** Timestamp of the last invocation failure. */
  lastInvocationFailureTimestamp?: Maybe<Scalars["Time"]>;
  /** Number of pending entries to be vectorized. */
  pendingItems: Scalars["Int"];
  /** Source table for the embeddings. */
  source: Scalars["String"];
  /** Status of the vectorizer invocation. */
  state: VectorInvocationState;
  /** Target table for the embeddings. */
  target: Scalars["String"];
  /** View where source and embeddings can be found. */
  view: Scalars["String"];
};

export type VerifyBillingEmailAddressInput = {
  billingEmailId: Scalars["ID"];
  hash: Scalars["String"];
};

export type VerifyEmailInput = {
  email: Scalars["String"];
  hash: Scalars["String"];
};

export type VerifyEmailOutput = {
  __typename?: "VerifyEmailOutput";
  status: Scalars["String"];
  user?: Maybe<User>;
  userID: Scalars["ID"];
  userToken?: Maybe<Scalars["String"]>;
};

export type VersionInfo = {
  __typename?: "VersionInfo";
  available: Array<Scalars["String"]>;
  installed: Scalars["String"];
  name: Scalars["String"];
};

export type VirtualPlan = {
  __typename?: "VirtualPlan";
  kind: VirtualPlanType;
};

export enum VirtualPlanType {
  Enterprise = "ENTERPRISE",
  Performance = "PERFORMANCE",
  Scale = "SCALE",
  Unspecified = "UNSPECIFIED",
}

export type CreateProjectMutationVariables = Exact<{
  name: Scalars["String"];
  trialDays?: InputMaybe<Scalars["Int"]>;
}>;

export type CreateProjectMutation = {
  __typename?: "Mutation";
  createProject: { __typename?: "Project"; id: string; name: string };
};

export type JoinProjectMutationVariables = Exact<{
  inviteId: Scalars["ID"];
  hash: Scalars["String"];
}>;

export type JoinProjectMutation = {
  __typename?: "Mutation";
  joinProject: string;
};

export type DeclineProjectInviteMutationVariables = Exact<{
  inviteId: Scalars["ID"];
}>;

export type DeclineProjectInviteMutation = {
  __typename?: "Mutation";
  declineProjectInvite: any;
};

export type LeaveProjectMutationVariables = Exact<{
  projectId: Scalars["ID"];
}>;

export type LeaveProjectMutation = {
  __typename?: "Mutation";
  leaveProject: any;
};

export type CancelProjectInviteMutationVariables = Exact<{
  inviteId: Scalars["ID"];
}>;

export type CancelProjectInviteMutation = {
  __typename?: "Mutation";
  cancelProjectInvite: any;
};

export type RevokeProjectMemberMutationVariables = Exact<{
  projectId: Scalars["ID"];
  userId: Scalars["ID"];
}>;

export type RevokeProjectMemberMutation = {
  __typename?: "Mutation";
  revokeProjectMember: any;
};

export type InviteProjectMemberMutationVariables = Exact<{
  projectId: Scalars["ID"];
  email: Scalars["String"];
}>;

export type InviteProjectMemberMutation = {
  __typename?: "Mutation";
  inviteProjectMember: any;
};

export type ResendInviteProjectMemberMutationVariables = Exact<{
  inviteId: Scalars["ID"];
}>;

export type ResendInviteProjectMemberMutation = {
  __typename?: "Mutation";
  resendInviteProjectMember: any;
};

export type CreateServiceMutationVariables = Exact<{
  projectId: Scalars["ID"];
  name: Scalars["String"];
  type: Type;
  billingTags?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  resourceConfig?: InputMaybe<ResourceConfig>;
  vpcId?: InputMaybe<Scalars["ID"]>;
  enableConnectionPooler?: InputMaybe<Scalars["Boolean"]>;
  regionCode: Scalars["String"];
  forkConfig?: InputMaybe<ForkConfig>;
  demoID?: InputMaybe<Scalars["Int"]>;
  environmentTag?: InputMaybe<ServiceEnvironment>;
  extensions?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>
  >;
}>;

export type CreateServiceMutation = {
  __typename?: "Mutation";
  createService: {
    __typename?: "CreateServiceOutput";
    initialPassword: string;
    service: {
      __typename?: "Service";
      name: string;
      id: string;
      projectId: string;
      type: Type;
      isReadOnly: boolean;
      regionCode: string;
      status: DeployStatus;
      vpcId?: string | null;
      primaryOrdinal?: number | null;
      replicaOrdinals?: Array<number> | null;
      replicaStatus?: DeployStatus | null;
      created: any;
      spec: {
        __typename?: "TimescaleDBServiceSpec";
        hostname: string;
        username: string;
        port: number;
        defaultDBName: string;
        connectionPoolerEnabled: boolean;
        poolerHostName?: string | null;
        poolerPort?: number | null;
      };
      resources: Array<{
        __typename?: "Resource";
        id: string;
        spec: {
          __typename?: "ResourceNode";
          milliCPU: number;
          memoryGB: number;
          storageGB: number;
          replicaCount: number;
          syncReplicaCount: number;
        };
      }>;
      vpcEndpoint?: {
        __typename?: "Endpoint";
        projectId: string;
        serviceId: string;
        vpcId: string;
        host: string;
        port: number;
      } | null;
      metrics?: {
        __typename?: "ResourceMetrics";
        memoryMB?: number | null;
        storageMB?: number | null;
        milliCPU?: number | null;
      } | null;
      forkedFromId?: {
        __typename?: "ForkSpec";
        serviceId: string;
        projectId: string;
      } | null;
    };
  };
};

export type CreateServiceForkMutationVariables = Exact<{
  projectId: Scalars["ID"];
  name: Scalars["String"];
  type: Type;
  resourceConfig?: InputMaybe<ResourceConfig>;
  vpcId?: InputMaybe<Scalars["ID"]>;
  enableConnectionPooler?: InputMaybe<Scalars["Boolean"]>;
  regionCode: Scalars["String"];
  forkConfig: ForkConfig;
  environmentTag?: InputMaybe<ServiceEnvironment>;
}>;

export type CreateServiceForkMutation = {
  __typename?: "Mutation";
  createService: {
    __typename?: "CreateServiceOutput";
    initialPassword: string;
    service: {
      __typename?: "Service";
      name: string;
      id: string;
      projectId: string;
      type: Type;
      isReadOnly: boolean;
      regionCode: string;
      status: DeployStatus;
      vpcId?: string | null;
      created: any;
      spec: {
        __typename?: "TimescaleDBServiceSpec";
        hostname: string;
        username: string;
        port: number;
        defaultDBName: string;
        connectionPoolerEnabled: boolean;
        poolerHostName?: string | null;
        poolerPort?: number | null;
      };
      resources: Array<{
        __typename?: "Resource";
        id: string;
        spec: {
          __typename?: "ResourceNode";
          milliCPU: number;
          memoryGB: number;
          storageGB: number;
          replicaCount: number;
        };
      }>;
      vpcEndpoint?: {
        __typename?: "Endpoint";
        projectId: string;
        serviceId: string;
        vpcId: string;
        host: string;
        port: number;
      } | null;
      metrics?: {
        __typename?: "ResourceMetrics";
        memoryMB?: number | null;
        storageMB?: number | null;
        milliCPU?: number | null;
      } | null;
      forkedFromId?: {
        __typename?: "ForkSpec";
        serviceId: string;
        projectId: string;
        isStandby?: boolean | null;
      } | null;
    };
  };
};

export type SetReplicaCountMutationVariables = Exact<{
  serviceId: Scalars["ID"];
  projectId: Scalars["ID"];
  replicaCount: Scalars["Int"];
  synchronousReplicaCount?: InputMaybe<Scalars["Int"]>;
  allReplicasAreSync?: InputMaybe<Scalars["Boolean"]>;
}>;

export type SetReplicaCountMutation = {
  __typename?: "Mutation";
  setReplicaCount: any;
};

export type UpgradePostgresMajorVersionMutationVariables = Exact<{
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
  postgresVersion: Scalars["Int"];
}>;

export type UpgradePostgresMajorVersionMutation = {
  __typename?: "Mutation";
  upgradePostgresMajorVersion: any;
};

export type CreateUserMutationVariables = Exact<{
  name: Scalars["String"];
  email: Scalars["String"];
  password: Scalars["String"];
  passwordConfirm: Scalars["String"];
  recaptchaToken: Scalars["String"];
  inviteHash?: InputMaybe<Scalars["String"]>;
}>;

export type CreateUserMutation = {
  __typename?: "Mutation";
  createUser: {
    __typename?: "User";
    name: string;
    email: string;
    id: string;
    created?: any | null;
  };
};

export type SetServiceEnvironmentTagMutationVariables = Exact<{
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
  environment: ServiceEnvironment;
}>;

export type SetServiceEnvironmentTagMutation = {
  __typename?: "Mutation";
  setServiceEnvironmentTag: any;
};

export type CreatePaymentMethodMutationVariables = Exact<{
  billingAccountId: Scalars["ID"];
  isPrimary: Scalars["Boolean"];
}>;

export type CreatePaymentMethodMutation = {
  __typename?: "Mutation";
  createPaymentMethod: string;
};

export type UpdatePaymentMethodMutationVariables = Exact<{
  billingAccountId: Scalars["ID"];
  paymentMethodId: Scalars["ID"];
  addressInfo?: InputMaybe<AddressInfoInput>;
  cardExp: CardExpInput;
}>;

export type UpdatePaymentMethodMutation = {
  __typename?: "Mutation";
  updatePaymentMethod: {
    __typename?: "PaymentMethod";
    id: string;
    isPrimary?: boolean | null;
    cardInfo: { __typename?: "CardInfo"; last4: string };
    addressInfo?: {
      __typename?: "AddressInfo";
      city: string;
      state: string;
      country: string;
      line1: string;
      line2: string;
      postalCode: string;
    } | null;
  };
};

export type DeletePaymentMethodMutationVariables = Exact<{
  billingAccountId: Scalars["ID"];
  paymentMethodId: Scalars["ID"];
}>;

export type DeletePaymentMethodMutation = {
  __typename?: "Mutation";
  deletePaymentMethod: { __typename?: "PaymentMethod"; id: string };
};

export type SetPrimaryPaymentMethodMutationVariables = Exact<{
  billingAccountId: Scalars["ID"];
  paymentMethodId: Scalars["ID"];
}>;

export type SetPrimaryPaymentMethodMutation = {
  __typename?: "Mutation";
  setPrimaryPaymentMethod: string;
};

export type CreateBillingEmailAddressMutationVariables = Exact<{
  email: Scalars["String"];
  billingAccountId: Scalars["ID"];
}>;

export type CreateBillingEmailAddressMutation = {
  __typename?: "Mutation";
  createBillingEmailAddress: {
    __typename?: "BillingEmailAddress";
    id: string;
    email: string;
    billingAccountId: string;
    created: any;
    activated?: any | null;
  };
};

export type DeleteBillingEmailAddressMutationVariables = Exact<{
  billingEmailId: Scalars["ID"];
  billingAccountId: Scalars["ID"];
}>;

export type DeleteBillingEmailAddressMutation = {
  __typename?: "Mutation";
  deleteBillingEmailAddress: any;
};

export type VerifyBillingEmailAddressMutationVariables = Exact<{
  billingEmailId: Scalars["ID"];
  hash: Scalars["String"];
}>;

export type VerifyBillingEmailAddressMutation = {
  __typename?: "Mutation";
  verifyBillingEmailAddress: string;
};

export type ResendVerifyBillingEmailAddressMutationVariables = Exact<{
  billingEmailId: Scalars["ID"];
  billingAccountId: Scalars["ID"];
}>;

export type ResendVerifyBillingEmailAddressMutation = {
  __typename?: "Mutation";
  resendVerifyBillingEmailAddress: string;
};

export type CancelPendingPaymentDeletionMutationVariables = Exact<{
  billingAccountId: Scalars["ID"];
  paymentMethodId: Scalars["ID"];
}>;

export type CancelPendingPaymentDeletionMutation = {
  __typename?: "Mutation";
  cancelPendingPaymentDeletion: any;
};

export type ChangePasswordMutationVariables = Exact<{
  oldPassword: Scalars["String"];
  newPassword: Scalars["String"];
  newPasswordConfirm: Scalars["String"];
  trustChallenge?: InputMaybe<Scalars["String"]>;
  trustToken?: InputMaybe<Scalars["String"]>;
}>;

export type ChangePasswordMutation = {
  __typename?: "Mutation";
  changePassword: string;
};

export type ResetPasswordMutationVariables = Exact<{
  password: Scalars["String"];
  passwordConfirm: Scalars["String"];
  token: Scalars["String"];
  trustChallenge?: InputMaybe<Scalars["String"]>;
  trustToken?: InputMaybe<Scalars["String"]>;
}>;

export type ResetPasswordMutation = {
  __typename?: "Mutation";
  resetPassword: string;
};

export type ResetServicePasswordMutationVariables = Exact<{
  serviceId: Scalars["ID"];
  projectId: Scalars["ID"];
  password: Scalars["String"];
  passwordType: PasswordType;
}>;

export type ResetServicePasswordMutation = {
  __typename?: "Mutation";
  resetServicePassword: string;
};

export type SendPasswordResetMutationVariables = Exact<{
  email: Scalars["String"];
}>;

export type SendPasswordResetMutation = {
  __typename?: "Mutation";
  sendPasswordReset: string;
};

export type UpdateUserMutationVariables = Exact<{
  name: Scalars["String"];
  phone?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateUserMutation = {
  __typename?: "Mutation";
  updateUser: {
    __typename?: "User";
    id: string;
    name: string;
    email: string;
    phone?: string | null;
    created?: any | null;
    origin?: string | null;
  };
};

export type VerifyEmailMutationVariables = Exact<{
  email: Scalars["String"];
  hash: Scalars["String"];
}>;

export type VerifyEmailMutation = {
  __typename?: "Mutation";
  verifyEmail: {
    __typename?: "VerifyEmailOutput";
    status: string;
    userID: string;
    userToken?: string | null;
    user?: {
      __typename?: "User";
      id: string;
      name: string;
      email: string;
      phone?: string | null;
      created?: any | null;
      managedByIdp: boolean;
    } | null;
  };
};

export type ResendVerifyEmailMutationVariables = Exact<{
  email: Scalars["String"];
}>;

export type ResendVerifyEmailMutation = {
  __typename?: "Mutation";
  resendVerifyEmail: string;
};

export type DeleteServiceMutationVariables = Exact<{
  serviceId: Scalars["ID"];
  projectId: Scalars["ID"];
}>;

export type DeleteServiceMutation = {
  __typename?: "Mutation";
  deleteService: { __typename?: "Service"; id: string; name: string };
};

export type ToggleServiceMutationVariables = Exact<{
  serviceId: Scalars["ID"];
  projectId: Scalars["ID"];
  status: Status;
}>;

export type ToggleServiceMutation = {
  __typename?: "Mutation";
  toggleService: {
    __typename?: "Service";
    id: string;
    name: string;
    spec: { __typename?: "TimescaleDBServiceSpec"; status: Status };
  };
};

export type TogglePopSqlIntegrationMutationVariables = Exact<{
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
  enable: Scalars["Boolean"];
}>;

export type TogglePopSqlIntegrationMutation = {
  __typename?: "Mutation";
  togglePopSQLIntegration: any;
};

export type ResizeInstanceMutationVariables = Exact<{
  serviceId: Scalars["ID"];
  projectId: Scalars["ID"];
  config: ResourceConfig;
}>;

export type ResizeInstanceMutation = {
  __typename?: "Mutation";
  resizeInstance: any;
};

export type SendSupportEmailMutationVariables = Exact<{
  serviceId: Scalars["ID"];
  projectId: Scalars["ID"];
  subject: Scalars["String"];
  formBody: Scalars["String"];
  email: Scalars["String"];
  userId: Scalars["ID"];
  name: Scalars["String"];
  destinations?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type SendSupportEmailMutation = {
  __typename?: "Mutation";
  sendSupportEmail: any;
};

export type SetAddressInfoMutationVariables = Exact<{
  billingAccountId: Scalars["ID"];
  companyAddress: AddressInfoInput;
}>;

export type SetAddressInfoMutation = {
  __typename?: "Mutation";
  setAddressInfo: {
    __typename?: "AddressDetails";
    companyAddress?: {
      __typename?: "AddressInfo";
      companyName?: string | null;
      line1: string;
      line2: string;
      city: string;
      state: string;
      country: string;
      postalCode: string;
    } | null;
  };
};

export type SetPostgresParametersMutationVariables = Exact<{
  serviceId: Scalars["ID"];
  projectId: Scalars["ID"];
  numericParameters?: InputMaybe<
    Array<SetNumericParameter> | SetNumericParameter
  >;
  stringParameters?: InputMaybe<Array<SetStringParameter> | SetStringParameter>;
}>;

export type SetPostgresParametersMutation = {
  __typename?: "Mutation";
  setPostgresParameters: Array<{
    __typename?: "ParamErrorMessages";
    name: string;
    errorMessage: string;
  }>;
};

export type ResetPostgresParametersMutationVariables = Exact<{
  serviceId: Scalars["ID"];
  projectId: Scalars["ID"];
}>;

export type ResetPostgresParametersMutation = {
  __typename?: "Mutation";
  resetPostgresParameters: any;
};

export type ChangeMaintenanceWindowMutationVariables = Exact<{
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
  start: Scalars["Time"];
  durationInSeconds: Scalars["Int"];
}>;

export type ChangeMaintenanceWindowMutation = {
  __typename?: "Mutation";
  setMaintenanceWindow: any;
};

export type CreateUserPersonaMutationVariables = Exact<{
  user_id: Scalars["ID"];
  uiState: Scalars["String"];
}>;

export type CreateUserPersonaMutation = {
  __typename?: "Mutation";
  createUserPersona: {
    __typename?: "UserPersona";
    user_id: string;
    uiState: string;
  };
};

export type UpdateUserPersonaMutationVariables = Exact<{
  user_id: Scalars["ID"];
  uiState: Scalars["String"];
}>;

export type UpdateUserPersonaMutation = {
  __typename?: "Mutation";
  updateUserPersona: {
    __typename?: "UserPersona";
    user_id: string;
    uiState: string;
  };
};

export type CreateVpcMutationVariables = Exact<{
  projectId: Scalars["ID"];
  cidr: Scalars["String"];
  cloudProvider: CloudProvider;
  name: Scalars["String"];
  regionCode: Scalars["String"];
}>;

export type CreateVpcMutation = {
  __typename?: "Mutation";
  createVpc: { __typename?: "VPC"; id: string };
};

export type RenameVpcMutationVariables = Exact<{
  projectId: Scalars["ID"];
  forgeVpcId: Scalars["ID"];
  newName: Scalars["String"];
}>;

export type RenameVpcMutation = { __typename?: "Mutation"; renameVpc: any };

export type RenameServiceMutationVariables = Exact<{
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
  newName: Scalars["String"];
}>;

export type RenameServiceMutation = {
  __typename?: "Mutation";
  renameService: any;
};

export type RenameProjectMutationVariables = Exact<{
  projectId: Scalars["ID"];
  name: Scalars["String"];
}>;

export type RenameProjectMutation = {
  __typename?: "Mutation";
  renameProject: { __typename?: "Project"; id: string; name: string };
};

export type OpenPeerRequestMutationVariables = Exact<{
  projectId: Scalars["ID"];
  forgeVpcId: Scalars["ID"];
  cloudProvider: CloudProvider;
  peerVpc: InputPeerVpc;
}>;

export type OpenPeerRequestMutation = {
  __typename?: "Mutation";
  openPeerRequest: any;
};

export type DeletePeeringConnectionMutationVariables = Exact<{
  projectId: Scalars["ID"];
  vpcId: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type DeletePeeringConnectionMutation = {
  __typename?: "Mutation";
  deletePeeringConnection: any;
};

export type AttachServiceToVpcMutationVariables = Exact<{
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
  vpcId: Scalars["ID"];
}>;

export type AttachServiceToVpcMutation = {
  __typename?: "Mutation";
  attachServiceToVpc: any;
};

export type DetachServiceFromVpcMutationVariables = Exact<{
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
  vpcId: Scalars["ID"];
}>;

export type DetachServiceFromVpcMutation = {
  __typename?: "Mutation";
  detachServiceFromVpc: any;
};

export type DeleteVpcMutationVariables = Exact<{
  projectId: Scalars["ID"];
  vpcId: Scalars["ID"];
}>;

export type DeleteVpcMutation = { __typename?: "Mutation"; deleteVpc: any };

export type CreateDemoServiceMutationVariables = Exact<{
  projectId: Scalars["ID"];
  name: Scalars["String"];
  type: Type;
  resourceConfig: ResourceConfig;
  demoID?: InputMaybe<Scalars["Int"]>;
  regionCode?: InputMaybe<Scalars["String"]>;
}>;

export type CreateDemoServiceMutation = {
  __typename?: "Mutation";
  createService: {
    __typename?: "CreateServiceOutput";
    service: {
      __typename?: "Service";
      id: string;
      projectId: string;
      name: string;
      type: Type;
      status: DeployStatus;
      created: any;
    };
  };
};

export type CreateMetricExporterMutationVariables = Exact<{
  projectId: Scalars["ID"];
  name: Scalars["String"];
  config: MetricExporterConfigInput;
  regionCode: Scalars["String"];
}>;

export type CreateMetricExporterMutation = {
  __typename?: "Mutation";
  createMetricExporter: {
    __typename?: "MetricExporter";
    id: string;
    projectId: string;
    created: any;
    name: string;
    type: MetricExporterType;
    config:
      | {
          __typename?: "CloudWatchMetricConfig";
          logGroupName: string;
          logStreamName: string;
          namespace: string;
          awsRegion: string;
          awsRoleArn?: string | null;
        }
      | {
          __typename?: "DatadogMetricConfig";
          apiKey: string;
          site?: string | null;
        }
      | { __typename?: "PrometheusMetricConfig"; foo: string };
  };
};

export type UpdateMetricExporterMutationVariables = Exact<{
  projectId: Scalars["ID"];
  name: Scalars["String"];
  config: MetricExporterConfigInput;
  exporterId: Scalars["ID"];
}>;

export type UpdateMetricExporterMutation = {
  __typename?: "Mutation";
  updateMetricExporter: any;
};

export type DeleteMetricExporterMutationVariables = Exact<{
  projectId: Scalars["ID"];
  exporterId: Scalars["ID"];
}>;

export type DeleteMetricExporterMutation = {
  __typename?: "Mutation";
  deleteMetricExporter: any;
};

export type AttachServiceToMetricExporterMutationVariables = Exact<{
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
  exporterId: Scalars["ID"];
}>;

export type AttachServiceToMetricExporterMutation = {
  __typename?: "Mutation";
  attachServiceToMetricExporter: any;
};

export type DetachServiceFromMetricExporterMutationVariables = Exact<{
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
  exporterId: Scalars["ID"];
}>;

export type DetachServiceFromMetricExporterMutation = {
  __typename?: "Mutation";
  detachServiceFromMetricExporter: any;
};

export type CreateGenericExporterMutationVariables = Exact<{
  projectId: Scalars["ID"];
  name: Scalars["String"];
  region: Scalars["String"];
  type: GenericExporterType;
  dataType: GenericExporterDataType;
  config: GenericExporterConfigInput;
}>;

export type CreateGenericExporterMutation = {
  __typename?: "Mutation";
  createGenericExporter: {
    __typename?: "GenericExporter";
    id: string;
    projectId: string;
    regionCode: string;
    created: any;
    name: string;
    type: GenericExporterType;
  };
};

export type UpdateGenericExporterMutationVariables = Exact<{
  projectId: Scalars["ID"];
  exporterId: Scalars["String"];
  name: Scalars["String"];
  config: GenericExporterConfigInput;
}>;

export type UpdateGenericExporterMutation = {
  __typename?: "Mutation";
  updateGenericExporter: any;
};

export type DeleteGenericExporterMutationVariables = Exact<{
  projectId: Scalars["ID"];
  exporterId: Scalars["String"];
}>;

export type DeleteGenericExporterMutation = {
  __typename?: "Mutation";
  deleteGenericExporter: any;
};

export type AttachServiceToGenericExporterMutationVariables = Exact<{
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
  exporterId: Scalars["String"];
}>;

export type AttachServiceToGenericExporterMutation = {
  __typename?: "Mutation";
  attachServiceToGenericExporter: any;
};

export type DetachServiceFromGenericExporterMutationVariables = Exact<{
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
  exporterId: Scalars["String"];
}>;

export type DetachServiceFromGenericExporterMutation = {
  __typename?: "Mutation";
  detachServiceFromGenericExporter: any;
};

export type SetCompressionHypertableMutationVariables = Exact<{
  serviceId: Scalars["ID"];
  projectId: Scalars["ID"];
  regionCode: Scalars["String"];
  config: CompressionConfig;
  tableSchema: Scalars["ID"];
  tableName: Scalars["ID"];
}>;

export type SetCompressionHypertableMutation = {
  __typename?: "Mutation";
  setCompressionHypertable: any;
};

export type SetCompressionMutationVariables = Exact<{
  serviceId: Scalars["ID"];
  projectId: Scalars["ID"];
  regionCode: Scalars["String"];
  config: CompressionConfig;
  schema: Scalars["ID"];
  tableName?: InputMaybe<Scalars["ID"]>;
  contAggName?: InputMaybe<Scalars["ID"]>;
}>;

export type SetCompressionMutation = {
  __typename?: "Mutation";
  setCompression: any;
};

export type AddCompressionPolicyMutationVariables = Exact<{
  serviceId: Scalars["ID"];
  projectId: Scalars["ID"];
  regionCode: Scalars["String"];
  tableSchema: Scalars["ID"];
  tableName?: InputMaybe<Scalars["ID"]>;
  contAggName?: InputMaybe<Scalars["ID"]>;
  olderThan: Scalars["String"];
}>;

export type AddCompressionPolicyMutation = {
  __typename?: "Mutation";
  addCompressionPolicy: any;
};

export type DeleteCompressionPolicyMutationVariables = Exact<{
  serviceId: Scalars["ID"];
  projectId: Scalars["ID"];
  regionCode: Scalars["String"];
  tableSchema: Scalars["ID"];
  tableName: Scalars["ID"];
}>;

export type DeleteCompressionPolicyMutation = {
  __typename?: "Mutation";
  deleteCompressionPolicy: any;
};

export type AddMfaMethodMutationVariables = Exact<{
  code: Scalars["String"];
  secret: Scalars["String"];
}>;

export type AddMfaMethodMutation = {
  __typename?: "Mutation";
  addMfaMethod: {
    __typename?: "AddMfaMethodResponse";
    recoveryCodes?: Array<string> | null;
    method: { __typename?: "AuthenticatorMethod"; id: string };
  };
};

export type DeleteMfaMethodMutationVariables = Exact<{
  id: Scalars["ID"];
  code: Scalars["String"];
}>;

export type DeleteMfaMethodMutation = {
  __typename?: "Mutation";
  deleteMfaMethod: any;
};

export type RegenerateRecoveryCodesMutationVariables = Exact<{
  code: Scalars["String"];
}>;

export type RegenerateRecoveryCodesMutation = {
  __typename?: "Mutation";
  regenerateRecoveryCodes: Array<string>;
};

export type CreateSupportTicketMutationVariables = Exact<{
  projectId: Scalars["ID"];
  services?: InputMaybe<
    Array<SupportServiceInfoInput> | SupportServiceInfoInput
  >;
  subject: Scalars["String"];
  description: Scalars["String"];
  email: Scalars["String"];
  userId: Scalars["ID"];
  name: Scalars["String"];
  isProduction: Scalars["Boolean"];
  impact: Scalars["String"];
}>;

export type CreateSupportTicketMutation = {
  __typename?: "Mutation";
  createSupportTicket: any;
};

export type ToggleDataTieringInputMutationVariables = Exact<{
  serviceId: Scalars["ID"];
  projectId: Scalars["ID"];
  enable: Scalars["Boolean"];
}>;

export type ToggleDataTieringInputMutation = {
  __typename?: "Mutation";
  toggleDataTiering: any;
};

export type CreatePatRecordMutationVariables = Exact<{
  projectId: Scalars["ID"];
  name: Scalars["String"];
}>;

export type CreatePatRecordMutation = {
  __typename?: "Mutation";
  createPATRecord: {
    __typename?: "PATRecord";
    name: string;
    projectId: string;
    created: any;
    clientCredentials: {
      __typename?: "ClientCredentials";
      accessKey: string;
      secretKey?: string | null;
    };
  };
};

export type DeletePatRecordMutationVariables = Exact<{
  accessKey: Scalars["String"];
}>;

export type DeletePatRecordMutation = {
  __typename?: "Mutation";
  deletePATRecord: any;
};

export type RenamePatRecordMutationVariables = Exact<{
  accessKey: Scalars["String"];
  newName: Scalars["String"];
}>;

export type RenamePatRecordMutation = {
  __typename?: "Mutation";
  renamePATRecord: any;
};

export type SwitchoverInstanceMutationVariables = Exact<{
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
}>;

export type SwitchoverInstanceMutation = {
  __typename?: "Mutation";
  switchoverInstance: any;
};

export type ToggleConnectionPoolerMutationVariables = Exact<{
  serviceId: Scalars["ID"];
  projectId: Scalars["ID"];
  enable: Scalars["Boolean"];
}>;

export type ToggleConnectionPoolerMutation = {
  __typename?: "Mutation";
  toggleConnectionPooler: any;
};

export type ChargeInvoiceMutationVariables = Exact<{
  invoiceId: Scalars["ID"];
}>;

export type ChargeInvoiceMutation = {
  __typename?: "Mutation";
  chargeInvoice: any;
};

export type TransferProjectOwnershipMutationVariables = Exact<{
  projectId: Scalars["ID"];
  newOwnerId: Scalars["ID"];
  mfaCode: Scalars["String"];
  password: Scalars["String"];
}>;

export type TransferProjectOwnershipMutation = {
  __typename?: "Mutation";
  transferProjectOwnership: any;
};

export type NewFileImportMutationVariables = Exact<{
  serviceId: Scalars["ID"];
  projectId: Scalars["ID"];
}>;

export type NewFileImportMutation = {
  __typename?: "Mutation";
  newFileImport: {
    __typename?: "FileImport";
    id: string;
    state: ImportState;
    url?: string | null;
  };
};

export type ProcessFileImportMutationVariables = Exact<{
  parquet?: InputMaybe<ProcessParquetImportInput>;
  csv?: InputMaybe<ProcessCsvImportInput>;
}>;

export type ProcessFileImportMutation = {
  __typename?: "Mutation";
  processFileImport: {
    __typename?: "FileImport";
    id: string;
    state: ImportState;
  };
};

export type SetVirtualPlanForProjectMutationVariables = Exact<{
  projectId: Scalars["ID"];
  virtualPlanKind: VirtualPlanType;
}>;

export type SetVirtualPlanForProjectMutation = {
  __typename?: "Mutation";
  setVirtualPlanForProject: any;
};

export type SetHypertableConfMutationVariables = Exact<{
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
  schema: Scalars["ID"];
  tableName: Scalars["ID"];
  chunkSizeIntervalDuration: Scalars["String"];
}>;

export type SetHypertableConfMutation = {
  __typename?: "Mutation";
  setHypertableConf: any;
};

export type CreateProjectSecretMutationVariables = Exact<{
  projectId: Scalars["ID"];
  name: Scalars["String"];
  value: Scalars["String"];
}>;

export type CreateProjectSecretMutation = {
  __typename?: "Mutation";
  createProjectSecret: any;
};

export type UpdateProjectSecretMutationVariables = Exact<{
  projectId: Scalars["ID"];
  name: Scalars["String"];
  value: Scalars["String"];
}>;

export type UpdateProjectSecretMutation = {
  __typename?: "Mutation";
  updateProjectSecret: any;
};

export type DeleteProjectSecretMutationVariables = Exact<{
  projectId: Scalars["ID"];
  name: Scalars["String"];
}>;

export type DeleteProjectSecretMutation = {
  __typename?: "Mutation";
  deleteProjectSecret: any;
};

export type DismissRecommendationMutationVariables = Exact<{
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
  recommendationId: Scalars["ID"];
}>;

export type DismissRecommendationMutation = {
  __typename?: "Mutation";
  dismissRecommendation: any;
};

export type BillingAccountCostInfoFragmentFragment = {
  __typename?: "BillingAccountCostInfo";
  costToDate: number;
  costToDateGross: number;
  estimatedTotalCost: number;
  estimatedTotalCostGross: number;
  billingPeriodStart: any;
  billingPeriodEnd: any;
  productCostInfos: Array<{
    __typename?: "ProductCostInfo";
    productID: string;
    costToDate: number;
    costToDateGross: number;
    estimatedTotalCost: number;
    estimatedTotalCostGross: number;
  }>;
  serviceCostInfos: Array<{
    __typename?: "ServiceCostInfo";
    serviceID: string;
    costToDate: number;
    costToDateGross: number;
    estimatedTotalCost: number;
    estimatedTotalCostGross: number;
  }>;
};

export type ProjectFragmentFragment = {
  __typename?: "Project";
  id: string;
  name: string;
  maxVPCs: number;
  created?: any | null;
  productTier: ProductTier;
  billingAccount?: {
    __typename?: "BillingAccount";
    id: string;
    created: any;
    name: string;
    isUsingInvoicing: boolean;
    trialEndDate: any;
    permission: {
      __typename?: "BillingPermission";
      billingAccountId: string;
      created: any;
      userId: string;
      role: BillingRole;
    };
    allPermissions: Array<{
      __typename?: "BillingPermission";
      userId: string;
      billingAccountId: string;
      created: any;
      role: BillingRole;
    }>;
    costInfo: {
      __typename?: "BillingAccountCostInfo";
      costToDate: number;
      costToDateGross: number;
      estimatedTotalCost: number;
      estimatedTotalCostGross: number;
      billingPeriodStart: any;
      billingPeriodEnd: any;
      productCostInfos: Array<{
        __typename?: "ProductCostInfo";
        productID: string;
        costToDate: number;
        costToDateGross: number;
        estimatedTotalCost: number;
        estimatedTotalCostGross: number;
      }>;
      serviceCostInfos: Array<{
        __typename?: "ServiceCostInfo";
        serviceID: string;
        costToDate: number;
        costToDateGross: number;
        estimatedTotalCost: number;
        estimatedTotalCostGross: number;
      }>;
    };
    paymentMethods: Array<{
      __typename?: "PaymentMethod";
      id: string;
      isPrimary?: boolean | null;
      cardInfo: {
        __typename?: "CardInfo";
        brand: string;
        last4: string;
        expMonth: number;
        expYear: number;
      };
      addressInfo?: {
        __typename?: "AddressInfo";
        city: string;
        state: string;
        country: string;
        line1: string;
        line2: string;
        postalCode: string;
      } | null;
    }>;
    addressDetails: {
      __typename?: "AddressDetails";
      companyAddress?: {
        __typename?: "AddressInfo";
        companyName?: string | null;
        line1: string;
        line2: string;
        city: string;
        state: string;
        country: string;
        postalCode: string;
      } | null;
    };
  } | null;
  entitlementSpec?: {
    __typename?: "EntitlementSpec";
    plan_type: VirtualPlanType;
    exporter: { __typename?: "ExporterEntitlement"; enabled: boolean };
    ha_replica: {
      __typename?: "HAReplicaEntitlement";
      enabled: boolean;
      max_instances_per_service: number;
    };
    insights: {
      __typename?: "InsightsEntitlement";
      enabled: boolean;
      max_hours: number;
    };
    invoice_billing: {
      __typename?: "InvoiceBillingEntitlement";
      enabled: boolean;
    };
    pitr: {
      __typename?: "PITREntitlement";
      enabled: boolean;
      max_days: number;
    };
    production_support: {
      __typename?: "ProductionSupportEntitlement";
      enabled: boolean;
    };
    read_replica: { __typename?: "ReadReplicaEntitlement"; enabled: boolean };
    saml: { __typename?: "SAMLEntitlement"; enabled: boolean };
    services: {
      __typename?: "ServiceEntitlement";
      enabled: boolean;
      max_bandwidth_mb: number;
      max_cpu: number;
      max_cpu_milli: number;
      max_instances: number;
      max_iops: number;
      max_memory: number;
      max_memory_gb: number;
      max_storage_gb: number;
      min_bandwidth_mb: number;
      min_iops: number;
      unlimited: boolean;
    };
    tiered_storage: {
      __typename?: "TieredStorageEntitlement";
      enabled: boolean;
    };
    vpc: {
      __typename?: "VPCAttachmentEntitlement";
      attachment_type: VpcAttachmentType;
      enabled: boolean;
      max_instances: number;
    };
  } | null;
};

export type GetProjectQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetProjectQuery = {
  __typename?: "Query";
  getProject: {
    __typename?: "Project";
    id: string;
    name: string;
    maxVPCs: number;
    created?: any | null;
    productTier: ProductTier;
    billingAccount?: {
      __typename?: "BillingAccount";
      id: string;
      created: any;
      name: string;
      isUsingInvoicing: boolean;
      trialEndDate: any;
      permission: {
        __typename?: "BillingPermission";
        billingAccountId: string;
        created: any;
        userId: string;
        role: BillingRole;
      };
      allPermissions: Array<{
        __typename?: "BillingPermission";
        userId: string;
        billingAccountId: string;
        created: any;
        role: BillingRole;
      }>;
      costInfo: {
        __typename?: "BillingAccountCostInfo";
        costToDate: number;
        costToDateGross: number;
        estimatedTotalCost: number;
        estimatedTotalCostGross: number;
        billingPeriodStart: any;
        billingPeriodEnd: any;
        productCostInfos: Array<{
          __typename?: "ProductCostInfo";
          productID: string;
          costToDate: number;
          costToDateGross: number;
          estimatedTotalCost: number;
          estimatedTotalCostGross: number;
        }>;
        serviceCostInfos: Array<{
          __typename?: "ServiceCostInfo";
          serviceID: string;
          costToDate: number;
          costToDateGross: number;
          estimatedTotalCost: number;
          estimatedTotalCostGross: number;
        }>;
      };
      paymentMethods: Array<{
        __typename?: "PaymentMethod";
        id: string;
        isPrimary?: boolean | null;
        cardInfo: {
          __typename?: "CardInfo";
          brand: string;
          last4: string;
          expMonth: number;
          expYear: number;
        };
        addressInfo?: {
          __typename?: "AddressInfo";
          city: string;
          state: string;
          country: string;
          line1: string;
          line2: string;
          postalCode: string;
        } | null;
      }>;
      addressDetails: {
        __typename?: "AddressDetails";
        companyAddress?: {
          __typename?: "AddressInfo";
          companyName?: string | null;
          line1: string;
          line2: string;
          city: string;
          state: string;
          country: string;
          postalCode: string;
        } | null;
      };
    } | null;
    entitlementSpec?: {
      __typename?: "EntitlementSpec";
      plan_type: VirtualPlanType;
      exporter: { __typename?: "ExporterEntitlement"; enabled: boolean };
      ha_replica: {
        __typename?: "HAReplicaEntitlement";
        enabled: boolean;
        max_instances_per_service: number;
      };
      insights: {
        __typename?: "InsightsEntitlement";
        enabled: boolean;
        max_hours: number;
      };
      invoice_billing: {
        __typename?: "InvoiceBillingEntitlement";
        enabled: boolean;
      };
      pitr: {
        __typename?: "PITREntitlement";
        enabled: boolean;
        max_days: number;
      };
      production_support: {
        __typename?: "ProductionSupportEntitlement";
        enabled: boolean;
      };
      read_replica: { __typename?: "ReadReplicaEntitlement"; enabled: boolean };
      saml: { __typename?: "SAMLEntitlement"; enabled: boolean };
      services: {
        __typename?: "ServiceEntitlement";
        enabled: boolean;
        max_bandwidth_mb: number;
        max_cpu: number;
        max_cpu_milli: number;
        max_instances: number;
        max_iops: number;
        max_memory: number;
        max_memory_gb: number;
        max_storage_gb: number;
        min_bandwidth_mb: number;
        min_iops: number;
        unlimited: boolean;
      };
      tiered_storage: {
        __typename?: "TieredStorageEntitlement";
        enabled: boolean;
      };
      vpc: {
        __typename?: "VPCAttachmentEntitlement";
        attachment_type: VpcAttachmentType;
        enabled: boolean;
        max_instances: number;
      };
    } | null;
  };
};

export type GetUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserQuery = {
  __typename?: "Query";
  getUser: {
    __typename?: "User";
    id: string;
    name: string;
    email: string;
    phone?: string | null;
    created?: any | null;
    managedByIdp: boolean;
    origin?: string | null;
    uiState?: string | null;
    mfaMethods?: Array<{
      __typename?: "AuthenticatorMethod";
      id: string;
    }> | null;
  };
};

export type GetAllProjectsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllProjectsQuery = {
  __typename?: "Query";
  getAllProjects: Array<{
    __typename?: "Project";
    id: string;
    name: string;
    maxVPCs: number;
    created?: any | null;
    productTier: ProductTier;
    billingAccount?: {
      __typename?: "BillingAccount";
      id: string;
      created: any;
      name: string;
      isUsingInvoicing: boolean;
      trialEndDate: any;
      permission: {
        __typename?: "BillingPermission";
        billingAccountId: string;
        created: any;
        userId: string;
        role: BillingRole;
      };
      allPermissions: Array<{
        __typename?: "BillingPermission";
        userId: string;
        billingAccountId: string;
        created: any;
        role: BillingRole;
      }>;
      costInfo: {
        __typename?: "BillingAccountCostInfo";
        costToDate: number;
        costToDateGross: number;
        estimatedTotalCost: number;
        estimatedTotalCostGross: number;
        billingPeriodStart: any;
        billingPeriodEnd: any;
        productCostInfos: Array<{
          __typename?: "ProductCostInfo";
          productID: string;
          costToDate: number;
          costToDateGross: number;
          estimatedTotalCost: number;
          estimatedTotalCostGross: number;
        }>;
        serviceCostInfos: Array<{
          __typename?: "ServiceCostInfo";
          serviceID: string;
          costToDate: number;
          costToDateGross: number;
          estimatedTotalCost: number;
          estimatedTotalCostGross: number;
        }>;
      };
      paymentMethods: Array<{
        __typename?: "PaymentMethod";
        id: string;
        isPrimary?: boolean | null;
        cardInfo: {
          __typename?: "CardInfo";
          brand: string;
          last4: string;
          expMonth: number;
          expYear: number;
        };
        addressInfo?: {
          __typename?: "AddressInfo";
          city: string;
          state: string;
          country: string;
          line1: string;
          line2: string;
          postalCode: string;
        } | null;
      }>;
      addressDetails: {
        __typename?: "AddressDetails";
        companyAddress?: {
          __typename?: "AddressInfo";
          companyName?: string | null;
          line1: string;
          line2: string;
          city: string;
          state: string;
          country: string;
          postalCode: string;
        } | null;
      };
    } | null;
    entitlementSpec?: {
      __typename?: "EntitlementSpec";
      plan_type: VirtualPlanType;
      exporter: { __typename?: "ExporterEntitlement"; enabled: boolean };
      ha_replica: {
        __typename?: "HAReplicaEntitlement";
        enabled: boolean;
        max_instances_per_service: number;
      };
      insights: {
        __typename?: "InsightsEntitlement";
        enabled: boolean;
        max_hours: number;
      };
      invoice_billing: {
        __typename?: "InvoiceBillingEntitlement";
        enabled: boolean;
      };
      pitr: {
        __typename?: "PITREntitlement";
        enabled: boolean;
        max_days: number;
      };
      production_support: {
        __typename?: "ProductionSupportEntitlement";
        enabled: boolean;
      };
      read_replica: { __typename?: "ReadReplicaEntitlement"; enabled: boolean };
      saml: { __typename?: "SAMLEntitlement"; enabled: boolean };
      services: {
        __typename?: "ServiceEntitlement";
        enabled: boolean;
        max_bandwidth_mb: number;
        max_cpu: number;
        max_cpu_milli: number;
        max_instances: number;
        max_iops: number;
        max_memory: number;
        max_memory_gb: number;
        max_storage_gb: number;
        min_bandwidth_mb: number;
        min_iops: number;
        unlimited: boolean;
      };
      tiered_storage: {
        __typename?: "TieredStorageEntitlement";
        enabled: boolean;
      };
      vpc: {
        __typename?: "VPCAttachmentEntitlement";
        attachment_type: VpcAttachmentType;
        enabled: boolean;
        max_instances: number;
      };
    } | null;
  }>;
};

export type GetBillingAccountCorporateInvoicingQueryVariables = Exact<{
  projectId: Scalars["ID"];
}>;

export type GetBillingAccountCorporateInvoicingQuery = {
  __typename?: "Query";
  getProject: {
    __typename?: "Project";
    billingAccount?: {
      __typename?: "BillingAccount";
      id: string;
      isUsingInvoicing: boolean;
      trialEndDate: any;
      costInfo: {
        __typename?: "BillingAccountCostInfo";
        costToDate: number;
        costToDateGross: number;
        estimatedTotalCost: number;
        estimatedTotalCostGross: number;
        billingPeriodStart: any;
        billingPeriodEnd: any;
        productCostInfos: Array<{
          __typename?: "ProductCostInfo";
          productID: string;
          costToDate: number;
          costToDateGross: number;
          estimatedTotalCost: number;
          estimatedTotalCostGross: number;
        }>;
        serviceCostInfos: Array<{
          __typename?: "ServiceCostInfo";
          serviceID: string;
          costToDate: number;
          costToDateGross: number;
          estimatedTotalCost: number;
          estimatedTotalCostGross: number;
        }>;
      };
      paymentMethods: Array<{
        __typename?: "PaymentMethod";
        id: string;
        isPrimary?: boolean | null;
        cardInfo: {
          __typename?: "CardInfo";
          brand: string;
          last4: string;
          expMonth: number;
          expYear: number;
        };
        addressInfo?: {
          __typename?: "AddressInfo";
          city: string;
          state: string;
          country: string;
          line1: string;
          line2: string;
          postalCode: string;
        } | null;
      }>;
      addressDetails: {
        __typename?: "AddressDetails";
        companyAddress?: {
          __typename?: "AddressInfo";
          companyName?: string | null;
          line1: string;
          line2: string;
          city: string;
          state: string;
          country: string;
          postalCode: string;
        } | null;
      };
    } | null;
  };
};

export type GetBillingEmailAddressesQueryVariables = Exact<{
  billingAccountId: Scalars["ID"];
}>;

export type GetBillingEmailAddressesQuery = {
  __typename?: "Query";
  getBillingEmailAddresses: Array<{
    __typename?: "BillingEmailAddress";
    id: string;
    email: string;
    billingAccountId: string;
    created: any;
    activated?: any | null;
  }>;
};

export type GetOrbInvoicesQueryVariables = Exact<{
  billingAccountId: Scalars["ID"];
  year?: InputMaybe<Scalars["Int"]>;
}>;

export type GetOrbInvoicesQuery = {
  __typename?: "Query";
  getOrbInvoices: {
    __typename?: "Invoices";
    invoices: Array<{
      __typename?: "Invoice";
      id: string;
      created: any;
      updated?: any | null;
      billingAccountId: string;
      totalCharged?: number | null;
      paymentState?: string | null;
      pdfUrl?: string | null;
      fromInternalBilling?: boolean | null;
    }>;
  };
};

export type GetPaymentMethodsQueryVariables = Exact<{
  billingAccountId: Scalars["ID"];
}>;

export type GetPaymentMethodsQuery = {
  __typename?: "Query";
  getPaymentMethods: Array<{
    __typename?: "PaymentMethod";
    id: string;
    isPrimary?: boolean | null;
    cardInfo: {
      __typename?: "CardInfo";
      brand: string;
      last4: string;
      expMonth: number;
      expYear: number;
    };
    addressInfo?: {
      __typename?: "AddressInfo";
      city: string;
      state: string;
      country: string;
      line1: string;
      line2: string;
      postalCode: string;
    } | null;
  }>;
};

export type GetServiceQueryVariables = Exact<{
  serviceId: Scalars["ID"];
  projectId: Scalars["ID"];
}>;

export type GetServiceQuery = {
  __typename?: "Query";
  getService: {
    __typename?: "Service";
    name: string;
    id: string;
    projectId: string;
    type: Type;
    initialExtensions?: Array<string> | null;
    isReadOnly: boolean;
    created: any;
    regionCode: string;
    status: DeployStatus;
    primaryOrdinal?: number | null;
    replicaOrdinals?: Array<number> | null;
    replicaStatus?: DeployStatus | null;
    vpcId?: string | null;
    forkedFromId?: {
      __typename?: "ForkSpec";
      serviceId: string;
      projectId: string;
      isStandby?: boolean | null;
      targetTime?: any | null;
    } | null;
    endpoints?: {
      __typename?: "ServiceEndpoints";
      primary: { __typename?: "EndpointAddress"; host: string; port: number };
      replica: { __typename?: "EndpointAddress"; host: string; port: number };
      pooler: { __typename?: "EndpointAddress"; host: string; port: number };
    } | null;
    spec: {
      __typename?: "TimescaleDBServiceSpec";
      hostname: string;
      username: string;
      port: number;
      defaultDBName: string;
      replicaHostName?: string | null;
      replicaPort?: number | null;
      exporterID?: string | null;
      genericExporterID?: string | null;
      exporterSidecarEnabled: boolean;
      connectionPoolerEnabled: boolean;
      poolerPort?: number | null;
      poolerHostName?: string | null;
      popSQLIntegrationEnabled: boolean;
    };
    resources: Array<{
      __typename?: "Resource";
      id: string;
      spec: {
        __typename?: "ResourceNode";
        storageGB: number;
        milliCPU: number;
        memoryGB: number;
        replicaCount: number;
        syncReplicaCount: number;
      };
    }>;
    dataTieringSettings?: {
      __typename?: "DataTieringSettings";
      enabled: boolean;
    } | null;
    metrics?: {
      __typename?: "ResourceMetrics";
      memoryMB?: number | null;
      storageMB?: number | null;
      milliCPU?: number | null;
      readIOPs?: number | null;
      writeIOPs?: number | null;
      readBandwidth?: number | null;
      writeBandwidth?: number | null;
    } | null;
    vpcEndpoint?: {
      __typename?: "Endpoint";
      projectId: string;
      serviceId: string;
      vpcId: string;
      host: string;
      port: number;
    } | null;
    metadata: {
      __typename?: "ServiceMetadata";
      environment: ServiceEnvironment;
    };
  };
};

export type GetServiceResourcesAndStatusQueryVariables = Exact<{
  serviceId: Scalars["ID"];
  projectId: Scalars["ID"];
}>;

export type GetServiceResourcesAndStatusQuery = {
  __typename?: "Query";
  getService: {
    __typename?: "Service";
    id: string;
    name: string;
    status: DeployStatus;
    resources: Array<{
      __typename?: "Resource";
      spec: {
        __typename?: "ResourceNode";
        storageGB: number;
        milliCPU: number;
        memoryGB: number;
        replicaCount: number;
        syncReplicaCount: number;
      };
    }>;
  };
};

export type GetServiceResizeInfoQueryVariables = Exact<{
  serviceId: Scalars["ID"];
  projectId: Scalars["ID"];
}>;

export type GetServiceResizeInfoQuery = {
  __typename?: "Query";
  getService: {
    __typename?: "Service";
    id: string;
    name: string;
    status: DeployStatus;
    resources: Array<{
      __typename?: "Resource";
      id: string;
      type: string;
      serviceId: string;
      spec: {
        __typename?: "ResourceNode";
        storageGB: number;
        milliCPU: number;
        memoryGB: number;
        replicaCount: number;
        syncReplicaCount: number;
      };
    }>;
  };
};

export type GetServiceResourceMetricsQueryVariables = Exact<{
  serviceId: Scalars["ID"];
  projectId: Scalars["ID"];
  start: Scalars["Time"];
  end: Scalars["Time"];
  bucketSeconds: Scalars["Int"];
  serviceOrdinal?: InputMaybe<Scalars["Int"]>;
}>;

export type GetServiceResourceMetricsQuery = {
  __typename?: "Query";
  getServiceResourceMetrics: Array<{
    __typename?: "ResourceMetrics";
    storageMB?: number | null;
    Time: any;
    memoryMB?: number | null;
    milliCPU?: number | null;
    readIOPs?: number | null;
    writeIOPs?: number | null;
    readBandwidth?: number | null;
    writeBandwidth?: number | null;
  }>;
};

export type GetPopSqlIntegrationStatusQueryVariables = Exact<{
  serviceId: Scalars["ID"];
  projectId: Scalars["ID"];
}>;

export type GetPopSqlIntegrationStatusQuery = {
  __typename?: "Query";
  getPopSQLIntegrationStatus: Status;
};

export type GetAllServicesQueryVariables = Exact<{
  projectId: Scalars["ID"];
}>;

export type GetAllServicesQuery = {
  __typename?: "Query";
  getAllServices: Array<{
    __typename?: "Service";
    name: string;
    id: string;
    projectId: string;
    type: Type;
    initialExtensions?: Array<string> | null;
    isReadOnly: boolean;
    created: any;
    regionCode: string;
    status: DeployStatus;
    vpcId?: string | null;
    primaryOrdinal?: number | null;
    replicaOrdinals?: Array<number> | null;
    replicaStatus?: DeployStatus | null;
    endpoints?: {
      __typename?: "ServiceEndpoints";
      primary: { __typename?: "EndpointAddress"; host: string; port: number };
      replica: { __typename?: "EndpointAddress"; host: string; port: number };
      pooler: { __typename?: "EndpointAddress"; host: string; port: number };
    } | null;
    spec: {
      __typename?: "TimescaleDBServiceSpec";
      hostname: string;
      username: string;
      port: number;
      defaultDBName: string;
      replicaHostName?: string | null;
      replicaPort?: number | null;
      exporterID?: string | null;
      genericExporterID?: string | null;
      exporterSidecarEnabled: boolean;
      connectionPoolerEnabled: boolean;
      poolerPort?: number | null;
      poolerHostName?: string | null;
      popSQLIntegrationEnabled: boolean;
    };
    resources: Array<{
      __typename?: "Resource";
      id: string;
      spec: {
        __typename?: "ResourceNode";
        milliCPU: number;
        memoryGB: number;
        storageGB: number;
        replicaCount: number;
        syncReplicaCount: number;
      };
    }>;
    vpcEndpoint?: {
      __typename?: "Endpoint";
      projectId: string;
      serviceId: string;
      vpcId: string;
      host: string;
      port: number;
    } | null;
    metadata: {
      __typename?: "ServiceMetadata";
      environment: ServiceEnvironment;
    };
    dataTieringSettings?: {
      __typename?: "DataTieringSettings";
      enabled: boolean;
    } | null;
    metrics?: {
      __typename?: "ResourceMetrics";
      memoryMB?: number | null;
      storageMB?: number | null;
      milliCPU?: number | null;
    } | null;
    forkedFromId?: {
      __typename?: "ForkSpec";
      serviceId: string;
      projectId: string;
      isStandby?: boolean | null;
      targetTime?: any | null;
    } | null;
  }>;
};

export type GetServiceLogsQueryVariables = Exact<{
  serviceId: Scalars["ID"];
  projectId: Scalars["ID"];
  serviceOrdinal?: InputMaybe<Scalars["Int"]>;
}>;

export type GetServiceLogsQuery = {
  __typename?: "Query";
  getServiceLogs: Array<string>;
};

export type LoginMfaQueryVariables = Exact<{
  email: Scalars["String"];
  password: Scalars["String"];
}>;

export type LoginMfaQuery = {
  __typename?: "Query";
  loginMfa:
    | {
        __typename?: "LoginMfaRequired";
        twoFactorId: string;
        mfaMethods: Array<{ __typename?: "AuthenticatorMethod"; id: string }>;
      }
    | {
        __typename?: "LoginSuccess";
        user: {
          __typename?: "User";
          id: string;
          name: string;
          email: string;
          phone?: string | null;
          created?: any | null;
        };
      };
};

export type LoginMfaChallengeQueryVariables = Exact<{
  code: Scalars["String"];
  twoFactorId: Scalars["String"];
}>;

export type LoginMfaChallengeQuery = {
  __typename?: "Query";
  loginMfa:
    | { __typename?: "LoginMfaRequired" }
    | {
        __typename?: "LoginSuccess";
        user: {
          __typename?: "User";
          id: string;
          name: string;
          email: string;
          phone?: string | null;
          created?: any | null;
        };
      };
};

export type MfaChallengeQueryVariables = Exact<{
  code: Scalars["String"];
  trustChallenge: Scalars["String"];
  resetToken?: InputMaybe<Scalars["String"]>;
}>;

export type MfaChallengeQuery = {
  __typename?: "Query";
  mfaChallenge: { __typename?: "MfaChallengeResponse"; trustToken: string };
};

export type LogoutQueryVariables = Exact<{ [key: string]: never }>;

export type LogoutQuery = { __typename?: "Query"; logout: any };

export type GetProductsQueryVariables = Exact<{ [key: string]: never }>;

export type GetProductsQuery = {
  __typename?: "Query";
  products: Array<{
    __typename?: "Product";
    id: string;
    name: string;
    description: string;
    plans: Array<{
      __typename?: "Plan";
      id: string;
      productId: string;
      price: number;
      milliCPU?: number | null;
      memoryGB?: number | null;
      storageGB?: number | null;
      regionCode: string;
    }>;
  }>;
};

export type GetOrbProductsQueryVariables = Exact<{
  projectId: Scalars["ID"];
  experimentGroup?: InputMaybe<ExperimentGroup>;
}>;

export type GetOrbProductsQuery = {
  __typename?: "Query";
  orbProducts: Array<{
    __typename?: "Product";
    id: string;
    name: string;
    description: string;
    plans: Array<{
      __typename?: "Plan";
      id: string;
      productId: string;
      price: number;
      milliCPU?: number | null;
      memoryGB?: number | null;
      storageGB?: number | null;
      regionCode: string;
    }>;
  }>;
};

export type GetServiceConfigHistoryQueryVariables = Exact<{
  serviceId: Scalars["ID"];
  projectId: Scalars["ID"];
  serviceOrdinal?: InputMaybe<Scalars["Int"]>;
}>;

export type GetServiceConfigHistoryQuery = {
  __typename?: "Query";
  getServiceConfigHistory: {
    __typename?: "ServiceConfigHistory";
    compute: Array<{
      __typename?: "ServiceComputeConfig";
      memoryGB: number;
      milliCPU: number;
      created: any;
    }>;
    storage: Array<{
      __typename?: "ServiceStorageConfig";
      storageGB: number;
      iops: number;
      bandwidthMBs: number;
      created: any;
    }>;
  };
};

export type ValidateResetTokenMfaQueryVariables = Exact<{
  token: Scalars["String"];
}>;

export type ValidateResetTokenMfaQuery = {
  __typename?: "Query";
  validateResetTokenMfa: {
    __typename?: "ValidateResetTokenMfaResponse";
    message: string;
    mfaMethods: Array<{ __typename?: "AuthenticatorMethod"; id: string }>;
  };
};

export type GetPostgresParametersQueryVariables = Exact<{
  serviceId: Scalars["ID"];
  projectId: Scalars["ID"];
}>;

export type GetPostgresParametersQuery = {
  __typename?: "Query";
  getPostgresParameters: {
    __typename?: "PostgresParameters";
    string_parameters: Array<{
      __typename?: "StringParameter";
      current_value: string;
      allowed_values: Array<string>;
      info: {
        __typename?: "ParameterInfo";
        name: string;
        description: string;
        is_user_editable: boolean;
        requires_restart: boolean;
        is_tune_overridable: boolean;
        ui_priority: number;
        is_pending_restart: boolean;
      };
    }>;
    numeric_parameters: Array<{
      __typename?: "NumericParameter";
      unit: ParameterUnit;
      current_value: number;
      max_allowed_value?: number | null;
      min_allowed_value?: number | null;
      info: {
        __typename?: "ParameterInfo";
        name: string;
        description: string;
        is_user_editable: boolean;
        requires_restart: boolean;
        is_tune_overridable: boolean;
        ui_priority: number;
        is_pending_restart: boolean;
      };
    }>;
  };
};

export type GetServiceMaintenanceInfoQueryVariables = Exact<{
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
}>;

export type GetServiceMaintenanceInfoQuery = {
  __typename?: "Query";
  getNextMaintenance: {
    __typename?: "NextMaintenance";
    isMaintenanceScheduled: boolean;
    start: any;
    end: any;
  };
};

export type GetServiceTablesQueryVariables = Exact<{
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
  regionCode?: InputMaybe<Scalars["String"]>;
}>;

export type GetServiceTablesQuery = {
  __typename?: "Query";
  getTableList: Array<{
    __typename?: "TableID";
    name: string;
    isHypertable: boolean;
    schema: string;
  }>;
};

export type GetServiceContinuousAggregatesQueryVariables = Exact<{
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
  regionCode?: InputMaybe<Scalars["String"]>;
}>;

export type GetServiceContinuousAggregatesQuery = {
  __typename?: "Query";
  getContAggList: Array<{
    __typename?: "ContAggID";
    name: string;
    schema: string;
  }>;
};

export type GetServiceLargestObjectsQueryVariables = Exact<{
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
  regionCode?: InputMaybe<Scalars["String"]>;
}>;

export type GetServiceLargestObjectsQuery = {
  __typename?: "Query";
  getLargestObjects: Array<{
    __typename?: "ObjectSizeInfo";
    size: number;
    objectId:
      | { __typename: "ContAggID"; name: string; schema: string }
      | {
          __typename: "TableID";
          isHypertable: boolean;
          schema: string;
          name: string;
        };
  }>;
};

export type GetServiceGeneralInformationQueryVariables = Exact<{
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
  regionCode?: InputMaybe<Scalars["String"]>;
}>;

export type GetServiceGeneralInformationQuery = {
  __typename?: "Query";
  getDatabaseOverview: {
    __typename?: "GetDatabaseOverviewOutput";
    info: {
      __typename?: "ObjectCounts";
      pgTables: number;
      hypertables: number;
      contAggs: number;
      realtimeContAggs: number;
      policies: number;
    };
    compressionV2?: {
      __typename?: "DBCompressionStats";
      numCompressedHypertables: number;
      numCompressedContAggs: number;
      stats: {
        __typename?: "CompressionStats";
        precompressed: number;
        compressed: number;
        diskSize: number;
        originalSize: number;
        savings: number;
      };
    } | null;
    policyCounts?: Array<{
      __typename?: "PolicyCount";
      name: string;
      count: number;
    }> | null;
  };
};

export type GetTableInfoQueryVariables = Exact<{
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
  tableSchema: Scalars["ID"];
  tableName: Scalars["ID"];
  regionCode?: InputMaybe<Scalars["String"]>;
}>;

export type GetTableInfoQuery = {
  __typename?: "Query";
  getTableInfo: {
    __typename?: "Table";
    metadata: {
      __typename?: "TableMetadata";
      size: number;
      approxRowCount: number;
      id: {
        __typename?: "TableID";
        schema: string;
        name: string;
        isHypertable: boolean;
      };
    };
    hyperMetadata?: {
      __typename?: "HyperMetadata";
      numChunks: number;
      compression: boolean;
      timeInterval: string;
      integerInterval: number;
      compressionStats?: {
        __typename?: "CompressionStats";
        precompressed: number;
        compressed: number;
        uncompressed: number;
        diskSize: number;
        originalSize: number;
        savings: number;
      } | null;
      dimensions?: Array<
        | {
            __typename?: "SpaceDimension";
            columnName: string;
            numPartitions: number;
          }
        | {
            __typename?: "TimeDimension";
            columnName: string;
            timeInterval?: string | null;
            integerInterval?: number | null;
          }
      > | null;
    } | null;
    schema: Array<{
      __typename?: "Column";
      name: string;
      type: string;
      default: string;
      nullable: boolean;
    }>;
    indexes: Array<{ __typename?: "Index"; name: string; definition: string }>;
    chunks: Array<{
      __typename?: "Chunk";
      name: string;
      size: number;
      startTime: string;
      endTime: string;
      isCompressed: boolean;
      compressedSize: number;
      precompressedSize: number;
      dataNodes?: Array<string> | null;
    }>;
    contAggs: Array<{
      __typename?: "ContAgg";
      name: string;
      schema: string;
      materializedOnly: boolean;
      definition: string;
    }>;
    foreignKeys: Array<{
      __typename?: "ForeignKey";
      name: string;
      keyCols: string;
      refSchema: string;
      refName: string;
      refCols: string;
    }>;
    policies: Array<{
      __typename?: "Policy";
      id: number;
      type: string;
      target: string;
      interval: string;
      fullInfo: string;
    }>;
  };
};

export type GetServiceCaggInfoQueryVariables = Exact<{
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
  contAggSchema: Scalars["ID"];
  contAggName: Scalars["ID"];
  regionCode?: InputMaybe<Scalars["String"]>;
}>;

export type GetServiceCaggInfoQuery = {
  __typename?: "Query";
  getContAggInfo: {
    __typename?: "ContAggInfo";
    definition: string;
    metadata: {
      __typename?: "ContAggMetadata";
      size: number;
      approxRowCount: number;
      materializedOnly: boolean;
      lastRefresh: string;
      nextRefresh: string;
      id: { __typename?: "ContAggID"; schema: string; name: string };
      originalHypertable: {
        __typename?: "TableID";
        originalSchema: string;
        originalName: string;
      };
    };
    hyperMetadata: {
      __typename?: "HyperMetadata";
      numChunks: number;
      compression: boolean;
      timeInterval: string;
      integerInterval: number;
      compressionStats?: {
        __typename?: "CompressionStats";
        precompressed: number;
        compressed: number;
        uncompressed: number;
        diskSize: number;
        originalSize: number;
        savings: number;
      } | null;
      dimensions?: Array<
        | {
            __typename?: "SpaceDimension";
            columnName: string;
            numPartitions: number;
          }
        | {
            __typename?: "TimeDimension";
            columnName: string;
            timeInterval?: string | null;
            integerInterval?: number | null;
          }
      > | null;
    };
    schema: Array<{
      __typename?: "Column";
      name: string;
      type: string;
      default: string;
      nullable: boolean;
      constraints: Array<string>;
    }>;
    indexes: Array<{ __typename?: "Index"; name: string; definition: string }>;
    policies: Array<{
      __typename?: "Policy";
      id: number;
      type: string;
      target: string;
      interval: string;
      fullInfo: string;
    }>;
    refreshPolicies: Array<{
      __typename?: "ContAggRefreshPolicy";
      startOffset: string;
      endOffset: string;
      interval: string;
    }>;
    chunks: Array<{
      __typename?: "Chunk";
      name: string;
      size: number;
      startTime: string;
      endTime: string;
      isCompressed: boolean;
      compressedSize: number;
      precompressedSize: number;
    }>;
  };
};

export type GetAllVpcsQueryVariables = Exact<{
  projectId: Scalars["ID"];
}>;

export type GetAllVpcsQuery = {
  __typename?: "Query";
  getAllVpcs: Array<{
    __typename?: "VPC";
    id: string;
    projectId: string;
    cidr: string;
    cloudProvider: CloudProvider;
    name: string;
    created?: any | null;
    updated?: any | null;
    errorMessage?: string | null;
    status: VpcStatus;
    regionCode: string;
    peeringConnections: Array<{
      __typename?: "PeeringConnection";
      id?: string | null;
      vpcId: string;
      errorMessage?: string | null;
      status: PeeringConnectionStatus;
      peerVpc: {
        __typename?: "PeerVPC";
        id: string;
        accountId: string;
        regionCode: string;
        cidr: string;
      };
    }>;
  }>;
};

export type GetProjectMembersQueryVariables = Exact<{
  projectId: Scalars["ID"];
}>;

export type GetProjectMembersQuery = {
  __typename?: "Query";
  getProjectMembers: Array<{
    __typename?: "ProjectMember";
    projectId: string;
    userId: string;
    joined: any;
    role: Role;
    user: {
      __typename?: "User";
      id: string;
      name: string;
      email: string;
      phone?: string | null;
      created?: any | null;
      mfaMethods?: Array<{
        __typename?: "AuthenticatorMethod";
        id: string;
      }> | null;
    };
  }>;
};

export type GetProjectInvitesQueryVariables = Exact<{
  projectId: Scalars["ID"];
}>;

export type GetProjectInvitesQuery = {
  __typename?: "Query";
  getProjectInvites: Array<{
    __typename?: "ProjectInvite";
    id: string;
    email: string;
    role: Role;
    created: any;
    declined?: any | null;
    project: { __typename?: "Project"; id: string; name: string };
  }>;
};

export type GetProjectInvitesForUserQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetProjectInvitesForUserQuery = {
  __typename?: "Query";
  getProjectInvitesForUser: Array<{
    __typename?: "ProjectInvite";
    id: string;
    email: string;
    role: Role;
    created: any;
    declined?: any | null;
    hash?: string | null;
    project: { __typename?: "Project"; id: string; name: string };
    inviter?: {
      __typename?: "User";
      id: string;
      name: string;
      email: string;
      phone?: string | null;
    } | null;
  }>;
};

export type GetPendingPaymentDeletionQueryVariables = Exact<{
  billingAccountId: Scalars["ID"];
}>;

export type GetPendingPaymentDeletionQuery = {
  __typename?: "Query";
  getPendingPaymentDeletion?: {
    __typename?: "PaymentMethod";
    id: string;
  } | null;
};

export type GetPostgresAndTimescaleDbVersionQueryVariables = Exact<{
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
}>;

export type GetPostgresAndTimescaleDbVersionQuery = {
  __typename?: "Query";
  getPostgresAndTimescaleDBVersion: {
    __typename?: "PostgresAndTimescaleVersions";
    hasBackupInfo: boolean;
    postgresVersion: {
      __typename?: "VersionInfo";
      name: string;
      installed: string;
      available: Array<string>;
    };
    timescaleDBVersion: {
      __typename?: "VersionInfo";
      name: string;
      installed: string;
      available: Array<string>;
    };
  };
};

export type GetAllMetricExportersQueryVariables = Exact<{
  projectId: Scalars["ID"];
}>;

export type GetAllMetricExportersQuery = {
  __typename?: "Query";
  getAllMetricExporters: Array<{
    __typename?: "MetricExporter";
    id: string;
    exporterUuid: string;
    projectId: string;
    created: any;
    name: string;
    type: MetricExporterType;
    regionCode: string;
    config:
      | {
          __typename?: "CloudWatchMetricConfig";
          logGroupName: string;
          logStreamName: string;
          namespace: string;
          awsRegion: string;
          awsRoleArn?: string | null;
          awsAccessKey: string;
          awsSecretKey: string;
        }
      | {
          __typename?: "DatadogMetricConfig";
          apiKey: string;
          site?: string | null;
        }
      | { __typename?: "PrometheusMetricConfig"; foo: string };
  }>;
};

export type GetAllGenericExportersQueryVariables = Exact<{
  projectId: Scalars["ID"];
}>;

export type GetAllGenericExportersQuery = {
  __typename?: "Query";
  getAllGenericExporters: Array<{
    __typename?: "GenericExporter";
    id: string;
    projectId: string;
    created: any;
    name: string;
    type: GenericExporterType;
    regionCode: string;
    config: {
      __typename?: "CloudWatchConfig";
      logGroupName: string;
      logStreamName: string;
      awsRegion: string;
      awsRoleArn?: string | null;
      awsAccessKey: string;
      awsSecretKey: string;
    };
  }>;
};

export type GetPostgresMajorVersionUpgradeStatusQueryVariables = Exact<{
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
}>;

export type GetPostgresMajorVersionUpgradeStatusQuery = {
  __typename?: "Query";
  getPostgresMajorVersionUpgradeStatus: {
    __typename?: "PostgresMajorVersionUpgradeStatus";
    stage: PostgresMajorVersionUpgradeStage;
    error?: string | null;
  };
};

export type GetMetricExporterQueryVariables = Exact<{
  projectId: Scalars["ID"];
  exporterId: Scalars["ID"];
}>;

export type GetMetricExporterQuery = {
  __typename?: "Query";
  getMetricExporter: {
    __typename?: "MetricExporter";
    id: string;
    projectId: string;
    created: any;
    name: string;
    type: MetricExporterType;
    regionCode: string;
    config:
      | {
          __typename?: "CloudWatchMetricConfig";
          logGroupName: string;
          logStreamName: string;
          namespace: string;
          awsRegion: string;
          awsRoleArn?: string | null;
        }
      | {
          __typename?: "DatadogMetricConfig";
          apiKey: string;
          site?: string | null;
        }
      | { __typename?: "PrometheusMetricConfig"; foo: string };
  };
};

export type GetPatRecordsQueryVariables = Exact<{
  projectId: Scalars["ID"];
}>;

export type GetPatRecordsQuery = {
  __typename?: "Query";
  getPATRecords: Array<{
    __typename?: "PATRecord";
    name: string;
    projectId: string;
    created: any;
    clientCredentials: {
      __typename?: "ClientCredentials";
      accessKey: string;
      secretKey?: string | null;
    };
  } | null>;
};

export type GetPostgresAvailableExtensionsQueryVariables = Exact<{
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
  regionCode?: InputMaybe<Scalars["String"]>;
}>;

export type GetPostgresAvailableExtensionsQuery = {
  __typename?: "Query";
  getPostgresAvailableExtensions: Array<{
    __typename?: "PostgresExtension";
    name: string;
    defaultVersion: string;
    installedVersion?: string | null;
    comment: string;
  }>;
};

export type GetDataTieringSummaryQueryVariables = Exact<{
  projectId: Scalars["ID"];
}>;

export type GetDataTieringSummaryQuery = {
  __typename?: "Query";
  getDataTieringSummary?: Array<{
    __typename?: "TieringUsage";
    serviceIds?: Array<string> | null;
    tieredBytes: number;
  } | null> | null;
};

export type GetCostedProjectDataTieringUsageDistributionQueryVariables = Exact<{
  projectId: Scalars["ID"];
}>;

export type GetCostedProjectDataTieringUsageDistributionQuery = {
  __typename?: "Query";
  getCostedProjectDataTieringUsageDistribution: Array<{
    __typename?: "DataTieringStorageSummary";
    serviceIds: Array<string>;
    tieringUsageInBytes: number;
    tieringUsageCost: number;
    estimatedEbsUsageInBytes: number;
    estimatedEbsUsageCost: number;
  }>;
};

export type GetRecoveryWindowsQueryVariables = Exact<{
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
}>;

export type GetRecoveryWindowsQuery = {
  __typename?: "Query";
  getRecoveryWindows: Array<{
    __typename?: "RecoveryWindow";
    method: RecoveryMethod;
    startTime: any;
    endTime: any;
  }>;
};

export type MetricValueFragment = {
  __typename?: "MetricValue";
  value: string;
  unit: MetricUnit;
};

export type GetQueryStatsQueryVariables = Exact<{
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
  serviceOrdinal?: InputMaybe<Scalars["Int"]>;
  limit: Scalars["Int"];
  sortBy: QueryStatSortFieldOption;
  sortOrder: QueryStatSortOrderOption;
  constraints?: InputMaybe<Array<QueryStatConstraint> | QueryStatConstraint>;
  queryRange?: InputMaybe<QueryRange>;
}>;

export type GetQueryStatsQuery = {
  __typename?: "Query";
  getQueryStatsAndMetadata: {
    __typename?: "QueryStatResponse";
    statsAsOf?: any | null;
    stats: Array<{
      __typename?: "QueryStat";
      queryHash: string;
      query: string;
      userName: string;
      metrics: {
        __typename?: "QueryStatMetrics";
        executions?: {
          __typename?: "MetricValue";
          value: string;
          unit: MetricUnit;
        } | null;
        rows?: {
          __typename?: "MetricValue";
          value: string;
          unit: MetricUnit;
        } | null;
        p50Time?: {
          __typename?: "MetricValue";
          value: string;
          unit: MetricUnit;
        } | null;
        p95Time?: {
          __typename?: "MetricValue";
          value: string;
          unit: MetricUnit;
        } | null;
        totalTime?: {
          __typename?: "MetricValue";
          value: string;
          unit: MetricUnit;
        } | null;
        hypertablesCount?: {
          __typename?: "MetricValue";
          value: string;
          unit: MetricUnit;
        } | null;
        compressionCount?: {
          __typename?: "MetricValue";
          value: string;
          unit: MetricUnit;
        } | null;
      };
    } | null>;
    queriesDataPoints: Array<{
      __typename?: "PerQueryGraphDataPoint";
      queryHash: string;
      dataPoints: Array<{
        __typename?: "GraphDataPoint";
        bucket: any;
        metrics: {
          __typename?: "QueryStatMetrics";
          p50Time?: {
            __typename?: "MetricValue";
            value: string;
            unit: MetricUnit;
          } | null;
          p95Time?: {
            __typename?: "MetricValue";
            value: string;
            unit: MetricUnit;
          } | null;
        };
      }>;
    }>;
  };
};

export type GetQueryMetricGraphsQueryVariables = Exact<{
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
  serviceOrdinal: Scalars["Int"];
  queryRange: QueryRange;
  queryHash: Scalars["String"];
}>;

export type GetQueryMetricGraphsQuery = {
  __typename?: "Query";
  getQueryMetricGraphs: {
    __typename?: "QueryMetricGraphResponse";
    total: number;
    dataPoints: Array<{
      __typename?: "GraphDataPoint";
      bucket: any;
      queryHash: string;
      metrics: {
        __typename?: "QueryStatMetrics";
        executions?: {
          __typename?: "MetricValue";
          value: string;
          unit: MetricUnit;
        } | null;
        plans?: {
          __typename?: "MetricValue";
          value: string;
          unit: MetricUnit;
        } | null;
        rows?: {
          __typename?: "MetricValue";
          value: string;
          unit: MetricUnit;
        } | null;
        p50Time?: {
          __typename?: "MetricValue";
          value: string;
          unit: MetricUnit;
        } | null;
        p95Time?: {
          __typename?: "MetricValue";
          value: string;
          unit: MetricUnit;
        } | null;
        cacheHitRatio?: {
          __typename?: "MetricValue";
          value: string;
          unit: MetricUnit;
        } | null;
        cacheMemoryRate?: {
          __typename?: "MetricValue";
          value: string;
          unit: MetricUnit;
        } | null;
        bytesReadRate?: {
          __typename?: "MetricValue";
          value: string;
          unit: MetricUnit;
        } | null;
      };
    }>;
  };
};

export type GetQueryDetailsQueryVariables = Exact<{
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
  serviceOrdinal: Scalars["Int"];
  queryHash: Scalars["String"];
}>;

export type GetQueryDetailsQuery = {
  __typename?: "Query";
  getQueryDetails: {
    __typename?: "GetQueryDetailsResponse";
    queryDetails: { __typename?: "QueryDetails"; normalizedQuery: string };
  };
};

export type GetJobListQueryVariables = Exact<{
  projectId: Scalars["String"];
  serviceId: Scalars["String"];
  regionCode?: InputMaybe<Scalars["String"]>;
}>;

export type GetJobListQuery = {
  __typename?: "Query";
  explorer: {
    __typename?: "Explorer";
    jobs: {
      __typename?: "JobItems";
      items: Array<{
        __typename?: "JobItem";
        item: {
          __typename?: "Job";
          id: string;
          type: string;
          currentStart?: any | null;
          nextStart?: any | null;
          status: JobStatus;
          lastNExecutions: Array<{
            __typename?: "JobExecution";
            succeeded?: boolean | null;
          }>;
          proc: { __typename?: "ProcedureID"; name: string; schema: string };
          target?:
            | { __typename?: "ContAggID"; name: string; schema: string }
            | {
                __typename?: "TableID";
                isHypertable: boolean;
                schema: string;
                name: string;
              }
            | null;
        };
      }>;
    };
  };
};

export type GetObjectsMetadataQueryVariables = Exact<{
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
  excludePGTables?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GetObjectsMetadataQuery = {
  __typename?: "Query";
  getObjectsMetadata: Array<{
    __typename?: "ExtendedObjectMetadata";
    metadata:
      | {
          __typename: "ContAggMetadata";
          approxRowCount: number;
          finalized: boolean;
          size: number;
          originalHypertable: {
            __typename?: "TableID";
            name: string;
            schema: string;
          };
          id: { __typename?: "ContAggID"; name: string; schema: string };
        }
      | {
          __typename: "TableMetadata";
          approxRowCount: number;
          size: number;
          id: {
            __typename?: "TableID";
            name: string;
            schema: string;
            isHypertable: boolean;
          };
        };
    hyperMetadata?: {
      __typename?: "HyperMetadata";
      numChunks: number;
      timeInterval: string;
      compressionStats?: {
        __typename?: "CompressionStats";
        savings: number;
      } | null;
    } | null;
  }>;
};

export type GetCompressionRecommendationsForSegmentByQueryVariables = Exact<{
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
  regionCode?: InputMaybe<Scalars["String"]>;
  schema: Scalars["ID"];
  tableName?: InputMaybe<Scalars["ID"]>;
  contAggName?: InputMaybe<Scalars["ID"]>;
}>;

export type GetCompressionRecommendationsForSegmentByQuery = {
  __typename?: "Query";
  getCompressionRecommendationsForSegmentBy: {
    __typename?: "CompressionRecommendationsForSegmentBy";
    availableSegmentByColumns: Array<string>;
    recommendedSegmentByColumns: Array<string>;
  };
};

export type GetCompressionRecommendationsForOrderByQueryVariables = Exact<{
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
  regionCode?: InputMaybe<Scalars["String"]>;
  schema: Scalars["ID"];
  tableName?: InputMaybe<Scalars["ID"]>;
  contAggName?: InputMaybe<Scalars["ID"]>;
  segmentByColumns: Array<Scalars["String"]> | Scalars["String"];
}>;

export type GetCompressionRecommendationsForOrderByQuery = {
  __typename?: "Query";
  getCompressionRecommendationsForOrderBy: {
    __typename?: "CompressionRecommendationsForOrderBy";
    availableOrderByColumns: Array<string>;
    recommendedOrderByColumns: Array<{
      __typename?: "OrderByColumn";
      name: string;
      descending: boolean;
      nullsFirst: boolean;
    }>;
  };
};

export type GetImportStateQueryVariables = Exact<{
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
  id: Scalars["String"];
}>;

export type GetImportStateQuery = {
  __typename?: "Query";
  getImportState: {
    __typename?: "FileImport";
    id: string;
    state: ImportState;
    failure_reason: string;
  };
};

export type GetVirtualPlansQueryVariables = Exact<{ [key: string]: never }>;

export type GetVirtualPlansQuery = {
  __typename?: "Query";
  getVirtualPlans: {
    __typename?: "GetVirtualPlansResponse";
    plans: Array<{ __typename?: "VirtualPlan"; kind: VirtualPlanType }>;
  };
};

export type GetPlanDowngradeCheckQueryVariables = Exact<{
  projectId: Scalars["ID"];
  downgradeTo: VirtualPlanType;
}>;

export type GetPlanDowngradeCheckQuery = {
  __typename?: "Query";
  downgradeCheck: {
    __typename?: "EntitlementDowngradeResponse";
    dependencies?: Array<EntitlementKind> | null;
  };
};

export type OrbCostQueryVariables = Exact<{
  projectId: Scalars["ID"];
  billingAccountId: Scalars["ID"];
}>;

export type OrbCostQuery = {
  __typename?: "Query";
  orbCost: {
    __typename?: "BillingAccountCostInfo";
    costToDate: number;
    costToDateGross: number;
    estimatedTotalCost: number;
    estimatedTotalCostGross: number;
    billingPeriodStart: any;
    billingPeriodEnd: any;
    productCostInfos: Array<{
      __typename?: "ProductCostInfo";
      productID: string;
      costToDate: number;
      costToDateGross: number;
      estimatedTotalCost: number;
      estimatedTotalCostGross: number;
    }>;
    serviceCostInfos: Array<{
      __typename?: "ServiceCostInfo";
      serviceID: string;
      costToDate: number;
      costToDateGross: number;
      estimatedTotalCost: number;
      estimatedTotalCostGross: number;
    }>;
  };
};

export type GetRecoveryProgressQueryVariables = Exact<{
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
}>;

export type GetRecoveryProgressQuery = {
  __typename?: "Query";
  getRecoveryProgress: Array<{
    __typename?: "RecoveryProgress";
    recorded: any;
    ordinal: number;
    lag: number;
  }>;
};

export type GetProjectSecretsQueryVariables = Exact<{
  projectId: Scalars["ID"];
}>;

export type GetProjectSecretsQuery = {
  __typename?: "Query";
  getProjectSecrets: Array<{
    __typename?: "Secret";
    projectId: string;
    name: string;
    value: string;
    created: any;
  }>;
};

export type ListVectorizersQueryVariables = Exact<{
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
}>;

export type ListVectorizersQuery = {
  __typename?: "Query";
  listVectorizers: Array<{
    __typename?: "Vectorizer";
    id: string;
    source: string;
    target: string;
    view: string;
    pendingItems: number;
    state: VectorInvocationState;
    lastInvocationFailureTimestamp?: any | null;
    lastInvocationCompletedId?: string | null;
    lastInvocationFailureId?: string | null;
  }>;
};

export type GetInvocationDetailsQueryVariables = Exact<{
  regionCode?: InputMaybe<Scalars["String"]>;
  projectId: Scalars["ID"];
  serviceId: Scalars["ID"];
  vectorizerId: Scalars["ID"];
  invocationId: Scalars["ID"];
}>;

export type GetInvocationDetailsQuery = {
  __typename?: "Query";
  getInvocationDetails: {
    __typename?: "InvocationDetails";
    vectorizerId: string;
    source: string;
    target: string;
    view: string;
    runAt?: any | null;
    durationMs?: number | null;
    isSuccess: boolean;
    configuration: string;
    logs?: string | null;
    executionError?: string | null;
  };
};

export type GetRecommendationsQueryVariables = Exact<{
  projectId: Scalars["ID"];
  serviceId?: InputMaybe<Scalars["ID"]>;
}>;

export type GetRecommendationsQuery = {
  __typename?: "Query";
  getRecommendations: Array<{
    __typename?: "Recommendation";
    id: string;
    projectId: string;
    serviceId: string;
    message: string;
    type: RecommendationType;
    metadata?:
      | {
          __typename?: "HypertableChangeChunkTimeIntervalMetadata";
          tableName: string;
          schemaName: string;
          recommendedIntervalSeconds: number;
        }
      | { __typename?: "JobFailedNTimesMetadata" }
      | null;
  }>;
};
