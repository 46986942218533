//Note: flag names and values must be synced with flagsmith
//A good idea to set boolean flags to 'true' for @timescale.com emails on flagsmith
export const SHOW_FLAG_CHOOSER_ON_PROD = "show-flag-chooser-on-prod";
export const IGNORE_FLAGSMITH = "ignore_flagsmith";
export const E2E_TEST_FLAG = "e2e_test_flag";
export const DEV_SUPPORTED_REGIONS = "show-dev-supported-regions";
export const VECTOR_FEATURE = "vector_feature";

export const FEATURE_FLAGS = {
  SHOW_FLAG_CHOOSER_ON_PROD,
  IGNORE_FLAGSMITH,
  E2E_TEST_FLAG,
  DEV_SUPPORTED_REGIONS,
  VECTOR_FEATURE,
};

export default FEATURE_FLAGS;
