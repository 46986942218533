import { SystemStyleObject } from "@chakra-ui/theme-tools";

const baseStyleContent: SystemStyleObject = {
  width: "400px",
  borderRadius: "16px",
  _focus: {
    outline: 0,
    boxShadow: "none",
  },
};

const baseStyleBody: SystemStyleObject = {
  px: "0",
  pt: "24px",
  pb: "0",
};

const baseStyleCloseButton: SystemStyleObject = {
  mt: "14px",
  mr: "14px",
};

const baseStyle: any = {
  content: baseStyleContent,
  body: baseStyleBody,
  closeButton: baseStyleCloseButton,
};

export const Popover = {
  baseStyle,
};
