import { TAG_COLOR } from "components/tags/tagTypes";
import { ServiceStatus, VPC_SIMPLE_CONNECTION_STATUS } from "types";
import { ThemeColors } from "theme/foundations/colors";
import {
  DeployStatus,
  PeeringConnectionStatus,
  VpcStatus,
} from "graphql/generated";

const statusToLabel = (str: string): string => {
  const capitalizeString =
    str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  return capitalizeString.replaceAll("_", " ");
};

export const PAUSE_STATUSES: ServiceStatus[] = [
  DeployStatus.Paused,
  DeployStatus.Pausing,
];

export const mapServiceStatus = (
  status: ServiceStatus | "READ_ONLY",
  customText?: string,
): { label: string; color: TAG_COLOR } => {
  switch (status) {
    case DeployStatus.Ready:
      return { label: customText || "Running", color: TAG_COLOR.SUCCESS };
    case DeployStatus.Optimizing:
      return { label: customText || "Optimizing", color: TAG_COLOR.SUCCESS };
    case DeployStatus.Queued:
    case DeployStatus.Resuming:
    case DeployStatus.Configuring:
    case DeployStatus.Deleting:
      return { label: customText || "In Progress", color: TAG_COLOR.WARNING };
    case DeployStatus.Paused:
    case DeployStatus.Pausing:
      return { label: customText || "Paused", color: TAG_COLOR.GRAYSCALE };
    case DeployStatus.Unstable:
      return { label: customText || "Pending", color: TAG_COLOR.WARNING };
    case DeployStatus.Upgrading:
      return { label: customText || "Upgrading", color: TAG_COLOR.WARNING };
    case "READ_ONLY":
      return { label: customText || "Read only", color: TAG_COLOR.ERROR };
    default:
      return {
        label: statusToLabel(status),
        color: TAG_COLOR.WARNING,
      };
  }
};

export interface VpcStatusSpec {
  color: ThemeColors;
  statusLabel: string;
  description: string;
}

type MapVpcStatus = (status: VpcStatus | undefined) => VpcStatusSpec;

export const mapVpcStatus: MapVpcStatus = (status) => {
  switch (status) {
    case "CREATED":
      return {
        color: "success.900",
        statusLabel: "Active",
        description: "Your VPC and all active connections are running.",
      };
    case "ERROR":
      return {
        color: "error.900",
        statusLabel: "Error",
        description: "There is an error with your VPC.  Please contact support",
      };
    case "CREATING":
      return {
        color: "warning.900",
        statusLabel: "Processing",
        description: "We are currently allocating a VPC for you.",
      };
    case "DELETING":
    case "DELETED":
      return {
        color: "warning.900",
        statusLabel: "Deleting",
        description: "We are currently deleting this VPC for you.",
      };
    default:
      return {
        color: "grayscale.700",
        statusLabel: "Unknown",
        description: "Retreiving status",
      };
  }
};

export const vpcTagMap = {
  [VPC_SIMPLE_CONNECTION_STATUS.READY]: {
    simpleStatus: VPC_SIMPLE_CONNECTION_STATUS.READY,
    iconName: "elements/Plug/Plug-Circle",
    color: TAG_COLOR.SUCCESS,
    label: "Connected",
  },
  [VPC_SIMPLE_CONNECTION_STATUS.IN_PROGRESS]: {
    simpleStatus: VPC_SIMPLE_CONNECTION_STATUS.IN_PROGRESS,
    iconName: "elements/Time/History",
    color: TAG_COLOR.WARNING,
    label: "Processing",
  },
  [VPC_SIMPLE_CONNECTION_STATUS.ERROR]: {
    simpleStatus: VPC_SIMPLE_CONNECTION_STATUS.ERROR,
    iconName: "status/Alert/Triangle",
    color: TAG_COLOR.ERROR,
    label: "Error",
  },
};

export const mapVpcPeeringStatus = (
  status: PeeringConnectionStatus | undefined,
  fallBack: any = vpcTagMap[VPC_SIMPLE_CONNECTION_STATUS.IN_PROGRESS],
) => {
  switch (status) {
    case PeeringConnectionStatus.Approved:
    case PeeringConnectionStatus.Active: {
      return vpcTagMap[VPC_SIMPLE_CONNECTION_STATUS.READY];
    }
    case PeeringConnectionStatus.Pending:
    case PeeringConnectionStatus.Deleting: {
      return vpcTagMap[VPC_SIMPLE_CONNECTION_STATUS.IN_PROGRESS];
    }
    case PeeringConnectionStatus.Invalid:
    case PeeringConnectionStatus.Timeout:
    case PeeringConnectionStatus.Disabled:
    case PeeringConnectionStatus.Unknown: {
      return vpcTagMap[VPC_SIMPLE_CONNECTION_STATUS.ERROR];
    }
    default:
      return fallBack;
  }
};
