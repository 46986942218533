import { Box, Stack } from "@chakra-ui/react";
import { FormikErrors } from "formik";

export interface GroupProps {
  name?: string;
  label?: string | JSX.Element;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[] | null;
  isDisabled?: boolean;
  [x: string]: any;
}

interface GroupBaseProps extends GroupProps {
  children: JSX.Element;
}

const GroupBase = ({
  name,
  label,
  error,
  isDisabled,
  children,
  ...rest
}: GroupBaseProps) => (
  <Stack direction="column" spacing="8px" {...rest}>
    {label && (
      <Box
        textStyle="base"
        color={isDisabled ? "grayscale.500" : "grayscale.700"}
      >
        {label}
      </Box>
    )}
    {children}
    {error && (
      <Box
        textStyle="caption"
        color="error.900"
        data-cy={`error-messages-${name}`}
      >
        <>{error}</>
      </Box>
    )}
  </Stack>
);

export default GroupBase;
