import { useEffect, useState } from "react";
import Container, { ContainerProps } from "./Container";
import { Flex } from "@chakra-ui/react";
import Icon from "../icon/Icon";
import Collapse from "../collapse/Collapse";

export interface BasicCardProps extends ContainerProps {
  header?: string | JSX.Element;
  canCollapse?: boolean;
  enableCollapseClick?: boolean;
  isOpen?: boolean;
  setIsOpen?: any;
  dataCy?: string;
  hasBackground?: boolean;
  hasBoxShadow?: boolean;
  children:
    | JSX.Element
    | string
    | null
    | Array<JSX.Element | JSX.Element[] | string | null>;
}

export const BasicCard = ({
  header,
  canCollapse = false,
  enableCollapseClick = true,
  isOpen = true,
  setIsOpen,
  dataCy,
  children,
  hasBackground = true,
  hasBoxShadow = true,
  ...rest
}: BasicCardProps) => {
  const [isExpanded, setIsExpanded] = useState(isOpen);
  useEffect(() => {
    setIsExpanded(isOpen);
  }, [isOpen]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container
      display="flex"
      flexDirection="column"
      p={hasBackground ? "24px" : "0px"}
      boxShadow={hasBoxShadow ? "sm" : "none"}
      margin={hasBoxShadow ? "2px 5px 7px 4px" : "unset"}
      borderRadius="16px"
      minW="200px"
      gap="24px"
      h="fit-content"
      {...(!hasBackground && { backgroundColor: "transparent" })}
      {...rest}
    >
      {(header || canCollapse) && (
        <Flex
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          onClick={() =>
            canCollapse && enableCollapseClick && setIsExpanded(!isExpanded)
          }
          cursor={canCollapse && enableCollapseClick ? "pointer" : "auto"}
          data-cy={dataCy}
        >
          {header && (
            <Flex textStyle="subtitle4Bold" width="100%">
              {header}
            </Flex>
          )}
          {canCollapse && enableCollapseClick && (
            <Icon name={`actions/Chevron/${isExpanded ? "Up" : "Down"}`} />
          )}
        </Flex>
      )}

      {/*
        If the card isn't collapsible, don't render the Collapse
        component since it has a bit of extra height to it, even
        when closed.
      */}
      {canCollapse && (
        <Collapse in={!canCollapse || isExpanded}>{children}</Collapse>
      )}
      {!canCollapse && <>{children}</>}
    </Container>
  );
};
