import { useEffect, useState } from "react";
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  Stack,
  Text,
} from "@chakra-ui/react";
import Button from "../button/Button";
import Icon from "../icon/Icon";
import { useMountedState } from "react-use";

export const CopyButton = ({
  valueToCopy,
  copyEvent,
}: {
  valueToCopy?: string;
  copyEvent?: () => void;
}) => {
  const [wasCopied, setWasCopied] = useState(false);
  const isMounted = useMountedState();

  return (
    <Button
      size="sm"
      variant="secondary"
      icon={<Icon name="actions/Copy" />}
      backgroundColor="grayscale.0"
      position="absolute"
      right="6px"
      top="6px"
      zIndex="100"
      onClick={() => {
        setWasCopied(true);
        valueToCopy && navigator.clipboard.writeText(valueToCopy);
        copyEvent && copyEvent();
        setTimeout(() => {
          if (isMounted()) {
            setWasCopied(false);
          }
        }, 1000);
      }}
    >
      {wasCopied ? "Copied!" : "Copy"}
    </Button>
  );
};

export const ReadOnlyInput = ({
  dataCy,
  isCode,
  inputLeftElement,
  label,
  value,
  inputProps,
  customButton,
  copyEvent,
  ...rest
}: InputProps & {
  dataCy?: string;
  isCode?: boolean;
  inputLeftElement?: JSX.Element;
  label?: string;
  inputProps?: any;
  customButton?: JSX.Element;
  copyEvent?: () => void;
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const [showButton, setShowButton] = useState(isHovered || isFocused);

  useEffect(() => {
    setShowButton(isHovered || isFocused);
  }, [isHovered, isFocused]);

  return (
    <Stack
      spacing="8px"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      onFocus={() => setIsFocused(true)}
      onBlur={() => {
        setIsFocused(false);
        setIsHovered(false);
      }}
      {...rest}
    >
      {label && (
        <Text textStyle="base" color="grayscale.700" align="left">
          {label}
        </Text>
      )}
      <InputGroup data-cy={dataCy} position="relative">
        {inputLeftElement && (
          <InputLeftElement pointerEvents="none" children={inputLeftElement} />
        )}
        <Input
          value={value}
          onChange={() => {}}
          bg={isCode ? "grayscale.800" : "primary.200"}
          borderColor={isCode ? "grayscale.800" : "primary.200"}
          textStyle={isCode ? "codeS" : "base"}
          color={isCode ? "grayscale.0" : "grayscale.800"}
          _hover={{ borderColor: "primary.200" }}
          sx={{ ...inputProps?.sx, fontVariantLigatures: "none" }}
        />
        {showButton ? (
          customButton ? (
            customButton
          ) : (
            <CopyButton
              valueToCopy={value ? value.toString() : undefined}
              copyEvent={copyEvent}
            />
          )
        ) : null}
      </InputGroup>
    </Stack>
  );
};

export default ReadOnlyInput;
