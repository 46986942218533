import React from "react";

// https://stackoverflow.com/questions/70302376/how-to-type-custom-preload-method-for-react-lazy-components

interface LazyPreload<Props>
  extends React.LazyExoticComponent<React.ComponentType<Props>> {
  preload: () => {};
}

export function LazyWithPreload<Props>(
  importStatement: () => Promise<{ default: React.ComponentType<Props> }>,
) {
  // use Object.assign to set preload
  // otherwise it will complain that Component doesn't have preload
  const Component: LazyPreload<Props> = Object.assign(
    React.lazy(importStatement),
    {
      preload: importStatement,
    },
  );

  return Component;
}
