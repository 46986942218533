import { Flex, StyleProps, Text } from "@chakra-ui/react";

export const STEP_NUMBER_SIZE = ["small", "medium", "large"] as const;
export type StepNumberSize = (typeof STEP_NUMBER_SIZE)[number];

export const STEP_NUMBER_COLOR = [
  "grayscale.900",
  "grayscale.300",
  "primary.900",
  "primary.200",
  "warning.900",
] as const;
export type StepNumberColor = (typeof STEP_NUMBER_COLOR)[number];

export interface StepNumberProps extends Omit<StyleProps, "color"> {
  label?: string | number;
  size?: StepNumberSize;
  color?: StepNumberColor;
  isFilled?: boolean;
}

export const SIZE_CONFIG: Record<
  StepNumberSize,
  { boxSize: string; textStyle: string }
> = {
  small: {
    boxSize: "8px",
    textStyle: "",
  },
  medium: {
    boxSize: "20px",
    textStyle: "captionBold",
  },
  large: {
    boxSize: "28px",
    textStyle: "baseBold",
  },
};

const COLOR_CONFIG: (
  isFilled: boolean,
) => Record<
  StepNumberColor,
  { background: string; textColor: string; borderColor: string }
> = (isFilled: boolean) => ({
  "grayscale.900": {
    background: `${isFilled ? "grayscale.900" : "grayscale.0"}`,
    textColor: `${isFilled ? "grayscale.0" : "grayscale.900"}`,
    borderColor: "grayscale.900",
  },
  "grayscale.300": {
    background: `${isFilled ? "grayscale.300" : "grayscale.0"}`,
    textColor: `${isFilled ? "grayscale.0" : "grayscale.300"}`,
    borderColor: "grayscale.300",
  },
  "primary.900": {
    background: `${isFilled ? "primary.900" : "grayscale.0"}`,
    textColor: `${isFilled ? "grayscale.0" : "primary.900"}`,
    borderColor: "primary.900",
  },
  "primary.200": {
    background: `${isFilled ? "primary.200" : "grayscale.0"}`,
    textColor: "primary.900",
    borderColor: `${isFilled ? "primary.200" : "primary.500"}`,
  },
  "warning.900": {
    background: `${isFilled ? "warning.900" : "grayscale.0"}`,
    textColor: `${isFilled ? "grayscale.0" : "warning.900"}`,
    borderColor: `${isFilled ? "warning.900" : "warning.900"}`,
  },
});

export const StepNumber = ({
  label,
  size = "large",
  color = "grayscale.900",
  isFilled = true,
  ...customStyles
}: StepNumberProps) => {
  const { boxSize, textStyle } = SIZE_CONFIG[size];
  const { background, textColor, borderColor } = COLOR_CONFIG(isFilled)[color];

  return (
    <Flex
      background={background}
      alignItems="center"
      justifyContent="center"
      borderRadius="50%"
      minWidth={boxSize}
      minHeight={boxSize}
      height={boxSize}
      borderColor={borderColor}
      borderStyle="solid"
      borderWidth="1px"
      overflow="hidden"
      {...customStyles}
    >
      {/* `small` drops the number label */}
      {size !== "small" && (
        <Text textStyle={textStyle} color={textColor}>
          {label}
        </Text>
      )}
    </Flex>
  );
};

export default StepNumber;
