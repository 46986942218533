import { observer } from "mobx-react";
import { useStores } from "stores";
import { Flex, Box, Text, Link } from "@chakra-ui/react";
import { Formik, Form, Field, FieldProps } from "formik";
import { useMutation } from "@apollo/react-hooks";
import { SEND_NEW_PASSWORD } from "api/mutations";
import Button from "components/button/Button";
import InputGroup from "components/input/InputGroup";
import * as Yup from "yup";
import Filler from "components/filler/Filler";
import { Container } from "./registerComponents";

const emailValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Required"),
});

export const ForgotPasswordLink = ({ ...customStyles }) => {
  const { authStore } = useStores();
  const handleClick = () => {
    authStore.setForgotPassword(!authStore.forgotPassword);
  };
  return (
    <Link
      data-cy="forgot-password"
      onClick={handleClick}
      display="block"
      fontSize="12px"
      {...customStyles}
    >
      Forgot password?
    </Link>
  );
};

export const ForgotPassword = () => {
  const { authStore } = useStores();
  const { isResetPasswordPending, forgotPassword } = authStore;
  const [sendPasswordReset, { data, reset }] = useMutation(SEND_NEW_PASSWORD);

  const handleClick = () => {
    authStore.setResetPasswordStatus(false);
    reset();
  };
  const handlePasswordFormClick = () =>
    authStore.setForgotPassword(!forgotPassword);

  if (isResetPasswordPending) {
    return (
      <ForgotPasswordMessage
        onClickResend={handleClick}
        onClickSignIn={handlePasswordFormClick}
      />
    );
  }
  if (data) {
    authStore.setResetPasswordStatus(true);
  }

  return (
    <Formik
      initialValues={{ email: "" }}
      validationSchema={emailValidationSchema}
      onSubmit={async ({ email }: any, { setSubmitting }) => {
        setSubmitting(true);
        await sendPasswordReset({
          variables: {
            email,
          },
        });
        setSubmitting(false);
        return;
      }}
    >
      {({ isSubmitting, values }) => (
        <PasswordForm
          isSubmitting={isSubmitting}
          values={values}
          onClickSignIn={handlePasswordFormClick}
        />
      )}
    </Formik>
  );
};

export const PasswordForm = ({ isSubmitting, onClickSignIn }: any) => (
  <Form noValidate>
    <Container>
      <Flex
        gap="16px"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Filler.SVGFiller
          imagePath="neutral/Click.svg"
          variant="other"
          headline="Forgot your password?"
          mt="0"
        />
        <Box textAlign="center" mb="40px" textStyle="base">
          Dangit. We hate when that happens. Please enter the email address you
          used to register.
        </Box>
      </Flex>
      <Field name="email">
        {({ field, meta }: FieldProps) => (
          <InputGroup
            data-cy="forgot-email"
            label="Email address"
            placeholder="Email address"
            error={meta.touched && meta.error ? meta.error : null}
            layoutProps={{ mb: "40px" }}
            {...field}
          />
        )}
      </Field>
      <Button
        data-cy="forgot-button"
        type="submit"
        size="lg"
        maxW="unset"
        w="100%"
        mb="24px"
        isLoading={isSubmitting}
      >
        Reset my password
      </Button>
      <Flex gap="2px" textStyle="caption" justifyContent="center">
        Remembered your password?
        <Link
          data-cy="remembered-password"
          onClick={onClickSignIn}
          cursor="pointer"
          textStyle="captionLink"
        >
          Log in
        </Link>
      </Flex>
    </Container>
  </Form>
);

export const ForgotPasswordMessage = ({
  onClickResend,
  onClickSignIn,
}: any) => (
  <Container>
    <Flex
      gap="16px"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
    >
      <Filler.SVGFiller
        imagePath="neutral/Click.svg"
        variant="other"
        headline="Please check your email"
        mt="0"
      />
      <Box mb="24px" textStyle="base">
        We just sent a link to reset your password. <br />
        Please check your email.
      </Box>
      <Button
        type="submit"
        size="lg"
        maxW="unset"
        w="100%"
        mb="8px"
        onClick={onClickSignIn}
      >
        Log in
      </Button>
      <Flex>
        <Text as="span" textStyle="caption">
          Did not receive email?&nbsp;
        </Text>
        <Link textStyle="captionLink" onClick={onClickResend}>
          Resend
        </Link>
      </Flex>
    </Flex>
  </Container>
);

export default observer(ForgotPassword);
