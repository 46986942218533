import { StylesConfig } from "react-select";
import { OptionType } from "./dropdownTypes";
import { typography } from "../../theme/foundations/typography";
import { BOX_SHADOW_VARIANT } from "../container/Container";
import { focusIndicator } from "theme/foundations/accessibility";

const stylesValueContainer = {
  padding: "0 0 0 8px",
};

const stylesValueContainerMulti = {
  padding: "0 0 3px 3px",
};

const stylesValueContainerMultiSmallScreen = {
  textOverflow: "ellipsis",
  maxWidth: "90%",
  whiteSpace: "nowrap" as any,
  overflow: "hidden",
};

export const dropdownCustomStyles: StylesConfig<OptionType> = {
  container: (provided, state) => ({
    ...provided,
    boxShadow: "none",
    borderRadius: "8px",
    color: "var(--chakra-colors-grayscale-700)",
    width: state.selectProps.width,
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: "8px",
    minHeight: "36px",
    backgroundColor: "var(--chakra-colors-grayscale-0)",
    borderColor: `var(--chakra-colors-grayscale-${
      state.isDisabled ? 300 : 500
    })`,
    "&:hover, &:focus-within": {
      borderColor: "var(--chakra-colors-primary-900)",
    },
    "&:focus-within": {
      ...focusIndicator,
    },
    width: state.selectProps.width,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    ...(state.isMulti && state.hasValue
      ? //@ts-ignore
        state.smallScreen
        ? stylesValueContainerMultiSmallScreen
        : stylesValueContainerMulti
      : stylesValueContainer),
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: `var(--chakra-colors-grayscale-${state.isDisabled ? 300 : 800})`,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: `var(--chakra-colors-grayscale-${state.isDisabled ? 300 : 800})`,
    display: "flex",
    alignItems: "center",
    path: {
      fill: `${
        state.isDisabled ? "var(--chakra-colors-grayscale-300)" : null
      }}`,
    },
  }),
  multiValue: (provided) => ({
    ...provided,
    borderRadius: "8px",
    backgroundColor: "var(--chakra-colors-primary-300)",
    border: "1px solid var(--chakra-colors-primary-900)",
    margin: "3px 3px 0 0",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    borderRadius: "8px",
    color: "var(--chakra-colors-primary-900)",
    backgroundColor: "var(--chakra-colors-primary-300)",
    ...typography.textStyles.baseBold,
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    borderRadius: "8px",
    color: "var(--chakra-colors-primary-900)",
    "&:hover": {
      color: "var(--chakra-colors-primary-900)",
      backgroundColor: "var(--chakra-colors-primary-300)",
    },
    path: {
      fill: "var(--chakra-colors-primary-900)",
    },
  }),
  menu: (provided) => ({
    ...provided,
    boxShadow: BOX_SHADOW_VARIANT.sm,
    top: "initial",
    zIndex: "101",
    borderRadius: "8px",
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 101,
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: "8px",
    border: "1px solid var(--chakra-colors-primary-400)",
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    padding: "8px",
    backgroundColor: state.isSelected
      ? "var(--chakra-colors-primary-300)"
      : "var(--chakra-colors-grayscale-0)",
    "&:hover": {
      backgroundColor: state.isSelected
        ? "var(--chakra-colors-primary-300)"
        : "var(--chakra-colors-primary-100)",
    },
    color: `var(--chakra-colors-grayscale-${state.isDisabled ? 300 : 700})`,
    "&:active": {
      backgroundColor: "var(--chakra-colors-primary-100)",
    },
    path: {
      fill: `${state.isDisabled ? "var(--chakra-colors-grayscale-300)" : ""}`,
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    transform: `${state.selectProps.menuIsOpen ? "rotate(-180deg)" : "none"}`,
    path: {
      fill: `var(--chakra-colors-grayscale-${state.isDisabled ? 300 : 800})`,
    },
  }),
  clearIndicator: (provided) => ({
    ...provided,
    display: "none",
  }),
};

export const dropdownBorderlessCustomStyles: StylesConfig<OptionType> = {
  ...dropdownCustomStyles,
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: 0,
    cursor: "pointer",
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: "inherit",
    borderWidth: 0,
    "&:focus-within": {
      boxShadow: "none",
    },
    minHeight: "unset",
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: "2px",
    borderRadius: "0px 0px 8px 8px",
    border: "1px solid var(--chakra-colors-primary-400)",
    borderTop: "1px solid var(--chakra-colors-primary-900)",
    zIndex: 1000,
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: "0px 0px 8px 8px",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    transform: `${state.selectProps.menuIsOpen ? "rotate(-180deg)" : "none"}`,
    padding: 0,
  }),
};

export const dropdownBorderlessNavCustomStyles: StylesConfig<
  OptionType,
  boolean
> = {
  ...dropdownBorderlessCustomStyles,
  menu: (provided) => ({
    ...provided,
    marginTop: "8px",
    borderRadius: "8px",
    border: "1px solid var(--chakra-colors-primary-400)",
    boxShadow:
      "1px 3px 4px rgba(0, 0, 0, 0.06), 1px 5px 4px -1px rgba(0, 0, 0, 0.06)",
  }),
  menuList: (provided) => ({
    ...provided,
    padding: "4px 0",
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    padding: "8px",
    backgroundColor: "var(--chakra-colors-grayscale-0)",
    "&:hover": {
      backgroundColor: "var(--chakra-colors-primary-100)",
    },
    color: `var(--chakra-colors-grayscale-${state.isDisabled ? 300 : 700})`,
    "&:active": {
      backgroundColor: "var(--chakra-colors-primary-100)",
    },
    display: "flex",
    alignItems: "center",
    path: {
      fill: `${state.isDisabled ? "var(--chakra-colors-grayscale-300)" : ""}`,
    },
  }),
};
