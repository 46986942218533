import { toJS } from "mobx";
import { storeDirectlyIntoStorage } from "utils/localStorage";
import { LOCAL_STORAGE_USER_DATA } from "utils/localStorage";
import { ObservableQuery } from "@apollo/client";
import { Subscription } from "zen-observable-ts";
import { GraphQLError } from "graphql";
import { IFlags } from "flagsmith/types";
import { FeatureFlagList } from "./featuresStore";

export const reactionStoreLocal = (localKey: string) => (params: any) => {
  storeDirectlyIntoStorage(localKey, toJS(params), LOCAL_STORAGE_USER_DATA);
};

export type QueryWatcher = {
  watcher: ObservableQuery<any, any>;
  subscription: Subscription;
};

export const checkServiceDeletedError = (errors?: readonly GraphQLError[]) =>
  errors && /no service.+id.+exists/i.test(errors[0].message);

export const mapFlags = (
  allFlags: IFlags<string>,
  storeAllAsStrings: boolean = false,
) =>
  Object.keys(allFlags).reduce((heapAttributes: FeatureFlagList, flagName) => {
    const flag = allFlags[flagName];
    // If flag is multivariate, flag.value is a string, so returned flag is <string>(flag.enabled AND 'value') or "false" (flag.enabled: false)
    // If flag is boolean, flag.value is null, so returned flag is true (flag.enabled and "true") or "false" (flag.enabled: false)
    heapAttributes[flagName] = flag.enabled
      ? (typeof flag.value === "string" && flag.value) || //multivariate flag
        (storeAllAsStrings ? "true" : true) //boolean flag
      : storeAllAsStrings
        ? "false"
        : false;
    return heapAttributes;
  }, {});
