export enum TAG_COLOR {
  SUCCESS = "success",
  WARNING = "warning",
  GRAYSCALE = "grayscale",
  GRAYSCALE700 = "grayscale700",
  ERROR = "error",
  PRIMARY = "primary",
  PRIMARY600 = "primary600",
}

export type TAG_SIZE = "small" | "large";

export enum TAG_FONT_STYLE {
  UNDERLINE = "underline",
  DASHED = "dashed",
}
