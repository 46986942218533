import { autorun, toJS, makeAutoObservable, reaction, runInAction } from "mobx";
import { FEATURE_FLAGS, IGNORE_FLAGSMITH } from "utils/featureFlags";
import { PROD } from "utils/config";
import { ENV } from "utils/hostEnv";
import {
  LOCAL_STORAGE_ACTIVE_FEATURES,
  USER_ID_UNKNOWN,
  storeDirectlyIntoStorage,
  removeFromStorage,
} from "utils/localStorage";
import flagsmith from "flagsmith";
import { ValueOf } from "types/extensions";
import userStore from "./userStore";

export type FeatureFlagNames = ValueOf<typeof FEATURE_FLAGS>;

export type FeatureFlagList = Record<FeatureFlagNames, string | boolean>;

const localActiveFeatures = JSON.parse(
  sessionStorage.getItem(LOCAL_STORAGE_ACTIVE_FEATURES) || "{}",
);
export class FeaturesStore {
  flagsmithFeatures: FeatureFlagList = {};
  allConsoleActiveFeatures = {
    ...localActiveFeatures,
    [USER_ID_UNKNOWN]: {
      [FEATURE_FLAGS.SHOW_FLAG_CHOOSER_ON_PROD]:
        process.env.REACT_APP_SHOW_FLAGS_ON_PROD ||
        flagsmith.hasFeature(FEATURE_FLAGS.SHOW_FLAG_CHOOSER_ON_PROD) ||
        ENV.NAME !== PROD,
      [FEATURE_FLAGS.IGNORE_FLAGSMITH]: false,
      ...localActiveFeatures[USER_ID_UNKNOWN],
    },
  };
  currentUserId = null;
  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.currentUserId,
      (id) => {
        if (id) {
          this.allConsoleActiveFeatures = {
            [id]: {
              ...this.allConsoleActiveFeatures[USER_ID_UNKNOWN],
              ...this.allConsoleActiveFeatures[id],
            },
          };
        }
      },
    );

    autorun(() => {
      storeDirectlyIntoStorage(
        this.featureId,
        toJS(this.currentFeatures),
        LOCAL_STORAGE_ACTIVE_FEATURES,
      );
      if (this.currentUserId) {
        removeFromStorage(USER_ID_UNKNOWN, LOCAL_STORAGE_ACTIVE_FEATURES);
      }
    });
  }

  get featureId() {
    return this.currentUserId || USER_ID_UNKNOWN;
  }

  get localFeatures() {
    return this.allConsoleActiveFeatures[this.featureId] || {};
  }

  get currentFeatures() {
    const newCurrent: FeatureFlagList = {};
    Object.values(FEATURE_FLAGS).forEach((featureName) => {
      const localFeature = ["boolean", "string"].includes(
        typeof this.localFeatures[featureName],
      )
        ? this.localFeatures[featureName]
        : null;
      // If we set this `IGNORE_FLAGSMITH` flag, it just reads state locally.
      newCurrent[featureName] = this.localFeatures[IGNORE_FLAGSMITH]
        ? (localFeature ?? this.flagsmithFeatures[featureName])
        : (this.flagsmithFeatures[featureName] ?? localFeature);
    });
    return newCurrent;
  }

  setFlagsmithFeatures = async (featureMap: FeatureFlagList) => {
    runInAction(() => {
      this.flagsmithFeatures = featureMap;
    });
    await this.activateFeatures(this.currentFeatures);
  };

  getAllFeatureStates = () => {
    const allFeatures: FeatureFlagList = {};
    Object.values(FEATURE_FLAGS).forEach((feature) => {
      allFeatures[feature] = this.featureValue(feature);
    });
    return allFeatures;
  };

  activateFeatures = async (featuresObject: FeatureFlagList) => {
    runInAction(() => {
      this.allConsoleActiveFeatures = {
        ...this.allConsoleActiveFeatures,
        [this.featureId]: featuresObject,
      };
    });
    if (this.currentUserId) {
      await userStore.setUserPersona({
        uiState: {
          ...userStore.uiState,
          flags: toJS(this.allConsoleActiveFeatures),
        },
        userId: this.currentUserId,
      });
    }
  };

  clearStore = () => {
    this.allConsoleActiveFeatures = {};
  };

  isActiveFeature = (featureName: FeatureFlagNames) =>
    Boolean(this.currentFeatures[featureName]);

  // same as isActiveFeature, but for multivariate flags.  Eventually replace isActiveFeature
  featureValue = (featureName: FeatureFlagNames) =>
    this.currentFeatures[featureName];
}

const featuresStore = new FeaturesStore();

export default featuresStore;
