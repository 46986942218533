import { Service } from "graphql/generated";

export type ConnectionPoolerMode = "session" | "transaction";

export const connectionPoolerModeOptions = [
  { value: "session", label: "Session pool" },
  { value: "transaction", label: "Transaction pool" },
];

export const getConnectionConfig = (
  service: Service,
  isPrimary: boolean,
  connectionPooler?: { selected: boolean; mode: ConnectionPoolerMode },
) => {
  const { endpoints } = service || {};
  // backend resolves VPC host and port for all endpoints
  const { primary, replica, pooler } = endpoints || {};
  const { host: primaryHostname, port: primaryPort } = primary || {};
  const { host: replicaHostName, port: replicaPort } = replica || {};
  const { host: poolerHostName, port: poolerPort } = pooler || {};

  const { username, defaultDBName } = service?.spec || {};

  const isPooler = !!connectionPooler?.selected;

  const getUrl = (cHost: string, cPort: number, cDefaultDBName: string) =>
    `postgres://${username}@${cHost}:${cPort}/${cDefaultDBName}?sslmode=require`;

  const dbName = isPooler
    ? connectionPooler.mode === "transaction"
      ? `${defaultDBName}_transaction`
      : defaultDBName
    : defaultDBName;

  // case: connection pooler
  if (isPooler) {
    return {
      connectionHost: poolerHostName!,
      connectionPort: poolerPort!,
      url: getUrl(poolerHostName!, poolerPort!, dbName),
      defaultDBName: dbName,
    };
  }

  // case: HA replica
  if (!isPrimary) {
    return {
      connectionHost: replicaHostName!,
      connectionPort: replicaPort!,
      url: getUrl(replicaHostName!, replicaPort!, defaultDBName),
      defaultDBName,
    };
  }

  // default case: primary service
  return {
    connectionHost: primaryHostname!,
    connectionPort: primaryPort!,
    url: getUrl(primaryHostname!, primaryPort!, defaultDBName),
    defaultDBName,
  };
};
