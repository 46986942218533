import { useEffect } from "react";
import { Box, Flex, Link, Text } from "@chakra-ui/react";
import Button from "components/button/Button";
import { Code } from "components/code/Code";
import {
  createHypertableCode,
  createHypertableConvertCode,
  createHypertableTableCode,
  createPostgresCode,
  insertDataCode,
  insertPostgresDataCode,
  queryHypertableCode,
  queryPostgresCode,
} from "pages/project/service/instructions/codeExamples";
import { useHistory, Link as ReactLink } from "react-router-dom";
import { useStoresUntyped } from "stores";
import { GETTING_STARTED_PROGRESS_KEY } from "utils/localStorage";
import { getConnectionConfig } from "pages/project/service/overview/connectionInfoUtils";
import ReadOnlyInput from "components/input/ReadOnlyInput";
import { Type } from "graphql/generated";
import Step from "components/steps/Step";
import FeedbackSecondary from "components/feedbackSecondary/FeedbackSecondary";
import FeedbackRecommendation from "components/feedbackRecommendation/FeedbackRecommendation";
import {
  ServiceQueryEngineContext,
  useServiceQueryEngine,
} from "contexts/queryEngine";
import { RunnableCode } from "components/code/RunnableCode";

type AdditionalStepsCompletion = {
  addTables: boolean;
  loadData: boolean;
  queryData: boolean;
};

export type GettingStartedProgress = {
  serviceId: string;
  variantType: "timescale_standard";
  serviceType: Type;
  completedTimestamp: string | null;
  additionalStepsCompletion: AdditionalStepsCompletion;
};

export const useStartedSteps = () => {
  const { serviceStore, userStore, projectsStore } = useStoresUntyped();
  const { uiState } = userStore;
  const { serviceById } = serviceStore;

  // Note: serviceId is the id of the service that was created for this experiment.
  // service.id is the id of the full service returned from state/API.
  // A serviceId existing but service.id not means the service itself was likely deleted.
  const gettingStartedProgressData: GettingStartedProgress =
    uiState?.[GETTING_STARTED_PROGRESS_KEY] || "{}";
  const { serviceId, additionalStepsCompletion } = gettingStartedProgressData;
  const projectId = projectsStore?.currentProject?.id;
  const service = serviceById(serviceId);
  const serviceType = service?.type || undefined;

  const completeStep = (stepKey: keyof AdditionalStepsCompletion) => {
    userStore.mergeUiState({
      [GETTING_STARTED_PROGRESS_KEY]: {
        ...gettingStartedProgressData,
        additionalStepsCompletion: {
          ...gettingStartedProgressData.additionalStepsCompletion,
          [stepKey]: true,
        },
      },
    });
  };

  useEffect(() => {
    serviceStore.getPostgresAndTimescaleDbVersions({ projectId, serviceId });
  }, [projectId, serviceId, serviceStore]);

  const { serviceVersions } = serviceStore;
  const dbVersion = serviceVersions?.[serviceId]?.postgresVersion?.installed;

  const command = `psql "${getConnectionConfig(service, true).url}"`;

  const steps = [
    {
      title: "Verify your email address",
      content: <></>,
      isCompleted: true,
    },
    {
      title: "Create a service",
      content: <CreateService />,
      isCompleted: Boolean(serviceId),
    },
    {
      title: serviceType === Type.Postgres ? "Add tables" : "Add hypertables",
      content: (
        <CreateHypertable
          serviceId={serviceId}
          command={command}
          serviceType={serviceType}
          dbVersion={dbVersion}
          isCurrent={Boolean(serviceId)}
          completeStep={completeStep}
        />
      ),
      isCompleted: additionalStepsCompletion?.addTables,
    },
    {
      title: "Load data",
      content: (
        <LoadData
          serviceId={serviceId}
          serviceType={serviceType}
          completeStep={completeStep}
          isCurrent={additionalStepsCompletion?.addTables} // `isCurrent` is a bit faked, as it checks the previous state completion.
        />
      ),
      isCompleted: additionalStepsCompletion?.loadData,
    },
    {
      title: "Query your data",
      content: (
        <QueryData
          serviceId={serviceId}
          serviceType={serviceType}
          completeStep={completeStep}
          isCurrent={additionalStepsCompletion?.loadData}
        />
      ),
      isCompleted: additionalStepsCompletion?.queryData,
    },
  ];

  const indexDone = steps.filter((step) => step.isCompleted).length;
  const percentDone = Math.trunc((indexDone / steps.length) * 100);

  return { steps, percentDone, indexDone };
};

const CreateService = () => {
  const history = useHistory();

  return (
    <Flex direction="column" gap="8px">
      <Text as="span" ml="24px" color="grayscale.700">
        In the Timescale console, you create a service to house your Timescale
        database. Each service contains a single database. To split up your
        data, you can create multiple services or create separate schemas within
        one service.
      </Text>
      <Button
        data-cy="getting-started-create-service"
        size="sm"
        ml="24px"
        onClick={() => history.push("/dashboard/create_services")}
      >
        Create service
      </Button>
    </Flex>
  );
};

const CreateHypertable = ({
  serviceId,
  command,
  serviceType,
  dbVersion,
  isCurrent,
  completeStep,
}: {
  serviceId: string;
  dbVersion?: string;
  command: string;
  serviceType: Type;
  isCurrent: boolean;
  completeStep: (stepKey: keyof AdditionalStepsCompletion) => void;
}) => {
  const isPostgres = serviceType === Type.Postgres;

  const ctx = useServiceQueryEngine({
    serviceId,
  });

  const migrateInfo = (
    <FeedbackSecondary iconName="elements/Database/Server-2" my="16px">
      <Text textStyle="caption">
        Learn more about how to{" "}
        <Link
          href="https://docs.timescale.com/migrate/latest/"
          isExternal={true}
          textStyle="captionLink"
        >
          migrate your own data
        </Link>{" "}
        with our guides.
      </Text>
    </FeedbackSecondary>
  );

  const markCompletedBtn = isCurrent ? (
    <Button
      size="sm"
      variant="secondary"
      maxW="unset"
      onClick={() => completeStep("addTables")}
    >
      Mark as completed
    </Button>
  ) : null;

  return (
    <Flex direction="column" gap="8px">
      {!serviceId ? (
        <FeedbackRecommendation
          styleVariant="info"
          isBackgroundOn={true}
          show={true}
          marginLeft="24px"
        >
          Once you've created a service, you will connect and create a table
          within the database either directly, or when you migrate your data.
        </FeedbackRecommendation>
      ) : ctx.integrationEnabled ? (
        <ServiceQueryEngineContext.Provider value={ctx}>
          <Flex direction="column" gap={2} overflow="hidden" marginLeft="24px">
            <Text>Run the following commands</Text>
            <RunnableCode
              id="getting-started-ht-1"
              code={isPostgres ? createPostgresCode : createHypertableTableCode}
            />
            {!isPostgres && (
              <RunnableCode
                id="getting-started-ht-2"
                code={createHypertableConvertCode}
                resultsHeight={75}
              />
            )}
            {migrateInfo}
            {markCompletedBtn}
          </Flex>
        </ServiceQueryEngineContext.Provider>
      ) : (
        <Flex direction="column" marginLeft="24px">
          <Step.Layout
            label={1}
            key="step-1"
            isLineVisible={false}
            stepGap="8px"
          >
            <Step.Header textStyle="subtitle4">
              Connect to your service
            </Step.Header>
            <Step.Body>
              <Box>
                {dbVersion && (
                  <Box mb="8px">PostgreSQL version: {dbVersion}</Box>
                )}
                <ReadOnlyInput
                  isCode={true}
                  value={command}
                  inputLeftElement={<Text color="grayscale.0">$</Text>}
                  w="100%"
                />
                <FeedbackSecondary iconName="elements/App/General" my="16px">
                  <Text textStyle="caption">
                    Learn more about how to{" "}
                    <Link
                      href="https://www.timescale.com/blog/how-to-install-psql-on-mac-ubuntu-debian-windows/"
                      isExternal={true}
                      textStyle="captionLink"
                    >
                      get started with psql
                    </Link>
                    .
                  </Text>
                </FeedbackSecondary>
              </Box>
            </Step.Body>
          </Step.Layout>
          <Step.Layout
            label={2}
            key="step-2"
            isLineVisible={false}
            stepGap="8px"
          >
            <Step.Header textStyle="subtitle4">
              Copy & run the following command in psql
            </Step.Header>
            <Step.Body>
              <Box mt="-16px">
                You'll be asked to enter your service password when running this
                command.
              </Box>
              <Link
                as={ReactLink}
                to={`/dashboard/services/${serviceId}/operations/management/reset`}
                textStyle="captionLink"
              >
                Forget service password?
              </Link>

              <Code
                withLines={true}
                copyFn={true}
                code={
                  serviceType === Type.Postgres
                    ? createPostgresCode
                    : createHypertableCode
                }
                my="16px"
                data-cy="copy-hypertable"
              />

              {migrateInfo}
              {markCompletedBtn}
            </Step.Body>
          </Step.Layout>
        </Flex>
      )}
    </Flex>
  );
};

const LoadData = ({
  serviceId,
  serviceType,
  isCurrent,
  completeStep,
}: {
  serviceId: string;
  serviceType: Type;
  isCurrent: boolean;
  completeStep: (stepKey: keyof AdditionalStepsCompletion) => void;
}) => {
  const ctx = useServiceQueryEngine({
    serviceId,
  });

  return (
    <ServiceQueryEngineContext.Provider value={ctx}>
      <Flex direction="column" gap="8px" ml="24px">
        <Text as="span" color="grayscale.700">
          Run the following code
        </Text>
        <RunnableCode
          id="getting-started-insert"
          withLines={true}
          copyFn={true}
          code={
            serviceType === Type.Postgres
              ? insertPostgresDataCode
              : insertDataCode
          }
          data-cy="copy-insert"
        />
        <FeedbackSecondary iconName="elements/Chart" mt="16px">
          <Text textStyle="caption">
            Learn more about how to{" "}
            <Link
              href="https://docs.timescale.com/use-timescale/latest/write-data/insert/"
              isExternal={true}
              textStyle="captionLink"
            >
              insert data.
            </Link>
          </Text>
        </FeedbackSecondary>

        {isCurrent && (
          <Button
            size="sm"
            variant="secondary"
            my="16px"
            maxW="unset"
            onClick={() => completeStep("loadData")}
          >
            Mark as completed
          </Button>
        )}
      </Flex>
    </ServiceQueryEngineContext.Provider>
  );
};

const QueryData = ({
  serviceId,
  serviceType,
  isCurrent,
  completeStep,
}: {
  serviceId: string;
  serviceType: Type;
  isCurrent: boolean;
  completeStep: (stepKey: keyof AdditionalStepsCompletion) => void;
}) => {
  const ctx = useServiceQueryEngine({
    serviceId,
  });

  return (
    <ServiceQueryEngineContext.Provider value={ctx}>
      <Flex direction="column" gap="8px" ml="24px">
        <Text as="span" color="grayscale.700">
          Run the following code
        </Text>
        <RunnableCode
          id="getting-started-query"
          withLines={true}
          copyFn={true}
          code={
            serviceType === Type.Postgres
              ? queryPostgresCode
              : queryHypertableCode
          }
          data-cy="copy-insert"
        />

        <FeedbackSecondary iconName="elements/Chart" mt="16px">
          <Text textStyle="caption">
            Learn more about how to{" "}
            <Link
              href="https://docs.timescale.com/use-timescale/latest/query-data/"
              isExternal={true}
              textStyle="captionLink"
            >
              query data.
            </Link>
          </Text>
        </FeedbackSecondary>
        {isCurrent && (
          <Button
            size="sm"
            variant="secondary"
            my="16px"
            maxW="unset"
            onClick={() => completeStep("queryData")}
          >
            Mark as completed
          </Button>
        )}
      </Flex>
    </ServiceQueryEngineContext.Provider>
  );
};
