import { menuAnatomy as parts } from "@chakra-ui/anatomy";
import type {
  PartsStyleFunction,
  SystemStyleFunction,
  SystemStyleObject,
} from "@chakra-ui/theme-tools";
import { BOX_SHADOW_VARIANT } from "components/container/Container";
import { typography } from "../foundations/typography";

const baseStyleList: SystemStyleFunction = () => {
  return {
    py: "0",
    borderWidth: "1px",
    borderColor: "primary.200",
    borderStyle: "solid",
    boxShadow: BOX_SHADOW_VARIANT.sm,
    transitionDuration: (theme) => theme.transition.duration.fast,
    borderRadius: "8px",
    mb: "8px",
  };
};

const baseStyleItem: SystemStyleFunction = () => {
  return {
    textStyle: "base",
    py: "8px",
    px: "12px",
    color: "grayscale.700",
    _focus: {
      bg: "primary.300",
    },
    _active: {
      bg: "primary.300",
    },
    _disabled: {
      cursor: "default",
      opacity: 1,
      color: "grayscale.500",
      path: { fill: "grayscale.500" },
    },
  };
};

const baseStyleButton: SystemStyleObject = {
  padding: "8px",
  textStyle: "base",
};

const baseStyleGroupTitle: SystemStyleObject = {
  color: "grayscale.700",
  mx: "8px",
  mt: "0",
  mb: "4px",
  ...typography.textStyles.baseBold,
};

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  button: baseStyleButton,
  list: baseStyleList(props),
  item: baseStyleItem(props),
  groupTitle: baseStyleGroupTitle,
});

export const Menu = {
  baseStyle,
};
