import { makeAutoObservable, runInAction } from "mobx";
import { client } from "api/client";
import projectsStore from "./projectsStore";
import notificationStore from "./notificationStore";
import { Maybe, QueryRange, QueryStatConstraint } from "graphql/generated";
import {
  GET_QUERY_DETAILS,
  GET_QUERY_DETAILS__TYPE,
  GET_QUERY_METRICS_GRAPHS,
  GET_QUERY_METRICS_GRAPHS__TYPE,
  GET_QUERY_STATS,
  GET_QUERY_STATS__TYPE,
} from "../api/query";
import serviceStore from "./serviceStore";

export class QueryStatsStore {
  queryStats = {
    stats: [],
  };

  constructor() {
    makeAutoObservable(this);
  }

  getQueryStats = async ({
    projectId = projectsStore?.currentProject?.id,
    serviceId = serviceStore.service?.id,
    serviceOrdinal = serviceStore.service?.primaryOrdinal,
    limit = 50,
    sortBy = "EXECUTIONS",
    sortOrder = "DESC",
    queryRange,
    constraints = [],
  }: {
    projectId?: string;
    serviceId?: string;
    serviceOrdinal?: Maybe<number>;
    limit: number;
    sortBy: string;
    sortOrder: string;
    queryRange: QueryRange;
    constraints: QueryStatConstraint[];
  }) => {
    if (projectId && serviceId) {
      try {
        const { data, errors } = await client.query({
          variables: {
            projectId,
            serviceId,
            serviceOrdinal,
            limit,
            sortBy,
            sortOrder,
            queryRange,
            constraints,
          },
          query: GET_QUERY_STATS,
        });
        if (data) {
          runInAction(() => (this.queryStats = data[GET_QUERY_STATS__TYPE]));
        } else if (errors) {
          notificationStore.showErrorToaster(
            "Failed to retrieve service query stats",
          );
        }
      } catch (e) {
        console.log("getQueryStats: Network error", e);
      }
    }
  };

  getQueryMetricsGraphs = async ({
    projectId = projectsStore?.currentProject?.id,
    serviceId = serviceStore.service?.id,
    serviceOrdinal = serviceStore.service?.primaryOrdinal,
    queryRange,
    queryHash,
  }: {
    projectId?: string;
    serviceId?: string;
    serviceOrdinal?: Maybe<number>;
    queryRange: QueryRange;
    queryHash: string;
  }) => {
    try {
      const { data, errors } = await client.query({
        variables: {
          projectId,
          serviceId,
          serviceOrdinal,
          queryRange,
          queryHash,
        },
        query: GET_QUERY_METRICS_GRAPHS,
      });
      if (errors) {
        notificationStore.showErrorToaster(
          "Failed to retrieve query metrics graphs",
        );
      }
      return data[GET_QUERY_METRICS_GRAPHS__TYPE].dataPoints;
    } catch (e) {
      console.log("getQueryMetricsGraphs: Network error", e);
    }
  };

  getQueryDetails = async ({
    projectId = projectsStore?.currentProject?.id,
    serviceId = serviceStore.service?.id,
    serviceOrdinal = serviceStore.service?.primaryOrdinal,
    queryHash,
  }: {
    projectId?: string;
    serviceId?: string;
    serviceOrdinal?: Maybe<number>;
    queryHash: string;
  }) => {
    try {
      const { data, errors } = await client.query({
        variables: {
          projectId,
          serviceId,
          serviceOrdinal,
          queryHash,
        },
        query: GET_QUERY_DETAILS,
      });
      if (errors) {
        notificationStore.showErrorToaster("Failed to retrieve query details");
      }
      return data[GET_QUERY_DETAILS__TYPE];
    } catch (e) {
      console.log("getQueryDetails: Network error", e);
    }
  };
}

const queryStatsStore = new QueryStatsStore();

export default queryStatsStore;
