import { Collapse as ChakraCollapse, CollapseProps } from "@chakra-ui/react";
import transition, {
  transitionEasing,
} from "../../theme/foundations/transition";

const Collapse = (props: CollapseProps) => (
  <ChakraCollapse
    {...props}
    transition={{
      enter: {
        height: {
          duration: transition.duration.fast / 1000,
          ease: transitionEasing.easeOut,
        },
        opacity: {
          duration: transition.duration.fast / 1000,
          ease: transitionEasing.easeOut,
        },
      },
      exit: {
        height: {
          duration: transition.duration.extraFast / 1000,
          ease: transitionEasing.easeIn,
        },
        opacity: {
          duration: transition.duration.extraFast / 1000,
          ease: transitionEasing.easeIn,
        },
      },
    }}
  >
    {props.children}
  </ChakraCollapse>
);

export default Collapse;
