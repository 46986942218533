import { ComponentStyleConfig } from "@chakra-ui/theme";

export const Alert: ComponentStyleConfig = {
  baseStyle: {
    container: {
      color: "white",
    },
  },
  variants: {
    subtle: ({ colorScheme }) => {
      let bg;
      switch (colorScheme) {
        case "primary":
          bg = "primary.900";
          break;
        case "orange":
          bg = "warning.900";
          break;
        case "red":
          bg = "error.900";
      }
      return {
        container: {
          bg,
        },
      };
    },
  },
};
