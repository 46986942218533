// libraries
import { useLocation } from "react-router-dom";
import { Statsig } from "statsig-react";
// components third party
import {
  Flex,
  Grid,
  Box,
  Heading,
  Image,
  Text,
  Avatar,
  Link,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react";
// components custom
import Divider from "components/divider/Divider";
import Icon, { IconProps } from "components/icon/Icon";
// assets
import TimescaleLogo from "assets/logos/timescale-logo.svg";
import lucid from "assets/images/client_logos/lucid.svg";
import coinbase from "assets/images/client_logos/coinbase.svg";
import hewlett from "assets/images/client_logos/hewlett.svg";
import uber from "assets/images/client_logos/uber.svg";
import warnerMusic from "assets/images/client_logos/warner.svg";
import circle from "assets/images/page_backgrounds/circle.svg";
// utils
import { EXP_SIGNUP_COPY } from "utils/featuresGates";
// constants
import { DEFAULT_TRIAL_DAYS } from "stores/constants";
// types
import { UTM_CAMPAIGNS } from "types";

const brandImages = [lucid, warnerMusic, coinbase, uber, hewlett];

const FeatureItem = ({
  iconName,
  text,
}: {
  iconName: IconProps["name"];
  text: string;
}) => {
  return (
    <Flex gap="16px" alignItems="center">
      <Avatar
        bgColor="primary.200"
        icon={<Icon size="24" fill="primary.900" name={iconName} />}
      />
      <Box w="100%">
        <Text textStyle="subtitle3" color="grayscale.800" fontSize="16px">
          {text}
        </Text>
      </Box>
    </Flex>
  );
};

const SignUpLandingDefault = () => (
  <>
    <Text>No credit card required</Text>
    <Divider mt="32px" mb="32px" color="primary.400" />
    <Text textStyle="base">
      Need to host on Azure or GCP?
      <br />
      <Link
        data-cy="mst-signup-link"
        href="https://www.timescale.com/mst-signup"
        isExternal
      >
        Sign up here
      </Link>
    </Text>
  </>
);

const SignUpLandingTest = () => (
  <>
    <Text mb="8px">(No credit card required)</Text>
    <Box>
      Need to host on Azure or GCP?
      <br />
      <Link
        data-cy="mst-signup-link"
        href="https://www.timescale.com/mst-signup"
        isExternal
      >
        Sign up here
      </Link>
      <Divider my="32px" color="primary.400" />
    </Box>
    <Text textStyle="h3" mb="29px" color="grayscale.800">
      During your trial, access the full suite of Timescale features and:
    </Text>
    <UnorderedList spacing="14px">
      <ListItem fontSize="lg">
        Reduce development time and manual coding efforts with quick start
        guides for various programming languages and SQL documentation for APIs
      </ListItem>
      <ListItem fontSize="lg">
        Compress data while increasing query performance
      </ListItem>
      <ListItem fontSize="lg">
        Optimize applications for specific use cases with tutorials for sensors,
        IoT, finance, and more
      </ListItem>
      <ListItem fontSize="lg">
        Collaborate across platforms in a unified environment with the PopSQL
        editor
      </ListItem>
      <ListItem fontSize="lg">
        See insights, analyze performance, and evaluate potential cost savings
      </ListItem>
      <ListItem fontSize="lg">
        Easily transfer to full Timescale service when ready
      </ListItem>
    </UnorderedList>
  </>
);

const landingCopy = (isSignUp: boolean, campaign: string) => {
  switch (true) {
    case isSignUp && campaign === UTM_CAMPAIGNS.VECTOR_LAUNCH:
      return {
        header: `Try pgai on Timescale free for ${DEFAULT_TRIAL_DAYS} days`,
        body: (
          <Text>
            Start building your AI applications with PostgreSQL.
            <br />
            No credit card required.
          </Text>
        ),
      };
    case isSignUp && campaign === UTM_CAMPAIGNS.TIME_SERIES:
      return {
        header: `Try Timescale Cloud free for ${DEFAULT_TRIAL_DAYS} days`,
        body: (
          <>
            <Text>
              Start building powerful time-series analytics applications with
              Postgres on Timescale Cloud.
            </Text>
            <Text sx={{ mt: 4 }}>No credit card required.</Text>
          </>
        ),
      };
    case isSignUp:
      return {
        header: `Try Timescale for AWS free for ${DEFAULT_TRIAL_DAYS} days`,
        body: (
          <>
            {Statsig.checkGate(EXP_SIGNUP_COPY.GATE) ? (
              <SignUpLandingTest />
            ) : (
              <SignUpLandingDefault />
            )}
          </>
        ),
      };
    default:
      return {
        header: "Welcome to Timescale!",
        body: "A modern, cloud-native relational database platform for time-series. Get insight into how your business and systems change over time.",
      };
  }
};

export const MainLayout = ({
  children,
}: {
  children?: JSX.Element | JSX.Element[];
}) => {
  const location = useLocation();
  const isSignup = location.pathname.includes("/signup");
  const isSignupTest = Statsig.checkGate(EXP_SIGNUP_COPY.GATE);
  const query = new URLSearchParams(location.search);
  const copy = landingCopy(isSignup, query?.get?.("utm_campaign") || "");

  return (
    <Grid
      backgroundColor="grayscale.0"
      minH="100vh"
      maxWidth={{ base: "100vw" }}
      alignContent="center"
      justifyContent="center"
      backgroundImage={`url(${circle})`}
      backgroundSize="2000px"
      backgroundRepeat="no-repeat"
      backgroundPosition="calc(50% - -760px) center"
      p={{ base: "10px", lg: "24px", md: "40px 60px", sm: "24px" }}
    >
      <Grid
        alignItems={{ base: "center", md: "flex-start" }}
        justifyContent="center"
        fontSize="lg"
        maxW="100%"
        gridTemplateAreas={{
          lg: `"title login"
               "description login"`,
          base: `"title"
               "login"
               "description"`,
        }}
        columnGap={{ base: "60px", lg: "120px", md: 0, sm: 0 }}
        width="100%"
      >
        <Grid
          gridArea="title"
          maxW={{ lg: "457px", md: "100%" }}
          gap="16px"
          alignSelf="end"
          my={{ lg: isSignup && !isSignupTest ? "50%" : 0, md: "0" }}
          px={{ md: "0", base: "32px" }}
          data-cy="mainlayout-title"
        >
          <Image src={TimescaleLogo} mb="16px" />
          <Heading as="h4" size="3xl" lineHeight="48px" color="grayscale.900">
            {copy.header}
          </Heading>
          <Box
            mb="32px"
            fontSize="18px"
            lineHeight="24px"
            color="grayscale.800"
          >
            {copy.body}
          </Box>
        </Grid>
        <Grid
          w={{ lg: "562px", md: "100%", sm: "100%" }}
          m="0"
          gridArea="login"
          height="100%"
        >
          <Flex
            w="100%"
            maxW={{ lg: "580px", base: "460px" }}
            flexDirection="column"
            placeSelf="center"
          >
            {children}
          </Flex>
        </Grid>

        {/* Login sales copy */}
        {!isSignup && (
          <Grid
            gridArea="description"
            maxW={{ lg: "445px", md: "100%" }}
            gap="36px"
            mt={{ base: "32px", lg: "0px" }}
          >
            <Grid
              gap="24px"
              justifyContent={{ base: "center", md: "space-between" }}
              gridTemplateColumns={{
                base: "1fr",
                md: "repeat(2, 1fr)",
                lg: "1fr",
              }}
            >
              <FeatureItem
                iconName="elements/Elephant"
                text="100% PostgreSQL."
              />
              <FeatureItem
                iconName="elements/Thunder/On"
                text="10-100x faster queries than vanilla PostgreSQL, InfluxDB, MongoDB."
              />
              <FeatureItem
                iconName="elements/Chart"
                text="90-95% storage cost savings with compression."
              />
              <FeatureItem
                iconName="elements/Time/Clock"
                text="Create a fully-managed Timescale instance in 30 seconds."
              />
            </Grid>
            <Text textStyle="base">
              Need to log into Managed Service for TimescaleDB?<br></br>
              <Link
                data-cy="mst-signup-link"
                href="https://portal.managed.timescale.com/login"
                isExternal
              >
                Log in here
              </Link>
            </Text>
          </Grid>
        )}
      </Grid>

      {/* Customer icon grid, only shows on Sign Up */}
      {isSignup && (
        <Flex
          w="100%"
          maxW={{ base: "100%", sm: "500px", md: "700px", lg: "1147px" }}
          alignItems="center"
          direction="column"
          marginTop="93px"
          marginX="auto"
        >
          <Heading
            as="h4"
            textStyle="subtitle2"
            size="lg"
            fontWeight={500}
            mb="16px"
          >
            Great companies trust Timescale
          </Heading>
          <Flex gap="16px" flexWrap="wrap" justifyContent="center">
            {brandImages.map((image, index) => (
              <Flex
                key={index}
                justifyContent="center"
                alignItems="center"
                w={{ base: "100px", md: "auto", lg: "120px" }}
                m="18px"
              >
                <Image src={image} />
              </Flex>
            ))}
          </Flex>
        </Flex>
      )}
    </Grid>
  );
};
