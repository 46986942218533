import { ENVS, prHostRegex } from "./config";

//needs to be separate from rest of ./config b/c "window" object disrupts nodejs functions
export const ENV = (() => {
  const host = window.location.hostname;
  switch (host) {
    case "console.forge.timescale.com":
    case "console.cloud.timescale.com":
    case "local.cloud.timescale.com":
      return ENVS["production"];
    case "api.dev.metronome-cloud.com":
      return ENVS["development"];
    default:
      // PR instances should use development config
      // e.g. https://savannah-tiger-cloud-pr-4480.onrender.com
      if (host && prHostRegex.test(host)) return ENVS["development"];
      return ENVS["local"];
  }
})();
