import { ComponentStyleConfig } from "@chakra-ui/react";
import { inputStyle } from "./input";

export const cardStyle = {
  baseStyle: {
    maxWidth: "400px",
    display: "grid",
    gridTemplateColumns: "1fr",
    alignContent: "center",
    "& .StripeElement": {
      outline: "none",
      border: "1px solid",
      position: "relative",
      padding: "10px 8px 8px 8px",
      ...inputStyle.variants.outline.field,
    },
    "& .StripeElement--empty": {
      outline: "none",
    },
    "& .StripeElement--focus": {
      ...inputStyle.variants.outline.field._focus,
    },
    "& .StripeElement--complete": {
      ...inputStyle.variants.outline.field._focus,
    },
    "& .StripeElement--invalid": {
      outline: "none",
      ...inputStyle.variants.outline.field._invalid,
    },
    "& .CardField-expiry .InputElement::placeholder": {
      textTransform: "lowercase",
    },
  },
};

export const CreditCardInput: ComponentStyleConfig = cardStyle;
