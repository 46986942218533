import { Box, Flex, Text } from "@chakra-ui/react";
import Hover from "../tooltip/Hover/Hover";
import { GradientText } from "../gradients/GradientText";

export type ModeOptionProps = {
  id: string;
  label: string;
  isTimescale: boolean;
};

export type ModeSwitcherProps = {
  options: [ModeOptionProps, ModeOptionProps];
  onClick: () => void;
  isDisabled: boolean;
  isMinified: boolean;
};

export const ModeSwitcher = ({
  options,
  onClick,
  isDisabled,
  isMinified,
}: ModeSwitcherProps) => {
  return (
    <Flex
      direction={isMinified ? "column" : "row"}
      borderRadius="8px"
      borderWidth="1px"
      borderColor="grayscale.200"
      gap="2px"
    >
      {options.map(({ id, label, isTimescale }) => (
        <Hover
          key={id}
          theme="menu"
          placement="right"
          content={
            !isTimescale && isDisabled
              ? "Data mode will be ready once you create a service"
              : label
          }
          disabled={!isMinified && (!isDisabled || isTimescale)}
        >
          <Flex
            justifyContent="center"
            py={isMinified ? "12px" : "6px"}
            px={isMinified ? "13px" : "9px"}
            borderRadius="8px"
            m={isTimescale ? "-1px" : "0"}
            borderWidth="1px"
            cursor={isDisabled ? "default" : "pointer"}
            flex="1 1 0px"
            borderColor={isTimescale ? "grayscale.200" : "grayscale.0"}
            bgColor={isTimescale ? "grayscale.100" : "grayscale.0"}
            _hover={{
              ...(!isDisabled
                ? {
                    "&>p": {
                      bg: isTimescale
                        ? "timescaleGradient.90"
                        : "popsqlGradient.90",
                      backgroundClip: "text",
                      textFillColor: "transparent",
                    },
                    "&>div>div": {
                      bg: isTimescale
                        ? "timescaleGradient.90"
                        : "popsqlGradient.90",
                    },
                  }
                : {}),
            }}
            data-cy="mode-switcher"
            onClick={() => (isDisabled ? undefined : onClick())}
          >
            {isMinified ? (
              <Box
                bg={
                  isTimescale
                    ? "timescaleGradient.90"
                    : isDisabled
                      ? "grayscale.300"
                      : "popsqlGradient.90"
                }
                p="1px"
                borderRadius="2px"
              >
                <Flex
                  w="6px"
                  h="6px"
                  borderRadius="1px"
                  bg={isTimescale ? "timescaleGradient.90" : "grayscale.0"}
                />
              </Box>
            ) : isTimescale ? (
              <GradientText gradient="timescaleGradient.90" textStyle="base">
                {label}
              </GradientText>
            ) : (
              <Text color={isDisabled ? "grayscale.300" : "grayscale.700"}>
                {label}
              </Text>
            )}
          </Flex>
        </Hover>
      ))}
    </Flex>
  );
};
