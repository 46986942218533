import {
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Text,
} from "@chakra-ui/react";
import Button from "components/button/Button";
import { observer } from "mobx-react";
import { useStartedSteps } from "./useStartedSteps";
import Hover from "components/tooltip/Hover/Hover";

export const GettingStartedButton = observer(
  ({
    onClick,
    isLeftNavMinified,
  }: {
    onClick: () => void;
    isLeftNavMinified: boolean;
  }) => {
    const { percentDone } = useStartedSteps();

    return (
      <Hover
        theme="menu"
        placement="right"
        content="Getting started"
        disabled={!isLeftNavMinified}
      >
        <Button
          sx={{
            "&:hover": {
              bg: "grayscale.0",
              borderColor: "primary.400",
              "&>p": {
                color: "grayscale.900",
              },
            },
            "&:focus": {
              borderColor: "primary.400",
              "&>p": {
                color: "grayscale.900",
                textStyle: "baseBold",
              },
            },
            "&:active": {
              bg: "primary.100",
              borderColor: "primary.400",
              "&>p": {
                color: "grayscale.900",
                textStyle: "baseBold",
              },
              "&>div>p": {
                color: "grayscale.900",
              },
            },
          }}
          p={isLeftNavMinified ? "0 3px" : "8px"}
          height="56px"
          variant="ghost"
          justifyContent="unset"
          bg="primary.100"
          borderWidth="1px"
          borderColor="primary.100"
          width="100%"
          minWidth="unset"
          onClick={onClick}
        >
          {isLeftNavMinified ? (
            <Flex direction="column">
              <CircularProgress
                value={percentDone}
                color="primary.900"
                trackColor="primary.400"
                size="24px"
                px="3px"
              />
              <Text textStyle="caption" color="grayscale.700">
                {percentDone}%
              </Text>
            </Flex>
          ) : (
            <>
              <CircularProgress
                value={percentDone}
                color="primary.900"
                trackColor="primary.400"
                size="40px"
              >
                <CircularProgressLabel
                  textStyle="captionBold"
                  color="primary.900"
                >
                  {percentDone}%
                </CircularProgressLabel>
              </CircularProgress>
              <Text ml="4px" textStyle="base" color="grayscale.700">
                Getting started
              </Text>
            </>
          )}
        </Button>
      </Hover>
    );
  },
);
