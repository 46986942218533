import { Avatar as ChakraAvatar, StyleProps } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { ReactElement, useEffect, useState } from "react";
import { useStores } from "stores";
import { ChakraSizes } from "types";
import { AVATAR_BG } from "pages/account/ChangeAvatarTooltip";
import { typography } from "theme/foundations/typography";

const DEFAULT_BACKGROUND = "#B0C0FB";

export interface AvatarProps extends StyleProps {
  size?: ChakraSizes;
  src?: string;
  icon?: ReactElement;
  name?: string | null;
}

export const Avatar = ({
  size = "md",
  src,
  icon,
  bgColor,
  name,
  ...rest
}: AvatarProps) => {
  const { userStore } = useStores();
  const [userBgColor, setUserBgColor] = useState(userStore?.uiState[AVATAR_BG]);

  const getTextStyle = (size: ChakraSizes) => {
    if (size === "lg") {
      return typography.textStyles.h1;
    } else if (size === "md") {
      return typography.textStyles.subtitle3Bold;
    } else {
      return typography.textStyles.captionBold;
    }
  };

  useEffect(() => {
    setUserBgColor(userStore?.uiState[AVATAR_BG]);
  }, [userStore?.uiState]);

  return (
    <ChakraAvatar
      size={size}
      name={name === undefined ? userStore?.currentUser || "" : name}
      icon={icon}
      bg={bgColor || userBgColor || DEFAULT_BACKGROUND}
      backgroundSize="cover"
      sx={{
        "& *": {
          ...getTextStyle(size),
          color: "grayscale.0", // keeps the initials white
        },
      }}
      {...rest}
    />
  );
};

export default observer(Avatar);
