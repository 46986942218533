import {
  Box,
  Flex,
  Input,
  Link,
  Menu,
  MenuButton,
  MenuList,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { css } from "@emotion/react";
import Button from "components/button/Button";
import IconButton from "components/button/IconButton";
import Icon from "components/icon/Icon";
import { MenuItem } from "layouts/navigation/menuComponents";
import {
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
  type FC,
  type RefObject,
} from "react";
import Markdown, { type ExtraProps } from "react-markdown";
import { NavLink, useLocation } from "react-router-dom";
import { Statsig } from "statsig-react";
import { useStores } from "stores";
import { EXP_AI_CHAT } from "utils/featuresExperiments";
import { ENV } from "utils/hostEnv";
import { v4 as uuidv4 } from "uuid";

interface Message {
  message: string;
  type: "error" | "user" | "assistant";
  reaction?: MessageReaction;
  id: string;
}

enum MessageReaction {
  Like = "Like",
  Dislike = "Dislike",
  None = "None",
}

const LinkRenderer = ({
  href,
  title,
  children,
}: JSX.IntrinsicElements["a"] & ExtraProps) => {
  const isExternal = !href?.startsWith("/");
  return (
    <Link
      href={href}
      title={title}
      as={isExternal ? undefined : NavLink}
      to={isExternal ? undefined : href}
      isExternal={isExternal}
    >
      {children}
    </Link>
  );
};

const PreRenderer = ({
  children,
}: JSX.IntrinsicElements["pre"] & ExtraProps) => (
  <pre style={{ whiteSpace: "pre-wrap" }}>{children}</pre>
);

const StrongRenderer = ({
  children,
}: JSX.IntrinsicElements["strong"] & ExtraProps) => (
  <Text as="span" fontWeight="700">
    {children}
  </Text>
);

const UlRenderer = ({ children }: JSX.IntrinsicElements["ul"] & ExtraProps) => (
  <ul
    style={{
      listStyle: "disc",
      marginLeft: 16,
    }}
  >
    {children}
  </ul>
);

const MagicIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clipPath="url(#clip0_5041_897)">
      <path
        d="M6.49997 15.9999C6.34196 16.0007 6.18746 15.9532 6.05713 15.8639C5.92679 15.7745 5.82683 15.6475 5.7706 15.4999L4.5256 12.2624C4.50038 12.1971 4.46181 12.1379 4.41235 12.0884C4.3629 12.039 4.30365 12.0004 4.23841 11.9752L0.999972 10.7292C0.852454 10.6726 0.725572 10.5726 0.636071 10.4424C0.54657 10.3122 0.498657 10.1579 0.498657 9.99985C0.498657 9.84184 0.54657 9.68755 0.636071 9.55733C0.725572 9.42711 0.852454 9.32709 0.999972 9.27048L4.23747 8.02548C4.30271 8.00026 4.36196 7.96169 4.41141 7.91223C4.46087 7.86277 4.49944 7.80353 4.52466 7.73829L5.7706 4.49985C5.82721 4.35233 5.92723 4.22545 6.05745 4.13595C6.18767 4.04645 6.34196 3.99854 6.49997 3.99854C6.65798 3.99854 6.81228 4.04645 6.94249 4.13595C7.07271 4.22545 7.17273 4.35233 7.22935 4.49985L8.47435 7.73735C8.49956 7.80259 8.53814 7.86184 8.58759 7.91129C8.63705 7.96075 8.6963 7.99932 8.76153 8.02454L11.9803 9.26298C12.1338 9.31988 12.2661 9.42271 12.3591 9.55747C12.452 9.69223 12.5013 9.85238 12.5 10.0161C12.4976 10.1714 12.4487 10.3223 12.3595 10.4495C12.2704 10.5766 12.1451 10.6741 12 10.7292L8.76247 11.9742C8.69723 11.9994 8.63799 12.038 8.58853 12.0875C8.53907 12.1369 8.5005 12.1962 8.47528 12.2614L7.22935 15.4999C7.17311 15.6475 7.07315 15.7745 6.94282 15.8639C6.81249 15.9532 6.65799 16.0007 6.49997 15.9999Z"
        fill="url(#paint0_linear_5041_897)"
      />
      <path
        d="M2.74994 5.49991C2.6573 5.49991 2.56684 5.47184 2.49047 5.4194C2.4141 5.36696 2.35541 5.29262 2.32213 5.20616L1.79525 3.83616C1.78383 3.8062 1.76621 3.77899 1.74354 3.75632C1.72086 3.73365 1.69365 3.71602 1.66369 3.7046L0.293691 3.17773C0.207247 3.14444 0.132915 3.08574 0.0804885 3.00937C0.0280618 2.93301 0 2.84255 0 2.74991C0 2.65728 0.0280618 2.56682 0.0804885 2.49045C0.132915 2.41409 0.207247 2.35539 0.293691 2.3221L1.66369 1.79523C1.69362 1.78376 1.72081 1.76611 1.74347 1.74345C1.76614 1.72078 1.78378 1.6936 1.79525 1.66366L2.31744 0.305851C2.34689 0.225914 2.39757 0.155514 2.46402 0.102216C2.53048 0.0489189 2.6102 0.0147393 2.69463 0.00335107C2.79599 -0.00897074 2.89856 0.0128976 2.98608 0.0654883C3.0736 0.118079 3.14105 0.198382 3.17775 0.293664L3.70463 1.66366C3.7161 1.6936 3.73374 1.72078 3.75641 1.74345C3.77908 1.76611 3.80626 1.78376 3.83619 1.79523L5.20619 2.3221C5.29263 2.35539 5.36697 2.41409 5.41939 2.49045C5.47182 2.56682 5.49988 2.65728 5.49988 2.74991C5.49988 2.84255 5.47182 2.93301 5.41939 3.00937C5.36697 3.08574 5.29263 3.14444 5.20619 3.17773L3.83619 3.7046C3.80623 3.71602 3.77902 3.73365 3.75635 3.75632C3.73367 3.77899 3.71605 3.8062 3.70463 3.83616L3.17775 5.20616C3.14447 5.29262 3.08578 5.36696 3.00941 5.4194C2.93304 5.47184 2.84258 5.49991 2.74994 5.49991Z"
        fill="url(#paint1_linear_5041_897)"
      />
      <path
        d="M12.5 8.00019C12.3989 8.00016 12.3002 7.9695 12.2169 7.91226C12.1336 7.85503 12.0697 7.7739 12.0334 7.67956L11.3197 5.82425C11.3071 5.79156 11.2878 5.76187 11.263 5.7371C11.2383 5.71234 11.2086 5.69306 11.1759 5.6805L9.32059 4.96675C9.22633 4.93043 9.14529 4.86641 9.08812 4.78313C9.03096 4.69984 9.00037 4.6012 9.00037 4.50019C9.00037 4.39917 9.03096 4.30053 9.08812 4.21724C9.14529 4.13396 9.22633 4.06994 9.32059 4.03362L11.1759 3.31987C11.2086 3.30732 11.2383 3.28803 11.263 3.26327C11.2878 3.23851 11.3071 3.20882 11.3197 3.17612L12.0281 1.33394C12.0604 1.24682 12.1158 1.1701 12.1883 1.11194C12.2607 1.05378 12.3476 1.01634 12.4397 1.00362C12.5503 0.990235 12.6622 1.01417 12.7576 1.07164C12.8531 1.1291 12.9266 1.2168 12.9665 1.32081L13.6803 3.17612C13.6928 3.20882 13.7121 3.23851 13.7369 3.26327C13.7616 3.28803 13.7913 3.30732 13.824 3.31987L15.6793 4.03362C15.7736 4.06994 15.8546 4.13396 15.9118 4.21724C15.969 4.30053 15.9996 4.39917 15.9996 4.50019C15.9996 4.6012 15.969 4.69984 15.9118 4.78313C15.8546 4.86641 15.7736 4.93043 15.6793 4.96675L13.824 5.6805C13.7913 5.69306 13.7616 5.71234 13.7369 5.7371C13.7121 5.76187 13.6928 5.79156 13.6803 5.82425L12.9665 7.67956C12.9303 7.7739 12.8663 7.85503 12.783 7.91226C12.6997 7.9695 12.601 8.00016 12.5 8.00019Z"
        fill="url(#paint2_linear_5041_897)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_5041_897"
        x1="0.498657"
        y1="9.9992"
        x2="12.5"
        y2="9.9992"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6447FB" />
        <stop offset="1" stopColor="#FF6767" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5041_897"
        x1="0"
        y1="2.74996"
        x2="5.49988"
        y2="2.74996"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6447FB" />
        <stop offset="1" stopColor="#FF6767" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_5041_897"
        x1="9.00037"
        y1="4.50009"
        x2="15.9996"
        y2="4.50009"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6447FB" />
        <stop offset="1" stopColor="#FF6767" />
      </linearGradient>
      <clipPath id="clip0_5041_897">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const InnerPopover: FC<{
  handleCancel: () => void;
  handleSubmit: (msg?: string) => void;
  inputRef: RefObject<HTMLInputElement>;
  messages: Message[];
  inProgress: boolean;
  isOpen: boolean;
  setMessages: (messages: Message[]) => void;
  setQuestion: (question: string) => void;
  thinking: boolean;
  question: string;
}> = ({
  handleCancel,
  handleSubmit,
  messages,
  inputRef,
  inProgress,
  isOpen,
  setMessages,
  setQuestion,
  thinking,
  question,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Ideally Popover would focus the input on open, but for whatever reason
    // we need to have a _very_ slight delay for it to actually focus.
    const timeout = isOpen
      ? setTimeout(() => inputRef.current?.focus(), 10)
      : undefined;
    return () => clearTimeout(timeout);
  }, [inputRef, isOpen]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  }, [messages]);

  const updateMessageReaction = (idx: number, reaction: MessageReaction) => {
    const msg = messages[idx];
    if (reaction !== msg.reaction) {
      const newMessages = [
        ...messages.slice(0, idx),
        {
          ...msg,
          reaction,
        },
      ];
      setMessages(newMessages);
      fetch(`${ENV.CHAT_URL}/reaction`, {
        body: JSON.stringify({
          id: msg.id,
          reaction: reaction,
        }),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      }).catch((err) => {
        console.error(err);
      });
    }
  };

  const MessageFeedback: FC<{ idx: number }> = ({ idx }) => {
    const currentReaction = messages[idx].reaction ?? MessageReaction.None;
    const getLabelForReaction = (reaction: MessageReaction) => {
      switch (reaction) {
        case MessageReaction.None:
          return "Is this helpful";
        default:
          return "Thanks";
      }
    };
    return (
      <div>
        <Text as="span" mr="8px">
          {getLabelForReaction(currentReaction)}
        </Text>
        <IconButton
          size="sm"
          _hover={{ bg: "success.100", color: "success.900" }}
          bg={currentReaction === MessageReaction.Like ? "success.100" : ""}
          iconName="elements/Thumbs/Up"
          variant="ghostIcon"
          label="AIChat-likeButton"
          data-cy="AIChat-likeButton"
          iconFill={
            currentReaction === MessageReaction.Like ? "success.900" : undefined
          }
          color={
            currentReaction === MessageReaction.Like ? "success.900" : undefined
          }
          mr="6px"
          onClick={() => updateMessageReaction(idx, MessageReaction.Like)}
        />
        <IconButton
          size="sm"
          _hover={{ bg: "error.100", color: "error.900" }}
          bg={currentReaction === MessageReaction.Dislike ? "error.100" : ""}
          iconName="elements/Thumbs/Down"
          variant="ghostIcon"
          label="AIChat-dislikeButton"
          data-cy="AIChat-dislikeButton"
          iconFill={
            currentReaction === MessageReaction.Dislike
              ? "error.900"
              : undefined
          }
          color={
            currentReaction === MessageReaction.Dislike
              ? "error.900"
              : undefined
          }
          onClick={() => updateMessageReaction(idx, MessageReaction.Dislike)}
        />
      </div>
    );
  };

  return (
    <>
      <PopoverTrigger>
        <Box bottom="40px" position="fixed" right="20px">
          {!isOpen && (
            <Box
              bg="grayscale.0"
              borderRadius="12px"
              boxShadow="1px 3px 4px 0px rgba(0, 0, 0, 0.06), 1px 5px 4px -1px rgba(0, 0, 0, 0.06)"
              cursor="pointer"
              padding="8px"
            >
              <Flex alignItems="center" gap="8px">
                <MagicIcon />
                <Box>Ask AI</Box>
              </Flex>
            </Box>
          )}
        </Box>
      </PopoverTrigger>
      <PopoverContent pb="10px" width="410px">
        <PopoverHeader>
          <Flex alignItems="center" justifyContent="space-between" gap="8px">
            <Flex alignItems="center" gap="8px">
              <MagicIcon />
              Ask AI
            </Flex>
            <Flex>
              <Menu>
                <MenuButton
                  as={Button}
                  _hover={{ bg: "grayscale.200" }}
                  alignItems="center"
                  borderRadius="md"
                  color="grayscale.800"
                  h="24px"
                  minHeight="unset"
                  minWidth="unset"
                  padding="8px 4px"
                  variant="ghost"
                >
                  <Icon name="actions/Menu/Horizontal" />
                </MenuButton>
                <MenuList>
                  <MenuItem
                    id="contact-support"
                    label="Contact support"
                    iconName="elements/Support"
                    to="/dashboard/support"
                  />
                  <MenuItem
                    id="timescale-docs"
                    label="Timescale docs"
                    iconName="elements/Book/Open"
                    isExternal
                    to="https://docs.timescale.com/"
                  />
                  <MenuItem
                    id="clear-chat"
                    label="Clear chat"
                    iconName="actions/Delete/Lines"
                    onClick={() => setMessages([])}
                  />
                </MenuList>
              </Menu>
              <PopoverCloseButton
                position="unset"
                top="unset"
                right="unset"
                marginTop="unset"
                marginRight="unset"
              />
            </Flex>
          </Flex>
        </PopoverHeader>
        <PopoverBody pt="0px">
          {messages.length ? (
            <Flex
              direction="column"
              gap="8px"
              maxHeight="600px"
              minHeight="400px"
              overflow="scroll"
              p="16px"
              pb="24px"
              ref={containerRef}
            >
              {messages.map(({ type, message }, idx) => {
                const isUser = type === "user";
                return (
                  <Flex
                    key={idx}
                    direction="column"
                    alignItems={isUser ? "flex-end" : "flex-start"}
                  >
                    <Box
                      key={idx}
                      borderRadius="8px"
                      bg={
                        isUser
                          ? "var(--Highlight-Strong, linear-gradient(90deg, #6447FB 0%, #FF6767 100%))"
                          : type === "error"
                            ? "error.100"
                            : "grayscale.100"
                      }
                      color={isUser ? "grayscale.0" : "grayscale.800"}
                      p="8px"
                    >
                      {type === "assistant" ? (
                        <Box
                          css={css`
                            p:not(:last-child) {
                              margin-bottom: 8px;
                            }
                          `}
                        >
                          <Markdown
                            components={{
                              a: LinkRenderer,
                              pre: PreRenderer,
                              strong: StrongRenderer,
                              ul: UlRenderer,
                            }}
                          >
                            {message}
                          </Markdown>
                          {(!inProgress ||
                            (inProgress && idx < messages.length - 1)) && (
                            <MessageFeedback idx={idx} />
                          )}
                        </Box>
                      ) : (
                        message
                      )}
                    </Box>
                  </Flex>
                );
              })}
              {thinking && (
                <Box bg="grayscale.100" borderRadius="8px" p="8px">
                  <Spinner size="xs" /> Assistant is thinking...
                </Box>
              )}
            </Flex>
          ) : (
            <Flex direction="column" gap="24px" my="24px" px="16px">
              <Box px="16px">
                <Flex direction="column" gap="16px">
                  <Flex justifyContent="center">
                    <MagicIcon />
                  </Flex>
                  <Flex direction="column" gap="8px">
                    <Text
                      color="grayscale.900"
                      fontSize="lg"
                      textAlign="center"
                    >
                      Ask anything or select a question
                    </Text>
                    <Text textAlign="center">
                      Use this chat to ask questions about Timescale and our
                      Docs easily.
                    </Text>
                  </Flex>
                </Flex>
              </Box>
              <Box>
                <Flex direction="column" gap="8px">
                  <Text color="grayscale.600" fontSize="sm">
                    Suggested
                  </Text>
                  {[
                    "Why should I use Timescale?",
                    "How do I use hypertables?",
                    "What are continuous aggregates?",
                  ].map((suggestion, idx) => (
                    <Box
                      key={idx}
                      _hover={{ bg: "grayscale.100" }}
                      border="1px"
                      borderColor="grayscale.300"
                      borderRadius="12px"
                      cursor="pointer"
                      onClick={() => {
                        handleSubmit(suggestion);
                        inputRef.current?.focus();
                      }}
                      p="8px"
                      w="fit-content"
                    >
                      <Flex gap="8px" alignItems="center">
                        <Icon name="status/Question" />
                        {suggestion}
                      </Flex>
                    </Box>
                  ))}
                </Flex>
              </Box>
            </Flex>
          )}
          <Flex gap="8px" px="16px" pb="16px">
            <Input
              onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
                if (e.key === "ArrowUp") {
                  if (!question.length) {
                    for (let i = messages.length - 1; i >= 0; i--) {
                      if (messages[i].type === "user") {
                        setQuestion(messages[i].message);
                        break;
                      }
                    }
                  }
                }
              }}
              onChange={(e) => setQuestion(e.target.value)}
              placeholder="Ask question"
              ref={inputRef}
              type="text"
              value={question}
            />
            {inProgress ? (
              <Button minWidth="36px" onClick={handleCancel} padding="10px">
                <Box h="12px" w="12px" bg="grayscale.100" />
              </Button>
            ) : (
              <Button
                bg="var(--Highlight-Strong, linear-gradient(90deg, #6447FB 0%, #FF6767 100%))"
                _focus={{
                  bg: question.length
                    ? "var(--Highlight-Strong, linear-gradient(90deg, #6447FB 0%, #FF6767 100%))"
                    : undefined,
                }}
                _hover={{
                  bg: question.length
                    ? "linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), linear-gradient(90deg, #6447FB 0%, #FF6767 100%);"
                    : undefined,
                }}
                isDisabled={!question.length}
                minWidth="unset"
                onClick={() => {
                  handleSubmit();
                  inputRef.current?.focus();
                }}
                padding="10px"
                size="md"
              >
                <Icon name="actions/Send" />
              </Button>
            )}
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </>
  );
};

const AiChatWrapped: FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [question, setQuestion] = useState("");
  const [thinking, setThinking] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const controllerRef = useRef<AbortController | null>(null);

  const { projectsStore, userStore } = useStores();
  const { projectId } = projectsStore;
  const { currentEmail } = userStore;

  const location = useLocation();

  const handleCancel = () => {
    controllerRef.current?.abort();
  };

  const handleSubmit = (msg?: string) => {
    if (inProgress) {
      return;
    }

    const userQuestion = msg ?? question;
    const idx = messages.length + 1;
    const messageId = uuidv4();
    setMessages((prev) => [
      ...prev,
      { type: "user", message: userQuestion, id: messageId },
    ]);
    setQuestion("");
    setThinking(true);
    setInProgress(true);

    const controller = new AbortController();
    controllerRef.current = controller;
    return fetch(`${ENV.CHAT_URL}/answer`, {
      body: JSON.stringify({
        console_location: location.pathname,
        project_id: projectId,
        question: userQuestion,
        user_email: currentEmail,
        id: messageId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      signal: controller.signal,
    })
      .then((response) => response.body)
      .then(async (body) => {
        if (!body) {
          return;
        }
        const reader = body.getReader();
        const decoder = new TextDecoder("utf-8");
        let first = false;
        while (true) {
          const { done, value } = await reader.read();

          if (!first) {
            setThinking(false);
            first = true;
          }

          if (done) {
            break;
          }

          let decoded = decoder.decode(value, { stream: true });
          if (decoded === "tool_call: get_support_link") {
            decoded =
              "Sorry, I cannot answer that question. Please contact [support](/dashboard/support) for more assistance.";
          }
          setMessages((prev) => {
            const lastMessage = prev[idx] ?? {
              type: "assistant",
              message: "",
              id: messageId,
            };
            return [
              ...prev.slice(0, idx),
              {
                ...lastMessage,
                message: lastMessage.message + decoded,
                id: messageId,
              },
            ];
          });
        }
      })
      .catch((err) => {
        if (err.name === "AbortError") {
          return;
        }

        console.error(err);
        setMessages((prev) => [
          ...prev,
          { type: "error", message: "Failed to get answer", id: messageId },
        ]);
      })
      .finally(() => {
        controllerRef.current = null;
        setThinking(false);
        setInProgress(false);
      });
  };

  return (
    <>
      <Popover
        closeOnEsc={true}
        closeOnBlur={true}
        initialFocusRef={inputRef}
        // offset={[]]}
        placement="top-start"
      >
        {({ isOpen }) => (
          <InnerPopover
            handleCancel={handleCancel}
            handleSubmit={handleSubmit}
            inputRef={inputRef}
            messages={messages}
            inProgress={inProgress}
            isOpen={isOpen}
            setMessages={setMessages}
            setQuestion={setQuestion}
            thinking={thinking}
            question={question}
          />
        )}
      </Popover>
    </>
  );
};

export const AiChat: FC = () => {
  if (
    !Statsig.getExperiment(EXP_AI_CHAT.ID).getValue(
      EXP_AI_CHAT.PARAMS.SHOW_FEATURE,
    )
  ) {
    return null;
  }

  return <AiChatWrapped />;
};
