import { typography } from "../foundations/typography";

const baseStyleContainer = {
  borderTopWidth: "1px",
  borderColor: "primary.400",
  _first: {
    borderTopWidth: "0",
  },
  _last: {
    borderBottomWidth: "0",
  },
};

const baseStyleButton = {
  _focus: {
    boxShadow: "none",
  },
  _hover: {
    bg: "transparent",
  },
  _disabled: {
    opacity: 1,
    color: "grayscale.600",
    cursor: "default",
  },
  px: "0",
  py: "16px",
  ...typography.textStyles.base,
};

const baseStylePanel = {
  pt: "0",
  px: "0",
  pb: "16px",
  ...typography.textStyles.caption,
};

const baseStyle = {
  container: baseStyleContainer,
  button: baseStyleButton,
  panel: baseStylePanel,
};

export const Accordion = { baseStyle };
