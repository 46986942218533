import { ForwardedRef, forwardRef, useRef, useState } from "react";
import { useStores } from "stores";
import { Box, Flex, Text, Button } from "@chakra-ui/react";
import Avatar from "components/avatar/Avatar";
import Tooltip from "components/tooltip/Default/Tooltip";
import Icon from "components/icon/Icon";
import { observer } from "mobx-react";
import { ButtonProps } from "components/button/buttonTypes";

export const AVATAR_BG = "avatarBG";

export const avatarBgColors = [
  "primary.800",
  "purple2.900",
  "ultramarine.900",
  "denim.900",
  "lake.900",
  "sky.900",
  "aqua.900",
  "green.900",
  "success.900",
  "olive.900",
  "rusty.900",
  "warning.900",
  "error.800",
  "error.900",
  "pink.900",
  "grayscale.800",
];

const ChangeAvatarButton = forwardRef(
  ({ onMouseEnter, ...props }: ButtonProps, ref: ForwardedRef<any>) => {
    return (
      <Button
        variant="link"
        size="md"
        padding={0}
        sx={{
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
        ref={ref}
        onMouseEnter={onMouseEnter}
        {...props}
      >
        <Text textStyle="buttonLinkS" color="primary.1000">
          Change color
        </Text>
      </Button>
    );
  },
);

const ChangeAvatarForm = observer(
  ({
    onChange,
    selectedAvatar,
  }: {
    onChange: (key: string) => void;
    selectedAvatar: string;
  }) => {
    const { userStore } = useStores();

    const getName = (color: string) => {
      return color !== selectedAvatar ? userStore?.currentUser : null;
    };

    const getIcon = (color: string) => {
      return color === selectedAvatar ? (
        <Icon
          name="status/Check/Check"
          size="20"
          iconStyles={{
            width: "20px",
            height: "20px",
            fill: "#FFF",
          }}
        />
      ) : undefined;
    };

    return (
      <Box>
        <Flex flexDirection="row" flexWrap="wrap" justifyContent="space-evenly">
          {avatarBgColors.map((value, i: number) => {
            return (
              <Box
                key={i}
                border="2px solid transparent"
                borderRadius="50%"
                height="fit-content"
                onClick={() => onChange(value)}
                _hover={{
                  border: "2px solid #000",
                  cursor: "pointer",
                }}
              >
                <Avatar
                  bgColor={value}
                  name={getName(value)}
                  icon={getIcon(value)}
                  size="md"
                />
              </Box>
            );
          })}
        </Flex>
      </Box>
    );
  },
);

const ChangeAvatarTooltip = () => {
  const { userStore } = useStores();
  const { uiState } = userStore;
  const ref = useRef(null);

  const [visible, setVisible] = useState<boolean>(false);
  const [selectedAvatar, setSelectedAvatar] = useState<string>(
    uiState[AVATAR_BG] || "#B0C0FB",
  );

  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const onChangeAvatar = async (color: string) => {
    await userStore.mergeUiState({
      [AVATAR_BG]: color,
    });
  };

  const handleChange = async (color: string) => {
    setSelectedAvatar(color);
    await onChangeAvatar(color);
    hide();
  };

  return (
    <Tooltip
      reference={ref}
      onClickOutside={hide}
      visible={visible}
      placement="bottom"
      maxWidth={240}
      content={
        <ChangeAvatarForm
          onChange={handleChange}
          selectedAvatar={selectedAvatar}
        />
      }
      interactive={true}
    >
      <ChangeAvatarButton ref={ref} onMouseEnter={() => show()} />
    </Tooltip>
  );
};

export default observer(ChangeAvatarTooltip);
