import { ValueOf } from "types/extensions";

type ColorValues<T> = {
  [Property in Extract<keyof T, number> as `${Property}`]: T[Property];
};

type ThemeColBase = {
  [Property in keyof typeof colors]: `${Property}.${keyof ColorValues<
    typeof colors[Property]
  >}`;
};

export type ThemeColors = ValueOf<ThemeColBase> | "white";

// New color names that will take over after the rebrand.
const semantic = {
  primary: {
    900: "#6447FB",
    800: "#B0C0FB",
    700: "#C8D3FD",
    600: "#D0D9FD",
    500: "#D7DFFC",
    400: "#DFE6FD",
    300: "#E8EDFE",
    200: "#EFF2FE",
    100: "#F8F9FF",
  },
  grayscale: {
    900: "#000000",
    800: "#444444",
    700: "#595959",
    600: "#7E7E7E",
    500: "#939393",
    400: "#A5A5A5",
    300: "#BDBDBD",
    200: "#EBEBEB",
    100: "#F8F8F8",
    0: "#FFFFFF",
  },
  success: {
    900: "#00B88C",
    200: "#B8E5DA",
    100: "#E4F6F2",
    50: "#F0FCF9",
  },
  error: {
    900: "#E91F04",
    800: "#F27968",
    600: "#F7AFA4",
    100: "#FDE9E6",
    50: "#FEF4F2",
  },
  warning: {
    900: "#F39C12",
    100: "#FEF6E8",
    50: "#FEFAF3",
  },
};

const codeColors = {
  crispBlue: {
    900: "#A8DFF5",
  },
  purple: {
    900: "#BF96FF",
  },
  yellow: {
    900: "#EFFF6D",
  },
  green: {
    900: "#48DE72",
  },
  pink: {
    900: "#FA53B7",
  },
  sky: {
    900: "#70BAFF",
    800: "#8DC8FFF2",
    600: "#CDE6FE",
    100: "#E6F3FF",
  },
  purple2: {
    900: "#A871FF",
    700: "#CBAAFF",
    600: "#E0CCFF",
    100: "#EFE6FF",
  },
  sand: {
    900: "#FFB930",
    800: "#FEC658",
    600: "#FBE7B7",
    400: "#FFEF9E",
    200: "#FFF7CF",
    100: "#FCEFCF",
  },
  ultramarine: {
    900: "#4000A3",
  },
  denim: {
    900: "#0064C2",
  },
  lake: {
    900: "#168CBC",
  },
  aqua: {
    900: "#0FC9D1",
    600: "#6FDFE4",
  },
  olive: {
    900: "#97A512",
  },
  rusty: {
    900: "#946200",
  },
  magenta: {
    900: "#FF00E5",
  },
  transparent: {
    0: "transparent",
  },
};

const gradientColors = {
  blackToPurple: {
    141100: "linear-gradient(141deg, #000000,#745AFF) 0 0/100% 100%",
    90100: "linear-gradient(90deg, #000000,#745AFF) 0 0/100% 100%",
    90400: "linear-gradient(90deg, #000000,#745AFF) 0 0/400% 100%",
  },
  purpleToBlue: {
    90100: "linear-gradient(90deg, #6447FB,#B0C0FB) 0 0/100% 100%",
    90400: "linear-gradient(90deg, #6447FB,#B0C0FB) 0 0/400% 100%",
  },
  purpleToPink: {
    90100: "linear-gradient(90deg, #6447FB,#C275FF,#FF8383) 0 0/100% 100%",
    90200: "linear-gradient(90deg, #6447FB,#C275FF,#FF8383) 0 0/200% 100%",
    90400:
      "linear-gradient(90deg, #6447FB,#C275FF,#FF8383,#6447FB) 0 0/400% 100%",
  },
  whiteToPink: {
    90100: "linear-gradient(90deg, #EFF2FE 0%, #FFF6F7 100%)",
    90200: "linear-gradient(90deg, #DBE2FF 0%, #FFE5E8 100%)",
  },
  purpleToWhite: {
    90: "linear-gradient(180deg, #EFF2FE 0%, #F8F9FF 100%)",
  },
  softGradient: {
    90: "linear-gradient(90deg, #EFF2FE 0%, #FFF6F7 100%)",
  },
  mediumGradient: {
    90: "linear-gradient(90deg, #DBE2FF 0%, #FFE5E8 100%)",
  },
  strongGradient: {
    90: "linear-gradient(90deg, #6447FB 0%, #FF6767 100%)",
  },
  timescaleGradient: {
    0: "linear-gradient(0deg, #6447FB 0%, #B844EA 100%)",
    90: "linear-gradient(90deg, #6447FB 0%, #B844EA 100%)",
  },
  popsqlGradient: {
    90: "linear-gradient(90deg, #EA8044 0%, #EA4476 100%)",
  },
};

const brandColors = {
  parquet: {
    100: "#50ABF1",
  },
};

export const colors: Record<string, Record<number, string>> = {
  ...semantic,
  ...codeColors,
  ...gradientColors,
  ...brandColors,
};

// Takes a theme string ("primary.900") and converts to hex ("#1472EC")
export const themeToHex = (fill: string): string => {
  const [color, value] = fill.split(".");
  return colors[color][Number(value)];
};
