import { ColumnDef } from "@tanstack/react-table";

export enum SampleDataType {
  IoT = "IoT",
  NYCTaxi = "NYCTaxi",
  Crypto = "Crypto",
}

export type Config = {
  [Key in keyof typeof SampleDataType]: SampleDataset;
};

export type DatabasePeak = {
  definition: ColumnDef<{ [key: string]: string | number }>[];
  data: { [key: string]: string | number }[];
};

export type SampleDataset = {
  name: string;
  logo: string;
  description: string;
  serviceName: string;
  tableName: string;
  tag: string;
  disabled: boolean;
  demoId: number;
  totalRecords: string;
  recordDescription: string;
  databasePeak: DatabasePeak;
  queryPerformanceExamples: QueryPerformanceExample[];
  costSavingExample: CostSavingExample;
};

export type CostSavingExample = {
  graph: GraphComparisonData;
  compressionPercentage: number;
  compressionFactor: number;
};

export type QueryPerformanceExample = {
  name: string;
  description: string;
  graph: GraphComparisonData;
  code: QueryCode;
};

export type GraphComparisonData = Array<{
  name: string;
  progress: number;
  value: number;
  unit: string;
}>;

export type QueryCode = {
  postgres: string;
  ht: string;
  htCompressed: string;
};
