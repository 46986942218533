import { createContext, useContext, ReactNode } from "react";
import { Flex, StyleProps, Image, Text, Box } from "@chakra-ui/react";
import { SVGFillerImage } from "./svgFillerTypes";
import V2Button from "components/button/Button";
import { ButtonProps } from "components/button/Button";
import Icon, { Icons } from "../icon/Icon";
import { useEffect, useState } from "react";
import { isNil } from "lodash";
export const IMAGE_SIZE = ["large", "medium", "small"] as const;

const SVGFillerContext = createContext<any>({ isOther: false });

export type ImageSize = (typeof IMAGE_SIZE)[number];

interface FillerProps extends StyleProps {
  imagePath?: SVGFillerImage;
  headline?: string;
  children?: ReactNode;
}

export interface SVGFillerProps extends FillerProps {
  variant?: "empty" | "other";
  headlineFontSize?: string;
}

type FillerButtonProps = Omit<
  ButtonProps,
  "children" | "icon" | "size" | "variant"
> & {
  iconName?: Icons | null;
  [x: string]: any;
};

interface InternalFillerProps extends FillerProps {
  variant?: "horizontal" | "vertical";
  text?: string;
  imageHeight?: string;
  imageWidth?: string;
}

type InternalCTAProps = Omit<ButtonProps, "size" | "variant">;

namespace Filler {
  export const SVGFiller = ({
    variant = "empty",
    imagePath = "error/Triangle.svg",
    headline = "Not found",
    headlineFontSize,
    children,
    ...rest
  }: SVGFillerProps) => {
    const [src, setSrc] = useState();
    const isOther = variant === "other";
    useEffect(() => {
      let isMounted = true;
      const getImage = async () => {
        import(`assets/illustrations/${imagePath}`).then((newSrc) => {
          if (isMounted) {
            setSrc(newSrc.default);
          }
        });
      };
      getImage();
      return () => {
        isMounted = false;
      };
    }, [imagePath]);

    return (
      <Flex
        direction="column"
        gap={isOther ? "24px" : "32px"}
        maxWidth={isOther ? "287px" : { base: "100%", lg: "360px" }}
        margin="88px auto 0 auto"
        {...rest}
      >
        <Image
          src={src}
          opacity={src ? 1 : 0}
          alt="empty state"
          data-cy="eon-empty-state" // Called 'eon-empty-state' b/c it used to be Eon SVGs.
          height={
            isOther ? "200px" : { base: "160px", lg: "200px", "3xl": "300px" }
          }
        />
        <Flex
          direction="column"
          alignItems="center"
          gap={isOther ? "16px" : "8px"}
        >
          <Text
            textStyle={isOther ? "h3" : "h4"}
            textAlign="center"
            textColor="grayscale.800"
            fontSize={headlineFontSize}
          >
            {headline}
          </Text>
          {children && (
            <Box
              display="grid"
              rowGap={isOther ? "24px" : "32px"}
              gridTemplateRows={`min-content`}
              gridTemplateColumns="1fr"
              textStyle={isOther ? "base" : "subtitle4"}
              justifyContent="center"
              justifyItems={"center"}
              textAlign="center"
              color="grayscale.800"
            >
              <SVGFillerContext.Provider value={{ isOther }}>
                {children}
              </SVGFillerContext.Provider>
            </Box>
          )}
        </Flex>
      </Flex>
    );
  };

  export const InternalFiller = ({
    variant = "vertical",
    imagePath = "error/Triangle.svg",
    headline = "Not found",
    text,
    imageHeight,
    imageWidth,
    children,
    ...rest
  }: InternalFillerProps) => {
    const [src, setSrc] = useState();
    const isVertical = variant === "vertical";
    useEffect(() => {
      let isMounted = true;
      const getImage = async () => {
        import(`assets/illustrations/${imagePath}`).then((newSrc) => {
          if (isMounted) {
            setSrc(newSrc.default);
          }
        });
      };
      getImage();
      return () => {
        isMounted = false;
      };
    }, [imagePath]);

    return (
      <Flex
        direction={isVertical ? "column" : "row"}
        gap="24px"
        alignItems="center"
        justifyContent={"flex-start"}
        {...rest}
      >
        <Image
          src={src}
          alt="empty state"
          height={imageHeight ? imageHeight : "120px"}
          width={imageWidth ? imageWidth : "120px"}
          objectFit="cover"
          objectPosition="50% 0%"
        />
        <Flex
          direction="column"
          gap="16px"
          alignItems={isVertical ? "center" : "flex-start"}
          justifyContent="flex-start"
        >
          <Flex
            direction="column"
            gap="4px"
            alignItems={isVertical ? "center" : "flex-start"}
            justifyContent="flex-start"
          >
            <Text
              as="span"
              textStyle="baseBold"
              color="grayscale.800"
              textAlign={isVertical ? "center" : "left"}
            >
              {headline}
            </Text>
            {text && (
              <Text
                as="span"
                color="grayscale.700"
                textStyle="base"
                textAlign={isVertical ? "center" : "left"}
              >
                {text}
              </Text>
            )}
            {children && (
              <Flex direction="row" gap="24px">
                {children}
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
    );
  };

  export const Button = ({
    children,
    iconName = "actions/Add/Add",
    variant = "primary",
    ...rest
  }: FillerButtonProps) => {
    const { isOther } = useContext(SVGFillerContext);
    return (
      <V2Button
        {...rest}
        variant={variant}
        margin="0px"
        size={isOther ? "md" : "lg"}
        width={
          isOther
            ? { base: "fit-content" }
            : { base: "100%", sm: "fit-content" }
        }
        maxWidth={isOther ? {} : { base: "100%" }}
        icon={isNil(iconName) ? undefined : <Icon name={iconName} />}
      >
        {children}
      </V2Button>
    );
  };

  export const InternalCTA = ({ children, ...props }: InternalCTAProps) => (
    <V2Button variant="link" size="md" padding={0} {...props}>
      {children}
    </V2Button>
  );
}

export default Filler;
