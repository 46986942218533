import { createContext, useState } from "react";

export const LeftNavContext = createContext(
  {} as {
    isLeftNavMinified: boolean;
    isMenuOpen: boolean;
    setLeftNavMinified: (state: boolean) => void;
    setIsMenuOpen: (state: boolean) => void;
  }
);

export const NavigationProvider = ({ children }: { children: JSX.Element }) => {
  const [isLeftNavMinified, setLeftNavMinified] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <LeftNavContext.Provider
      value={{
        isLeftNavMinified,
        setLeftNavMinified,
        isMenuOpen,
        setIsMenuOpen,
      }}
    >
      {children}
    </LeftNavContext.Provider>
  );
};
