import { Box, Text, Stack, StyleProps } from "@chakra-ui/react";
import Icon from "../icon/Icon";
import Container from "../container/Container";

const SNACKBAR_TYPE = ["success", "warning", "error"] as const;
type SnackbarType = (typeof SNACKBAR_TYPE)[number];

export interface SnackbarProps extends StyleProps {
  type: SnackbarType;
  onClick?: () => void;
  onClose?: () => void;
  buttonText?: string;
  children?: JSX.Element | string;
}

const snackbarConfig = {
  success: {
    Icon: <Icon name="status/Color/Success" fill="success.900" />,
    bg: "success.100",
  },
  warning: {
    Icon: <Icon name="status/Color/Alert" fill="warning.900" />,
    bg: "warning.100",
  },
  error: {
    Icon: <Icon name="status/Color/Error" fill="error.900" />,
    bg: "error.100",
  },
};

export const Snackbar = ({
  type,
  onClick,
  onClose,
  buttonText,
  children,
}: SnackbarProps) => {
  return (
    <Container
      data-cy={`snackbar-${type}`}
      padding="16px"
      borderRadius={["0px", "0px", "4px"]}
      minWidth={["100%", "100%", "376px"]}
      maxWidth={["100%", "100%", "480px"]}
      bg={snackbarConfig[type].bg}
    >
      <Stack direction="row" spacing="8px" alignItems="flex-start">
        <Box width="16px" paddingTop="2px">
          {snackbarConfig[type].Icon}
        </Box>
        <Stack
          direction={["column", "column", "row"]}
          alignItems="flex-start"
          gap="16px"
          width="100%"
          justifyContent={["flex-start", "space-between", "space-between"]}
        >
          <Text textStyle="base">{children}</Text>
          {onClick && buttonText ? (
            <Text
              textStyle="buttonLinkS"
              cursor={"pointer"}
              color="primary.900"
              paddingLeft={[0, 0, "8px"]}
              onClick={onClick}
            >
              {buttonText}
            </Text>
          ) : (
            <Icon
              name="actions/Close/Small"
              cursor="pointer"
              onClick={onClose}
            />
          )}
        </Stack>
      </Stack>
    </Container>
  );
};

export default Snackbar;
