import { sliderAnatomy as parts } from "@chakra-ui/anatomy";
import { ComponentStyleConfig } from "@chakra-ui/theme";
import type {
  SystemStyleFunction,
  PartsStyleFunction,
} from "@chakra-ui/theme-tools";

const baseStyleContainer: SystemStyleFunction = () => ({
  _disabled: {
    opacity: 1,
  },
});

const baseStyleTrack: SystemStyleFunction = () => ({
  borderRadius: "full",
  bg: "primary.300",

  // the three lines below are needed to avoid the glitch with the sub-pixel rendering
  position: "relative !important",
  top: "0 !important",
  transform: "initial !important",
  _disabled: {
    bg: "grayscale.200",
  },
});

const baseStyleThumb: SystemStyleFunction = () => ({
  borderRadius: "full",
  bg: "grayscale.0",
  border: "4px solid",
  borderColor: "primary.900",
  _focus: {
    boxShadow: "none",
    outline: "8px solid #B0C0FB33",
    transitionProperty: "transform, left",
    transitionDuration: (theme) => theme.transition.duration.fast,
    transitionTimingFunction: (theme) => theme.transition.easing.easeOut,
  },
  _disabled: {
    bg: "grayscale.200",
    borderColor: "grayscale.300",
  },
});

const baseStyleFilledTrack: SystemStyleFunction = ({ isDisabled }) => ({
  bg: isDisabled ? "grayscale.300" : "primary.900",

  // the three lines below are needed to avoid the glitch with the sub-pixel rendering
  position: "relative !important",
  top: "0 !important",
  transform: "initial !important",
});

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  container: baseStyleContainer(props),
  track: baseStyleTrack(props),
  thumb: baseStyleThumb(props),
  filledTrack: baseStyleFilledTrack(props),
});

const rangeStyleTrack: SystemStyleFunction = () => ({
  h: "12px",
  bg: "primary.300",
  _disabled: {
    bg: "grayscale.200",
  },
});

const rangeStyleFilledTrack: SystemStyleFunction = ({ isDisabled }) => ({
  bg: isDisabled ? "grayscale.300" : "primary.600",
});

const rangeStyle: PartsStyleFunction<typeof parts> = (props) => ({
  track: rangeStyleTrack(props),
  filledTrack: rangeStyleFilledTrack(props),
});

const sizeMd: PartsStyleFunction<typeof parts> = () => ({
  thumb: { w: "16px", h: "16px", outline: "0px" },
  track: {
    h: "5px",
  },
});

// @ts-expect-error this should be migrated to use `defineStyleConfig`
export const Slider: ComponentStyleConfig = {
  baseStyle,
  sizes: {
    md: sizeMd,
  },
  variants: {
    range: rangeStyle,
  },
};
