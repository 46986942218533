import dayjs from "dayjs";

export const formatRecoveryCodes = (codes: string[]) =>
  codes.map(
    (code) =>
      (
        code.substring(0, Math.floor(code.length / 2)) +
        "-" +
        code.substring(Math.floor(code.length / 2))
      ).replace(/--/, "-"), //to catch if actual recovery code has a dash already
  );

export const generateRecoveryCodeFile = ({
  codes,
  email,
}: {
  codes: string[];
  email: string;
}) => {
  const recoveryContent =
    `Timescale recovery codes
Account email: ${email}
Generated: ${dayjs().format("YYYY-MM-DD HH:mm:ss Z [UTC]")}

You can use your codes to log in if you lose access to your device.
Each code can only be used once.
If you generate new recovery codes, these ones will stop working.

` + formatRecoveryCodes(codes).join("\n");

  const file = new Blob([recoveryContent], { type: "text/plain" });
  const href = URL.createObjectURL(file);

  return href;
};
