import { Box, HTMLChakraProps } from "@chakra-ui/react";
import { ThemeColors, themeToHex } from "theme/foundations/colors";
import { Icons } from "./iconTypes";

export const ICON_SIZE = ["12", "16", "20", "24", "32", "48"] as const;
export type IconSize = (typeof ICON_SIZE)[number];
export interface IconProps
  extends Omit<HTMLChakraProps<"div">, "width" | "height"> {
  name: Icons;
  size?: IconSize;
  fill?: ThemeColors | "currentColor" | "null";
  iconStyles?: any;
}

const Icon: (props: IconProps) => JSX.Element | null = ({
  name,
  size = "16",
  fill = "grayscale.800",
  iconStyles,
  ...customStyles
}) => {
  const ImportedIcon = require(
    `!!@svgr/webpack?-svgo,+titleProp,+ref!../../assets/icons/${name}.svg`,
  ).default;

  return (
    <Box height={size + "px"} {...customStyles}>
      <ImportedIcon
        width={size}
        height={size}
        fill={
          fill === "null"
            ? ""
            : fill === "currentColor"
              ? "currentColor"
              : themeToHex(fill)
        }
        {...iconStyles}
      />
    </Box>
  );
};

export default Icon;

export type { Icons } from "./iconTypes";
