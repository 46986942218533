import {
  CircularProgress as ChakraCircularProgress,
  CircularProgressProps as ChakraCircularProgressProps,
} from "@chakra-ui/react";
import { ChakraSizes } from "types";

const PROGRESS_SIZE: Record<
  Extract<ChakraSizes, "xs" | "sm" | "md" | "lg">,
  string
> = {
  xs: "20px",
  sm: "40px",
  md: "80px",
  lg: "128px",
};

export interface CircularProgressProps extends ChakraCircularProgressProps {
  size?: Extract<ChakraSizes, "xs" | "sm" | "md" | "lg">;
}

export const CircularProgress = ({
  size = "md",
  ...customStyles
}: CircularProgressProps) => {
  return (
    <ChakraCircularProgress
      isIndeterminate
      thickness="0.375rem"
      capIsRound
      color="primary.800"
      trackColor="primary.300"
      size={PROGRESS_SIZE[size]}
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      height="fit-content"
      width="fit-content"
      {...customStyles}
    />
  );
};

export default CircularProgress;
