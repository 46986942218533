import { Flex, StyleProps, Text } from "@chakra-ui/react";
import Icon from "../icon/Icon";

export interface NotificationCardProps extends StyleProps {
  title: string;
  timestamp: string;
  isSeen: boolean;
  ctaPrimary?: React.ReactElement;
  ctaSecondary?: React.ReactElement;
  children: React.ReactElement | string | Array<React.ReactElement | string>;
}

export const NotificationCard = ({
  title,
  children,
  timestamp,
  isSeen: read,
  ctaPrimary,
  ctaSecondary,
}: NotificationCardProps) => {
  return (
    <Flex
      py="16px"
      px="8px"
      borderTop="1px solid"
      borderTopColor="primary.400"
      bgColor="grayscale.0"
      cursor="pointer"
      _hover={{ bgColor: "primary.200" }}
    >
      <Icon
        name="shapes/Circle"
        size="16"
        mr="4px"
        mt="1px"
        fill="primary.900"
        opacity={read ? 0 : 100}
      />

      <Flex flexDir="column" flex="1">
        <Flex alignItems="baseline">
          <Text textStyle="baseBold" color="grayscale.900" flex="1">
            {title}
          </Text>
          <Text textStyle="caption" color="grayscale.600">
            {timestamp}
          </Text>
        </Flex>

        <Text
          textStyle="caption"
          color="grayscale.700"
          mb={ctaPrimary || ctaSecondary ? "8px" : "0px"}
        >
          {children}
        </Text>
        <Flex gap="8px">
          {ctaPrimary}
          {ctaSecondary}
        </Flex>
      </Flex>
    </Flex>
  );
};
