import { ReactElement, useState } from "react";
import Tippy, { TippyProps } from "@tippyjs/react";
import { Instance, Placement } from "tippy.js";
import "tippy.js/dist/tippy.css";
import "../tippyTheme.css";
import { Box } from "@chakra-ui/react";
import transition from "theme/foundations/transition";

export const HOVER_THEME = [
  "default",
  "warning",
  "disabled",
  "notice",
  "success",
  "error",
  "none",
  "menu",
  "gradient",
] as const;

export type HoverTheme = (typeof HOVER_THEME)[number];

export interface HoverProps extends TippyProps {
  content: ReactElement | string;
  children?: ReactElement;
  theme?: HoverTheme;
  placement?: Placement;
}

export const Hover = ({
  content,
  children,
  theme = "none",
  placement = "bottom",
  ...rest
}: HoverProps) => {
  const [instance, setInstance] = useState<Instance>();

  const RenderContent = () => (
    <Box maxWidth={{ base: 280, sm: 240, md: 260, lg: 280 }}>{content}</Box>
  );

  const { easing, duration, durationMs } = transition;
  return (
    <Tippy
      content={instance && <RenderContent />}
      onCreate={setInstance}
      interactive={true}
      arrow={false}
      duration={duration.fast}
      placement={placement}
      moveTransition={`transform ${durationMs.fast} ${easing.easeOut}`}
      theme={`timescale-hover-${theme}`}
      animation="fade"
      disabled={!content}
      {...rest}
    >
      {children}
    </Tippy>
  );
};

export default Hover;
