import { useMutation } from "@apollo/react-hooks";
import { Box, Center, Flex, Heading, Link, Text } from "@chakra-ui/react";
import { CREATE_USER } from "api/mutations";
import Button from "components/button/Button";
import Divider from "components/divider/Divider";
import InputGroup from "components/input/InputGroup";
import PasswordInputGroup from "components/input/PasswordInputGroup";
import CircularProgress from "components/progress/CircularProgress";
import Checkbox from "components/selectors/Checkbox";
import registrationData from "data/registration.json";
import { Field, FieldProps, Form, Formik } from "formik";
import { capitalize } from "lodash";
import { observer } from "mobx-react";
import { lazy, useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { NavLink, useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { Statsig } from "statsig-react";
import { useStores } from "stores";
import { LIVE_TEST_SIGNUP_PROD_GOOGLE_RECAPTCHA } from "utils/config";
import { EXP_SIGNUP_COPY } from "utils/featuresGates";
import { ENV } from "utils/hostEnv";
import {
  IS_INVITED,
  NEW_USER_DATA,
  STORAGE_TYPE,
  storeDirectlyIntoStorage,
  UTM_PARAMS,
  WANTS_SUBSCRIPTION,
} from "utils/localStorage";
import * as Yup from "yup";
import { GoogleSSOButton } from "./GoogleSSOButton";
import { Container } from "./registerComponents";

const registrationValidationSchema = Yup.object().shape({
  fullName: Yup.string()
    .required("Required")
    .matches(/^\w+\s+\w+/, {
      message: "Please include first and last name",
      excludeEmptyString: true,
    }),
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Required"),
  password: Yup.string()
    .min(12, "Please enter a valid password")
    .required("Required"),
});

const USER_ALREADY_EXISTS = "a user with that email already exists";

const ForgotPassword = lazy(
  () => import(/*webpackPrefetch:true*/ "./ForgotPassword"),
);

const ResendEmail = lazy(
  () => import(/*webpackPrefetch:true*/ "./ResendEmail"),
);

const RegisterPage = () => {
  const { authStore, userStore } = useStores();
  const [confirmEmail, setConfirmEmail] = useState(false);
  const [checkEmail, setCheckEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [variables, setVariables] = useState({
    email: null,
    name: null,
    subscription: true,
  });
  const [recaptcha, setRecaptcha] = useState(false);
  const [isMultiUserMember, setIsMultiUserMember] = useState(false);
  let history = useHistory();

  const query = new URLSearchParams(
    window?.location?.search.replace("+", "_plusSign_"),
  );

  const isTestEmail = /^timescale-ci(\+\d+)?@timescale\.com$/i.test(
    variables?.email || "",
  );

  const queryStringEmail = query?.get?.("email")?.replace("_plusSign_", "+");
  const queryStringInviteHash = query?.get?.("hash");
  const queryStringId = query?.get?.("id");
  const isInvited = !!queryStringEmail && !!queryStringInviteHash;

  const { forgotPassword, errors } = authStore;
  const [createUser] = useMutation(CREATE_USER);

  const onChange = async (token: any) => {
    setRecaptcha(false);

    try {
      setLoading(true);
      const { data, errors } = await createUser({
        variables: {
          recaptchaToken: token,
          ...variables,
          // The `createUser` mutation accepts extra vars for users
          // who joined via a multi-user invite. This allows auto email-validation,
          // to make the login process smoother for them.
          ...(isMultiUserMember && { inviteHash: queryStringInviteHash }),
        },
      });

      if (errors?.[0].message === USER_ALREADY_EXISTS) {
        setLoading(false);
        return setCheckEmail(true);
      }

      if (data) {
        userStore.setThirdPartyIdentify(data.createUser);
        userStore.clearRegistrationForm();

        Statsig.logEvent(EXP_SIGNUP_COPY.EVENTS.NEW_USER_UNVERIFIED, null, {
          email: data.createUser?.email,
        });
      } else {
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const handleMultiUserMember = () => {
    setIsMultiUserMember(true);
  };

  useEffectOnce(() => {
    authStore.resetEmailFlow();

    const utmParams = {
      utm_campaign: query?.get?.("utm_campaign") || "",
      utm_content: query?.get?.("utm_content") || "",
      utm_keyword: query?.get?.("utm_keyword") || "",
      utm_medium: query?.get?.("utm_medium") || "",
      utm_source: query?.get?.("utm_source") || "",
      utm_term: query?.get?.("utm_term") || "",
    };

    storeDirectlyIntoStorage(
      UTM_PARAMS,
      utmParams,
      NEW_USER_DATA,
      STORAGE_TYPE.LOCAL,
    );

    storeDirectlyIntoStorage(
      IS_INVITED,
      isInvited,
      NEW_USER_DATA,
      STORAGE_TYPE.LOCAL,
    );

    return () => authStore.resetEmailFlow();
  });

  useEffect(() => {
    storeDirectlyIntoStorage(
      WANTS_SUBSCRIPTION,
      variables.subscription,
      NEW_USER_DATA,
      STORAGE_TYPE.LOCAL,
    );
  }, [variables]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//js.hsforms.net/forms/embed/v2.js";
    script.async = true;
    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "3409477",
          formId: ENV.HUBSPOT_FORM_ID,
          region: "na1",
          onFormReady: () => {
            const form =
              document.body.querySelector<HTMLElement>(".hbspt-form");

            if (form) {
              form.style.display = "none";
            }
          },
          onFormSubmitted: () => {
            setConfirmEmail(true);
            setLoading(false);
          },
        });
      }
    });

    document.body.appendChild(script);

    return () => {
      const form = document.querySelector(".hbspt-form");
      const hubspotFormScript = document.querySelector(
        'script[data-hubspot-rendered="true"]',
      );

      if (form) {
        document.body.removeChild(form);
      }

      if (hubspotFormScript) {
        document.body.removeChild(hubspotFormScript);
      }
    };
  }, []);

  if (loading) {
    return (
      <Container>
        <Center>
          <CircularProgress size="lg" />
        </Center>
      </Container>
    );
  }

  if (checkEmail) {
    return <ResendEmail />;
  }

  if (forgotPassword) {
    return <ForgotPassword />;
  }

  if (confirmEmail && isMultiUserMember) {
    history.push(`/?email=${queryStringEmail}&id=${queryStringId}`);
    return null;
  }

  if (confirmEmail) {
    history.push(`/signup/success`);
    return null;
  }

  return (
    <RegisterComponent
      errors={errors}
      forms={registrationData.signupFormValues}
      recaptcha={recaptcha}
      onChange={onChange}
      userStore={userStore}
      variables={variables}
      setVariables={setVariables}
      setRecaptcha={setRecaptcha}
      handleMultiUserMember={handleMultiUserMember}
      queryStringEmail={queryStringEmail}
      queryStringId={queryStringId}
      queryStringInviteHash={queryStringInviteHash}
      isTestEmail={isTestEmail}
    />
  );
};

const RegisterComponent = ({
  errors,
  forms,
  onChange,
  userStore,
  variables,
  setVariables,
  setRecaptcha,
  recaptcha,
  handleMultiUserMember,
  queryStringEmail,
  queryStringInviteHash,
  queryStringId,
  isTestEmail,
}: any) => {
  const { title, initialValues, buttonText } = forms;

  useEffectOnce(() => {
    if (queryStringEmail && queryStringInviteHash && queryStringId) {
      handleMultiUserMember();
    }
  });

  return (
    <Formik
      initialValues={{
        ...initialValues,
        email: queryStringEmail ?? "",
      }}
      validationSchema={registrationValidationSchema}
      onSubmit={async ({ fullName, email, password }, { setSubmitting }) => {
        setSubmitting(true);
        await setVariables({
          name: fullName,
          email: email,
          subscription: variables.subscription,
          password: password,
          passwordConfirm: password,
        });
        await userStore.setRegistrationForm({
          name: fullName,
          email: email,
        });
        await setRecaptcha(true);
        return;
      }}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <Container data-cy="register-form">
            <Box>
              <Heading as="h3" size="h3" mb="40px" textAlign="center">
                {title}
              </Heading>

              <Flex flexDirection="column" gap="24px">
                <Field name="fullName">
                  {({ field, meta }: FieldProps) => (
                    <InputGroup
                      data-cy="fullName"
                      error={meta.touched && meta.error ? meta.error : null}
                      placeholder="Your name"
                      label="Full name"
                      autoFocus={true}
                      {...field}
                    />
                  )}
                </Field>
                <Field name="email">
                  {({ field, meta }: FieldProps) => (
                    <InputGroup
                      data-cy="email"
                      error={
                        (meta.touched && meta.error) || errors?.message
                          ? capitalize(errors?.message) || meta.error
                          : null
                      }
                      placeholder="Your address"
                      label="Work email"
                      autoFocus={false}
                      {...field}
                    />
                  )}
                </Field>
                <Field name="password">
                  {({ field, meta }: FieldProps) => (
                    <PasswordInputGroup
                      data-cy="password"
                      label="Password"
                      autocomplete="on"
                      passwordType={values?.password && "create"}
                      passwordStrength={Math.max(
                        0.01,
                        Math.min(
                          100,
                          (Math.log(values?.password?.length + 1) /
                            Math.log(13)) *
                            81,
                        ),
                      )}
                      placeholder="12 characters minimum"
                      validationRuleText="12 characters minimum"
                      error={meta.touched && (errors?.message || meta.error)}
                      {...field}
                    />
                  )}
                </Field>
              </Flex>

              <Checkbox
                mt="10px"
                mb="40px"
                isChecked={variables.subscription}
                onChange={(e) =>
                  setVariables({
                    ...variables,
                    subscription: e.target.checked,
                  })
                }
              >
                I'd like to receive Timescale updates
              </Checkbox>

              {!recaptcha ? (
                <>
                  <Button
                    type="submit"
                    size="lg"
                    maxW="unset"
                    w="100%"
                    mt="24px"
                    mb="16px"
                    isLoading={isSubmitting}
                    data-cy="account-signup"
                  >
                    {buttonText}
                  </Button>

                  <GoogleSSOButton textType="signup_with" />
                </>
              ) : (
                <Flex>
                  <ReCAPTCHA
                    id="reCAPTCHA"
                    theme="dark"
                    sitekey={
                      isTestEmail
                        ? LIVE_TEST_SIGNUP_PROD_GOOGLE_RECAPTCHA
                        : process.env.REACT_APP_GOOGLE_TEST_RECAPTCHA ||
                          ENV.GOOGLE_RECAPTCHA
                    }
                    onChange={onChange}
                  />
                </Flex>
              )}
              <Divider m="32px 0" color="primary.400" />

              <Text textAlign="center" textStyle="caption" mb="16px">
                Already have an account?
                <Link as={NavLink} pl="4px" to="/" textStyle="captionLink">
                  Log in
                </Link>
              </Text>
            </Box>
            <Text textAlign="center" textStyle="caption">
              By clicking "Start your free trial", I agree to the Timescale
              <Link
                data-cy="tos"
                textStyle="captionLink"
                p="0 4px"
                href="https://www.timescale.com/legal/timescale-cloud-terms-of-service"
                isExternal
              >
                Terms of Service
              </Link>
              and acknowledge the
              <Link
                data-cy="pp"
                textStyle="captionLink"
                pl="4px"
                href="https://www.timescale.com/legal/privacy"
                isExternal
              >
                Privacy Policy.
              </Link>
            </Text>
          </Container>
        </Form>
      )}
    </Formik>
  );
};

export default observer(RegisterPage);
