import { ComponentStyleConfig } from "@chakra-ui/theme";

export const Link: ComponentStyleConfig = {
  baseStyle: ({ theme, disabled, textStyle }) => ({
    ...theme.textStyles.buttonLinkS,
    ...theme.textStyles[textStyle],
    color: disabled ? "grayscale.500" : "primary.900",
    cursor: disabled ? "default" : "pointer",
    width: "fit-content",
    _focus: {
      boxShadow: "none",
    },
  }),
};
