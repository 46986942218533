import { useEffect, useState, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { Redirect, useHistory } from "react-router-dom";
import { Box, Flex, Link, Text } from "@chakra-ui/react";
import { useStores, useStoresUntyped } from "stores";
import Modal, { ModalButtons } from "components/modal/Modal";
import Button from "components/button/Button";
import { QRCodeSVG } from "qrcode.react";
import { authenticator } from "otplib";
import { useEffectOnce } from "react-use";
import ReadOnlyInput from "components/input/ReadOnlyInput";
import DigitInputGroup from "components/input/DigitInputGroup";
import RecoveryCodeDisplay from "components/authentication/RecoveryCodeDisplay";
import InputGroup from "components/input/InputGroup";
import { InputType, MfaChallengeProps } from "pages/account/mfa/mfaTypes";
import { useChallenge } from "pages/account/mfa/mfaHooks";

/***************************
 * Reused components/hooks *
 ***************************/

export const MfaChallenge = ({
  shouldVerifyOnClick = false,
  canUseRecoveryCode = false,
  verify,
  authVars,
  onVerify,
  onConfirm = () => {},
  onCancel = () => {},
  confirmText = "Next",
  appText,
  recoveryText,
  appSubtext,
  recoverySubText,
  isDangerous,
  size = "sm",
  children, //render prop used to make custom buttons
}: MfaChallengeProps) => {
  const [authCode, setAuthCode] = useState("");
  const [inputType, setInputType] = useState<InputType>("digits");
  const [isConfirmed, setIsConfirmed] = useState(false);
  const { hasError, setHasError, isVerified, isSubmitting, isComplete } =
    useChallenge({
      verify,
      authVars,
      authCode,
      shouldVerify: !shouldVerifyOnClick || isConfirmed,
      inputType,
    });

  const handleDigitChange = (value: string) => {
    if (authCode !== value) {
      setAuthCode(value);
      setHasError(false);
      setIsConfirmed(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newAuthCode = e.target.value.substring(0, 11).toUpperCase();
    if (authCode !== newAuthCode) {
      setAuthCode(e.target.value.substring(0, 11).toUpperCase());
      setHasError(false);
      setIsConfirmed(false);
    }
  };

  const handleConfirm = (isVerified: boolean) => {
    setIsConfirmed(true);
    onConfirm(isVerified);
  };

  useEffect(() => {
    if (isVerified && onVerify) {
      onVerify();
    }
  }, [isVerified, onVerify]);

  const [text, subText] = useMemo(
    () => [
      (inputType === "digits" && appText) ||
        (inputType === "recoveryCode" && recoveryText),
      (inputType === "digits" && appSubtext) ||
        (inputType === "recoveryCode" && recoverySubText),
    ],
    [inputType, appText, appSubtext, recoverySubText, recoveryText],
  );

  return (
    <Flex direction="column" gap="32px" alignItems={"stretch"} width="100%">
      {text && (
        <Text as="span" textAlign={"center"}>
          {text}
        </Text>
      )}
      <Flex direction="column" gap="8px" alignItems="stretch">
        {subText && (
          <Text as="span" textAlign="center">
            {subText}
          </Text>
        )}
        {inputType === "digits" ? (
          <DigitInputGroup
            value={authCode}
            onChange={handleDigitChange}
            hasError={hasError}
            size={size}
            length={6}
          />
        ) : (
          <InputGroup
            width="100%"
            value={
              authCode.length < 6
                ? authCode.toUpperCase()
                : `${authCode.substring(0, 5)}-${authCode.substring(5, 15)}`
                    .split(/-+/)
                    .join("-")
                    .substring(0, 11)
                    .toUpperCase()
            }
            autoFocus
            textAlign="center"
            onChange={handleInputChange}
            error={
              hasError
                ? "The code you entered is incorrect or has already been used."
                : ""
            }
          />
        )}
      </Flex>
      {children ? (
        children({
          confirm: () => handleConfirm(isVerified),
          isSubmitting,
          hasError,
          isComplete,
        })
      ) : (
        <ModalButtons
          mt="0px"
          isDangerous={isDangerous}
          primaryCTA={{
            onClick: () => handleConfirm(isVerified),
            text: confirmText,
            isLoading: isSubmitting,
            isDisabled: hasError || isSubmitting || !isComplete,
          }}
          secondaryCTA={{
            onClick: onCancel,
            text: "Cancel",
          }}
        />
      )}
      {canUseRecoveryCode && (
        <Button
          variant={"link"}
          textStyle={"captionLink"}
          maxW={"unset"}
          alignSelf="center"
          onClick={() => {
            setAuthCode("");
            setInputType(inputType === "digits" ? "recoveryCode" : "digits");
          }}
        >
          {inputType === "digits"
            ? "Use recovery code instead"
            : "Use two-factor authentication code instead"}
        </Button>
      )}
    </Flex>
  );
};

export const BasicMfaChallenge = observer(
  ({ onClose }: { onClose?: () => void }) => {
    const { authStore } = useStoresUntyped();
    const history = useHistory();
    return (
      <Modal
        title={modalContent[3].title}
        isOpen={true}
        onClose={onClose ? onClose : () => history.push("/dashboard/account")}
      >
        <MfaChallenge
          verify={authStore.getMfaToken}
          appText={modalContent[3].text}
          recoveryText={modalContent[3].altText}
          canUseRecoveryCode
        />
      </Modal>
    );
  },
);

export const modalContent = [
  {
    title: "Connect to an authenticator app",
    confirmText: "Next",
    isCancelable: true,
  },
  {
    title: "Connect to an authenticator app",
    confirmText: "Next",
    isCancelable: true,
  },
  {
    title: "Save your recovery codes",
    text: "Recovery codes are used to access your account if you lose access to your device and cannot receive two-factor authentication codes.",
    confirmText: "Ok, I saved my recovery codes",
    isCancelable: false,
  },
  {
    title: "Two-factor authentication",
    text: "Enter the verification code on your app to proceed to regenerate recovery codes.",
    altText: "Enter recovery code to confirm.",
    confirmText: "Next",
    isCancelable: false,
  },
];

/************************
 * "Add MFA" components *
 ************************/
export const pageInfo = modalContent.slice(0, 3);

const AuthButtons = ({
  page,
  onConfirm,
  isLoading,
  isDisabled,
  isDangerous,
}: {
  page: number;
  onConfirm: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  isDangerous?: boolean;
}) => {
  const { confirmText, isCancelable } = pageInfo[page - 1]; //index for array 1 less than page #
  const history = useHistory();
  return isCancelable ? (
    <ModalButtons
      isDangerous={isDangerous}
      primaryCTA={{
        text: confirmText,
        onClick: onConfirm,
        isLoading,
        isDisabled,
      }}
      secondaryCTA={{
        text: "Cancel",
        onClick: () => {
          history.push("/dashboard/account");
        },
      }}
      mt="0px"
    />
  ) : (
    <Button onClick={onConfirm} justifySelf="center">
      {confirmText}
    </Button>
  );
};

const QrCodeDisplay = ({ authURL }: { authURL: string }) => (
  <QRCodeSVG value={authURL} size={144} />
);

const QR_CODE = "qrCode";
const STRING_CODE = "stringCode";
const CODE_TYPE = [QR_CODE, STRING_CODE] as const;
type CodeType = (typeof CODE_TYPE)[number];

//Add MFA page 1
export const QRPanel = observer(() => {
  const [codeType, setCodeType] = useState<CodeType>("qrCode");
  const history = useHistory();
  const { userStore, authStore } = useStores();
  const switchContent: Record<
    CodeType,
    { switchType: CodeType; switchText: string; linkText: string }
  > = {
    qrCode: {
      switchType: STRING_CODE,
      switchText: "Unable to scan?",
      linkText: "Enter this code instead",
    },
    stringCode: {
      switchType: QR_CODE,
      switchText: "Unable to use code?",
      linkText: "Scan the QR code instead",
    },
  };
  const { switchType, switchText, linkText } = switchContent[codeType];

  useEffectOnce(() => {
    authStore.setMfaSecret({ secret: authenticator.generateSecret() });
  });

  //Note: 'Timescale' at the front of this string has to
  //equal exactly the value in the 'issuer' query parameter at the end
  const qrString = `otpauth://totp/Timescale:${userStore.currentEmail}?secret=${authStore.mfaSecret}&issuer=Timescale`;

  return (
    <Flex direction="column" gap="32px" alignItems="center" maxWidth={"320px"}>
      <Text as="span">
        Download the free{" "}
        <Link target="_blank" href="https://googleauthenticator.net/">
          Google Authenticator app
        </Link>
        , add a new account, then{" "}
        {codeType === QR_CODE ? "scan this QR" : "use this"} code to set up your
        account.
      </Text>
      {codeType === QR_CODE ? (
        <Box
          p="8px"
          border="1px solid"
          borderColor={"grayscale.500"}
          borderRadius="4px"
          width="fit-content"
          data-cy="qr-code"
        >
          <QrCodeDisplay authURL={qrString} />
        </Box>
      ) : (
        <ReadOnlyInput
          isCode={false}
          sx={{
            "& input": {
              textStyle: "codeS",
              textAlign: "center",
              letterSpacing: "3.5px",
            },
          }}
          value={authStore.mfaSecret}
          width="100%"
        />
      )}
      <Flex direction="column" gap="16px">
        <AuthButtons
          page={1}
          onConfirm={() => {
            history.push("/dashboard/account/mfa/add_mfa/2");
          }}
        />
        <Text as="span" textStyle="caption">
          {switchText}{" "}
          <Link
            onClick={() => {
              setCodeType(switchType);
            }}
            textStyle="captionLink"
          >
            {linkText}
          </Link>
          .
        </Text>
      </Flex>
    </Flex>
  );
});

// add MFA page 2
export const AddMfaChallenge = observer(() => {
  const { authStore } = useStores();
  const history = useHistory();

  if (!authStore.mfaSecret && authStore.recoveryCodes.length === 0) {
    return <Redirect to="/dashboard/account/mfa/add_mfa/1" />;
  }

  return (
    <MfaChallenge
      verify={authStore.addMfa}
      onVerify={() => history.push("/dashboard/account/mfa/add_mfa/3")}
      onCancel={() => history.push("/dashboard/account")}
      appText="Enter the verification code to confirm your device."
    />
  );
});

// add MFA page 3
export const RecoveryCodes = observer(() => {
  const { authStore, userStore, notificationStore } = useStores();
  const history = useHistory();

  return (
    <Flex direction="column" gap="24px" alignItems={"center"}>
      {authStore.recoveryCodes.length ? (
        <>
          <Text as="span" color={"grayscale.800"}>
            {modalContent[2].text}
          </Text>
          <RecoveryCodeDisplay
            codes={authStore.recoveryCodes}
            email={userStore.currentEmail}
          />
        </>
      ) : (
        <Text as="span" color={"grayscale.800"}>
          Oops, we're unable to show your recovery codes, try refreshing the
          page.
        </Text>
      )}
      <AuthButtons
        page={3}
        onConfirm={() => {
          authStore.removeRecoveryCodes();
          notificationStore.showSuccessToaster(
            "Added two-factor authentication successfully",
          ); //Atypical use of notification store.  Only b/c authStore call happens in previous modal step
          history.push("/dashboard/account");
        }}
      />
    </Flex>
  );
});

/***************************
 * "Remove MFA" components *
 ***************************/

export const RemoveMfa = observer(() => {
  const { authStore } = useStores();
  const history = useHistory();

  const goToAccount = () => {
    history.push("/dashboard/account");
  };

  return (
    <Modal title={"Are you sure?"} isOpen={true} onClose={goToAccount}>
      <MfaChallenge
        shouldVerifyOnClick
        canUseRecoveryCode
        verify={authStore.removeMfa}
        onVerify={goToAccount}
        appText="Two-factor authentication will be removed from your account"
        recoveryText="Two-factor authentication will be removed from your account"
        isDangerous
        confirmText="Remove"
        onCancel={goToAccount}
      />
    </Modal>
  );
});

/******************************************
 * "Regenerate recovery codes" components *
 ******************************************/

export const GenerateRecoveryCodes = observer(() => {
  const { authStore, userStore } = useStores();
  const hasCodes = authStore.recoveryCodes.length > 0;
  const history = useHistory();
  const goToAccount = () => {
    authStore.removeRecoveryCodes();
    history.push("/dashboard/account");
  };
  return (
    <Modal
      title={modalContent[hasCodes ? 2 : 3].title}
      isOpen={true}
      onClose={goToAccount}
    >
      {hasCodes ? (
        <Flex direction="column" alignItems={"center"}>
          <Flex direction="column" gap="24px" alignItems={"center"}>
            {modalContent[2].text}
            <RecoveryCodeDisplay
              codes={authStore.recoveryCodes}
              email={userStore.currentEmail}
            />
          </Flex>
          <Button mt="32px" onClick={goToAccount}>
            {modalContent[2].confirmText}
          </Button>
        </Flex>
      ) : (
        <MfaChallenge
          verify={authStore.regenerateMfaRecoveryCodes}
          appText={modalContent[3].text}
          recoveryText={modalContent[3].altText}
          canUseRecoveryCode
          onCancel={goToAccount}
        />
      )}
    </Modal>
  );
});
