import { Breadcrumb } from "@sentry/react";
import _ from "lodash";

// Keys that we want to scrub or redact.
// Notice that "pass" will match "password", "key" will match "apikey", etc.
// This should be on top of a logger's standard scrubbing.
const KEYS_TO_SCRUB = [
  "key",
  "pass",
  "pword",
  "pwd",
  "secret",
  "private",
  "ssn",
  "ccard",
  "creditcard",
  "cvv",
  "token",
  "credential",
  "access",
  "auth",
];

const REDACTED = "********";

// Takes an object and flattens the keys into an array.
// https://stackoverflow.com/questions/47062922/how-to-get-all-keys-with-values-from-nested-objects
//
// Takes a nested JS object and returns a flat list of keys. These keys will be
// used to find values that need to be redacted. Example (see below for the
// example object):
//
// [
//     "email",
//     "password",
//     "config.site",
//     "config.apiKey",
//     "billing.0.ccard",
//     "billing.0.cvv2",
//     "awsCredentials.0",
//     "awsCredentials.1",
//     "awsCredentials.2"
// ]

const keyify = (object: any, prefix = ""): string[] =>
  Object.keys(object).reduce((res: any, el: any) => {
    if (typeof object[el] === "object" && object[el] !== null) {
      return [...res, ...keyify(object[el], prefix + el + ".")];
    }
    return [...res, prefix + el];
  }, []);

// Takes a Breadcrumb and sanitizes it before logging. This is on because
// `attachBreadcrumbs: { includeVariables: true }` is set at the SentryLink
// for ApolloClient.
//
// Turns these variables:
//
// {
//     "email": "mocktester@timescale.com",
//     "password": "hunter2",
//     "config": {
//         "site": "timescale.com",
//         "apiKey": "954ce15a-7127-4377-928d-f77fcfd83511"
//     },
//     "billing": [{
//         "ccard": "4242 4242 4242 4242",
//         "cvv2": 123
//     }],
//     "awsCredentials": ["1", 2, true, null, undefined, 0, false, "", "0"]
// }
//
// into:
//
// {
//     "email": "mocktester@timescale.com",
//     "password": "********",
//     "config": {
//         "site": "timescale.com",
//         "apiKey": "********"
//     },
//     "billing": [{
//         "ccard": "********",
//         "cvv2": "********"
//     }],
//     "awsCredentials": [
//        "********",
//        "********",
//        "********",
//        null,
//        null,
//        0,
//        false,
//        "",
//       "********"
//     ]
// }

export const breadcrumbSanitize = (breadcrumb: Breadcrumb) => {
  // Remove extra "fetch" breakcrumb, as we now have added "graphQL".
  if (breadcrumb.category === "fetch") {
    const url = breadcrumb.data?.url ?? "";
    // Because we use `/query` over `/graphql` in fetch url.
    if (url.includes("/query")) return null;
  }

  // Sanitize variables of sensitive keys.
  if (breadcrumb.data && breadcrumb.data.variables) {
    try {
      const breadcrumbVariables = JSON.parse(
        breadcrumb?.data?.variables || "{}",
      );
      keyify(breadcrumbVariables).forEach((key: string) => {
        KEYS_TO_SCRUB.some((value) => {
          if (
            key.toLowerCase().includes(value.toLowerCase()) &&
            !!_.get(breadcrumbVariables, key)
          ) {
            _.set(breadcrumbVariables, key, REDACTED);
            return true;
          }
          return false;
        });
      });
      breadcrumb.data.variables = breadcrumbVariables;
    } catch (_e) {
      throw new Error(
        `Error in log sanitization with operation: ${breadcrumb.data?.operationName}`,
      );
    }
  }
  return breadcrumb;
};
