import {
  Box,
  CloseButton,
  Flex,
  forwardRef,
  StyleProps,
  useBreakpointValue,
} from "@chakra-ui/react";
import Tippy, { TippyProps } from "@tippyjs/react";
import { createContext, ReactElement, useContext, useState } from "react";
import transition from "theme/foundations/transition";
import { followCursor, Instance, Placement } from "tippy.js";
import "tippy.js/dist/tippy.css";
import "../tippyTheme.css";

export interface TooltipProps extends Omit<TippyProps, "content"> {
  content: ReactElement | string | ((instance: Instance) => ReactElement);
  children?: ReactElement;
  canDismiss?: boolean;
  icon?: JSX.Element;
  title?: string | ReactElement;
  placement?: Placement;
  isDisabled?: boolean;
  maxWidth?: number | string;
  interactive?: boolean;
  containerStyles?: StyleProps;
  onDismiss?: () => void;
}

const DisabledContext = createContext<any>({ isDisabled: false });

export const Tooltip = ({
  content,
  children,
  canDismiss,
  icon,
  title,
  onDismiss,
  isDisabled = false,
  placement = "top",
  maxWidth,
  interactive = false,
  containerStyles,
  ...rest
}: TooltipProps) => {
  const [instance, setInstance] = useState<Instance>();
  const maxWidthDefault = useBreakpointValue({
    base: 280,
    sm: 240,
    md: 260,
    lg: 280,
  });

  const RenderContent = () => (
    <Box maxWidth={maxWidth || maxWidthDefault}>
      {canDismiss && (
        <CloseButton
          data-tooltip-button
          float="right"
          size="sm"
          onClick={(e) => {
            e.stopPropagation();
            instance!.hide();
            onDismiss?.();
          }}
        />
      )}
      {(icon || title) && (
        <Flex marginBottom={2} alignItems="center">
          {icon && <Box marginRight={2}>{icon}</Box>}
          {title && <Box textStyle="captionBold">{title}</Box>}
        </Flex>
      )}
      <Box color="grayscale.800">
        {typeof content === "function" ? content(instance!) : content}
      </Box>
    </Box>
  );
  const { easing, duration, durationMs } = transition;
  return (
    <DisabledContext.Provider value={{ isDisabled }}>
      <Box display="inline-block" {...containerStyles}>
        <Tippy
          plugins={[followCursor]}
          content={instance && <RenderContent />}
          onCreate={setInstance}
          interactive={interactive}
          arrow={true}
          duration={duration.fast}
          placement={placement}
          moveTransition={`transform ${durationMs.fast} ${easing.easeOut}`}
          theme="timescale-light"
          animation="fade"
          disabled={!content || isDisabled}
          zIndex={9000}
          maxWidth={Number(maxWidth || maxWidthDefault) ?? 350} // 350 is the tippy default
          // showOnCreate // Uncomment to force showing Tippy for debugging
          {...rest}
          {...(interactive && { appendTo: document.body })}
        >
          {children}
        </Tippy>
      </Box>
    </DisabledContext.Provider>
  );
};

interface TooltipTextProps extends StyleProps {
  children: string | JSX.Element | Array<string | JSX.Element>;
}

const createLinearGradient = (color: string) => {
  const parsedColor = color.replace(".", "-");
  return `repeating-linear-gradient(90deg, var(--chakra-colors-${parsedColor}), var(--chakra-colors-${parsedColor}) 3px, rgba(0,0,0,0) 3px, rgba(0,0,0,0) 6px) 6`;
};

export const TooltipText = forwardRef(
  ({ children, ...rest }: TooltipTextProps, ref) => {
    const { isDisabled } = useContext(DisabledContext);
    return (
      <Box
        width="fit-content"
        {...rest}
        ref={ref}
        sx={{
          ...(isDisabled ? { color: "grayscale.500" } : {}),
          borderImage: createLinearGradient(
            isDisabled ? "grayscale.500" : "grayscale.700",
          ), // Gives same visual effect as "text-decoration-style: dashed;"
          borderBottomWidth: "1px",
          borderColor: "grayscale.700",
          paddingBottom: "2px",
          paddingTop: "2px", //To center text in flex:row situations
          _hover: isDisabled
            ? {}
            : {
                borderImage: createLinearGradient("primary.900"),
                borderBottomWidth: "2px",
                paddingBottom: "1px",
              },
        }}
      >
        {children}
      </Box>
    );
  },
);

export default Tooltip;
