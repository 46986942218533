import { Text, Stack, StyleProps } from "@chakra-ui/react";
import { PasswordInput, PasswordInputProps } from "./PasswordInput";
import PasswordStrength, {
  Strength,
} from "components/passwordStrength/PasswordStrength";

export interface PasswordInputGroupPropsBase extends PasswordInputProps {
  value?: string;
  label?: string;
  error?: string;
  isDisabled?: boolean;
  layoutProps?: object;
  name?: string;
  validationRuleText?: string;
}

export interface PasswordInputGroupPropsEnter
  extends PasswordInputGroupPropsBase {
  passwordType: "enter";
  passwordStrength?: never;
  threshold?: never;
}

export interface PasswordInputGroupPropsCreate
  extends PasswordInputGroupPropsBase {
  passwordType: "create";
  passwordStrength: number | Strength;
  threshold?: number;
}

type PasswordInputGroupProps =
  | PasswordInputGroupPropsEnter
  | PasswordInputGroupPropsCreate;

export const PasswordInputGroup = ({
  value,
  label,
  error,
  passwordType = "enter",
  passwordStrength,
  validationRuleText,
  threshold,
  isDisabled,
  layoutProps,
  name = "",
  ...rest
}: PasswordInputGroupProps & { layoutProps?: StyleProps }) => {
  return (
    <Stack direction="column" spacing="8px" {...layoutProps}>
      {label && (
        <Text
          as="span"
          textStyle="base"
          alignSelf="flex-start"
          color={isDisabled ? "grayscale.500" : "grayscale.700"}
        >
          {label}
        </Text>
      )}
      <PasswordInput
        isInvalid={Boolean(error)}
        isDisabled={isDisabled}
        name={name}
        {...(typeof value === "string" ? { value } : {})}
        {...rest}
      />
      {(passwordType === "enter" && error) || value?.length === 0 ? (
        <Text
          textStyle="caption"
          color="error.900"
          margin={0}
          data-cy={`error-messages-${name}`}
        >
          {error}
        </Text>
      ) : passwordType === "create" && value ? (
        <PasswordStrength
          strength={passwordStrength || "weak"}
          threshold={threshold}
          validationRuleText={validationRuleText}
        />
      ) : null}
    </Stack>
  );
};

export default PasswordInputGroup;
