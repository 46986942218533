import { ComponentStyleConfig } from "@chakra-ui/theme";
import { typography } from "../foundations/typography";

const baseStyle = {
  container: {
    boxShadow: "none",
    borderRadius: 4,
    fontSize: typography.fontSizes.sm,
    width: "fit-content",
  },
  closeButton: {
    opacity: "1",
    _hover: {
      opacity: "1",
    },
    _focus: {
      boxShadow: "none",
      background: "transparent",
      opacity: "1",
    },
  },
};

export const Tag: ComponentStyleConfig = {
  baseStyle,
};
