import { extendTheme } from "@chakra-ui/react";
import styles from "./styles";
import foundations from "./foundations";
import components from "./components";
import "./snow.css";

export const breakpoints = {
  sm: "480px",
  md: "768px",
  lg: "992px",
  xl: "1280px",
  "2xl": "1440px",
  "3xl": "1920px",
};

export const timescaleTheme = extendTheme(
  styles,
  foundations,
  { components },
  { breakpoints },
);
