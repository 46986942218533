import { Divider as ChakraDivider, StyleProps } from "@chakra-ui/react";

export const DIVIDER_COLORS = ["grayscale.200", "primary.400"] as const;
export const DIVIDER_ORIENTATION = ["horizontal", "vertical"] as const;

export interface DividerProps extends StyleProps {
  orientation?: (typeof DIVIDER_ORIENTATION)[number];
  color?: (typeof DIVIDER_COLORS)[number];
}

export const Divider = ({
  orientation,
  color = "grayscale.200",
  ...customStyles
}: DividerProps) => (
  <ChakraDivider
    orientation={orientation}
    borderColor={color}
    opacity="1"
    height={orientation === "vertical" ? "100%" : "initial"}
    width={orientation === "vertical" ? "0" : "unset"}
    {...customStyles}
  />
);

export default Divider;
