import { ComponentStyleConfig } from "@chakra-ui/theme";
import { SystemStyleObject } from "@chakra-ui/react";

const getCheckedStyles = (color: string) => ({
  _checked: {
    background: "white",
    borderColor: color,
    color: color,
    _hover: {
      background: "white",
      borderColor: color,
      color: color,
    },
    _focus: {
      boxShadow: "none",
    },
  },
});

const baseStyleContainer: SystemStyleObject = {
  _disabled: { cursor: "default" },
};

const baseStyleControl: SystemStyleObject = {
  borderColor: "grayscale.700",
  transitionProperty: "opacity",
  transitionDuration: (theme) => theme.transition.duration.extraFast,
  transitionTimingFunction: (theme) => theme.transition.easing.easeOut,
  ...getCheckedStyles("primary.900"),
  _disabled: {
    borderColor: "grayscale.300",
    background: "white",
    ...getCheckedStyles("grayscale.300"),
  },
};

const baseStyleLabel: SystemStyleObject = {
  _disabled: { opacity: 1 },
};

// @ts-expect-error this should be migrated to use `defineStyleConfig`
export const Radio: ComponentStyleConfig = {
  baseStyle: {
    container: baseStyleContainer,
    control: baseStyleControl,
    label: baseStyleLabel,
  },
};
